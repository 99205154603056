import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { TProductCategory } from '@lib/core/products/types';
import { selectRetailerSlug } from '@lib/core/retailers/selectors/retailer';
import { IRetailerDetail } from '@lib/core/retailers/types/retailerDetail';
import {
  REQUEST_ERROR_400,
  REQUEST_ERROR_422,
  REQUEST_ERROR_502,
  REQUEST_ERROR_504,
  REQUEST_METHODS,
} from '@lib/core/service/consts';
import { createTypedAsyncThunk } from '@lib/core/service/createTypedAsyncThunk';
import { requestWithKeysAsOptions } from '@lib/core/service/requests/request';
import { prependBasename } from '@lib/core/service/utils';
import { history } from '@lib/core/service/utils/Navigator';
import { selectUserBestMatchCharacterByProductCategory } from '@lib/core/users/selectors/characters';
import { selectFidelityCardId } from '@lib/core/users/selectors/fidelityCard';
import { actionGetUserCharacters, actionResetUserCharactersSlice } from '@lib/core/users/slices/characters';
import { actionGetDietaryPreferenceListData } from '@lib/core/users/slices/dietaryPreference';
import { fidelityCardApiUrlCreator } from '@lib/core/users/slices/urls';
import MixpanelTracker from '@lib/tools/dat/mixpanel';
import { resetCurrentProductTip } from '@lib/tools/productTip/slices';
import { parseError } from '@lib/tools/shared/helpers';
import { FidelityModalsTypes } from '@lib/tools/shared/helpers/consts';
import { PAGES } from '@lib/tools/views/urls';

export interface IFidelityCardResponse {
  fidelity_card: {
    retailer?: IRetailerDetail;
    card_identifier: string;
  };
}
export interface IFidelityCardState {
  isScanningLoading: boolean;
  fidelityCardData: IFidelityCardResponse | Record<string, never>;
  activeFidelityModalCode: FidelityModalsTypes | undefined;
  error: string;
}
export const initialState: IFidelityCardState = {
  activeFidelityModalCode: undefined,
  error: '',
  fidelityCardData: {},
  isScanningLoading: false,
};

const createFidelityCard = async ({
  fidelityCardCode = '',
  retailerSlug,
}: {
  retailerSlug: string;
  fidelityCardCode: string;
}) =>
  await requestWithKeysAsOptions({
    apiPath: fidelityCardApiUrlCreator({}),
    body: {
      fidelity_card: {
        card_identifier: `${fidelityCardCode}`,
        retailer: retailerSlug,
      },
    },
    config: {
      method: REQUEST_METHODS.POST,
    },
  });

export const handleFidelityCodeThunk = createTypedAsyncThunk(
  'handleFidelityCodeThunk',
  async (
    { fidelityCardCode, productCategory }: { fidelityCardCode: string; productCategory: TProductCategory },
    { getState, dispatch, rejectWithValue },
  ) => {
    try {
      const state = getState();
      const fidelityCardId = selectFidelityCardId(state);
      const isScannedSameCard = fidelityCardId && fidelityCardCode === fidelityCardId;
      const isScannedNewCardOverAnother = fidelityCardId && fidelityCardCode !== fidelityCardId;
      const retailerSlug = selectRetailerSlug(state);

      if (isScannedSameCard) {
        return Promise.reject();
      }

      dispatch(resetCurrentProductTip());

      if (isScannedNewCardOverAnother) {
        dispatch(actionResetUserCharactersSlice());
      }

      let fidelityCardData: IFidelityCardResponse = await requestWithKeysAsOptions({
        apiPath: fidelityCardApiUrlCreator({ fidelityCardCode }),
        withErrorReturn: true,
      });

      const isFidelityCardNotExists = !fidelityCardData?.fidelity_card;

      if (isFidelityCardNotExists) {
        fidelityCardData = await createFidelityCard({ fidelityCardCode, retailerSlug });
        history.push(prependBasename(PAGES.vinhood.home));
      }

      if (!isFidelityCardNotExists) {
        history.push(prependBasename(PAGES.vinhood.home));
      }

      MixpanelTracker.events.scanFidelityCard(fidelityCardData.fidelity_card.card_identifier, retailerSlug);
      // Return the active fidelity modal code and fidelity card data

      await dispatch(actionGetUserCharacters());
      dispatch(actionGetDietaryPreferenceListData());
      const userCharacter = selectUserBestMatchCharacterByProductCategory(state)?.[productCategory];
      return {
        activeFidelityModalCode: userCharacter
          ? FidelityModalsTypes.SuccessWithCharacter
          : FidelityModalsTypes.SuccessWithoutCharacter,
        fidelityCardData,
      };
    } catch (error) {
      MixpanelTracker.events.scanFailed(fidelityCardCode);
      history.push(prependBasename(PAGES.vinhood.home));
      return rejectWithValue(parseError(error));
    }
  },
);
export const fidelityCardSlice = createSlice({
  extraReducers: builder => {
    builder.addCase(handleFidelityCodeThunk.pending, state => {
      state.isScanningLoading = true;
    });
    builder.addCase(handleFidelityCodeThunk.fulfilled, (state, action) => {
      const { payload: { activeFidelityModalCode, fidelityCardData = {} } = {} } = action;
      state.isScanningLoading = false;
      state.fidelityCardData = { ...fidelityCardData };
      state.activeFidelityModalCode = activeFidelityModalCode;
    });
    builder.addCase(handleFidelityCodeThunk.rejected, (state, action) => {
      const errorStatus = action.payload?.status;
      if (errorStatus === REQUEST_ERROR_400) {
        state.activeFidelityModalCode = FidelityModalsTypes.NotFoundCard;
      } else if (errorStatus === REQUEST_ERROR_422) {
        state.activeFidelityModalCode = FidelityModalsTypes.NotCardOrEan;
      } else if (errorStatus === REQUEST_ERROR_502 || errorStatus === REQUEST_ERROR_504) {
        state.activeFidelityModalCode = FidelityModalsTypes.ServiceUnavailable;
      } else {
        state.activeFidelityModalCode = FidelityModalsTypes.NotFoundCard;
      }
      state.fidelityCardData = {};
      state.error = action.payload.message;
      state.isScanningLoading = false;
    });
  },
  initialState,
  name: 'fidelityCard',
  reducers: {
    actionShowFailedScanModal(state) {
      state.activeFidelityModalCode = FidelityModalsTypes.NotFoundCard;
    },
    closeFidelityCardModal(state) {
      state.activeFidelityModalCode = undefined;
    },
    detachFidelityCard(_state, action: PayloadAction<{ displayMessage?: boolean }>) {
      const {
        payload: { displayMessage },
      } = action;
      return {
        ...initialState,
        activeFidelityModalCode: displayMessage ? FidelityModalsTypes.CardDisconnected : undefined,
      };
    },
    firstTimeUserSimulationScan(state, { payload }: PayloadAction<string>) {
      state.isScanningLoading = false;
      state.fidelityCardData = {
        fidelity_card: {
          card_identifier: payload,
        },
      };
      state.activeFidelityModalCode = FidelityModalsTypes.SuccessWithoutCharacter;
    },
  },
});

export default fidelityCardSlice.reducer;

export const { detachFidelityCard, closeFidelityCardModal, firstTimeUserSimulationScan, actionShowFailedScanModal } =
  fidelityCardSlice.actions;
