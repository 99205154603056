import { FC } from 'react';
import { RWebShare } from 'react-web-share';

import { TParsedProductInstance } from '@lib/core/products/types';
import { prependBasename } from '@lib/core/service/utils';
import { useUser } from '@lib/core/users/hooks';
import {
  UTM_CAMPAIGN_AUTHENTICATED,
  UTM_CAMPAIGN_NON_AUTHENTICATED,
  UTM_MEDIUM_SHARE_MY_TASTING,
  UTM_SOURCE,
} from '@lib/tools/dat/consts';
import MixpanelTracker from '@lib/tools/dat/mixpanel';
import { MixpanelPositionContext } from '@lib/tools/dat/mixpanel/consts';
import { localeWidget } from '@lib/tools/locale/source/web/widget';
import { LocaleUtils } from '@lib/tools/locale/utils';
import { PRODUCT_ID_URL_PARAM } from '@lib/tools/shared/helpers/consts';
import { PAGES } from '@lib/tools/views/urls';

import * as S from '@components/web/src/atoms/Share/styles';
import { STATIC_COLORS } from '@components/web/src/foundations';

export type TShareIconSizes = 'small' | 'medium' | 'large';

interface IProps {
  size?: TShareIconSizes;
  variant?: 'default' | 'light';
  isFromSharedLink?: boolean;
  isFromProductCardSharedLink?: boolean;
  parsedProductInstanceData: TParsedProductInstance;
  mixpanelPositionContext: MixpanelPositionContext;
}

export const Share: FC<IProps> = ({
  size = 'small',
  variant = 'default',
  isFromSharedLink,
  isFromProductCardSharedLink,
  parsedProductInstanceData,
  mixpanelPositionContext,
}) => {
  const shareTitleText = LocaleUtils.publishedTerms[localeWidget.shareComponent?.titleText?.id];

  const { userId, profileId } = useUser();
  const { productInstanceId } = parsedProductInstanceData || {};

  const { origin, href } = window.location;
  const queryParams = {
    utm_campaign: userId ? UTM_CAMPAIGN_AUTHENTICATED : UTM_CAMPAIGN_NON_AUTHENTICATED,
    utm_medium: UTM_MEDIUM_SHARE_MY_TASTING,
    utm_source: UTM_SOURCE,
    utm_term: profileId,
  };
  const queryString = new URLSearchParams(queryParams).toString();
  const sharedLink = isFromSharedLink ? href : `${href}&${queryString}`;
  const hrefFromProductCard = `${origin}${prependBasename(PAGES.vinhood.product, { [PRODUCT_ID_URL_PARAM]: productInstanceId })}`;
  const sharedLinkFromProductCard = isFromSharedLink ? hrefFromProductCard : `${hrefFromProductCard}&${queryString}`;

  const handleClick = () =>
    MixpanelTracker.events.shareProductPageClick(parsedProductInstanceData, mixpanelPositionContext, profileId);

  return (
    <RWebShare
      data={{
        title: shareTitleText,
        url: isFromProductCardSharedLink ? sharedLinkFromProductCard : sharedLink,
      }}
    >
      <S.ShareContainer align="center" justify="center" onPointerDown={handleClick}>
        <S.SvgIcon $size={size} fill="none" viewBox="0 0 24 24">
          <path
            d="M22 2L11 13M22 2L15 22L11 13M22 2L2 9L11 13"
            stroke={variant === 'light' ? STATIC_COLORS.base.white : STATIC_COLORS.teal['600']}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.6"
          />
        </S.SvgIcon>
      </S.ShareContainer>
    </RWebShare>
  );
};
