import { FC, SyntheticEvent } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useProductFeedback } from '@lib/core/products/hooks/useProductFeedback';
import { TParsedProductInstance } from '@lib/core/products/types';
import { TRetailerLocationStoreType } from '@lib/core/retailers/types';
import { RetailerLocationStoreType } from '@lib/core/retailers/utils/consts';
import { prependBasename, promotionsFilter } from '@lib/core/service/utils';
import MixpanelTracker from '@lib/tools/dat/mixpanel';
import { MixpanelPositionContext } from '@lib/tools/dat/mixpanel/consts';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import {
  PRODUCT_ID_URL_PARAM,
  PROMOTION_BADGE_FIDELITY,
  PROMOTION_BADGE_OTHER,
  PROMOTION_BADGE_PROMOTION,
  PROMOTION_LABEL_SLUG,
  VH_VARIANTS,
} from '@lib/tools/shared/helpers/consts';
import { PAGES } from '@lib/tools/views/urls';

import addIcon from '@components/web/src/assets/icons/add_icon.svg';
import Button from '@components/web/src/atoms/Buttons/Button';
import { RatingButton } from '@components/web/src/atoms/ProductRating/ProductRating/RatingButton';
import Wishlist from '@components/web/src/atoms/Wishlist/Wishlist';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';
import * as S from '@components/web/src/organisms/ProductCards/ProductCard/styles';
import { PromotionBadges } from '@components/web/src/organisms/PromotionBadges/PromotionBadges';
import NewProductCardSkeleton from '@components/web/src/organisms/Skeleton/NewProductCardSkeleton/NewProductCardSkeleton';
import { SkeletonWrapper } from '@components/web/src/organisms/Skeleton/SkeletonWrapper';
import FindProductPopup from '@components/web/src/shared/FindProductPopup/FindProductPopup';
import { IDiscoveryQuiz } from '@components/web/src/templates/Modals/DiscoveryQuizModal/DiscoveryQuizModal';

interface IProps {
  parsedProductInstanceData: TParsedProductInstance;
  discoveryQuiz?: IDiscoveryQuiz;
  storeType?: TRetailerLocationStoreType;
  mixpanelIndex?: number | null;
  mixpanelPositionContext?: MixpanelPositionContext;
  isCardClickDisabled?: boolean;
  isLoading?: boolean;
  isResponsive?: boolean;
}

export const ProductCard: FC<IProps> = ({
  parsedProductInstanceData,
  discoveryQuiz,
  storeType,
  mixpanelIndex,
  mixpanelPositionContext,
  isResponsive = false,
  isCardClickDisabled = false,
  isLoading = false,
}) => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  const {
    commonMessages: { promo },
    productCard: { givePeekBtn, findMeBtn, tested },
  } = localeCommon;

  const {
    productCategory,
    productCharacterName,
    productCharacterTechnicalCaption,
    productName,
    productEcommerceId,
    productLocationDescription,
    productLocationMap,
    productProducerUrl,
    productId,
    productInstanceId,
    promotions,
    productOriginalPriceAndCurrency,
    productDiscountPriceAndCurrency,
    productDiscountPrice,
    productImage,
    isProductInstanceInWishlist,
    isFindProductFunctionalityEnable,
    isFindProductButtonEnable,
    isEnableLocationMapAddon,
    isEnableVusionAddon,
    isWishlistProductListLoading,
    productInstanceData,
    productFeedbackStatus,
    productOriginalPrice,
    handleUpdateWishlistProductList,
  } = parsedProductInstanceData || {};
  const isProductDiscountAvailable = !!productDiscountPrice && !!productOriginalPrice;

  const { handleToggleOpenedFeedbackModal } = useProductFeedback();

  const isShowRatingButton = productFeedbackStatus?.isDisabled || productFeedbackStatus?.isFinished;
  const isProductFeedbackEnabled = productFeedbackStatus?.isEnabled && !productFeedbackStatus?.isFinished;
  const isShowPromoLabel = !!promotionsFilter(promotions, [PROMOTION_LABEL_SLUG]).length;

  const promotionBadges = promotionsFilter(promotions, [
    PROMOTION_BADGE_FIDELITY,
    PROMOTION_BADGE_PROMOTION,
    PROMOTION_BADGE_OTHER,
  ]);

  const characterTitle = `${productCharacterName && `**#${productCharacterName}** - `} ${productCharacterTechnicalCaption}`;

  const handleAreaClick = (e: SyntheticEvent) => e.stopPropagation();

  const handleCardClick = (e: SyntheticEvent) => {
    handleAreaClick(e);

    const isExternalTarget = storeType === RetailerLocationStoreType.ecommerce;

    if (storeType === RetailerLocationStoreType.main) {
      navigate(prependBasename(PAGES.vinhood.product, { [PRODUCT_ID_URL_PARAM]: productInstanceId }), {
        state: { from: pathname + search },
      });

      MixpanelTracker.events.productClick(parsedProductInstanceData, mixpanelIndex, mixpanelPositionContext);
    } else if (isExternalTarget && productProducerUrl) {
      window.open(productProducerUrl, '_blank');
      MixpanelTracker.events.contactTheProductProducer(
        parsedProductInstanceData,
        mixpanelIndex,
        mixpanelPositionContext,
      );
    }
  };

  const handleTasteBoostClick = (e: SyntheticEvent) => {
    handleAreaClick(e);
    handleToggleOpenedFeedbackModal({ productInstanceData });
  };

  return (
    <SkeletonWrapper showSkeleton={isLoading} skeleton={() => NewProductCardSkeleton({ isResponsive })}>
      <S.ProductCardContainer
        $isCardClickDisabled={isCardClickDisabled}
        $isProductFeedbackFinished={productFeedbackStatus.isFinished}
        $isResponsive={isResponsive}
        $productCategory={productCategory}
        direction="column"
        gap={0}
        onClick={handleCardClick}
      >
        <S.ProductImageWrapper align="center" gap={0} justify="center" padding="8px">
          <S.ProductImage
            $isProductFeedbackEnabled={isProductFeedbackEnabled}
            alt="product image"
            height="92px"
            objectFit="contain"
            src={productImage}
            width="92px"
          />
          {isShowPromoLabel && (
            <S.PromoLabel>
              <Text
                color={STATIC_COLORS.base.white}
                size="body3"
                text={promo}
                textTransform="uppercase"
                weight="semibold"
              />
            </S.PromoLabel>
          )}
          {isProductFeedbackEnabled && (
            <S.ProductFeedbackIconWrapper align="center" justify="center" onClick={handleTasteBoostClick}>
              <img alt="product feedback icon" src={addIcon} />
            </S.ProductFeedbackIconWrapper>
          )}
          <S.CTAContainer align="center" direction="column" gap={0} onClick={handleAreaClick}>
            <Wishlist
              handleUpdateWishlist={() => handleUpdateWishlistProductList(productId, productName)}
              isItemInWishlist={isProductInstanceInWishlist}
              isItemListLoading={isWishlistProductListLoading}
            />
            {isShowRatingButton && (
              <RatingButton discoveryQuiz={discoveryQuiz} parsedProductInstanceData={parsedProductInstanceData} />
            )}
          </S.CTAContainer>
        </S.ProductImageWrapper>
        <Flexbox direction="column" gap={8} padding="0 8px 8px">
          <Text
            color={STATIC_COLORS.base.black}
            fontFamily="Fraunces"
            linesLimit={2}
            size="subtitle1"
            text={productName}
            weight="bold"
          />
          <S.CharacterTitle
            $productCategory={productCategory}
            color={STATIC_COLORS.warmGray[900]}
            text={characterTitle}
          />
          <Flexbox gap={8} justify="flex-end">
            {isProductDiscountAvailable && (
              <Text
                color={STATIC_COLORS.gray[400]}
                size="subtitle1"
                text={productOriginalPriceAndCurrency}
                textDecoration="line-through"
              />
            )}
            {(isProductDiscountAvailable || !!productOriginalPrice) && (
              <Text
                color={STATIC_COLORS.base.black}
                size="subtitle1"
                text={isProductDiscountAvailable ? productDiscountPriceAndCurrency : productOriginalPriceAndCurrency}
                weight="semibold"
              />
            )}
          </Flexbox>
          {!!promotionBadges?.length && <PromotionBadges promotionsData={promotionBadges} variant="small" />}
          {productFeedbackStatus.isFinished ? (
            <Flexbox align="center" gap={4} justify="center">
              <Text
                color={STATIC_COLORS.productColors[productCategory][500]}
                size="body3"
                text={tested}
                weight="semibold"
              />
              <svg fill="none" height="18" viewBox="0 0 19 18" width="19">
                <path
                  d="M15.5 4.5L7.25 12.75L3.5 9"
                  stroke={STATIC_COLORS.productColors[productCategory]['500']}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                />
              </svg>
            </Flexbox>
          ) : (
            <S.ProductCardCTA
              fontSize="body3"
              text={givePeekBtn}
              textWeight="semibold"
              variant="link"
              onClick={handleCardClick}
            />
          )}
          {isFindProductFunctionalityEnable && (
            <S.FindProductWrapper role="button" tabIndex={0} onClick={handleAreaClick} onKeyDown={handleAreaClick}>
              {isFindProductButtonEnable && (
                <FindProductPopup
                  isEnableLocationMapAddon={isEnableLocationMapAddon}
                  isEnableVusionAddon={isEnableVusionAddon}
                  productEcommerceId={productEcommerceId}
                  productLocationDescription={productLocationDescription}
                  productLocationMap={productLocationMap}
                >
                  <Button isFullWidth size="sm" text={findMeBtn} variant={VH_VARIANTS.PRIMARY} />
                </FindProductPopup>
              )}
            </S.FindProductWrapper>
          )}
        </Flexbox>
      </S.ProductCardContainer>
    </SkeletonWrapper>
  );
};
