import {
  FAILED_POST_FLASH_LABEL,
  FINISHED_POST_FLASH_LABEL,
  START_POST_FLASH_LABEL,
} from '@lib/core/products/slices/consts';
import { selectRetailerSlug } from '@lib/core/retailers/selectors/retailer';
import {
  selectAssociatedRetailerLocation,
  selectRetailerLocationSlug,
} from '@lib/core/retailers/selectors/retailerLocation';
import { REQUEST_METHODS, isApplicationKiosk } from '@lib/core/service/consts';
import backendApiUrls from '@lib/core/service/requests/backend_api_urls';
import request from '@lib/core/service/requests/request';
import requestErrorHandler from '@lib/core/service/requests/requestErrorHandler';
import { createBackendFlashLabelUrl } from '@lib/core/service/utils';

const startPostFlashLabel = () => ({
  type: START_POST_FLASH_LABEL,
});

const finishPostFlashLabel = data => ({
  data,
  receivedAt: Date.now(),
  type: FINISHED_POST_FLASH_LABEL,
});

const failedPostFlashLabel = data => ({
  errors: data,
  receivedAt: Date.now(),
  type: FAILED_POST_FLASH_LABEL,
});

const fetchPostFlashLabel = (ecommerce_id: string) => (dispatch, getState) => {
  dispatch(startPostFlashLabel());
  const config = {
    method: REQUEST_METHODS.POST,
  };

  const itemId = ecommerce_id || '';
  const retailerSlug = selectRetailerSlug(getState());
  const retailerLocationSlug = isApplicationKiosk
    ? selectRetailerLocationSlug(getState())
    : selectAssociatedRetailerLocation(getState())?.slug || selectRetailerLocationSlug(getState());
  const storeId = `${retailerSlug}.${retailerLocationSlug}`;

  return request(
    createBackendFlashLabelUrl(backendApiUrls.apiUrlPostFlashLabel, retailerSlug, storeId, itemId),
    config,
    {
      payload: {
        color: 'RED',
        duration: 40,
        pattern: 'FLASH_4_TIMES',
      },
    },
  )
    .then(json => {
      dispatch(finishPostFlashLabel(json));
    })
    .catch(error => {
      requestErrorHandler({
        error,
        func: failedPostFlashLabel,
        url: backendApiUrls.apiUrlPostFlashLabel,
      });
    });
};

export default fetchPostFlashLabel;
