import { FC } from 'react';

import { IParsedCharacter } from '@lib/core/characters/utils/parseCharacter';
import { localeWidget } from '@lib/tools/locale/source/web/widget';
import { VH_VARIANTS } from '@lib/tools/shared/helpers/consts';

import closeIcon from '@components/web/src/assets/icons/close-more-icon.svg';
import Button from '@components/web/src/atoms/Buttons/Button';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';
import * as S from '@components/web/src/templates/CharacterOverwriting/styles';

interface IProps {
  userName: string;
  newCharacter: IParsedCharacter;
  oldCharacter: IParsedCharacter;
  handleOverwriteCharacter: () => void;
  handleKeepOldCharacter: () => void;
}

export const CharacterOverwritingPage: FC<IProps> = ({
  userName,
  newCharacter,
  oldCharacter,
  handleKeepOldCharacter,
  handleOverwriteCharacter,
}) => {
  const { characterTechnicalCaption, characterName, characterImage, characterProductCategory } = newCharacter || {};
  const productCategory = characterProductCategory?.name || '';

  const {
    characterTechnicalCaption: oldCharacterTechnicalCaption,
    characterName: oldCharacterName,
    characterImage: oldCharacterImage,
  } = oldCharacter || {};

  const { titleText, subtitleText, newCharacterText, oldCharacterText, acceptNewCharacterText, keepOldCharacterText } =
    localeWidget.characterOverWritingPage;

  return (
    <S.CharacterOverWritingContainer isFullWidth align="center" direction="column" gap={0}>
      <Flexbox isFullWidth align="center" direction="column" gap={8} justify="center">
        <Text
          color={STATIC_COLORS.base.white}
          fontFamily="Fraunces"
          localeOptions={{ name: userName }}
          size="h5"
          text={titleText}
          weight="bold"
        />
        <Text color={STATIC_COLORS.base.white} size="subtitle2" text={subtitleText} weight="medium" />
      </Flexbox>
      <Flexbox isFullWidth direction="column" gap={10} padding="32px 0 0 0">
        <Text
          color={STATIC_COLORS.base.white}
          size="subtitle1"
          text={newCharacterText}
          textAlign="center"
          weight="bold"
        />
        <S.CharacterCard isFullWidth align="center" direction="column" padding="16px">
          <S.CharacterWrapper>
            <S.CharacterImage alt="character" src={characterImage} />
            <S.BackgroundIcon $backgroundColor={STATIC_COLORS.productColors[productCategory][50]} />
          </S.CharacterWrapper>
          <S.TextWrapper isFullWidth align="center" direction="column" gap={0} justify="center">
            <S.Border />
            <Text
              color={STATIC_COLORS.gray['900']}
              fontFamily="Fraunces"
              size="h4"
              text={`#${characterName}`}
              weight="semibold"
            />
            <Text color={STATIC_COLORS.gray['900']} size="body1" text={characterTechnicalCaption} weight="medium" />
          </S.TextWrapper>
        </S.CharacterCard>
      </Flexbox>
      <S.AnimatedArrow alt="Arrow Animation" src={closeIcon} />
      <Flexbox isFullWidth direction="column" gap={10}>
        <Text
          color={STATIC_COLORS.teal['900']}
          size="body1"
          text={oldCharacterText}
          textAlign="center"
          textTransform="lowercase"
          weight="semibold"
        />
        <S.OldCard isFullWidth align="center">
          <S.OldCharacterImage alt="character" src={oldCharacterImage} />
          <S.OldCharacterDetails direction="column" gap={0} justify="center">
            <Text
              color={STATIC_COLORS.base.white}
              fontFamily="Fraunces"
              size="h5"
              text={`#${oldCharacterName}`}
              weight="bold"
            />
            <Text color={STATIC_COLORS.base.white} size="body2" text={oldCharacterTechnicalCaption} />
          </S.OldCharacterDetails>
        </S.OldCard>
      </Flexbox>
      <Flexbox isFullWidth align="center" direction="column" gap={16} padding="48px 0 0">
        <Button
          isFullWidth
          fontSize="subtitle2"
          handleClick={() => handleOverwriteCharacter()}
          size="md"
          text={acceptNewCharacterText}
          variant={VH_VARIANTS.LIGHT}
        />
        <Button
          isFullWidth
          fontSize="subtitle2"
          style={{ color: STATIC_COLORS.base.white }}
          text={keepOldCharacterText}
          textDecoration="underline"
          variant="transparent"
          onClick={() => handleKeepOldCharacter()}
        />
      </Flexbox>
    </S.CharacterOverWritingContainer>
  );
};
