import styled, { css } from 'styled-components';

import { STATIC_COLORS, STATIC_GRADIENTS, STATIC_SHADOWS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { PRODUCT_CARD_WIDTH } from '@components/web/src/organisms/ProductCards/OldProductCard/styles';
import { skeletonLoadingAnimation, skeletonLoadingKeyframes } from '@components/web/src/organisms/Skeleton/styles';

export const ProductCardSkeletonContainer = styled(Flexbox)<{ $isResponsive: boolean }>`
  width: ${({ $isResponsive }) => ($isResponsive ? `100%` : PRODUCT_CARD_WIDTH.WEB)};
  background-color: ${STATIC_COLORS.base.white};
  border-radius: 8px;
  box-shadow: ${STATIC_SHADOWS.lg};

  ${({ theme, $isResponsive }) =>
    theme?.isKioskRootElementType &&
    css`
      max-width: ${$isResponsive ? '100%' : PRODUCT_CARD_WIDTH.KIOSK};
      width: ${$isResponsive ? '100%' : PRODUCT_CARD_WIDTH.KIOSK};
    `}
`;

export const skeletonCommonStyles = css`
  background: ${STATIC_GRADIENTS.warmGradientForSkeleton};
  animation: ${skeletonLoadingKeyframes} 1s linear infinite alternate;
  margin: 0;
  border-radius: 4px;
  padding-right: 20px;
`;

export const ImageSkeleton = styled.div`
  height: 210px;
  width: 100%;
  padding-right: 20px;
  gap: 8px;
  border-radius: 8px 8px 0 0;
  background: ${STATIC_GRADIENTS.warmGradientForSkeleton};
  animation: ${skeletonLoadingKeyframes} ${skeletonLoadingAnimation};
`;

export const SectionOne = styled.div`
  width: 100%;
  height: 18px;
  ${skeletonCommonStyles}
`;

export const SectionTwo = styled.div`
  width: 100%;
  height: 52px;
  ${skeletonCommonStyles};
`;

export const SectionThree = styled.div`
  width: 100%;
  height: 16px;
  ${skeletonCommonStyles};
`;

export const SectionFour = styled.div`
  width: 60px;
  height: 36px;
  ${skeletonCommonStyles};
`;
