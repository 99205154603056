import { TProductCategory } from '@lib/core/products/types';
import {
  PRODUCT_CATEGORY_BEER,
  PRODUCT_CATEGORY_COFFEE,
  PRODUCT_CATEGORY_WINE,
} from '@lib/tools/shared/helpers/consts';

import missingBeerImage from '@components/web/src/assets/icons/missingProductImages/missing_product_image_beer.png';
import missingCoffeeImage from '@components/web/src/assets/icons/missingProductImages/missing_product_image_coffee.png';
import missingWineImage from '@components/web/src/assets/icons/missingProductImages/missing_product_image_wine.png';

export const getMissingProductImage = (category: TProductCategory): string =>
  ({
    [PRODUCT_CATEGORY_BEER]: missingBeerImage,
    [PRODUCT_CATEGORY_COFFEE]: missingCoffeeImage,
    [PRODUCT_CATEGORY_WINE]: missingWineImage,
  })[category] ?? missingWineImage;
