import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { useDietaryPreferences } from '@lib/core/users/hooks';
import { useDisclosure } from '@lib/tools/views/hooks/useDisclosure';

import { TagsModal } from '@components/web/src/templates/Modals/TagsModal/TagsModal';
import TagsPage from '@components/web/src/templates/TasteId/TasteIdTagsPage/TasteIdTagsPage';

const TasteIdTagsPageContainer: FC = () => {
  const navigate = useNavigate();

  const [isTagsModalOpen, { open: openModal, close: closeModal }] = useDisclosure();

  const { dietaryPreferenceTagsOptions, toggleTagsOptionsData, handleUpdateToggleDietaryPreferenceOptionsData } =
    useDietaryPreferences();

  const handleBackBtnClick = () => navigate(-1);

  return (
    <>
      <TagsPage
        handleBackBtnClick={handleBackBtnClick}
        handleUpdateToggleDietaryPreferenceOptionsData={handleUpdateToggleDietaryPreferenceOptionsData}
        toggleTagsOptionsData={toggleTagsOptionsData}
        onTagsInfoClick={openModal}
      />
      {isTagsModalOpen && (
        <TagsModal
          dietaryPreferenceTagsOptions={dietaryPreferenceTagsOptions}
          hideModal={closeModal}
          isModalOpen={isTagsModalOpen}
        />
      )}
    </>
  );
};

export default TasteIdTagsPageContainer;
