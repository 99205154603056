import React, { FC } from 'react';

type ISkeletonWrapperProps = {
  showSkeleton: boolean;
  skeleton: React.FunctionComponent;
  children: JSX.Element;
};

export const SkeletonWrapper: FC<ISkeletonWrapperProps> = ({ showSkeleton, skeleton: Component, children }) => {
  return showSkeleton ? <Component /> : children;
};
