import { FC } from 'react';

import { localeCommon } from '@lib/tools/locale/source/web/common';

import { STATIC_COLORS } from '@components/web/src/foundations';
import { Text } from '@components/web/src/foundations/Text/Text';
import { SLOW_PAGE_SCENARIOS } from '@components/web/src/templates/Loading/LoadingSpinner/LoadingSpinner';
import * as S from '@components/web/src/templates/Loading/LowSpeedWarning/styles';

interface IProps {
  slowPageScenario: keyof typeof SLOW_PAGE_SCENARIOS;
}

export const LowSpeedWarning: FC<IProps> = ({ slowPageScenario }) => {
  const { lowSpeedWarningTitle, lowSpeedWarningDescription, slowApiWarningDescription, slowApiWarningTitle } =
    localeCommon.lowSpeedWarning;

  const titleText =
    slowPageScenario === SLOW_PAGE_SCENARIOS.isLowSpeedConnection ? lowSpeedWarningTitle : slowApiWarningTitle;
  const subTitleText =
    slowPageScenario === SLOW_PAGE_SCENARIOS.isLowSpeedConnection
      ? lowSpeedWarningDescription
      : slowApiWarningDescription;

  return (
    <S.LowSpeedWarningContainer direction="column" gap={4} padding="16px">
      <Text color={STATIC_COLORS.base.white} size="subtitle2" text={titleText} textAlign="center" weight="bold" />
      <Text color={STATIC_COLORS.base.white} size="body1" text={subTitleText} textAlign="center" />
    </S.LowSpeedWarningContainer>
  );
};
