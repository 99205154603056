import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { useCharacters } from '@lib/core/characters/hooks';
import { isCharacterByIdentifiers } from '@lib/core/characters/utils/filters';
import { useParsedProducts } from '@lib/core/products/hooks/useParsedProducts';
import { TProductInstance } from '@lib/core/products/types';
import { B2C_SEARCH_TYPE_PRODUCTS } from '@lib/core/service/consts';
import { prependBasename } from '@lib/core/service/utils';
import { useUser } from '@lib/core/users/hooks';
import { useGlobalSearch } from '@lib/tools/globalSearch/hooks/useGlobalSearch';
import { fetchGlobalSearchResult, fetchSuggestionProductsCount } from '@lib/tools/globalSearch/slices';
import { localeApp } from '@lib/tools/locale/source/web/app';
import { LocaleUtils } from '@lib/tools/locale/utils';
import { PRODUCT_ID_URL_PARAM } from '@lib/tools/shared/helpers/consts';
import { PAGES } from '@lib/tools/views/urls';

import { GlobalSearchPage } from '@components/web/src/templates/GlobalSearchPages/GlobalSearchPage/GlobalSearchPage';
import { GlobalSearchResultTab } from '@components/web/src/templates/GlobalSearchPages/GlobalSearchResultTab/GlobalSearchResultTab';

export const GlobalSearchContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  const { userCharactersIds } = useUser();

  const { characters } = useCharacters();
  const translatedUserCharactersData = characters.filter(character =>
    isCharacterByIdentifiers(character, userCharactersIds),
  );

  const { products, searchQuery, isLoadingGlobalSearch, suggestionProductsCount } = useGlobalSearch();
  const { publishedTerms } = LocaleUtils;

  const suggestionData = useMemo(
    () =>
      translatedUserCharactersData.map(
        ({ characterName, characterProductCategory: { name: productCategoryName } }) => ({
          characterName,
          productCategory: productCategoryName,
          productsCount: suggestionProductsCount[productCategoryName] || 0,
        }),
      ),
    [translatedUserCharactersData, suggestionProductsCount],
  );

  useEffect(() => {
    dispatch(fetchSuggestionProductsCount({ userCharactersIds }));
  }, []);
  const { parseProduct } = useParsedProducts();

  const createSearchResultData = (productsData: TProductInstance[] = []) => {
    return productsData.map(productInstanceData => {
      const { identifier = '' } = productInstanceData || {};
      const parsedProductInstanceData = parseProduct(productInstanceData);

      return {
        handleCTAClick: () =>
          navigate(prependBasename(PAGES.vinhood.product, { [PRODUCT_ID_URL_PARAM]: identifier }), {
            state: { from: pathname + search },
          }),
        parsedProductInstanceData,
      };
    });
  };

  const handleSuggestionBtnClick = productCategory =>
    navigate(prependBasename(PAGES.vinhood.catalog, { productCategory }));

  const handleSearchSubmit = (query: string) => dispatch(fetchGlobalSearchResult({ params: { query } }));

  const tabsData = useMemo(
    () => ({
      active: B2C_SEARCH_TYPE_PRODUCTS,
      tabs: [
        {
          children: (
            <GlobalSearchResultTab
              handleSuggestionBtnClick={handleSuggestionBtnClick}
              parsedSearchResultData={createSearchResultData(products?.results)}
              suggestionData={suggestionData}
            />
          ),
          isHiddenTab: false,
          tabCode: B2C_SEARCH_TYPE_PRODUCTS,
          tabText: `${publishedTerms[localeApp.globalSearchPage.productsTabText.id]} (${products?.searchCount})`,
        },
      ],
    }),
    [products, suggestionData],
  );

  return (
    <GlobalSearchPage
      handleSearchSubmit={handleSearchSubmit}
      isLoading={isLoadingGlobalSearch}
      searchedQuery={searchQuery}
      tabsData={tabsData}
    />
  );
};
