import { styled } from 'styled-components';

import { STATIC_COLORS, STATIC_GRADIENTS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';

export const AnswerContainer = styled(Flexbox)`
  background: ${STATIC_GRADIENTS.primaryGradient};
  flex: 1 1 0%;
  justify-content: space-between;
`;

export const ButtonSection = styled(Flexbox)`
  position: sticky;
  bottom: 0;
  background: ${STATIC_COLORS.base.white};
  box-shadow: 0px -8px 18px 0px rgba(16, 24, 40, 0.15);

  .full-width-button {
    width: 100%;
  }
`;
