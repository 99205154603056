import { css, styled } from 'styled-components';

import { STATIC_COLORS, styleMatcher } from '@components/web/src/foundations';

export type TVariant = 'dark' | 'light';
export type TType = 'regular' | 'bold';
export type TPosition = 'left' | 'right';

export const getVariantStyles = (variant: TVariant) =>
  styleMatcher(variant, {
    dark: () => css`
      border: 1px solid ${STATIC_COLORS.base.black};

      &::after,
      &::before {
        background-color: ${STATIC_COLORS.base.black};
      }
    `,
    light: () => css`
      border: 1px solid ${STATIC_COLORS.base.white};

      &::after,
      &::before {
        background-color: ${STATIC_COLORS.base.white};
      }
    `,
  });

export const CloseButtonContainer = styled.button<{ $type: TType; $variant: TVariant; $position: TPosition }>`
  position: relative;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  background-color: transparent;
  border-radius: 50%;
  cursor: pointer;
  ${({ $position }) => $position === 'right' && 'margin-left: auto;'}

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 9px;
    height: ${({ $type }) => ($type === 'bold' ? '1.5px' : '1px')};
    border-radius: 8px;
  }

  &::before {
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  &::after {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  ${({ $variant }) => getVariantStyles($variant)}
`;
