import { FC } from 'react';

import * as S from '@components/web/src/kiosk/KioskCirclesBackground/styles';

interface IProps {
  color?: string;
  zIndex?: number;
}

export const KioskCirclesBackground: FC<IProps> = ({ zIndex = 0, color = '#000' }) => (
  <S.CirclesBackgroundContainer $zIndex={zIndex}>
    <S.CirclesBackground>
      <svg fill="none" height="2600" viewBox="0 0 1080 2600" width="1080">
        <path
          clipRule="evenodd"
          d="M520.053 330C478.497 330.001 435.233 331.916 390.361 335.702C209.522 355.048 64.8701 443.527 0 565.485V1208.32C91.5146 1347.5 264.971 1469.68 446.239 1485.55C707.838 1538.78 959.929 1381.42 1080 1186.26V573.676C979.721 407.516 784.499 330.005 520.099 330C520.084 330 520.068 330 520.053 330ZM1058.93 788.862C1023.42 532.445 845.653 356.233 565.013 378.377C382.564 392.775 259.528 425.263 168.356 505.26C91.8932 572.314 -7.80468 799.986 41.1603 1005.31C103.796 1268.07 475.14 1467.05 804.484 1341.15C996.186 1267.91 1095.47 1052.97 1058.93 788.862Z"
          fill={color}
          fillOpacity="0.02"
          fillRule="evenodd"
        />
        <path
          d="M1080 1375.91C1055.97 1376.85 1031.41 1378.38 1006.36 1380.49C824.639 1399.93 679.459 1489.18 615.056 1612.06C569.054 1699.96 508.549 1943.85 559.178 2131.08C608.507 2313.32 828.901 2509.91 1062.24 2530.34C1068.16 2531.54 1074.09 2532.64 1080 2533.63V2411.16C872.205 2367.4 698.252 2222.47 657.16 2050.09C608.195 1844.77 707.893 1617.1 784.356 1550.05C857.588 1485.79 951.377 1452.19 1080 1433.99V1375.91Z"
          fill={color}
          fillOpacity="0.02"
        />
      </svg>
    </S.CirclesBackground>
  </S.CirclesBackgroundContainer>
);
