import { keyframes, styled } from 'styled-components';

import { STATIC_COLORS, STATIC_GRADIENTS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';

const floatUp = keyframes`
  0% {
    transform: translateY(10px);
  }
  50% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(10px);
  }
`;

export const CharacterOverWritingContainer = styled(Flexbox)`
  flex: 1;
  padding: 32px 16px;
  background: ${STATIC_GRADIENTS.primaryGradient};
`;

export const CharacterCard = styled(Flexbox)`
  align-self: stretch;
  border-radius: 16px;
  background: ${STATIC_COLORS.warmGray['50']};
  position: relative;
  height: 211px;
  overflow: hidden;
`;

export const CharacterImage = styled.img`
  width: 161.504px;
  height: 161.504px;
  flex-shrink: 0;
  position: absolute;
  z-index: 1;
`;

export const CharacterWrapper = styled.div`
  position: relative;
  width: 161.504px;
  height: 161.504px;
`;

export const TextWrapper = styled(Flexbox)`
  position: absolute;
  bottom: 0;
  background: ${STATIC_COLORS.warmGray['50']};
  z-index: 1;
  flex-direction: column;
  padding: 0 16px 16px;
`;

export const Border = styled.div`
  width: 100%;
  border-top: 0.5px solid ${STATIC_COLORS.gray['200']};
  margin-bottom: 8px;
`;

export const AnimatedArrow = styled.img`
  width: 32px;
  height: 32px;
  margin: 8px 0;
  animation: ${floatUp} 2s ease-in-out infinite;
`;

export const OldCard = styled(Flexbox)`
  align-self: stretch;
  background: ${STATIC_COLORS.teal['900']};
  backdrop-filter: blur(4px);
  border-radius: 16px;
  height: 92px;
  position: relative;
  overflow: hidden;
`;

export const OldCharacterImage = styled.img`
  width: 114px;
  height: 114px;
  position: absolute;
  left: 0;
  top: 0;
`;

export const OldCharacterDetails = styled(Flexbox)`
  overflow: hidden;
  position: absolute;
  left: 100px;
`;

export const BackgroundIcon = styled.div<{ $backgroundColor: string }>`
  position: absolute;
  top: 80px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 105.405px;
  height: 105.405px;
  border-radius: 50%;
  border: 2.1px solid ${STATIC_COLORS.base.white};
  background-color: ${({ $backgroundColor }) => $backgroundColor};
`;
