import { zodResolver } from '@hookform/resolvers/zod';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';

import { prependBasename } from '@lib/core/service/utils';
import { fetchGlobalSearchResult } from '@lib/tools/globalSearch/slices';
import { localeApp } from '@lib/tools/locale/source/web/app';
import { LocaleUtils } from '@lib/tools/locale/utils';
import { PAGES } from '@lib/tools/views/urls';

import Button from '@components/web/src/atoms/Buttons/Button';
import InputField from '@components/web/src/organisms/Input/InputFields';
import ModalWrapper from '@components/web/src/templates/Modals/ModalWrapper/ModalWrapper';

export const SearchModal = ({ hideModal, isModalOpen }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { inputFieldLabel, buttonText } = localeApp.b2cSearchModal;

  const { publishedTerms } = LocaleUtils;

  const inputFieldLabelText = publishedTerms[inputFieldLabel.id];

  const validationSchema = useMemo(() => {
    return z.object({
      searchField: z.string(),
    });
  }, []);

  const { register, handleSubmit, setValue } = useForm({ mode: 'onChange', resolver: zodResolver(validationSchema) });

  const onSubmit = data => {
    const { searchField } = data;
    if (searchField !== '') {
      dispatch(fetchGlobalSearchResult({ params: { query: searchField } }));
    }
    navigate(prependBasename(PAGES.vinhood.globalSearchResults));
    hideModal();
    setValue('searchField', '');
  };
  return (
    <ModalWrapper hideModal={hideModal} isOpen={isModalOpen} modalClassName="search-modal">
      <div className="search-modal-wrapper">
        <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
          <div className="modal-content-wrapper">
            <InputField
              className="search-modal-input"
              name="searchField"
              {...register('searchField')}
              placeholder={inputFieldLabelText}
            />
          </div>
          <div className="modal-actions-wrapper">
            <Button text={buttonText} type="submit" />
          </div>
        </form>
      </div>
    </ModalWrapper>
  );
};
