import { FC } from 'react';

import { TDietaryPreferenceOption } from '@lib/core/users/types';
import { localeWidget } from '@lib/tools/locale/source/web/widget';

import { CloseButton } from '@components/web/src/atoms/Buttons/CloseButton/CloseButton';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';
import { ModalWrapperNew } from '@components/web/src/templates/Modals/ModalWrapper/ModalWrapperNew';
import * as S from '@components/web/src/templates/Modals/TagsModal/style';

interface IProps {
  isModalOpen: boolean;
  dietaryPreferenceTagsOptions: TDietaryPreferenceOption[];
  hideModal: () => void;
}

export const TagsModal: FC<IProps> = ({ isModalOpen, dietaryPreferenceTagsOptions, hideModal }) => {
  const { heading, headingDescription } = localeWidget.tagsModal;

  return (
    <ModalWrapperNew isOpen={isModalOpen} onClose={hideModal}>
      <S.TagsModalContainer direction="column" gap={0}>
        <Flexbox direction="column" gap={0} padding="16px 16px 24px">
          <CloseButton handleClick={hideModal} position="right" />
          <Flexbox direction="column" gap={16}>
            <Text color={STATIC_COLORS.base.black} fontFamily="Fraunces" size="h6" text={heading} weight="semibold" />
            <Text color={STATIC_COLORS.base.black} size="body1" text={headingDescription} />
          </Flexbox>
        </Flexbox>
        <S.ContentWrapper direction="column" gap={16} padding="24px 16px">
          {dietaryPreferenceTagsOptions.map(({ name, description }, index) => (
            <Flexbox key={index} direction="column" gap={0}>
              <Text color={STATIC_COLORS.base.black} size="body2" text={name} weight="semibold" />
              <Text color={STATIC_COLORS.base.black} size="body2" text={description} />
            </Flexbox>
          ))}
        </S.ContentWrapper>
      </S.TagsModalContainer>
    </ModalWrapperNew>
  );
};
