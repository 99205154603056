import { FC } from 'react';

import { IParsedCharacter } from '@lib/core/characters/utils/parseCharacter';

import { ICharacterAdjectiveItemData } from '@components/web/src/atoms/CharacterAdjective/CharacterAdjective';
import { TSwiperData } from '@components/web/src/organisms/Authentication/AuthBanners/ExtraInfoSwiper/ExtraInfoSwiper';
import { ICharacterInfoCardProps } from '@components/web/src/organisms/Cards/CharacterInfo/CharacterInfo';
import ReadMoreCard, { IReadMoreCardProps } from '@components/web/src/organisms/Cards/ReadMoreCard/ReadMoreCard';
import CharacterAdjectivesGroup from '@components/web/src/organisms/CharacterAdjectivesGroup/CharacterAdjectivesGroup';
import CharacterInfoGroup from '@components/web/src/organisms/CharacterInfoGroup/CharacterInfoGroup';
import * as S from '@components/web/src/templates/TasteId/TasteIdPage/styles';

interface IProps {
  characterAdjectiveItemsData: ICharacterAdjectiveItemData[];
  characterAdjectiveModalData: TSwiperData[];
  characterData: IParsedCharacter;
  isLoading?: boolean;
  characterDetailCardsData: IReadMoreCardProps[];
  characterInfoCardsData: ICharacterInfoCardProps[];
  isUserAuthenticated: boolean;
  productCategory: string;
}

export const CharacterDetailsCommon: FC<IProps> = ({
  characterAdjectiveItemsData,
  characterAdjectiveModalData,
  isLoading = false,
  characterData,
  characterDetailCardsData,
  characterInfoCardsData,
  isUserAuthenticated,
  productCategory,
}) => {
  const characterDetailList = isLoading ? Array(2).fill({}) : characterDetailCardsData || [];
  return (
    <>
      <CharacterAdjectivesGroup
        characterAdjectiveItemsData={characterAdjectiveItemsData}
        characterAdjectiveModalData={characterAdjectiveModalData}
        characterData={characterData}
        isLoading={isLoading}
        isUserAuthenticated={isUserAuthenticated}
        productCategory={productCategory}
      />

      <S.CharacterDetailsSection>
        {characterDetailList.map((cardData, idx) => (
          <ReadMoreCard
            key={idx}
            cardTitle={cardData.cardTitle}
            cardTitleLocaleIndex={{ productCategory }}
            description={cardData.description}
            isLoading={isLoading}
            minimumCharacters={cardData.minimumCharacters}
            productCategory={productCategory}
            title={cardData.title}
          />
        ))}
      </S.CharacterDetailsSection>
      <CharacterInfoGroup characterInfoList={characterInfoCardsData} isLoading={isLoading} />
    </>
  );
};
