import { FC } from 'react';

import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import * as S from '@components/web/src/organisms/Skeleton/NewProductCardSkeleton/styles';

interface IProps {
  isResponsive?: boolean;
}

const NewPlaceCardSkeleton: FC<IProps> = ({ isResponsive = false }) => {
  return (
    <S.ProductCardSkeletonContainer $isResponsive={isResponsive} direction="column" gap={8}>
      <Flexbox align="center" justify="center">
        <S.ImageSkeleton />
      </Flexbox>
      <Flexbox direction="column" gap={8}>
        <S.SectionOne />
        <S.SectionTwo />
        <S.SectionThree />
        <S.SectionFour />
      </Flexbox>
    </S.ProductCardSkeletonContainer>
  );
};

export default NewPlaceCardSkeleton;
