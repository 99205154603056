import { FC, PropsWithChildren, SyntheticEvent } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useProductFeedback } from '@lib/core/products/hooks/useProductFeedback';
import { TParsedProductInstance } from '@lib/core/products/types';
import { TRetailerLocationStoreType } from '@lib/core/retailers/types';
import { RetailerLocationStoreType } from '@lib/core/retailers/utils/consts';
import { prependBasename, promotionsFilter } from '@lib/core/service/utils';
import MixpanelTracker from '@lib/tools/dat/mixpanel';
import { MP_INTERACTIVE_TASTING_STATUS, MixpanelPositionContext } from '@lib/tools/dat/mixpanel/consts';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import {
  PRODUCT_CARD_VARIANTS,
  PRODUCT_ID_URL_PARAM,
  PROMOTION_BADGE_FIDELITY,
  PROMOTION_BADGE_OTHER,
  PROMOTION_BADGE_PROMOTION,
  PROMOTION_LABEL_SLUG,
  VH_VARIANTS,
} from '@lib/tools/shared/helpers/consts';
import { useAddons } from '@lib/tools/views/hooks';
import { PAGES } from '@lib/tools/views/urls';

import addIcon from '@components/web/src/assets/icons/add_icon.svg';
import Button from '@components/web/src/atoms/Buttons/Button';
import PriceRange from '@components/web/src/atoms/PriceRange/PriceRange';
import ProductCardTopBanner from '@components/web/src/atoms/ProductCardElements/ProductCardTopBanner/ProductCardTopBanner';
import { RatingButton } from '@components/web/src/atoms/ProductRating/ProductRating/RatingButton';
import { Share } from '@components/web/src/atoms/Share/Share';
import Wishlist from '@components/web/src/atoms/Wishlist/Wishlist';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Image } from '@components/web/src/foundations/Image/Image';
import { Text } from '@components/web/src/foundations/Text/Text';
import { CardFooter } from '@components/web/src/organisms/CardFooter/CardFooter';
import * as S from '@components/web/src/organisms/ProductCards/OldProductCard/styles';
import { PromotionBadges } from '@components/web/src/organisms/PromotionBadges/PromotionBadges';
import { ProductCardSkeleton } from '@components/web/src/organisms/Skeleton/ProductCardSkeleton/ProductCardSkeleton';
import { SkeletonWrapper } from '@components/web/src/organisms/Skeleton/SkeletonWrapper';
import FindProductPopup from '@components/web/src/shared/FindProductPopup/FindProductPopup';
import { IDiscoveryQuiz } from '@components/web/src/templates/Modals/DiscoveryQuizModal/DiscoveryQuizModal';

export type TProductCardVariants =
  | typeof PRODUCT_CARD_VARIANTS.DEFAULT
  | typeof PRODUCT_CARD_VARIANTS.TOP_BANNER
  | typeof PRODUCT_CARD_VARIANTS.BADGES
  | typeof PRODUCT_CARD_VARIANTS.COMPACT;

interface IBaseProductCardProps {
  isLoading: boolean;
  storeType: TRetailerLocationStoreType;
  variant?: TProductCardVariants;
  isResponsive?: boolean;
}

interface IBaseProductCardPropsSkeleton extends IBaseProductCardProps {
  mixpanelIndex?: undefined;
  mixpanelPositionContext?: undefined;
  parsedProductInstanceData?: undefined;
  intl?: undefined;
  isProductInstanceInWishlist?: boolean;
  isWishlistProductListLoading?: boolean;
  shouldHideShare?: boolean;
  shouldHideRatingButton?: boolean;
  discoveryQuiz?: IDiscoveryQuiz;
  isProductCardClickDisabled?: boolean;
  shouldShowFeedbackTooltip?: boolean;
  handleAuthFeatureClick?: () => void;
  shouldHideWishlist?: boolean;
}

interface IBaseProductCardPropsFull extends IBaseProductCardProps {
  /** Numerical index of the product card in the list */
  mixpanelIndex?: number | null;
  /** Descriptive name for the parent component wrapping the product card (`Swiper`, `Scrollable Catalog`) */
  mixpanelPositionContext: MixpanelPositionContext;
  parsedProductInstanceData: TParsedProductInstance;
  isEnableLocationMapAddon?: boolean;
  shouldHideRatingButton?: boolean;
  shouldHideWishlist?: boolean;
  shouldHideShare?: boolean;
  discoveryQuiz?: IDiscoveryQuiz;
  isProductCardClickDisabled?: boolean;
  shouldShowFeedbackTooltip?: boolean;
}

export type IProductCardProps = IBaseProductCardPropsSkeleton | IBaseProductCardPropsFull;

const ProductCard: FC<IProductCardProps> = ({
  mixpanelIndex = null,
  mixpanelPositionContext,
  parsedProductInstanceData,
  storeType,
  variant = 'default',
  shouldHideWishlist = false,
  shouldHideRatingButton = false,
  shouldHideShare,
  isResponsive = false,
  isLoading,
  isProductCardClickDisabled = false,
  discoveryQuiz,
  shouldShowFeedbackTooltip = false,
}) => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  const { productCard, commonMessages } = localeCommon;

  const {
    productInstanceId,
    productId,
    productCategory,
    productCharacterName,
    productCharacterTechnicalCaption,
    productName,
    productImage,
    productDescription,
    productTags,
    productBadges,
    productFormat,
    productFormatSize,
    productFormatName,
    productProducerName,
    productRegionName,
    promotions,
    productRegionCountry,
    productProducerUrl,
    productDiscountPrice,
    productOriginalPriceAndCurrency,
    productDiscountPriceAndCurrency,
    productPriceCurrencySymbol,
    productOriginalPrice,
    isFindProductButtonEnable,
    productLocationDescription,
    productLocationMap,
    productEcommerceId,
    isFindProductFunctionalityEnable,
    handleUpdateWishlistProductList,
    isWishlistProductListLoading,
    isProductInstanceInWishlist,
    isEnableLocationMapAddon,
    isEnableVusionAddon,
    productFeedbackStatus,
    productInstanceData,
  } = parsedProductInstanceData || {};
  const isProductDiscountAvailable = !!productDiscountPrice && !!productOriginalPrice;

  const { handleToggleOpenedFeedbackModal } = useProductFeedback();
  const { isPriceRangeAddon } = useAddons();

  const isShowPromoLabel = !!promotionsFilter(promotions, [PROMOTION_LABEL_SLUG]).length;
  const isShowRatingButton =
    (!shouldHideRatingButton && productFeedbackStatus?.isDisabled) ||
    (!productFeedbackStatus?.isDisabled && productFeedbackStatus?.isFinished);
  const isProductFeedbackEnabled = productFeedbackStatus?.isEnabled && !productFeedbackStatus?.isFinished;

  const promotionBadges = promotionsFilter(promotions, [
    PROMOTION_BADGE_FIDELITY,
    PROMOTION_BADGE_PROMOTION,
    PROMOTION_BADGE_OTHER,
  ]);

  const isTopBannerVariant = variant === PRODUCT_CARD_VARIANTS.TOP_BANNER;
  const isBadgesVariant = variant === PRODUCT_CARD_VARIANTS.BADGES;
  const isCompactVariant = variant === PRODUCT_CARD_VARIANTS.COMPACT;
  const isProductRegionAvailable = !!productRegionCountry && !!productRegionName;

  const handleAreaClick = (e: any) => e.stopPropagation();

  const redirectToProductPage = (e: SyntheticEvent) => {
    e.stopPropagation();

    const isExternalTarget = storeType === RetailerLocationStoreType.ecommerce;

    if (storeType === RetailerLocationStoreType.main) {
      navigate(prependBasename(PAGES.vinhood.product, { [PRODUCT_ID_URL_PARAM]: productInstanceId }), {
        state: { from: pathname + search },
      });

      MixpanelTracker.events.productClick(parsedProductInstanceData, mixpanelIndex, mixpanelPositionContext);
    } else if (isExternalTarget && productProducerUrl) {
      window.open(productProducerUrl, '_blank');
      MixpanelTracker.events.contactTheProductProducer(
        parsedProductInstanceData,
        mixpanelIndex,
        mixpanelPositionContext,
      );
    }
  };

  const handleToggleOpenedFeedbackModalProduct = (e: SyntheticEvent) => {
    e.stopPropagation();
    handleToggleOpenedFeedbackModal({ productInstanceData });

    MixpanelTracker.events.interactiveTastingClick(parsedProductInstanceData, MP_INTERACTIVE_TASTING_STATUS.PENDING);
  };

  return (
    <SkeletonWrapper showSkeleton={isLoading} skeleton={() => ProductCardSkeleton({ isResponsive })}>
      <S.ProductCardContainer
        $isResponsive={isResponsive}
        direction="column"
        gap={0}
        onClick={e => !isProductCardClickDisabled && redirectToProductPage(e)}
      >
        {isTopBannerVariant && (
          <ProductCardTopBanner
            characterName={productCharacterName}
            productCategory={productCategory}
            technicalCaption={productCharacterTechnicalCaption}
          />
        )}
        <S.ProductImageSection
          $isProductFeedbackFinished={productFeedbackStatus?.isFinished}
          $productCategory={productCategory}
          align="center"
          justify="center"
        >
          {isShowPromoLabel && (
            <S.PromoIcon className="promo-icon">
              <Text
                color={STATIC_COLORS.base.white}
                size="body3"
                text={commonMessages.promo}
                textTransform="uppercase"
                weight="semibold"
              />
            </S.PromoIcon>
          )}
          <S.ProductImageWrapper align="center" justify="center">
            <S.ProductImage
              $isProductFeedbackEnabled={isProductFeedbackEnabled}
              alt={productId}
              height="85%"
              objectFit="contain"
              src={productImage}
            />
            {isProductFeedbackEnabled && (
              <S.ProductFeedbackIconWrapper
                align="center"
                justify="center"
                onClick={handleToggleOpenedFeedbackModalProduct}
              >
                <img alt="product feedback icon" src={addIcon} />
              </S.ProductFeedbackIconWrapper>
            )}
          </S.ProductImageWrapper>
          <S.ProductTags>
            {productTags?.map((tag, idx) => (
              <Image key={idx} alt={`${productId}-${tag.tagName}`} height="32px" src={tag.icon} width="32px" />
            ))}
          </S.ProductTags>
          <S.ProductImageCtaContainer align="center" direction="column" gap={0} onClick={handleAreaClick}>
            {!shouldHideWishlist && (
              <Wishlist
                isItemInWishlist={isProductInstanceInWishlist}
                isItemListLoading={isWishlistProductListLoading}
                handleUpdateWishlist={() => {
                  if (handleUpdateWishlistProductList) handleUpdateWishlistProductList(productId, productName);
                  MixpanelTracker.events.productBookmark(
                    parsedProductInstanceData,
                    mixpanelIndex,
                    mixpanelPositionContext,
                  );
                }}
              />
            )}
            {isShowRatingButton && (
              <RatingButton
                discoveryQuiz={discoveryQuiz}
                parsedProductInstanceData={parsedProductInstanceData}
                shouldShowFeedbackTooltip={shouldShowFeedbackTooltip}
              />
            )}
            {!shouldHideShare && (
              <Share
                isFromProductCardSharedLink
                mixpanelPositionContext={mixpanelPositionContext}
                parsedProductInstanceData={parsedProductInstanceData}
              />
            )}
            {/* TODO currently only made available on product details, we add the logic here  later */}
            {/* <ReactPdf product={productInstanceData} /> */}
          </S.ProductImageCtaContainer>
        </S.ProductImageSection>
        <S.ProductContentSection>
          <S.ProducerName
            shouldRenderEmptySelector
            color={STATIC_COLORS.base.black}
            size="body1"
            text={productProducerName}
            weight="semibold"
          />
          <S.StyledProductName
            shouldRenderEmptySelector
            color={STATIC_COLORS.base.black}
            fontFamily="Fraunces"
            linesLimit={2}
            size="h5"
            text={productName}
            weight="semibold"
          />
          <S.ProductRegionText
            shouldRenderEmptySelector
            color={STATIC_COLORS.base.black}
            size="body1"
            text={(isProductRegionAvailable && `${productRegionName}, ${productRegionCountry}`) || ''}
          />
          <S.ProductCharacterDetailsText
            color={STATIC_COLORS.green['600']}
            linesLimit={1}
            size="body2"
            text={`${productCharacterName && `#${productCharacterName} • `} ${productCharacterTechnicalCaption}`}
            weight="medium"
          />
          {!isCompactVariant && (
            <>
              <S.StyledProductDescription color={STATIC_COLORS.base.black} size="body2" text={productDescription} />

              <S.ProductPriceFormatContainer>
                {productFormat && (
                  <S.StyledProductFormatText
                    size="body2"
                    text={`${productFormatName} ${productFormatSize && `, ${productFormatSize}`}`}
                  />
                )}
                {isPriceRangeAddon ? (
                  <PriceRange
                    currencySymbolValue={productPriceCurrencySymbol}
                    originalPrice={productOriginalPrice}
                    productCategory={productCategory}
                  />
                ) : (
                  <S.ProductPriceContainer>
                    {isProductDiscountAvailable && (
                      <Text
                        color={STATIC_COLORS.gray[400]}
                        size="subtitle1"
                        text={productOriginalPriceAndCurrency}
                        textDecoration="line-through"
                      />
                    )}
                    {(isProductDiscountAvailable || !!productOriginalPrice) && (
                      <Text
                        color={STATIC_COLORS.base.black}
                        size="subtitle1"
                        weight="semibold"
                        text={
                          isProductDiscountAvailable ? productDiscountPriceAndCurrency : productOriginalPriceAndCurrency
                        }
                      />
                    )}
                  </S.ProductPriceContainer>
                )}
              </S.ProductPriceFormatContainer>
            </>
          )}
          {!!promotionBadges?.length && <PromotionBadges promotionsData={promotionBadges} />}
        </S.ProductContentSection>
        {!isCompactVariant && (
          <S.ProductCTASection>
            {(storeType === RetailerLocationStoreType.main ||
              (storeType === RetailerLocationStoreType.ecommerce && productProducerUrl)) && (
              <Button
                className="give-it-button"
                size="sm"
                text={productCard.ctaGiveItBtn}
                textWeight="semibold"
                variant={VH_VARIANTS.LINK}
                onClick={!isCompactVariant && redirectToProductPage}
              />
            )}
            {isFindProductFunctionalityEnable && (
              <div
                className="find-product"
                role="button"
                tabIndex={0}
                onClick={handleAreaClick}
                onKeyDown={handleAreaClick}
              >
                {isFindProductButtonEnable && (
                  <FindProductPopup
                    isEnableLocationMapAddon={isEnableLocationMapAddon}
                    isEnableVusionAddon={isEnableVusionAddon}
                    productEcommerceId={productEcommerceId}
                    productLocationDescription={productLocationDescription}
                    productLocationMap={productLocationMap}
                  >
                    <Button
                      className="find-product-button"
                      iconVariant="location"
                      size="sm"
                      text={productCard.findMeBtn}
                      variant={VH_VARIANTS.PRIMARY}
                      onClick={() =>
                        MixpanelTracker.events.findMe(parsedProductInstanceData, mixpanelIndex, mixpanelPositionContext)
                      }
                    />
                  </FindProductPopup>
                )}
              </div>
            )}
            {isBadgesVariant && (
              <S.StyledBadgesWrapper>
                <CardFooter badges={productBadges} />
              </S.StyledBadgesWrapper>
            )}
          </S.ProductCTASection>
        )}
      </S.ProductCardContainer>
    </SkeletonWrapper>
  );
};

// Taken from https://github.com/microsoft/TypeScript/issues/31501#issuecomment-1280579305
// This is necessary to make a Union of Omit types. Omit by design merges
// Union types, therefore the ability to use "Discriminated Unions" is lost. This
// Method preserves Discriminated Unions and allows to enforce correct behavior.
// If you don't understand how this Type works, ask ChatGPT4.
type OmitUnion<T, K extends keyof any> = T extends any ? Omit<T, K> : never;

export default ProductCard as FC<OmitUnion<PropsWithChildren<IProductCardProps>, 'intl'>>;
