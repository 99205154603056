import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  selectProductFeedbackCachedAnswersByUrl,
  selectProductFeedbackQuestions,
} from '@lib/core/products/selectors/productFeedback';
import { TParsedProductInstance, TProductInstance } from '@lib/core/products/types';
import { REQUEST_METHODS } from '@lib/core/service/consts';
import { createTypedAsyncThunk } from '@lib/core/service/createTypedAsyncThunk';
import request from '@lib/core/service/requests/request';
import { getProductFeedbackApiUrlCreator, productFeedbackApiUrlCreator } from '@lib/core/users/slices/urls';

export enum ProductFeedbackSteps {
  Tutorial = 1,
  Start = 2,
  Questions = 3,
  Rating = 4,
  Comment = 5,
  Done = 6,
  ProductFeedbackResult = 7,
}

export const QuestionContext = { aroma: 'arm', color: 'pcm', taste: 'ptm' } as const;

export type TQuestionContextVariant = (typeof QuestionContext)[keyof typeof QuestionContext];

export interface IActionToggleOpenedFeedbackModalProductProps {
  productInstanceData?: TProductInstance;
  retailerLocationId: string;
  serviceProductCategory: string;
  productFeedbackModalStep?: ProductFeedbackSteps;
}

export interface IProductFeedbackColorAnswer {
  description: string;
  color: string;
  name: string;
  slug: string;
}

export interface IProductFeedbackAromaAnswer {
  description: string;
  identifier: string;
  color: string;
  image: string;
  secondaryImage: string;
}

export interface IProductFeedbackTasteAnswer {
  description: string;
  name: string;
  slug: string;
}

export type IProductFeedbackAnswer = Partial<
  IProductFeedbackColorAnswer & IProductFeedbackAromaAnswer & IProductFeedbackTasteAnswer
>;

export interface IProductFeedbackQuestionRawData {
  choices: string[];
  context: TQuestionContextVariant;
  description: string;
  flow: string;
  identifier: string;
  spot: number;
  subtitle: string;
  title: string;
  variety: string;
  image?: string;
}

export interface IProductFeedback {
  choices: { questions: IProductFeedbackQuestion[] };
  description: string;
  name: string;
}

export interface IProductFeedbackLoaded {
  product: string;
  answer: IProductFeedbackAnswer;
  question: IProductFeedbackQuestionRawData;
}

export type IProductFeedbackAnswerLoadedOrPending =
  | IProductFeedbackLoaded
  | { urlToBeLoaded?: string; isRequestFailed?: boolean };

export interface IPostFeedbackAnswer {
  product: string;
  question: string;
  answer: string;
  context: string;
}

export interface ISaveFeedbackAnswer {
  product: string;
  question: string;
  answer: IProductFeedbackAnswer;
  context: string;
}

export interface IProductFeedbackQuestion extends IProductFeedbackQuestionRawData {
  isFinished?: boolean;
  answer?: ISaveFeedbackAnswer;
  answers: IProductFeedbackAnswerLoadedOrPending[];
}

export interface IAnswerFinishProps {
  questionId: string;
}

export interface IProductFeedbackSlice {
  data: IProductFeedback;
  isQuestionsLoading: boolean;
  isDataLoading: boolean;
  isSubmitProductFeedbackAnswersLoading: boolean;
  productInstanceData: TProductInstance;
  productFeedbackModalStep: ProductFeedbackSteps;
  questions: IProductFeedbackQuestion[];
  retailerLocationId: string;
  serviceProductCategory: string;
  answeredProductsFeedbacksByProductsId: Record<string, IProductFeedbackLoaded[]>;
  cachedAnswersByUrl: Record<string, IProductFeedbackAnswer>;
  locale: string;
  tutorialProduct: TParsedProductInstance;
}

const initialState: IProductFeedbackSlice = {
  answeredProductsFeedbacksByProductsId: {},
  cachedAnswersByUrl: {},
  data: null,
  isDataLoading: false,
  isQuestionsLoading: false,
  isSubmitProductFeedbackAnswersLoading: false,
  locale: '',
  productFeedbackModalStep: ProductFeedbackSteps.Start,
  productInstanceData: null,
  questions: [],
  retailerLocationId: '',
  serviceProductCategory: '',
  tutorialProduct: null,
};

export const actionGetQuestionAnswer = createAsyncThunk<IProductFeedbackAnswer, { url: string; questionId: string }>(
  'actionGetQuestionAnswer',
  async ({ url }) =>
    request(url, { additionalHeaders: { Accept: 'application/json; version="2.0"' }, method: REQUEST_METHODS.GET }),
);

export const actionLoadProductFeedbackAnswers = createTypedAsyncThunk<void, { questions: IProductFeedbackQuestion[] }>(
  'actionLoadProductFeedbackAnswers',
  async ({ questions }, { dispatch, getState }) => {
    const state = getState();
    const cachedAnswersByUrl = selectProductFeedbackCachedAnswersByUrl(state);

    const nonCachedAnswers = questions.flatMap(({ identifier, choices }) =>
      choices.filter(url => !cachedAnswersByUrl[url]).map(url => ({ identifier, url })),
    );

    const processAnswersSequentially = async (index = 0) => {
      if (index >= nonCachedAnswers.length) return;

      const { identifier, url } = nonCachedAnswers[index];
      try {
        await dispatch(actionGetQuestionAnswer({ questionId: identifier, url })).unwrap();
      } catch (error) {
        console.error('Error while processing answer:', error);
      }

      await processAnswersSequentially(index + 1);
    };

    await processAnswersSequentially();
  },
);

export const actionGetProductFeedbackQuestions = createTypedAsyncThunk<
  IProductFeedback,
  { productCategory: string; characterTypeSlug: string; productId: string; locale?: string }
>('actionGetProductFeedbackQuestions', async (args, { dispatch }) => {
  const response = await request(getProductFeedbackApiUrlCreator(args), { method: REQUEST_METHODS.OPTIONS });
  dispatch(actionLoadProductFeedbackAnswers({ questions: response.choices.questions }));
  return response;
});

export const actionGetExistingProductFeedbacks = createAsyncThunk<IProductFeedbackLoaded[], { locale?: string }>(
  'actionGetExistingProductFeedbacks',
  async () => {
    const fetchFeedbacks = async (
      url: string | null,
      accumulated: IProductFeedbackLoaded[] = [],
    ): Promise<IProductFeedbackLoaded[]> => {
      if (!url) return accumulated;
      const { results, next } = await request(url);
      return fetchFeedbacks(next, [...accumulated, ...results]);
    };

    return fetchFeedbacks(productFeedbackApiUrlCreator());
  },
);

export const actionPostProductFeedbackAnswer = createAsyncThunk<IProductFeedbackLoaded, IPostFeedbackAnswer>(
  'actionPostProductFeedbackAnswer',
  async data => request(productFeedbackApiUrlCreator(), { method: REQUEST_METHODS.POST }, data),
);

export const actionSubmitProductFeedbackAnswers = createTypedAsyncThunk<IProductFeedbackLoaded[]>(
  'actionSubmitProductFeedbackAnswers',
  async (_, { getState, dispatch }) => {
    const state = getState();
    const questions = selectProductFeedbackQuestions(state);

    const promises = questions.map(question =>
      dispatch(
        actionPostProductFeedbackAnswer({
          ...question.answer,
          answer: question.answer.answer.slug || question.answer.answer.identifier,
        }),
      ).unwrap(),
    );

    return Promise.all(promises);
  },
);

export const productFeedbackNewSlice = createSlice({
  extraReducers: builder => {
    builder.addCase(actionGetProductFeedbackQuestions.pending, (state, action) => {
      const { locale } = action?.meta?.arg;
      if (locale && locale !== state.locale) {
        state.cachedAnswersByUrl = {};
      }
      state.locale = locale;
      state.isQuestionsLoading = true;
    });
    builder.addCase(actionGetProductFeedbackQuestions.rejected, state => {
      state.isQuestionsLoading = false;
    });
    builder.addCase(actionGetProductFeedbackQuestions.fulfilled, (state, { payload }) => {
      state.isQuestionsLoading = false;
      const newData: IProductFeedback = { ...payload };

      if (newData.choices?.questions) {
        newData.choices.questions = newData.choices.questions.map(question => {
          const savedQuestion = state.data?.choices?.questions?.find(
            existingQuestion => existingQuestion?.identifier === question.identifier,
          );
          return {
            ...question,
            ...(savedQuestion?.answer && { answer: savedQuestion?.answer }),
            ...(savedQuestion?.isFinished && { isFinished: true }),
            answers: question.choices.map(answerUrl =>
              state.cachedAnswersByUrl[answerUrl]
                ? {
                    answer: state.cachedAnswersByUrl[answerUrl],
                    product: state.productInstanceData?.product?.identifier,
                    question,
                  }
                : { urlToBeLoaded: answerUrl },
            ),
          };
        });
      }
      state.data = newData;
    });

    builder.addCase(actionGetQuestionAnswer.fulfilled, (state, { payload, meta }) => {
      const { questionId, url } = meta?.arg;
      const { productInstanceData, cachedAnswersByUrl, data: stateFeedbackData } = state;

      if (stateFeedbackData?.choices.questions) {
        stateFeedbackData.choices.questions = stateFeedbackData.choices.questions.map(question =>
          question.identifier === questionId
            ? {
                ...question,
                answers: question.answers
                  ? question.answers.map(answer => {
                      if ('urlToBeLoaded' in answer) {
                        return answer.urlToBeLoaded === url
                          ? {
                              answer: payload,
                              product: productInstanceData?.product?.identifier,
                              question: { ...question, answers: [] },
                            }
                          : answer;
                      }
                      return answer;
                    })
                  : [
                      {
                        answer: payload,
                        product: productInstanceData?.product?.identifier,
                        question: { ...question, answers: [] },
                      },
                    ],
              }
            : question,
        );
      }

      state.cachedAnswersByUrl = { ...cachedAnswersByUrl, [url]: payload };
    });

    builder.addCase(actionGetQuestionAnswer.rejected, (state, { payload, meta }) => {
      const { questionId, url } = meta?.arg;
      const { productInstanceData, cachedAnswersByUrl, data: stateFeedbackData } = state;

      if (stateFeedbackData?.choices.questions) {
        stateFeedbackData.choices.questions = stateFeedbackData.choices.questions.map(question =>
          question.identifier === questionId
            ? {
                ...question,
                answers: question.answers
                  ? question.answers.map(answer => {
                      if ('urlToBeLoaded' in answer) {
                        return answer.urlToBeLoaded === url ? { ...answer, isRequestFailed: true } : answer;
                      }
                      return answer;
                    })
                  : [
                      {
                        answer: payload,
                        product: productInstanceData?.product?.identifier,
                        question: { ...question, answers: [] },
                      },
                    ],
              }
            : question,
        );
      }

      state.cachedAnswersByUrl = { ...cachedAnswersByUrl, [url]: payload };
    });

    builder.addCase(actionSubmitProductFeedbackAnswers.pending, state => {
      state.isSubmitProductFeedbackAnswersLoading = true;
    });
    builder.addCase(actionSubmitProductFeedbackAnswers.rejected, state => {
      state.isSubmitProductFeedbackAnswersLoading = false;
    });
    builder.addCase(actionSubmitProductFeedbackAnswers.fulfilled, state => {
      state.isSubmitProductFeedbackAnswersLoading = false;
    });
    builder.addCase(actionPostProductFeedbackAnswer.fulfilled, (state, { payload, meta }) => {
      const { arg: { product: productId, question: questionId } = {} } = meta;
      const { data: stateFeedbackData, answeredProductsFeedbacksByProductsId } = state;

      if (stateFeedbackData?.choices.questions) {
        const completeAnswerObject: IProductFeedbackLoaded = {
          answer: payload.answer,
          product: productId,
          question: stateFeedbackData.choices.questions.find(q => q.identifier === questionId),
        };

        state.answeredProductsFeedbacksByProductsId = {
          ...answeredProductsFeedbacksByProductsId,
          [productId]: [...(answeredProductsFeedbacksByProductsId[productId] || []), completeAnswerObject],
        };
      }
    });

    builder.addCase(actionGetExistingProductFeedbacks.pending, (state, action) => {
      state.isDataLoading = true;
      const { locale } = action?.meta?.arg;
      if (locale && locale !== state.locale) {
        state.cachedAnswersByUrl = {};
        state.locale = locale;
      }
    });
    builder.addCase(actionGetExistingProductFeedbacks.rejected, state => {
      state.isDataLoading = false;
    });
    builder.addCase(actionGetExistingProductFeedbacks.fulfilled, (state, { payload }) => {
      state.isDataLoading = false;

      const answeredProductsFeedbacksByProductsId: Record<string, IProductFeedbackLoaded[]> = {};

      payload.forEach(answerObj => {
        answeredProductsFeedbacksByProductsId[answerObj.product] = [
          ...(answeredProductsFeedbacksByProductsId[answerObj.product] || []),
          answerObj,
        ];
      });
      state.answeredProductsFeedbacksByProductsId = answeredProductsFeedbacksByProductsId;
    });
  },
  initialState,
  name: 'productFeedbackNew',
  reducers: {
    actionDoneProductFeedbackAnswer: (state, action: PayloadAction<{ questionId: string }>) => {
      const {
        payload: { questionId: payloadQuestionId },
      } = action;
      const { data: stateData, productFeedbackModalStep: stateCurrentFeedbackType } = state;

      if (stateData?.choices.questions) {
        stateData.choices.questions = stateData.choices.questions.map(question =>
          question.identifier === payloadQuestionId ? { ...question, isFinished: true } : question,
        );
      }

      const nextQuestion = stateData?.choices?.questions?.find(question => !question.isFinished);

      if (!nextQuestion) {
        state.productFeedbackModalStep = stateCurrentFeedbackType + 1;
      }
    },

    actionForwardProductFeedbackStep: state => {
      if (state.productFeedbackModalStep < Object.keys(ProductFeedbackSteps).length) {
        state.productFeedbackModalStep += 1;
      } else {
        return initialState;
      }
      return state;
    },
    actionResetProductFeedbackFlow: state => ({
      ...initialState,
      answeredProductsFeedbacksByProductsId: state.answeredProductsFeedbacksByProductsId,
      cachedAnswersByUrl: state.cachedAnswersByUrl,
      locale: state.locale,
    }),
    actionResetProductFeedbackSlice: () => initialState,
    actionSaveProductFeedbackAnswer: (state, action: PayloadAction<ISaveFeedbackAnswer>) => {
      state.isSubmitProductFeedbackAnswersLoading = false;

      const { payload: { question: questionId } = {} } = action;
      const { data: stateFeedbackData } = state;

      if (stateFeedbackData?.choices.questions) {
        stateFeedbackData.choices.questions = stateFeedbackData.choices.questions.map(question =>
          question.identifier === questionId ? { ...question, answer: action.payload } : question,
        );
      }
    },
    actionToggleOpenedFeedbackModal: (state, action: PayloadAction<IActionToggleOpenedFeedbackModalProductProps>) => {
      const {
        retailerLocationId: payloadRetailerLocationId,
        serviceProductCategory: payloadServiceProductCategory,
        productFeedbackModalStep: payloadCurrentFeedbackType,
      } = action.payload;

      const {
        retailerLocationId: stateRetailerLocationId,
        serviceProductCategory: stateServiceProductCategory,
        productInstanceData: stateProductInstanceData,
        productFeedbackModalStep: stateCurrentFeedbackType,
      } = state;

      const isProductFeedbackResultPage = payloadCurrentFeedbackType === ProductFeedbackSteps.ProductFeedbackResult;

      const isRetailerChanged =
        (payloadRetailerLocationId &&
          stateRetailerLocationId &&
          stateRetailerLocationId !== payloadRetailerLocationId) ||
        (payloadServiceProductCategory &&
          stateServiceProductCategory &&
          stateServiceProductCategory !== payloadServiceProductCategory);

      if (isProductFeedbackResultPage || isRetailerChanged) {
        return {
          ...initialState,
          ...action.payload,
          answeredProductsFeedbacksByProductsId: state.answeredProductsFeedbacksByProductsId,
          cachedAnswersByUrl: isRetailerChanged ? initialState.cachedAnswersByUrl : state.cachedAnswersByUrl,
          data: isRetailerChanged ? initialState.data : state.data,
          locale: isRetailerChanged ? initialState.locale : state.locale,
          productFeedbackModalStep: isRetailerChanged
            ? payloadCurrentFeedbackType ?? ProductFeedbackSteps.Start
            : payloadCurrentFeedbackType ?? stateCurrentFeedbackType,
        };
      }

      if (stateProductInstanceData) {
        return {
          ...state,
          data: null,
          productFeedbackModalStep: ProductFeedbackSteps.Start,
          productInstanceData: null,
          questions: [],
        };
      }

      return {
        ...state,
        ...action.payload,
        productFeedbackModalStep: payloadCurrentFeedbackType ?? stateCurrentFeedbackType,
      };
    },
    actionToggleProductFeedbackTutorial: (
      state,
      action: PayloadAction<{ parsedProductInstance?: TParsedProductInstance }>,
    ) => {
      state.productFeedbackModalStep = action.payload.parsedProductInstance
        ? ProductFeedbackSteps.Tutorial
        : ProductFeedbackSteps.Start;
      state.tutorialProduct = action.payload.parsedProductInstance;
    },
  },
});

export default productFeedbackNewSlice.reducer;
export const {
  actionToggleOpenedFeedbackModal,
  actionToggleProductFeedbackTutorial,
  actionForwardProductFeedbackStep,
  actionDoneProductFeedbackAnswer,
  actionResetProductFeedbackSlice,
  actionResetProductFeedbackFlow,
  actionSaveProductFeedbackAnswer,
} = productFeedbackNewSlice.actions;
