import { selectIsClientIpBlocked } from '@lib/core/service/selectors/technical';
import { store } from '@lib/core/service/store';
import initFacebookPixel from '@lib/tools/dat/facebookPixel';
import initHotjar from '@lib/tools/dat/hotjar';
import { initMixpanel } from '@lib/tools/dat/mixpanel';

const initTrackers = () => {
  const state = store.getState();
  const isClientIpBlocked = selectIsClientIpBlocked(state);

  initMixpanel();

  // * Above this block: trackings that handle blacklisted IPs internally
  if (isClientIpBlocked) return;
  // * Below this block: trackings that don't handle blacklisted IPs internally

  initHotjar();

  initFacebookPixel();
};

export default initTrackers;
