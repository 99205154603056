import { FC } from 'react';

import MixpanelTracker from '@lib/tools/dat/mixpanel';
import { localeWidget } from '@lib/tools/locale/source/web/widget';
import { LocaleFragment } from '@lib/tools/locale/views/LocaleFragment';

import hideAllProducts from '@components/web/src/assets/legacy/b2b/icons/hideAllProducts.svg';
import seeAllProducts from '@components/web/src/assets/legacy/b2b/icons/seeAllProducts.svg';
import toggleAllProducts from '@components/web/src/assets/legacy/b2b/icons/toggleAllProducts.svg';

export interface Props {
  handleClick: () => void;
  isOpenedState: boolean;
}

const SeeAllProductsButton: FC<Props> = ({ handleClick, isOpenedState }) => {
  const { productCatalog: productCatalogMessagesWidget } = localeWidget;

  return (
    <div className="container-width">
      <div
        aria-hidden
        className="see-all-products-button"
        role="button"
        tabIndex={0}
        onClick={() => {
          handleClick();
          MixpanelTracker.events.seeAllProducts(isOpenedState);
        }}
      >
        <img alt="" src={toggleAllProducts} />
        <span>
          {/* @ts-ignore */}
          <LocaleFragment message={productCatalogMessagesWidget.seeOtherProducts} />
        </span>
        <img alt="" src={isOpenedState ? hideAllProducts : seeAllProducts} />
      </div>
    </div>
  );
};

export default SeeAllProductsButton;
