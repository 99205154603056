import cn from 'classnames';
import { FC } from 'react';

import { TProductCatalogOrdering } from '@lib/tools/filterManager/slices/productFilter';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { LocaleFragment } from '@lib/tools/locale/views/LocaleFragment';
import { ORDERING_TYPE_PRICE, ORDERING_TYPE_RANK } from '@lib/tools/shared/helpers/consts';

import ButtonsGroup from '@components/web/src/atoms/ButtonsGroup/ButtonsGroup';

/**
 * @todo Remove 'isHide' later (currently added only to visualize on storybook)
 */
interface Props {
  isProductsRequestLoading?: boolean;
  itemsCount?: number;
  currentDesignSet?: string;
  activeOrdering?: TProductCatalogOrdering;
  handleChangeOrdering?: (order: TProductCatalogOrdering) => void;
  isChangeOrderingEnabled?: boolean;
}
const SortingSection: FC<Props> = ({
  itemsCount,
  activeOrdering,
  isProductsRequestLoading,
  handleChangeOrdering,
  isChangeOrderingEnabled = false,
}) => {
  const { productCatalog } = localeCommon;

  const { sortRelevanceButtonText, sortPriceButtonText } = productCatalog;

  const groupItemList = [
    { id: ORDERING_TYPE_RANK, isActive: activeOrdering === ORDERING_TYPE_RANK, text: sortRelevanceButtonText },
    { id: ORDERING_TYPE_PRICE, isActive: activeOrdering === ORDERING_TYPE_PRICE, text: sortPriceButtonText },
  ];

  return (
    <div className="sorting-section-container">
      <div className="search-count">
        {typeof itemsCount === 'number' && !isProductsRequestLoading && (
          <>
            <span className="count">{`${itemsCount} `}</span>
            <LocaleFragment message={productCatalog.matchingText} />
          </>
        )}
      </div>
      <div
        className={cn({
          'd-none': !isChangeOrderingEnabled,
        })}
      >
        <ButtonsGroup
          handleClick={item => handleChangeOrdering(item as TProductCatalogOrdering)}
          itemList={groupItemList}
        />
      </div>
    </div>
  );
};

export default SortingSection;
