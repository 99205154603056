import { FC, useMemo, useState } from 'react';

import { useCatalogFilter } from '@lib/tools/filterManager/hooks';
import { IFilterChangeAction, ISublistFilterState } from '@lib/tools/filterManager/slices/sublistFilterSlice';
import { ILocaleText } from '@lib/tools/locale/views/LocaleFragment';
import {
  FILTER_TYPE_CHARACTERISTICS,
  FILTER_TYPE_FORMAT,
  FILTER_TYPE_LOCATION,
  FILTER_TYPE_MOOD,
  FILTER_TYPE_ORIGIN,
  FILTER_TYPE_PRICE_RANGE,
  FILTER_TYPE_PRODUCT_CATEGORY,
  FILTER_TYPE_RATING,
  FILTER_TYPE_STYLE,
} from '@lib/tools/shared/helpers/consts';
import { useTypedSelector } from '@lib/tools/views/hooks';

import { ReactComponent as characteristicsIcon } from '@components/web/src/assets/legacy/b2c/icons/filter_characteristics.svg';
import { ReactComponent as HideIcon } from '@components/web/src/assets/legacy/b2c/icons/filter_minus.svg';
import { ReactComponent as moodIcon } from '@components/web/src/assets/legacy/b2c/icons/filter_mood.svg';
import { ReactComponent as originIcon } from '@components/web/src/assets/legacy/b2c/icons/filter_origin.svg';
import { ReactComponent as OpenIcon } from '@components/web/src/assets/legacy/b2c/icons/filter_plus.svg';
import { ReactComponent as PriceIcon } from '@components/web/src/assets/legacy/b2c/icons/filter_price.svg';
import { ReactComponent as productFormatIcon } from '@components/web/src/assets/legacy/b2c/icons/filter_product_format.svg';
import { ReactComponent as productCategoryIcon } from '@components/web/src/assets/legacy/b2c/icons/filter_productType.svg';
import { ReactComponent as styleIcon } from '@components/web/src/assets/legacy/b2c/icons/filter_style.svg';
import { ReactComponent as ratingIcon } from '@components/web/src/assets/legacy/icons/filter_rating_icon.svg';
import PriceRangeFilter from '@components/web/src/atoms/Filters/PriceRangeFilter/PriceRangeFilter';
import RatingFilter from '@components/web/src/atoms/Filters/RatingFilter/RatingFilter';
import * as S from '@components/web/src/atoms/Filters/Sublist/styles';
import SublistItem from '@components/web/src/atoms/Filters/SublistItem/SublistItem';
import { TSublistItemProps } from '@components/web/src/atoms/Filters/types';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';

interface Props {
  title: ILocaleText;
  filterType:
    | typeof FILTER_TYPE_STYLE
    | typeof FILTER_TYPE_MOOD
    | typeof FILTER_TYPE_ORIGIN
    | typeof FILTER_TYPE_CHARACTERISTICS
    | typeof FILTER_TYPE_PRODUCT_CATEGORY
    | typeof FILTER_TYPE_FORMAT
    | typeof FILTER_TYPE_LOCATION
    | typeof FILTER_TYPE_RATING
    | typeof FILTER_TYPE_PRICE_RANGE;
  listContent?: TSublistItemProps[];
  handleUpdateFilterType: (filterItem: IFilterChangeAction) => void;
}

const Sublist: FC<Props> = ({ title, filterType, listContent, handleUpdateFilterType }) => {
  const [isOpened, setIsOpened] = useState(false);

  const { sublist } = useTypedSelector(state => state.productFilters);
  const { userUpperPriceRangeValue, userLowerPriceRangeValue, appMaxPriceRangeValue, appMinPriceRangeValue } =
    useCatalogFilter();
  const { [filterType as keyof ISublistFilterState]: selectedNamesObj = {} } = sublist || {};

  const isChecked = useMemo(
    () =>
      filterType === FILTER_TYPE_PRICE_RANGE
        ? appMaxPriceRangeValue !== userUpperPriceRangeValue || appMinPriceRangeValue !== userLowerPriceRangeValue
        : Object.values(selectedNamesObj).some(({ isActive }) => isActive),
    [selectedNamesObj, userLowerPriceRangeValue, userUpperPriceRangeValue],
  );

  const onItemClick = data => handleUpdateFilterType({ filterItem: { ...data, filterType } });

  const iconsSet = {
    [FILTER_TYPE_CHARACTERISTICS]: characteristicsIcon,
    [FILTER_TYPE_FORMAT]: productFormatIcon,
    [FILTER_TYPE_LOCATION]: originIcon,
    [FILTER_TYPE_MOOD]: moodIcon,
    [FILTER_TYPE_ORIGIN]: originIcon,
    [FILTER_TYPE_PRICE_RANGE]: PriceIcon,
    [FILTER_TYPE_PRODUCT_CATEGORY]: productCategoryIcon,
    [FILTER_TYPE_RATING]: ratingIcon,
    [FILTER_TYPE_STYLE]: styleIcon,
  };
  const CurrentIcon = iconsSet[filterType];

  const childrenComponent = {
    [FILTER_TYPE_PRICE_RANGE]: <PriceRangeFilter />,
    [FILTER_TYPE_RATING]: (
      <RatingFilter listContent={listContent} selectedNamesObj={selectedNamesObj} onItemClick={onItemClick} />
    ),
  };

  return (
    <S.SublistWrapper direction="column" gap={0}>
      <S.SublistContainer
        aria-hidden
        isFullWidth
        $isOpened={isOpened}
        align="center"
        justify="space-between"
        onClick={() => setIsOpened(prev => !prev)}
      >
        <Flexbox align="center">
          {CurrentIcon && (
            <S.Icon $isChecked={isChecked} align="center" justify="center">
              <CurrentIcon />
            </S.Icon>
          )}
          <Text
            color={isChecked ? STATIC_COLORS.teal['800'] : STATIC_COLORS.base.black}
            size="subtitle2"
            text={title}
            weight={isChecked ? 'semibold' : 'normal'}
          />
        </Flexbox>
        <S.SublistButton type="button">{isOpened ? <HideIcon /> : <OpenIcon />}</S.SublistButton>
      </S.SublistContainer>
      {isOpened &&
        (childrenComponent[filterType] || (
          <S.SublistLists>
            {listContent?.map(item => (
              <SublistItem
                key={item.name}
                isActive={selectedNamesObj?.[item.name]?.isActive || false}
                item={item}
                onItemClick={onItemClick}
              />
            ))}
          </S.SublistLists>
        ))}
    </S.SublistWrapper>
  );
};

export default Sublist;
