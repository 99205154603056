import { LocaleFragment } from '@lib/tools/locale/views/LocaleFragment';

const AuthFormServerError = ({ authErrors }) => {
  return (typeof authErrors === 'string' && authErrors !== '') || !!Object.values(authErrors).length ? (
    <div className="auth-form-server-error">
      {typeof authErrors === 'string' ? (
        <p className="error-text">{authErrors}</p>
      ) : (
        Object.values(authErrors)
          .flat()
          .map((error, idx) => (
            <p key={idx} className="error-text">
              <LocaleFragment message={JSON.stringify(error)} />
            </p>
          ))
      )}
    </div>
  ) : null;
};

export default AuthFormServerError;
