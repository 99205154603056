import styled from 'styled-components';

import { isApplicationKiosk } from '@lib/core/service/consts';

import BackButton from '@components/web/src/atoms/Buttons/BackButton/BackButton';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';

export const ModalOverlay = styled(Flexbox)<{ $withoutDefaultPadding?: boolean }>`
  position: absolute;
  inset: 0;
  z-index: 100000;
  background: rgb(0 0 0 / 75%);
  backdrop-filter: blur(4px);
  padding: ${({ $withoutDefaultPadding }) => {
    if ($withoutDefaultPadding) return '0';
    return isApplicationKiosk ? '128px' : '16px';
  }};
`;

export const ModalContent = styled(Flexbox)<{
  $isTransparent?: boolean;
  $modalContentWidth?: string;
  $minModalContentHeight: string;
  $maxModalContentHeight?: string;
}>`
  width: ${({ $modalContentWidth }) => $modalContentWidth};
  min-height: ${({ $minModalContentHeight }) => $minModalContentHeight};
  max-height: ${({ $maxModalContentHeight }) => $maxModalContentHeight};
  background: ${({ $isTransparent }) => ($isTransparent ? 'transparent' : STATIC_COLORS.base.white)};
  overflow-y: auto;

  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const ModalBackButton = styled(BackButton)`
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: ${STATIC_COLORS.base.white};
  box-sizing: border-box;
  width: 100%;
  padding: 14px;
`;
