import { useMemo, useState } from 'react';
// @ts-ignore
import { IntlProvider } from 'react-intl';
import DirectionProvider, { DIRECTIONS } from 'react-with-direction/dist/DirectionProvider';

import { useApp } from '@lib/core/service/hooks';
import { LocaleUtils } from '@lib/tools/locale/utils';

import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/locale-data/ar';
import '@formatjs/intl-pluralrules/locale-data/en';
import '@formatjs/intl-pluralrules/locale-data/bg';
import '@formatjs/intl-pluralrules/locale-data/bs';
import '@formatjs/intl-pluralrules/locale-data/cs';
import '@formatjs/intl-pluralrules/locale-data/da';
import '@formatjs/intl-pluralrules/locale-data/de';
import '@formatjs/intl-pluralrules/locale-data/el';
import '@formatjs/intl-pluralrules/locale-data/es';
import '@formatjs/intl-pluralrules/locale-data/fr';
import '@formatjs/intl-pluralrules/locale-data/he';
import '@formatjs/intl-pluralrules/locale-data/hr';
import '@formatjs/intl-pluralrules/locale-data/hu';
import '@formatjs/intl-pluralrules/locale-data/id';
import '@formatjs/intl-pluralrules/locale-data/it';
import '@formatjs/intl-pluralrules/locale-data/ja';
import '@formatjs/intl-pluralrules/locale-data/kk';
import '@formatjs/intl-pluralrules/locale-data/ko';
import '@formatjs/intl-pluralrules/locale-data/mk';
import '@formatjs/intl-pluralrules/locale-data/ms';
import '@formatjs/intl-pluralrules/locale-data/pl';
import '@formatjs/intl-pluralrules/locale-data/pt';
import '@formatjs/intl-pluralrules/locale-data/ro';
import '@formatjs/intl-pluralrules/locale-data/ru';
import '@formatjs/intl-pluralrules/locale-data/sk';
import '@formatjs/intl-pluralrules/locale-data/sl';
import '@formatjs/intl-pluralrules/locale-data/sr';
import '@formatjs/intl-pluralrules/locale-data/tr';

/**
 * * Locale Provider (HOC)
 *
 * This provider is responsible for translations, locale configuration, and text direction.
 */
export const LocaleProvider = ({ children }) => {
  const { locale, isLayoutRightToLeft, isLocaleEditMode } = useApp();

  const [messages, setMessages] = useState({});

  /**
   * * Fetch translations from S3 for default mode
   * * Fetch translations from Firebase for edit mode
   */
  useMemo(async () => {
    if (isLocaleEditMode) {
      LocaleUtils.getDraftTerms(locale, setMessages);
    } else {
      const publishedTerms = await LocaleUtils.getPublishedTerms(locale);
      setMessages(publishedTerms);
    }

    // * Direction change
    document.getElementsByTagName('body')[0].style.textAlign = isLayoutRightToLeft ? 'right' : 'left';
  }, [locale, isLocaleEditMode]);

  return (
    <IntlProvider
      locale={locale}
      messages={messages}
      onError={e => {
        if (e.code === 'MISSING_TRANSLATION') {
          // console.warn('Missing locale', e.descriptor);
          return;
        }
        console.error(e);
      }}
    >
      <DirectionProvider direction={isLayoutRightToLeft ? DIRECTIONS.RTL : DIRECTIONS.LTR}>
        <div lang={locale}>{children}</div>
      </DirectionProvider>
    </IntlProvider>
  );
};
