import { PayloadAction, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { REQUEST_METHODS } from '@lib/core/service/consts';
import { createTypedAsyncThunk } from '@lib/core/service/createTypedAsyncThunk';
import request from '@lib/core/service/requests/request';
import { uuidv4 } from '@lib/core/service/utils';
import { selectAnonymousUserProfileId } from '@lib/core/users/selectors/user';
import { ILoginResponse, actionFacebookLogin, actionGoogleLogin, actionLogin } from '@lib/core/users/slices/auth';
import {
  carryUserDataApiUrlCreator,
  uploadUserPictureApiUrlCreator,
  userDataApiUrlCreator,
} from '@lib/core/users/slices/urls';
import { TAnonymousUserData, TUser, TUserDataField } from '@lib/core/users/types';
import { parseError } from '@lib/tools/shared/helpers';

export interface IUserSlice {
  data: TUser | undefined;
  isLoading: boolean;
  error: string;
  isFullUserDataLoadedTemporaryHint: boolean;
  isUploadingProfilePicture: boolean;
  anonymousUserData: TAnonymousUserData;
}

const initialState: IUserSlice = {
  anonymousUserData: { character: undefined, isLoading: false, profileId: '' },
  data: undefined,
  error: '',
  isFullUserDataLoadedTemporaryHint: false,
  isLoading: false,
  isUploadingProfilePicture: false,
};

export const actionUploadUserPicture = createTypedAsyncThunk(
  'actionUploadUserPicture',
  async (imagebase64Data: string) => {
    return await request(
      uploadUserPictureApiUrlCreator(),
      { method: 'PUT' },
      {
        base64: imagebase64Data,
        destination: 'profile_image',
      },
    );
  },
);

export const actionGetUserData = createTypedAsyncThunk('actionGetUserData', async () => {
  return await request(userDataApiUrlCreator());
});

export const actionUpdateUserData = createTypedAsyncThunk(
  'actionUpdateUserData',
  async (requestParams: Partial<TUser>, { rejectWithValue }) => {
    try {
      return await request(userDataApiUrlCreator(), { method: REQUEST_METHODS.PATCH }, requestParams);
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  },
);

export const actionCarryAnonymousUserData = createTypedAsyncThunk(
  'actionCarryAnonymousUserData',
  async (fields: TUserDataField[], { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const sourceProfile = selectAnonymousUserProfileId(state);

      await request(carryUserDataApiUrlCreator(), { method: REQUEST_METHODS.POST }, { fields, sourceProfile });
      return null;
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  },
);

export const userSlice = createSlice({
  extraReducers: builder => {
    builder.addCase(actionUploadUserPicture.pending, state => {
      state.isUploadingProfilePicture = true;
    });
    builder.addCase(actionUploadUserPicture.fulfilled, state => {
      state.isUploadingProfilePicture = false;
    });
    builder.addCase(actionUploadUserPicture.rejected, state => {
      state.isUploadingProfilePicture = false;
    });
    builder.addCase(actionCarryAnonymousUserData.pending, state => {
      state.anonymousUserData.isLoading = true;
    });
    builder.addCase(actionCarryAnonymousUserData.fulfilled, state => {
      state.anonymousUserData.isLoading = false;
    });
    builder.addCase(actionCarryAnonymousUserData.rejected, state => {
      state.anonymousUserData.isLoading = false;
    });
    builder.addMatcher(isAnyOf(actionGetUserData.pending, actionUpdateUserData.pending), state => {
      state.isLoading = true;
      state.error = '';
    });
    builder.addMatcher(
      isAnyOf(actionGetUserData.fulfilled, actionUpdateUserData.fulfilled),
      (state, action: PayloadAction<TUser>) => {
        // Ignore null user_session_id after registrations. patchQuizComplete associates
        // the anonymous user_session_id to the newly registered user.
        const payload = { ...action.payload };
        const userSessionId = payload.user_session_id || state.data.user_session_id;
        state.data = { ...payload, user_session_id: userSessionId };
        state.isFullUserDataLoadedTemporaryHint = true;
        state.isLoading = false;
      },
    );
    builder.addMatcher(isAnyOf(actionGetUserData.rejected, actionUpdateUserData.rejected), (state, action: any) => {
      if (action.payload?.errorMessage) {
        state.error = action.payload.errorMessage;
      } else if (action.error?.message) {
        state.error = action.error.message;
      }
      state.isLoading = false;
    });
    // Listening for login actions from auth slice, which also returns partially user data
    builder.addMatcher(
      isAnyOf(actionLogin.fulfilled, actionGoogleLogin.fulfilled, actionFacebookLogin.fulfilled),
      (state, action: PayloadAction<ILoginResponse>) => {
        const { payload: { user } = {} } = action;
        state.data = state.data ? { ...state.data, ...user } : user;
      },
    );
  },
  initialState,
  name: 'user',
  reducers: {
    actionGenerateUserSession: state => {
      state.data = { ...state.data, user_session_id: uuidv4() };
    },
    actionResetAnonymousUserData: state => {
      state.anonymousUserData = initialState.anonymousUserData;
    },
    actionResetUserSlice: () => initialState,
    actionSetAnonymousUserCharacter: (state, action) => {
      state.anonymousUserData.character = action.payload;
    },
    actionSetAnonymousUserProfileId: (state, action) => {
      state.anonymousUserData.profileId = action.payload;
    },
  },
});

export default userSlice.reducer;

export const {
  actionResetUserSlice,
  actionGenerateUserSession,
  actionSetAnonymousUserCharacter,
  actionSetAnonymousUserProfileId,
  actionResetAnonymousUserData,
} = userSlice.actions;
