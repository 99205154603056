import styled from 'styled-components';

import { STATIC_COLORS } from '@components/web/src/foundations';

export const TastingTip = styled.div`
  display: flex;
  padding: 12px 16px;
  align-items: center;
  gap: 20px;
  width: 100%;
  border-radius: 16px;
  background-color: ${STATIC_COLORS.gray[800]};

  img {
    height: ${({ theme }) => (theme?.isKioskRootElementType ? '50px' : '40px')};
    width: ${({ theme }) => (theme?.isKioskRootElementType ? '50px' : '40px')};
  }
`;
