import { FC } from 'react';

import { TProductCategory } from '@lib/core/products/types';
import { localeWidget } from '@lib/tools/locale/source/web/widget';

import { ReactComponent as ScanIcon } from '@components/web/src/assets/icons/scan_barcode_icon.svg';
import * as S from '@components/web/src/atoms/Buttons/ScanProductButton/styles';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Text } from '@components/web/src/foundations/Text/Text';
import { getMissingProductImage } from '@components/web/src/shared/Icons/MissingProductImage';

interface IProps {
  productCategory: TProductCategory;
  handleClick: () => void;
}

export const ScanProductButton: FC<IProps> = ({ productCategory, handleClick }) => {
  const { scanProductButtonText } = localeWidget.scanBarcode;

  return (
    <S.ScanProductButtonContainer type="button" onClick={handleClick}>
      <S.ProductImage alt={`${productCategory} icon`} src={getMissingProductImage(productCategory)} />
      <Text color={STATIC_COLORS.teal[800]} size="subtitle2" text={scanProductButtonText} weight="medium" />
      <ScanIcon />
    </S.ScanProductButtonContainer>
  );
};
