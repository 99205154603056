import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useParsedProducts } from '@lib/core/products/hooks/useParsedProducts';
import { TProductInstance } from '@lib/core/products/types';
import { useRetailer } from '@lib/core/retailers/hooks/retailer';
import { useRetailerLocation } from '@lib/core/retailers/hooks/retailerLocation';
import { GPRL_CHARACTER_QUERY } from '@lib/core/service/consts';
import { useApp } from '@lib/core/service/hooks';
import { useThirdParty } from '@lib/core/service/hooks/useThirdParty';
import { prependBasename, shuffleArray } from '@lib/core/service/utils';
import { useUser } from '@lib/core/users/hooks';
import MixpanelTracker from '@lib/tools/dat/mixpanel';
import useDiscoveryQuizData from '@lib/tools/discoveryQuiz/hooks';
import { useCatalogFilter } from '@lib/tools/filterManager/hooks';
import { actionResetCatalogFilters } from '@lib/tools/filterManager/slices/productFilter';
import { useProductCatalog } from '@lib/tools/productCatalog/hooks/useProductCatalog';
import {
  actionFetchProductsListsLimited,
  resetProductCatalogState,
  setStaticMatchedProducts,
} from '@lib/tools/productCatalog/slices';
import { parseLimitedCatalogProduct } from '@lib/tools/shared/helpers';
import { CHARACTERS_URL_PARAM, LIMITED_CATALOG } from '@lib/tools/shared/helpers/consts';
import { useAddons } from '@lib/tools/views/hooks';
import { PAGES } from '@lib/tools/views/urls';

import { LimitedProductCatalogPage } from '@components/web/src/templates/Catalogs/Web/ProductCatalogPage/LimitedProductCatalogPage';

const LimitedCatalogContainer = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const { isEnableAppBanner } = useAddons();

  const characterStringFromUrl = searchParams.get(CHARACTERS_URL_PARAM);

  const { productCategory, shouldHideDownloadAppCard } = useApp();
  const { handleUpdateFilterType } = useCatalogFilter();
  const { staticMatchedProducts } = useProductCatalog();
  const { retailerName } = useRetailer();
  const { retailerStoreType } = useRetailerLocation();
  const { userBestMatchCharacterForCurrentServiceProductCategory, isUserAuthenticated } = useUser();
  const { handleThirdPartyAcceptance, thirdPartyNewsletterTexts, isShowThirdPartyAcceptance } = useThirdParty();
  const navigate = useNavigate();
  const navigateToHomePage = () => navigate(prependBasename(PAGES.vinhood.home));
  const characterIdsParam = (
    characterStringFromUrl || userBestMatchCharacterForCurrentServiceProductCategory?.characterId
  )
    ?.split('-')
    .join(',');

  useEffect(() => {
    if (!characterIdsParam) {
      navigateToHomePage();
    }
  }, [characterIdsParam, navigate]);

  const {
    lastDataLoadedTime,
    isProductsRequestLoading,
    firstPriorityProducts,
    secondPriorityProducts,
    thirdPriorityProducts,
  } = useProductCatalog();

  const handleProductsRequest = () => {
    dispatch(actionFetchProductsListsLimited({ params: { [GPRL_CHARACTER_QUERY]: characterIdsParam } }));
  };

  useEffect(() => {
    return () => {
      if (!window.location.pathname?.includes(PAGES.vinhood.product)) {
        dispatch(actionResetCatalogFilters());
        dispatch(resetProductCatalogState());
        dispatch(setStaticMatchedProducts([]));
      }
    };
  }, []);
  const groupByIdentifier = <T extends { [key: string]: any }>(array: T[]): { [key: string]: T[] }[] => {
    const identifiers: { [key: string]: T[] } = {};

    array.forEach(item => {
      const { identifier } = item.product.character;
      if (identifier) {
        if (!identifiers[identifier]) {
          identifiers[identifier] = [];
        }
        identifiers[identifier].push(item);
      }
    });

    const allProductsArray: T[] = [];
    Object.values(identifiers).forEach(items => {
      allProductsArray.push(...shuffleArray(items));
    });

    return allProductsArray;
  };

  const { matchedProducts, listProducts } = useMemo(() => {
    const getFilteredProducts = (productsToFilter: TProductInstance[], productsToExclude: TProductInstance[]) =>
      productsToFilter.filter(product => !productsToExclude.some(ex => ex.identifier === product.identifier));

    const shuffledFirstPriorityProducts = shuffleArray(firstPriorityProducts);
    const shuffledSecondPriorityProducts = groupByIdentifier(secondPriorityProducts);
    const shuffledThirdPriorityProducts = groupByIdentifier(thirdPriorityProducts);
    const allProducts = [
      ...shuffledFirstPriorityProducts,
      ...shuffledSecondPriorityProducts,
      ...shuffledThirdPriorityProducts,
    ].map(parseLimitedCatalogProduct);

    const firstPriorityProductsParsed = shuffledFirstPriorityProducts.map(parseLimitedCatalogProduct);
    const staticMatchedProductsParsed = staticMatchedProducts.map(parseLimitedCatalogProduct);

    if (shuffledFirstPriorityProducts.length > 3) {
      const filteredProducts = getFilteredProducts(allProducts, staticMatchedProducts);

      return {
        listProducts: filteredProducts.slice(3, filteredProducts.length),
        matchedProducts: [...staticMatchedProductsParsed, ...filteredProducts].slice(0, 3),
      };
    }
    if (shuffledFirstPriorityProducts.length >= 1 && shuffledFirstPriorityProducts.length < 4) {
      const filteredMatchedProducts = getFilteredProducts(firstPriorityProductsParsed, staticMatchedProducts);
      const filteredListProducts = getFilteredProducts(allProducts, staticMatchedProducts);

      return {
        listProducts: filteredListProducts.slice(shuffledFirstPriorityProducts.length, filteredListProducts.length),
        matchedProducts: [...staticMatchedProductsParsed, ...filteredMatchedProducts].slice(0, 3),
      };
    }
    return { listProducts: allProducts, matchedProducts: [...staticMatchedProductsParsed] };
  }, [firstPriorityProducts, secondPriorityProducts, thirdPriorityProducts]);

  useEffect(() => {
    if (characterIdsParam) handleProductsRequest();
  }, [characterIdsParam]);

  useEffect(() => {
    MixpanelTracker.events.productCatalogView(productCategory);
  }, []);

  const { discoveryQuiz } = useDiscoveryQuizData();

  const shouldShowDownloadAppCard = !shouldHideDownloadAppCard && isEnableAppBanner;

  const { parseProductsArray } = useParsedProducts();

  return (
    <LimitedProductCatalogPage
      catalogType={LIMITED_CATALOG}
      discoveryQuiz={discoveryQuiz}
      handleProductsRequest={handleProductsRequest}
      handleThirdPartyAcceptance={handleThirdPartyAcceptance}
      handleUpdateFilterType={handleUpdateFilterType}
      isProductsLoaded={!!lastDataLoadedTime}
      isProductsRequestLoading={isProductsRequestLoading}
      isShowThirdPartyAcceptance={isShowThirdPartyAcceptance}
      matchedProducts={parseProductsArray(matchedProducts) || []}
      productCategory={productCategory}
      products={parseProductsArray(listProducts)}
      retailerName={retailerName}
      shouldHideWishlist={!isUserAuthenticated}
      shouldShowDownloadAppCard={shouldShowDownloadAppCard}
      storeType={retailerStoreType}
      thirdPartyNewsletterTexts={thirdPartyNewsletterTexts}
    />
  );
};

export default LimitedCatalogContainer;
