import { QrScanner } from '@yudiel/react-qr-scanner';
import { FC, useEffect, useState } from 'react';

import { apiUrlGetEanCodeProduct } from '@lib/core/products/slices/urls';
import { TProductCategory, TProductInstance } from '@lib/core/products/types';
import request from '@lib/core/service/requests/request';
import MixpanelTracker from '@lib/tools/dat/mixpanel';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { localeWidget } from '@lib/tools/locale/source/web/widget';
import { LocaleUtils } from '@lib/tools/locale/utils';
import { parseError } from '@lib/tools/shared/helpers';

import { CloseButton } from '@components/web/src/atoms/Buttons/CloseButton/CloseButton';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';
import { getMissingProductImage } from '@components/web/src/shared/Icons/MissingProductImage';
import * as S from '@components/web/src/templates/Tutorials/ScanBarcode/styles';

interface IProps {
  productCategory: TProductCategory;
  handleClose: () => void;
  handleProductPageNavigation: (productId: string) => void;
}

export const ScanBarcode: FC<IProps> = ({ productCategory, handleClose, handleProductPageNavigation }) => {
  const { scanBarcodeText, notValidCodeErrorText, permissionDeniedErrorText } = localeWidget.scanBarcode;

  const { publishedTerms } = LocaleUtils;
  const productCategoryText = publishedTerms[localeCommon?.productCategories[productCategory].id];

  const [isScanning, setIsScanning] = useState(false);
  const [requestErrorMessage, setRequestErrorMessage] = useState(null);
  const [scannerErrorMessage, setScannerErrorMessage] = useState(null);
  const errorMessage = requestErrorMessage || scannerErrorMessage;

  useEffect(() => {
    if (!requestErrorMessage) return () => null;
    const timer = setTimeout(() => setRequestErrorMessage(null), 2000);
    return () => clearTimeout(timer);
  }, [requestErrorMessage]);

  const handleRequestError = (code: string, errorText) => {
    MixpanelTracker.events.scanFailed(code);
    setRequestErrorMessage(errorText);
  };

  const handleBarcodeScan = async (scannedCode: string) => {
    if (scannedCode.length < 6 || scannedCode.length > 13) {
      handleRequestError(scannedCode, notValidCodeErrorText);
      return;
    }

    setIsScanning(true);

    try {
      const response = await request(apiUrlGetEanCodeProduct(scannedCode), {
        additionalHeaders: { Accept: 'application/json; version="2.0"' },
      });

      const productInstanceData: TProductInstance = response?.results?.[0];

      if (productInstanceData) {
        MixpanelTracker.events.scanProduct(productInstanceData, scannedCode);
        handleProductPageNavigation(productInstanceData.identifier);
        handleClose();
      } else {
        handleRequestError(scannedCode, notValidCodeErrorText);
      }
    } catch (error) {
      handleRequestError(scannedCode, parseError(error).message);
    } finally {
      setIsScanning(false);
    }
  };

  return (
    <S.ScanBarcodeContainer direction="column" gap={0}>
      <Flexbox isFullWidth direction="column" gap={40} padding="16px">
        <CloseButton handleClick={handleClose} type="bold" />
        <S.ScannerWrapper>
          <QrScanner
            audio={false}
            containerStyle={{ borderRadius: '20px' }}
            onDecode={scannedCode => {
              if (!isScanning && !errorMessage) {
                handleBarcodeScan(scannedCode);
              }
            }}
            onError={error =>
              setScannerErrorMessage(error?.name === 'NotAllowedError' ? permissionDeniedErrorText : error?.name)
            }
          />
          <S.CustomValidatorIcon $isError={!!errorMessage} />
        </S.ScannerWrapper>
        {errorMessage && (
          <Text color={STATIC_COLORS.base.black} size="subtitle2" text={errorMessage} textAlign="center" />
        )}
      </Flexbox>

      <S.BannerWrapper isFullWidth align="center" gap={0} padding="24px 14px 24px 0">
        <S.ProductImage alt={`${productCategory} icon`} src={getMissingProductImage(productCategory)} />
        <Text
          color={STATIC_COLORS.teal[700]}
          localeIndex={{ productCategory }}
          localeOptions={{ productCategoryText }}
          size="subtitle2"
          text={scanBarcodeText}
          weight="semibold"
        />
      </S.BannerWrapper>
    </S.ScanBarcodeContainer>
  );
};
