import styled, { css, keyframes } from 'styled-components';

import { STATIC_COLORS, STATIC_GRADIENTS, STATIC_SHADOWS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';

export const skeletonLoadingKeyframes = keyframes`
  0% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
`;

export const skeletonCommonStyles = css`
  background: ${STATIC_GRADIENTS.warmGradientForSkeleton};
  animation: ${skeletonLoadingKeyframes} 1s linear infinite alternate;
  border-radius: 4px;
  padding-right: 20px;
`;

export const ProductCardSkeletonContainer = styled(Flexbox)<{ $isResponsive?: boolean }>`
  width: ${({ $isResponsive }) => ($isResponsive ? '100%' : '164px')};
  height: 100%;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: ${STATIC_SHADOWS.sm};
  background-color: ${STATIC_COLORS.warmGray[100]};
  padding: 8px;
`;

export const ImageSkeleton = styled.div`
  width: 92px;
  height: 92px;
  overflow: hidden;
  border-top-left-radius: 8px;
  ${skeletonCommonStyles}
  border-radius: 0;
`;

export const SectionOne = styled.div`
  height: 48px;
  width: 100%;
  ${skeletonCommonStyles}
`;

export const SectionTwo = styled.div`
  height: 18px;
  width: 100%;
  ${skeletonCommonStyles}
`;

export const SectionThree = styled.div`
  height: 18px;
  width: 70px;
  align-self: center;
  ${skeletonCommonStyles}
`;

export const SectionFour = styled.div`
  height: 28px;
  width: 100%;
  ${skeletonCommonStyles}
`;
