import { ButtonHTMLAttributes, FC } from 'react';

import * as S from '@components/web/src/atoms/Buttons/CloseButton/styles';

interface IProps extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'type'> {
  handleClick: () => void;
  variant?: S.TVariant;
  type?: S.TType;
  position?: S.TPosition;
}

export const CloseButton: FC<IProps> = ({
  handleClick,
  variant = 'dark',
  type = 'regular',
  position = 'right',
  ...rest
}) => (
  <S.CloseButtonContainer
    $position={position}
    $type={type}
    $variant={variant}
    type="button"
    onClick={handleClick}
    {...rest}
  />
);
