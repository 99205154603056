import { AsyncThunkPayloadCreator, SerializedError, createAsyncThunk } from '@reduxjs/toolkit';

import { AppStateType } from '@lib/core/service/types/appStateType';

interface IParsedToolkitError extends SerializedError {
  message: string;
  userMessage?: string;
  status?: number;
}

interface TypedAsyncThunkConfig<RejectValue> {
  state: AppStateType;
  rejectValue: RejectValue;
}

export function createTypedAsyncThunk<ReturnedType = any, ArgType = void, RejectValue = IParsedToolkitError>(
  typePrefix: string,
  payloadCreator: AsyncThunkPayloadCreator<ReturnedType, ArgType, TypedAsyncThunkConfig<RejectValue>>,
) {
  return createAsyncThunk<ReturnedType, ArgType, TypedAsyncThunkConfig<RejectValue>>(typePrefix, payloadCreator);
}
