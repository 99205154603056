import { FC } from 'react';

import * as S from '@components/web/src/organisms/Skeleton/ExperienceCardSkeleton/styles';

interface IProps {
  isResponsive?: boolean;
}
const ExperienceCardSkeleton: FC<IProps> = ({ isResponsive = false }) => (
  <S.ExperienceCardSkeletonContainer $isResponsive={isResponsive}>
    <S.Image />
    <S.TextsWrapper direction="column" gap={4}>
      <S.SectionOneText />
      <S.SectionTwoText />
    </S.TextsWrapper>
    <S.FooterWrapper align="center" direction="column" gap={0} justify="center" padding="0 0 16px 0">
      <S.SectionFiveText />
      <S.FooterSection />
    </S.FooterWrapper>
  </S.ExperienceCardSkeletonContainer>
);

export default ExperienceCardSkeleton;
