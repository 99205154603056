import { FC } from 'react';

import { TProductCategory } from '@lib/core/products/types';
import { localeApp } from '@lib/tools/locale/source/web/app';
import { LocaleFragment } from '@lib/tools/locale/views/LocaleFragment';
import { VH_VARIANTS } from '@lib/tools/shared/helpers/consts';

import { ReactComponent as ArrowIconB2C } from '@components/web/src/assets/legacy/b2c/icons/icon_arrow.svg';
import Button from '@components/web/src/atoms/Buttons/Button';

interface IProps {
  typeformLink: string;
  handleBackBtnClick?: () => void;
  productCategory?: TProductCategory;
  productCharacter?: string;
}

export const EdutainmentFormPage: FC<IProps> = ({
  productCategory,
  typeformLink,
  handleBackBtnClick,
  productCharacter,
}) => {
  const { backBtn, headingTitle } = localeApp.edutainmentFormPage;

  return (
    <div className="edutainment-page-container">
      <div className="heading-section">
        <div className={`heading ${productCategory}`}>
          <span className="title">
            <LocaleFragment message={headingTitle} />
          </span>
          {productCharacter && <span className="product-character">{productCharacter}</span>}
        </div>
      </div>
      <div className="frame-container">
        <iframe height={500} src={typeformLink} title="Quiz Typeform" />
      </div>
      <div className="cta-container">
        <Button
          className="previous-btn"
          icon={<ArrowIconB2C />}
          size="sm"
          text={backBtn}
          variant={VH_VARIANTS.LINK}
          onClick={handleBackBtnClick}
        />
      </div>
    </div>
  );
};
