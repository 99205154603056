import { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useQuizzes } from '@lib/core/quizzes/hooks';
import QuizUtils from '@lib/core/quizzes/utils';
import { tasteQuiz } from '@lib/core/quizzes/utils/filters';
import {
  DISABLE_USER_CHARACTER_TOGGLE_URL_PARAM,
  IS_FROM_SITUATIONAL_TEST_URL_PARAM,
  isApplicationKiosk,
} from '@lib/core/service/consts';
import { useApp } from '@lib/core/service/hooks';
import { prependBasename } from '@lib/core/service/utils';
import { useDietaryPreferences, useUser } from '@lib/core/users/hooks';
import { actionUpdateMultipleDietaryPreferenceListData } from '@lib/core/users/slices/dietaryPreference';
import {
  DIETARY_PREFERENCE_EXPOSURE,
  DIETARY_PREFERENCE_SLUG,
  IS_FROM_CATALOG_PAGE_URL_PARAM,
  PREFERENCES_URL_PARAM,
  PRODUCT_CATEGORY_URL_PARAM,
} from '@lib/tools/shared/helpers/consts';
import { useAddons, useRetailerDesignSet } from '@lib/tools/views/hooks';
import { useCustomStyles } from '@lib/tools/views/hooks/useCustomStyles';
import { PAGES } from '@lib/tools/views/urls';

import { LoadingSpinner } from '@components/web/src/templates/Loading/LoadingSpinner/LoadingSpinner';
import FoodHabits from '@components/web/src/templates/TestFlowsPages/FoodHabitsPage/FoodHabits';

const QuizFoodHabitsContainer: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const { isDevToolsEnabled } = useApp();
  const customStyles = useCustomStyles();
  const {
    userDietaryPreferencePreferencesSlugs,
    answerPreferenceOptionsData,
    isUserDietaryPreferenceDataLoading,
    isDietaryPreferenceOptionsLoading,
  } = useDietaryPreferences();
  const { isDisableQuizNoGluten } = useAddons();
  const { isUserAuthenticated } = useUser();
  const { isDesignSetVinhoodApp } = useRetailerDesignSet();
  const isFromSituationalTest = searchParams.get(IS_FROM_SITUATIONAL_TEST_URL_PARAM);
  const disableUserCharacterUrlParam = searchParams.get(DISABLE_USER_CHARACTER_TOGGLE_URL_PARAM);

  const { isQuizzesFetching } = useQuizzes();

  const [answers, setAnswers] = useState(answerPreferenceOptionsData);
  const isDisabledBtn = useMemo(() => !answers.some(answer => answer.isSelected), [answers]);
  const tasteQuizAvailable = tasteQuiz();

  useEffect(() => {
    setAnswers(answerPreferenceOptionsData);
  }, [isQuizzesFetching]);

  const onAnswerSelectHandler = (id: string) => {
    if (id === DIETARY_PREFERENCE_SLUG.NO_FOOD_PREFERENCES) {
      setAnswers(
        answers.map(answer =>
          answer.slug === id
            ? { ...answer, isDisabled: false, isSelected: true }
            : { ...answer, isDisabled: true, isSelected: false },
        ),
      );
    } else {
      setAnswers(
        answers
          .map(answer =>
            answer.slug === id
              ? { ...answer, isDisabled: false, isSelected: !answer.isSelected }
              : { ...answer, isDisabled: false },
          )
          .map(answer =>
            answer.slug === DIETARY_PREFERENCE_SLUG.NO_FOOD_PREFERENCES
              ? { ...answer, isDisabled: true, isSelected: false }
              : answer,
          ),
      );
    }
  };

  useEffect(() => {
    const isAllAnswersNotSelected =
      answers.filter(answer => !answer.isDisabled && !answer.isSelected).length === answers.length - 1;

    if (isAllAnswersNotSelected) setAnswers(answers.map(answer => ({ ...answer, isDisabled: false })));
  }, [answers]);

  useEffect(() => {
    setAnswers(answerPreferenceOptionsData);
  }, [isDisableQuizNoGluten]);

  const getUserPreferences = () => {
    const selectedPreferenceSlugs = answers.filter(answer => answer.isSelected).map(item => item.slug);

    dispatch(
      actionUpdateMultipleDietaryPreferenceListData({
        exposureToUpdate: DIETARY_PREFERENCE_EXPOSURE.PREFERENCES,
        slugsToUpdate: selectedPreferenceSlugs,
      }),
    );

    const preferredQuizSlug = QuizUtils.getPreferredQuizSlug(selectedPreferenceSlugs);

    return navigate(
      prependBasename(
        `${
          isDesignSetVinhoodApp ? PAGES.vinhood.quiz.multiProduct : PAGES.vinhood.quiz.taste
        }?${PREFERENCES_URL_PARAM}=${preferredQuizSlug}`,
      ),
    );
  };

  useEffect(() => {
    if (!isQuizzesFetching && tasteQuizAvailable.length === 1) {
      const quizSlug = QuizUtils.getTasteQuizSlugPreferences(tasteQuizAvailable?.[0].slug);
      navigate(prependBasename(`${PAGES.vinhood.quiz.taste}?${PREFERENCES_URL_PARAM}=${quizSlug}`));
    }
  }, [tasteQuizAvailable, isQuizzesFetching]);

  useEffect(() => {
    if (userDietaryPreferencePreferencesSlugs.length && isDesignSetVinhoodApp) {
      const preferredQuizSlug = QuizUtils.getPreferredQuizSlug(userDietaryPreferencePreferencesSlugs);
      const isFromCatalogPage = searchParams.get(IS_FROM_CATALOG_PAGE_URL_PARAM);
      const catalogPageProductCategory = searchParams.get(PRODUCT_CATEGORY_URL_PARAM);

      const queryParamsForCatalogPage = {
        [DISABLE_USER_CHARACTER_TOGGLE_URL_PARAM]: disableUserCharacterUrlParam ? true : '',
        [IS_FROM_CATALOG_PAGE_URL_PARAM]: isFromCatalogPage,
        [PRODUCT_CATEGORY_URL_PARAM]: catalogPageProductCategory,
      };

      const queryStringForCatalogPage = Object.entries(queryParamsForCatalogPage)
        .filter(([_, value]) => !!value)
        .map(([key, value]) => `${key}=${value}`)
        .join('&');

      navigate(
        `${prependBasename(PAGES.vinhood.quiz.multiProduct)}?${PREFERENCES_URL_PARAM}=${preferredQuizSlug}&${
          isFromCatalogPage || isFromSituationalTest ? queryStringForCatalogPage : ''
        }`,
      );
    }
  }, [userDietaryPreferencePreferencesSlugs]);

  const onGoBackClick = () => {
    let link;

    if (isDesignSetVinhoodApp) {
      link = PAGES.vinhood.start;
    } else if (isFromSituationalTest) {
      link = PAGES.vinhood.quiz.situational;
    } else {
      link = PAGES.vinhood.home;
    }

    navigate(prependBasename(link));
  };

  if (isUserAuthenticated && userDietaryPreferencePreferencesSlugs.length > 0 && isUserDietaryPreferenceDataLoading)
    return null;

  const isLoading = isQuizzesFetching || isDietaryPreferenceOptionsLoading || isUserDietaryPreferenceDataLoading;

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <FoodHabits
          answers={answers}
          customStyles={customStyles}
          goBack={onGoBackClick}
          handleUserPreferences={getUserPreferences}
          isApplicationKiosk={isApplicationKiosk}
          isDevToolsEnabled={isDevToolsEnabled}
          isDisabledBtn={isDisabledBtn}
          onAnswerSelectHandler={onAnswerSelectHandler}
        />
      )}
    </>
  );
};

export default QuizFoodHabitsContainer;
