import styled from 'styled-components';

import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';

export const ProductListContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 24px;
  width: 100%;
  padding: ${({ theme }) => (theme?.isKioskRootElementType ? '32px 16px 92px' : '0')};

  .infinite-scroll-component__outerdiv {
    display: flex;
    justify-content: center;
    width: 100%;
    z-index: 1;
  }

  .infinite-scroll-component {
    display: grid;
    grid-template-columns: 1fr;
    max-width: 1080px;
    width: 100%;
    gap: 24px;
    grid-template-columns: ${({ theme }) => (theme?.isKioskRootElementType ? 'repeat(3, 1fr)' : '1fr')};
    gap: ${({ theme }) => (theme?.isKioskRootElementType ? '32px 16px' : '24px')};
  }
`;

export const ProductCardWrapper = styled(Flexbox)`
  width: 100%;
  padding: ${({ theme }) => (theme?.isKioskRootElementType ? '0' : '0 16px')};
`;
