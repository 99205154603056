import { styled } from 'styled-components';

import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';

export const HintModalContainer = styled(Flexbox)`
  background-color: ${STATIC_COLORS.base.white};
  border-radius: 16px;
  overflow: hidden;

  span {
    white-space: pre-line;
  }
`;

export const ContentWrapper = styled(Flexbox)`
  background-color: ${STATIC_COLORS.warmGray[100]};
`;
