import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useCharacters } from '@lib/core/characters/hooks';
import { selectEssenceGroupsLoading } from '@lib/core/essences/selectors/groups';
import { useRecycling, useWellness } from '@lib/core/productAttributes/hooks';
import { useProducts } from '@lib/core/products/hooks/products';
import { useRetailer } from '@lib/core/retailers/hooks/retailer';
import { useApp } from '@lib/core/service/hooks';
import { setSplashScreenPageShown } from '@lib/core/service/slices';

import PartnerSplashScreen from '@components/web/src/templates/SplashScreen/PartnerSplashScreen/PartnerSplashScreen';

export const SplashScreenContainer = () => {
  const dispatch = useDispatch();

  const { isDevToolsEnabled } = useApp();
  const { retailerLogo, isRetailerLoading, retailerData } = useRetailer();
  const { isCharactersLoading } = useCharacters();
  const { isWellnessLoading } = useWellness();
  const { isRecyclingLoading } = useRecycling();
  const { isProductsLoading } = useProducts();
  const iEssenceLoading = useSelector(selectEssenceGroupsLoading);
  const isLoading =
    isCharactersLoading || iEssenceLoading || isWellnessLoading || isRecyclingLoading || isProductsLoading;

  const [isRetailerLogoShown, setIsRetailerLogoShown] = useState(false);

  useEffect(() => {
    // Timer to show or hide the Vinhood and retailer logos
    if (!isRetailerLoading && retailerData) {
      const logoAnimationDuration = retailerLogo ? 6000 : 3000;
      const finalAnimationDuration = isDevToolsEnabled ? 0 : logoAnimationDuration;

      const logoTimer = setTimeout(() => {
        setIsRetailerLogoShown(true);
      }, finalAnimationDuration);

      return () => clearTimeout(logoTimer);
    }
    return () => {};
  }, [retailerData, isRetailerLoading]);

  useEffect(() => {
    if (isRetailerLogoShown && !isLoading) {
      dispatch(setSplashScreenPageShown());
    }
  }, [isRetailerLogoShown, isLoading]);

  return (
    <PartnerSplashScreen
      isDevToolsEnabled={isDevToolsEnabled}
      isLoading={isLoading || isRetailerLoading}
      retailerLogo={retailerLogo}
    />
  );
};
