import { CloudFrontClient, CreateInvalidationCommand } from '@aws-sdk/client-cloudfront';
import { PutObjectCommand, S3Client } from '@aws-sdk/client-s3';
import { initializeApp } from 'firebase/app';
import { doc, getFirestore, onSnapshot, setDoc } from 'firebase/firestore';
import { Differ } from 'json-diff-kit';
import type { DiffResult } from 'json-diff-kit';
import Papa from 'papaparse';
import { Dispatch, SetStateAction } from 'react';

import {
  ENV_AWS_S3_BUCKET_NAME,
  ENV_AWS_S3_BUCKET_REGION,
  ENV_CLOUDFRONT_APP_DOMAIN,
  ENV_CLOUDFRONT_DIST_ID,
  ENV_FIREBASE_I18N_API_KEY,
  ENV_FIREBASE_I18N_APP_ID,
  ENV_FIREBASE_I18N_AUTH_DOMAIN,
  ENV_FIREBASE_I18N_MESSAGING_SENDER_ID,
  ENV_FIREBASE_I18N_PROJECT_ID,
  ENV_FIREBASE_I18N_STORAGE_BUCKET,
  ENV_IS_BUILD,
  ENV_LOCALE_S3_ACCESS_KEY_ID,
  ENV_LOCALE_S3_SECRET_ACCESS_KEY,
  isApplicationKiosk,
  isApplicationPmi,
} from '@lib/core/service/consts';
import { selectIsLocaleEditMode, selectLocale } from '@lib/core/service/selectors';
import { store } from '@lib/core/service/store';
import { ILocaleTableDataRow } from '@lib/tools/devtools/components/interfaces';
import localePmiEnglishTerms from '@lib/tools/locale/dist/pmi/en.json';
import localeWebEnglishTerms from '@lib/tools/locale/dist/web/en.json';
import localeWebItalianTerms from '@lib/tools/locale/dist/web/it.json';
import { currencies, languages } from '@lib/tools/locale/utils/consts';

/**
 * * Firebase and i18n utils
 * ! Add logger
 * ! Improve auth
 * ! Improve error handling
 * 0. Import/export terms in CSV format
 * 1. Read/write from firebase ONLY in edit mode. End users cannot hit firebase.
 * 2. Publish terms as JSON files to S3 when ready, and invalidate CloudFront cache.
 *
 */

export class LocaleUtils {
  private static vinhoodEnv = isApplicationKiosk ? 'kiosk' : 'web';

  private static localeEnv = isApplicationPmi ? 'pmi' : this.vinhoodEnv;

  private static firebaseEnv = isApplicationPmi ? 'pmi' : 'web';

  private static draftTerms = {};

  public static publishedTerms = {};

  private static firebaseConfig = {
    apiKey: ENV_FIREBASE_I18N_API_KEY,
    appId: ENV_FIREBASE_I18N_APP_ID,
    authDomain: ENV_FIREBASE_I18N_AUTH_DOMAIN,
    messagingSenderId: ENV_FIREBASE_I18N_MESSAGING_SENDER_ID,
    projectId: ENV_FIREBASE_I18N_PROJECT_ID,
    storageBucket: ENV_FIREBASE_I18N_STORAGE_BUCKET,
  };

  private static firebaseAppI18n = this.firebaseConfig.apiKey ? initializeApp(this.firebaseConfig) : null;

  private static fsLocaleDb = this.firebaseConfig.apiKey ? getFirestore(this.firebaseAppI18n) : null;

  public static getCurrencySymbolFromCode(currency) {
    return currencies[currency] || '';
  }

  /**
   * ! Only for DevTools LocalePanel
   * ! Add solid validations
   * ! Display diff between Firebase locale and S3 file
   * ! Save publish logs on Firebase
   */
  public static publishTerms = async () => {
    const locale = selectLocale(store.getState());

    if (locale === languages.ENGLISH) return;

    const publishedTerms = await LocaleUtils.getPublishedTerms(locale);
    const termsToPublish = { ...publishedTerms, ...this.draftTerms };

    const awsConfig = {
      credentials: { accessKeyId: ENV_LOCALE_S3_ACCESS_KEY_ID, secretAccessKey: ENV_LOCALE_S3_SECRET_ACCESS_KEY },
      region: ENV_AWS_S3_BUCKET_REGION,
    };

    const s3Client = new S3Client(awsConfig);
    const cloudfrontClient = new CloudFrontClient(awsConfig);

    if (Object.keys(termsToPublish).length) {
      try {
        const params = {
          Body: JSON.stringify(termsToPublish),
          Bucket: ENV_AWS_S3_BUCKET_NAME,
          CacheControl: 'max-age=0',
          ContentType: 'application/json',
          Headers: {
            'Access-Control-Request-Method': 'PUT',
          },
          Key: `apps/${this.localeEnv}/dist/locale/${locale}.json`,
        };

        const command = new PutObjectCommand(params);
        await s3Client.send(command);

        const invalidationParams = {
          DistributionId: ENV_CLOUDFRONT_DIST_ID,
          InvalidationBatch: {
            CallerReference: Date.now().toString(),
            Paths: {
              Items: [`/locale/*`],
              Quantity: 1,
            },
          },
        };

        const invalidationCommand = new CreateInvalidationCommand(invalidationParams);
        await cloudfrontClient.send(invalidationCommand);
      } catch (e) {
        console.error(e);
      }
    } else {
      console.warn('No terms to publish');
    }
  };

  /**
   * ! Read from S3 in default mode. Read from Firebase in edit mode.
   * ! Ensure this in invoked only once in the app lifecycle
   */
  public static getPublishedTerms = async locale => {
    try {
      if (ENV_IS_BUILD) {
        const publishedTerms = await fetch(`https://${ENV_CLOUDFRONT_APP_DOMAIN}/locale/${locale}.json`);
        this.publishedTerms = await publishedTerms.json();
        return this.publishedTerms;
      }

      // * Return the local files on disk in the local setup

      if (isApplicationPmi) {
        this.publishedTerms = localePmiEnglishTerms;
      } else if (locale === 'en') {
        this.publishedTerms = localeWebEnglishTerms;
      } else {
        this.publishedTerms = localeWebItalianTerms;
      }

      return this.publishedTerms;
    } catch (e) {
      // ! Sentry
      console.error(e);

      const publishedDefaultTerms = await fetch(
        `https://${ENV_CLOUDFRONT_APP_DOMAIN}/locale/${languages.ENGLISH}.json`,
      );
      const terms = await publishedDefaultTerms.json();
      return terms;
    }
  };

  /**
   * ! Replaces all firebase terms with s3 terms
   */
  public static restorePublishedTerms = async () => {
    Object.values(languages).forEach(async locale => {
      try {
        const publishedTerms = await fetch(`https://${ENV_CLOUDFRONT_APP_DOMAIN}/locale/${locale}.json`);
        const terms = await publishedTerms.json();

        // ! Add rules for local development and testing
        const localeRef = doc(this.fsLocaleDb, this.localeEnv, locale);

        console.warn('Restoring terms:', locale, terms);
        setDoc(localeRef, terms);
      } catch (e) {
        // ! Sentry
        console.error(e);
      }
    });
  };

  /**
   * * Obtain draft terms from Firebase
   */
  public static getDraftTerms = (locale, callback: Dispatch<SetStateAction<object>>) => {
    const isLocaleEditMode = selectIsLocaleEditMode(store.getState());
    const localeRef = doc(this.fsLocaleDb, this.firebaseEnv, locale);

    onSnapshot(localeRef, terms => {
      const isAvailable = Object.keys(terms.data()).length;
      if (isLocaleEditMode && isAvailable) {
        this.draftTerms = terms.data();
        callback(this.draftTerms);
      } else {
        // ! Sentry
      }
    });
  };

  /**
   * * Update a translation using context
   */
  public static updateTerm = async (context, translation) => {
    const locale = selectLocale(store.getState());
    const localeRef = doc(this.fsLocaleDb, this.firebaseEnv, locale);

    console.warn('Updating term:', context, translation);
    await setDoc(localeRef, { [context]: translation }, { merge: true });
  };

  /**
   * ! Show only useful diff. Needs additional parsing
   */
  public static generateDiff = (publishedTerms, draftTerms): readonly [DiffResult[], DiffResult[]] => {
    const publishedCopy = { ...publishedTerms };
    const draftCopy = { ...draftTerms };

    Object.keys(publishedCopy).forEach(context => {
      if (publishedCopy[context] === draftCopy[context] || !draftCopy[context]) {
        delete publishedCopy[context];
        delete draftCopy[context];
      }
    });

    const differ = new Differ({
      // default `true`
      arrayDiffMethod: 'lcs',
      detectCircular: true,
      // default `true`
      maxDepth: 4,
      // default `Infinity`
      showModifications: true, // default `"normal"`, but `"lcs"` may be more useful
    });

    // console.log('Diff:', before, after);
    const diff = differ.diff(publishedCopy, draftCopy);
    return diff;
  };

  /**
   * ! Only for DevTools LocalePanel
   * * CSV export of terms from S3 for a specific locale
   * * Headers: term_id, en, <locale>
   */
  public static exportTerms = async (tableData: ILocaleTableDataRow[]) => {
    const locale = selectLocale(store.getState());
    const localTermsEn = await this.getPublishedTerms(languages.ENGLISH);
    const downloadCsv = data => {
      const csvRows = [];
      const headers = ['term_id', languages.ENGLISH, locale];

      csvRows.push(headers.join(','));

      data.forEach(termEntry => {
        const termEn = localTermsEn[termEntry.context] || '';
        const termContext = termEntry.context || '';
        const termDraftText = termEntry.draftText || '';

        const row = [termContext, termEn, termDraftText];

        const escapedRow = row.map(cell => `"${String(cell).replace(/"/g, '""')}"`);
        csvRows.push(escapedRow.join(','));
      });

      const csvData = csvRows.join('\n');
      const blob = new Blob([csvData], { type: 'text/csv' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = `${locale}.csv`;
      link.click();
    };

    downloadCsv(tableData);
  };

  /**
   * ! Only for DevTools LocalePanel
   * * CSV import of terms into Firebase for a specific locale
   * * Headers: term_id, en, <locale>
   * The `en` field is ignored and only used for reference.
   */
  public static importTerms = async () => {
    const locale = selectLocale(store.getState());
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const localeRef = doc(this.fsLocaleDb, this.localeEnv, locale);

    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = '.csv';

    fileInput.addEventListener('change', async event => {
      // @ts-ignore
      const file = event.target.files[0]; // ? support multiple files

      if (file) {
        const reader = new FileReader();
        reader.onload = async e => {
          const csvData = e.target.result;

          Papa.parse(csvData, {
            complete: result => {
              const jsonObjects = result.data;
              const importedTerms = {};

              jsonObjects.forEach(row => {
                // eslint-disable-next-line prefer-destructuring
                importedTerms[row.term_id] = row[locale];
              });

              setDoc(localeRef, importedTerms, { merge: true });
            },
            header: true, // If CSV has headers
          });
        };
        reader.readAsText(file);
      }
    });
    fileInput.click();
  };
}
