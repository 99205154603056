import styled, { css } from 'styled-components';

import Btn from '@components/web/src/atoms/Buttons/Button';
import { STATIC_GRADIENTS } from '@components/web/src/foundations';
import { skeletonLoadingAnimation, skeletonLoadingKeyframes } from '@components/web/src/organisms/Skeleton/styles';
import SwiperHOC from '@components/web/src/organisms/Swiper/SwiperHOC';

export const ExplorePageContainer = styled.div`
  width: 100%;
  background-color: var(--color-secondary-100);
`;

export const ImagesWrapper = styled.div`
  position: relative;
`;

export const WishlistWrapper = styled.div<{ $isLoading: boolean }>`
  position: absolute;
  top: 11px;
  right: 0;
  z-index: 1;
  ${({ $isLoading }) =>
    $isLoading &&
    css`
      display: none;
    `}
`;

export const LocationImage = styled.div<{ $image: string; $isLoading: boolean }>`
  ${({ $isLoading, $image }) =>
    $isLoading
      ? css`
          margin: 20px 20px 20px 0;
          min-height: 131px;
          width: 355px;
          border-radius: 8px;
          background: ${STATIC_GRADIENTS.warmGradientForSkeleton};
          animation: ${skeletonLoadingKeyframes} ${skeletonLoadingAnimation};
        `
      : css`
          width: 100%;
          height: 150px;
          background:
            linear-gradient(
              0deg,
              rgba(0, 0, 0, 0) 0%,
              rgba(0, 0, 0, 0.21) 45%,
              rgba(0, 0, 0, 0.56) 70.22%,
              rgba(0, 0, 0, 0.7) 100%
            ),
            url(${$image}) lightgray 50% / cover no-repeat;
          background-size: cover;
          background-position: center;
        `}
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 10px 16px 32px;
  width: 100%;
`;

export const InfoSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;

  img {
    height: 67px;
    width: fit-content;
    margin-right: auto;
  }
`;

export const ContactSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;

  & > img {
    height: 171px;
    width: fit-content;
  }
`;

export const Contact = styled.div<{ $isAddressContact: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;
  text-decoration: underline;
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    color: transparent;
    height: auto;
  }

  span {
    ${({ $isAddressContact }) =>
      !$isAddressContact &&
      css`
        text-transform: lowercase;
      `}
  }
`;

export const ProductSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;

  & > span {
    text-align: center;
  }
`;

export const ExperienceSection = styled.div<{ $isSingleCard: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  padding: ${({ $isSingleCard }) => ($isSingleCard ? '0 16px 32px' : '0 0 32px 16px')};

  .hidden-pagination-block {
    height: 40px;
  }
`;

export const SwiperHOCSection = styled(SwiperHOC)<{ $isSingleCard?: boolean }>`
  .swiper-wrapper {
    .swiper-slide {
      width: ${({ $isSingleCard }) => ($isSingleCard ? '100%' : 'auto')};
    }
    > *:last-child {
      margin-inline-end: 16px;
    }
  }
`;

export const Button = styled(Btn)`
  width: 100%;
`;

export const CtaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0 16px 32px;
`;
