import { FC } from 'react';

import { localeCommon } from '@lib/tools/locale/source/web/common';

import { STATIC_COLORS } from '@components/web/src/foundations';
import CharacterInfo, {
  ICharacterInfoCardProps,
} from '@components/web/src/organisms/Cards/CharacterInfo/CharacterInfo';
import * as S from '@components/web/src/organisms/CharacterInfoGroup/styles';
import { Skeleton } from '@components/web/src/organisms/Skeleton/Skeleton';

interface IProps {
  characterInfoList: ICharacterInfoCardProps[];
  isLoading: boolean;
}

const CharacterInfoGroup: FC<IProps> = ({ characterInfoList, isLoading }) => {
  const { title } = localeCommon.characterInfoGroup;

  return (
    <S.CharacterInfoContainer>
      <S.Title color={STATIC_COLORS.base.black} fontFamily="Fraunces" size="h5" text={title} weight="semibold" />

      {isLoading ? (
        <Skeleton borderRadius="8px" margin="20px 20px 20px" minHeight="292px" width="100%" />
      ) : (
        <S.InfoContainer>
          {characterInfoList?.map((infoItem, index) => <CharacterInfo key={index} {...infoItem} />)}
        </S.InfoContainer>
      )}
    </S.CharacterInfoContainer>
  );
};

export default CharacterInfoGroup;
