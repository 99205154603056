import { FC } from 'react';

import * as S from '@components/web/src/organisms/Skeleton/PlaceCardSkeleton/styles';

interface IProps {
  isResponsive?: boolean;
}

const PlaceCardSkeleton: FC<IProps> = ({ isResponsive = false }) => {
  return (
    <S.PlacesCardSkeletonContainer $isResponsive={isResponsive} direction="column">
      <S.ImageSkeleton />
      <S.ContentWrapperSkeleton direction="column" gap={4}>
        <S.LogoSkeleton />
        <S.TitleSkeleton />
        <S.SubtitleSkeleton />
      </S.ContentWrapperSkeleton>
    </S.PlacesCardSkeletonContainer>
  );
};

export default PlaceCardSkeleton;
