import { FC } from 'react';

import { localeCommon } from '@lib/tools/locale/source/web/common';

import { STATIC_COLORS } from '@components/web/src/foundations';
import { Text } from '@components/web/src/foundations/Text/Text';
import * as S from '@components/web/src/templates/Loading/ScanFidelityCardWarning/styles';

export const ScanFidelityCardWarning: FC = () => {
  const { scanFidelityCardWarningTitle, scanFidelityCardWarningDescription } = localeCommon.scanFidelityCardWarning;

  return (
    <S.ScanFidelityCardWarningContainer direction="column" gap={4} padding="16px">
      <Text
        color={STATIC_COLORS.base.white}
        size="subtitle2"
        text={scanFidelityCardWarningTitle}
        textAlign="center"
        weight="bold"
      />
      <Text
        color={STATIC_COLORS.base.white}
        size="body1"
        text={scanFidelityCardWarningDescription}
        textAlign="center"
      />
    </S.ScanFidelityCardWarningContainer>
  );
};
