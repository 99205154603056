import { css, styled } from 'styled-components';

import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';

export const LowSpeedWarningContainer = styled(Flexbox)`
  width: 343px;
  opacity: 0.8;
  border-radius: 16px;
  background: rgba(42, 90, 111, 0.8);

  ${({ theme }) =>
    theme?.isKioskRootElementType &&
    css`
      width: 824px;
    `}
`;
