import { FC, useState } from 'react';

import { IAuthFeatureParams } from '@app/web/src/hooks/useAuthFeatureClick';

import { IParsedCharacter } from '@lib/core/characters/utils/parseCharacter';
import { TProductCategory } from '@lib/core/products/types';
import { sortCharacterAdjectiveModalData } from '@lib/tools/characterAdjective';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { localeWidget } from '@lib/tools/locale/source/web/widget';
import { AUTH_BANNERS } from '@lib/tools/shared/helpers/consts';

import CharacterAdjective, {
  ICharacterAdjectiveItemData,
} from '@components/web/src/atoms/CharacterAdjective/CharacterAdjective';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Text } from '@components/web/src/foundations/Text/Text';
import { TSwiperData } from '@components/web/src/organisms/Authentication/AuthBanners/ExtraInfoSwiper/ExtraInfoSwiper';
import * as S from '@components/web/src/organisms/CharacterAdjectivesGroup/styles';
import { Skeleton } from '@components/web/src/organisms/Skeleton/Skeleton';
import ExtraInfoModal from '@components/web/src/templates/Modals/ExtraInfoModal/ExtraInfoModal';

interface IProps {
  characterData: IParsedCharacter;
  productCategory: TProductCategory;
  isLoading: boolean;
  characterAdjectiveItemsData: ICharacterAdjectiveItemData[];
  characterAdjectiveModalData?: TSwiperData[];
  isUserAuthenticated?: boolean;
  isTestResultPage?: boolean;
  handleAdjectiveClick?: (params: IAuthFeatureParams) => void;
}

const CharacterAdjectivesGroup: FC<IProps> = ({
  characterData,
  productCategory,
  isLoading,
  characterAdjectiveItemsData,
  characterAdjectiveModalData,
  isUserAuthenticated = false,
  isTestResultPage = false,
  handleAdjectiveClick,
}) => {
  const [isEssenceModalOpen, setIsEssenceModalOpen] = useState(false);

  const { characterTitleText, authenticatedCharacterTitleText } = localeWidget.testResultPage;
  const { productCategories } = localeCommon;
  const titleText = isUserAuthenticated ? authenticatedCharacterTitleText : characterTitleText;

  const [sortedCharacterAdjectivesModalData, setSortedCharacterAdjectivesModalData] =
    useState(characterAdjectiveModalData);
  const { characterTechnicalCaption, characterName, characterImage } = characterData || {};

  const handleCharacterAdjectiveClick = identifier => {
    if (isTestResultPage) {
      handleAdjectiveClick({
        adjectiveCharacterIdToMove: identifier,
        productCategory,
        registrationBannerVariant: AUTH_BANNERS.adjective,
      });
    } else {
      const sortedData = sortCharacterAdjectiveModalData(characterAdjectiveModalData, identifier);

      setIsEssenceModalOpen(true);
      setSortedCharacterAdjectivesModalData(sortedData);
    }
  };

  return (
    <S.CharacterAdjectivesGroupContainer>
      <S.TextWrapper>
        <Text
          color={STATIC_COLORS.base.black}
          fontFamily="Fraunces"
          localeIndex={{ productCategory }}
          localeVariables={{ productCategoryText: productCategories[productCategory] }}
          size="h6"
          text={titleText}
          weight="semibold"
        />
        <Text
          color={STATIC_COLORS.productColors[productCategory][500]}
          fontFamily="Fraunces"
          size="h2"
          text={characterName}
          weight="semibold"
        />
        <Text color={STATIC_COLORS.base.black} size="body1" text={characterTechnicalCaption} weight="normal" />
      </S.TextWrapper>
      <S.CharacterImageWrapper
        $isLoading={isLoading}
        $isTestResultPage={isTestResultPage}
        align="center"
        justify="center"
      >
        {isLoading ? (
          <Skeleton borderRadius="8px" margin="20px 20px 20px 0" minHeight="274px" width="100%" />
        ) : (
          <S.CharacterImage alt="character" src={characterImage} />
        )}
      </S.CharacterImageWrapper>
      {!isLoading && (
        <S.CharacterAdjectivesWrapper>
          {characterAdjectiveItemsData?.map(({ identifier, preTitle, score, title }, idx) => (
            <CharacterAdjective
              key={idx}
              handleClick={() => handleCharacterAdjectiveClick(identifier)}
              identifier={identifier}
              preTitle={preTitle}
              productCategory={productCategory}
              score={score}
              title={title}
            />
          ))}
        </S.CharacterAdjectivesWrapper>
      )}

      {isEssenceModalOpen && (
        <ExtraInfoModal
          hideModal={() => setIsEssenceModalOpen(false)}
          isModalOpen={isEssenceModalOpen}
          swiperData={sortedCharacterAdjectivesModalData}
          variant={AUTH_BANNERS.adjective}
        />
      )}
    </S.CharacterAdjectivesGroupContainer>
  );
};

export default CharacterAdjectivesGroup;
