import { FC, useState } from 'react';

import { TQuizAnswerData } from '@lib/core/quizzes/types';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { ILocaleText } from '@lib/tools/locale/views/LocaleFragment';
import { ICustomStyles } from '@lib/tools/shared/helpers/interfaces';

import { TestAnswer } from '@components/web/src/atoms/TestAnswer/TestAnswerItem/TestAnswer';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Text } from '@components/web/src/foundations/Text/Text';
import { KioskCirclesBackground } from '@components/web/src/kiosk/KioskCirclesBackground/KioskCirclesBackground';
import ProgressBar from '@components/web/src/organisms/ProgressBar/ProgressBar';
import * as S from '@components/web/src/templates/TestFlowsPages/QuizPage/styles';

interface Props {
  answers: TQuizAnswerData[];
  question: ILocaleText;
  progressPercent: number;
  firstQuestion: boolean;
  withoutProgressBar?: boolean;
  isApplicationKiosk: boolean;
  isDevToolsEnabled: boolean;
  isUserQuizLoading?: boolean;
  customStyles: ICustomStyles;
  goBack: () => void;
  onAnswerSelectHandler: (id: string) => void;
}

const QuizPage: FC<Props> = ({
  answers,
  withoutProgressBar,
  question,
  progressPercent,
  isApplicationKiosk,
  isDevToolsEnabled,
  isUserQuizLoading,
  customStyles,
  goBack,
  onAnswerSelectHandler,
}) => {
  const [isDisableQuiz, setIsDisableQuiz] = useState(false);

  const { commonMessages } = localeCommon;
  const { customQuizBackgroundColor, customTextColor, customSecondaryColor } = customStyles;

  return (
    <S.QuizPageContainer
      isFullWidth
      $background={customQuizBackgroundColor}
      $withoutProgressBar={withoutProgressBar}
      align="center"
      direction="column"
      gap={24}
      justify="center"
      padding="16px"
    >
      {isApplicationKiosk && <KioskCirclesBackground />}
      {!withoutProgressBar && (
        <S.ProgressBarWrapper>
          <ProgressBar progressPercentage={progressPercent} />
        </S.ProgressBarWrapper>
      )}
      <Text
        color={customTextColor || STATIC_COLORS.base.black}
        fontFamily="Fraunces"
        size="h5"
        text={question}
        textAlign="center"
        weight="semibold"
      />
      <S.AnswersContainer align="center" justify="center">
        {answers.map(answer => {
          const answerId = Object.keys(answer)[0];
          return (
            <TestAnswer
              key={answerId}
              customTextColor={customTextColor}
              id={answerId}
              image={answer[answerId].image}
              isApplicationKiosk={isApplicationKiosk}
              isDevToolsEnabled={isDevToolsEnabled}
              isDisabled={isDisableQuiz}
              isSelected={false}
              isUserQuizLoading={isUserQuizLoading}
              setIsDisableQuiz={setIsDisableQuiz}
              text={answer[answerId].text}
              onSelect={onAnswerSelectHandler}
            />
          );
        })}
      </S.AnswersContainer>
      {goBack && (
        <S.GoBackButton
          customArrowColor={customSecondaryColor}
          handleClick={goBack}
          style={{ color: customTextColor }}
          text={commonMessages.goBackBtn}
          variant="long"
        />
      )}
    </S.QuizPageContainer>
  );
};

export default QuizPage;
