import { FC } from 'react';

import { localeWidget } from '@lib/tools/locale/source/web/widget';

import { CloseButton } from '@components/web/src/atoms/Buttons/CloseButton/CloseButton';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';
import * as S from '@components/web/src/templates/Modals/FoodPreferencesModal/styles';
import { ModalWrapperNew } from '@components/web/src/templates/Modals/ModalWrapper/ModalWrapperNew';

interface IProps {
  isModalOpen: boolean;
  hideModal: () => void;
}

export const FoodPreferencesModal: FC<IProps> = ({ isModalOpen, hideModal }) => {
  const { heading, description } = localeWidget.foodPreferencesModal;

  return (
    <ModalWrapperNew isOpen={isModalOpen} onClose={hideModal}>
      <S.FoodPreferencesModalContainer isFullWidth direction="column" gap={0}>
        <CloseButton handleClick={hideModal} position="right" />
        <Flexbox direction="column" gap={4}>
          <Text color={STATIC_COLORS.base.black} fontFamily="Fraunces" size="h5" text={heading} weight="semibold" />
          <Text color={STATIC_COLORS.base.black} size="body1" text={description} />
        </Flexbox>
      </S.FoodPreferencesModalContainer>
    </ModalWrapperNew>
  );
};
