import { FC } from 'react';

import { TParsedProductInstance, TProductCategory } from '@lib/core/products/types';
import { TRetailerLocationStoreType } from '@lib/core/retailers/types';
import { ILocation, IUpdateLocationListParams } from '@lib/core/users/types';
import { isLocationInWishlistFilter } from '@lib/core/users/utils/filters';
import { MP_POSITION_CONTEXT } from '@lib/tools/dat/mixpanel/consts';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import {
  JOURNAL_PRODUCT_VARIANT,
  PRODUCT_CARD_VARIANTS,
  PRODUCT_CATEGORY_URL_PARAM,
  TJournalVariant,
} from '@lib/tools/shared/helpers/consts';
import { PAGES } from '@lib/tools/views/urls';

import { ReactComponent as ButtonArrow } from '@components/web/src/assets/icons/Product-journal-arror.svg';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';
import { PlacesCard } from '@components/web/src/organisms/Cards/PlacesCard/PlacesCard';
import EmptyStateCard from '@components/web/src/organisms/EmptyStateCard/EmptyStateCard';
import ProductCard from '@components/web/src/organisms/ProductCards/OldProductCard/ProductCard';
import { IDiscoveryQuiz } from '@components/web/src/templates/Modals/DiscoveryQuizModal/DiscoveryQuizModal';
import * as S from '@components/web/src/templates/TasteId/TasteIdPage/JournalSwiper/styles';

interface IJournalSwiperProps {
  // base props
  variant: TJournalVariant;
  productCategory: TProductCategory;
  swiperData: TParsedProductInstance[] | ILocation[];
  handleNavigateClick: (link: string, params?: { [key: string]: string }) => void;
  storeType?: TRetailerLocationStoreType;
  shouldShowFeedbackTooltip?: boolean;
  shouldHideAddButton?: boolean;
  discoveryQuiz?: IDiscoveryQuiz;
  isSwiperDataLoading: boolean;
  handleEmptyStateNavigate?: (url: string) => void;
  // places variant props
  isLocationListLoading?: boolean;
  handleUpdateLocationList?: ({ retailerLocationSlug }: IUpdateLocationListParams) => void;
}

export const JournalSwiper: FC<IJournalSwiperProps> = ({
  // base props
  variant = JOURNAL_PRODUCT_VARIANT,
  productCategory,
  swiperData = [],
  isSwiperDataLoading,
  storeType,
  discoveryQuiz,
  shouldShowFeedbackTooltip,
  shouldHideAddButton = false,
  handleNavigateClick,
  // places variant props
  isLocationListLoading,
  handleUpdateLocationList,
  handleEmptyStateNavigate,
}) => {
  const { journalTitle, journalPlacesTitle, viewAllCTA } = localeCommon.tasteId;

  const swiperDataList = isSwiperDataLoading ? Array(3).fill({}) : swiperData || [];

  const isVariantProduct = variant === JOURNAL_PRODUCT_VARIANT;

  const showEmptyStateCard = !swiperDataList?.length;
  const isSingleCardSwiper = swiperDataList?.length === 1;

  const titleText = isVariantProduct ? journalTitle : journalPlacesTitle;

  const navigateToJournal = () => {
    const page = isVariantProduct
      ? PAGES.vinhood.tasteId.tasteIdProductsJournal
      : PAGES.vinhood.tasteId.tasteIdPlacesJournal;

    handleNavigateClick(page, { [PRODUCT_CATEGORY_URL_PARAM]: productCategory });
  };

  const renderProductCard = (data: TParsedProductInstance, index, isResponsive: boolean) => (
    <ProductCard
      key={index}
      discoveryQuiz={discoveryQuiz}
      isLoading={isSwiperDataLoading}
      isResponsive={isResponsive}
      mixpanelIndex={index}
      mixpanelPositionContext={MP_POSITION_CONTEXT.SWIPER}
      parsedProductInstanceData={data}
      shouldShowFeedbackTooltip={shouldShowFeedbackTooltip}
      storeType={storeType}
      variant={PRODUCT_CARD_VARIANTS.COMPACT}
    />
  );

  const renderPlacesCard = (data: ILocation, index, isResponsive: boolean) => {
    const locationId = data?.retailer_location?.identifier || data?.retailerLocation?.identifier;

    return (
      <PlacesCard
        key={index}
        handleUpdateLocationList={handleUpdateLocationList}
        isLoading={isSwiperDataLoading}
        isLocationInWishlist={isLocationInWishlistFilter(swiperData as ILocation[], locationId)}
        isLocationListLoading={isLocationListLoading}
        isResponsive={isResponsive}
        locationData={data}
        locationPositionContext={MP_POSITION_CONTEXT.SWIPER}
        locationPositionIndex={index}
        productCategory={productCategory}
      />
    );
  };

  return (
    <S.JournalSwiper>
      <S.HeaderWrapper>
        <S.TitleWrapper>
          <Text
            fontFamily="Fraunces"
            localeOptions={{ productCategory }}
            size="h5"
            text={titleText}
            weight="semibold"
          />
          {(isSwiperDataLoading || swiperData?.length > 3) && (
            <S.StyledButton
              fontSize="body2"
              handleClick={navigateToJournal}
              icon={<ButtonArrow />}
              iconPosition="right"
              text={viewAllCTA}
              textWeight="medium"
              variant="secondary"
            />
          )}
        </S.TitleWrapper>
        {showEmptyStateCard && (
          <EmptyStateCard
            productCategory={productCategory}
            shouldHideAddButton={shouldHideAddButton}
            variant={isVariantProduct ? 'product' : 'places'}
            handleBtnClick={() =>
              handleEmptyStateNavigate(isVariantProduct ? PAGES.vinhood.catalog : PAGES.vinhood.explore)
            }
          />
        )}
      </S.HeaderWrapper>

      <S.SwiperHOCSection
        $isSingleCard={isSingleCardSwiper}
        showPaginationDots={swiperData?.length > 1}
        spaceBetween={isSingleCardSwiper ? 0 : 20}
      >
        {swiperData.slice(0, 3).map((data, index) => {
          const shouldShowViewAllBtn = index === 2 && swiperData?.length > 3;

          return (
            <Flexbox key={index} isFullHeight align="center">
              {isVariantProduct
                ? renderProductCard(data, index, isSingleCardSwiper)
                : renderPlacesCard(data, index, isSingleCardSwiper)}
              {shouldShowViewAllBtn && (
                <S.ButtonWrapper>
                  <S.StyledButton
                    fontSize="body2"
                    handleClick={navigateToJournal}
                    icon={<ButtonArrow />}
                    iconPosition="right"
                    text={viewAllCTA}
                    textWeight="medium"
                    variant="secondary"
                  />
                </S.ButtonWrapper>
              )}
            </Flexbox>
          );
        })}
      </S.SwiperHOCSection>
    </S.JournalSwiper>
  );
};
