import styled from 'styled-components';

export const CirclesBackgroundContainer = styled.div<{ $zIndex: number }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
  z-index: ${({ $zIndex }) => $zIndex};
`;

export const CirclesBackground = styled.div`
  position: absolute;
  top: 60px;
  left: 50%;
  width: auto;
  margin: 0 auto;
  transform: translateX(-50%);
`;
