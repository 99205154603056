import cn from 'classnames';
import { FC } from 'react';

import { ILocaleText, LocaleFragment } from '@lib/tools/locale/views/LocaleFragment';

import tickIcon from '@components/web/src/assets/legacy/b2c/icons/icon_greentick.svg';
import keyIcon from '@components/web/src/assets/legacy/b2c/icons/icon_key.svg';
import mailIcon from '@components/web/src/assets/legacy/b2c/icons/icon_mail.svg';
import { Image } from '@components/web/src/foundations/Image/Image';

type TIconVariant = 'key' | 'greenTick' | 'mail';

interface IProps {
  headerIcon: TIconVariant;
  mainHeader: ILocaleText;
  subHeader: ILocaleText;
  className?: string;
  email?: string;
  subHeaderSecond?: ILocaleText;
  isCompleteResetPasswordPage?: boolean;
}

const AuthHeader: FC<IProps> = ({
  headerIcon,
  mainHeader,
  subHeader,
  className,
  email,
  subHeaderSecond,
  isCompleteResetPasswordPage = false,
}) => {
  const icon = {
    greenTick: tickIcon,
    key: keyIcon,
    mail: mailIcon,
  };

  return (
    <div className={cn(className, 'auth-header-container')}>
      <Image alt="icon" height="56px" src={icon[headerIcon]} width="56px" />
      <p className={cn('title', { isCompleteResetPasswordPage })}>
        <LocaleFragment message={mainHeader} />
      </p>
      <p className="subtitle">
        <LocaleFragment message={subHeader} />
      </p>
      {email && (
        <p className="subtitle">
          <LocaleFragment message={email} />
        </p>
      )}
      {subHeaderSecond && (
        <p className="subtitle">
          <LocaleFragment message={subHeaderSecond} />
        </p>
      )}
    </div>
  );
};

export default AuthHeader;
