import { useState } from 'react';

import { IParsedServiceTerm } from '@lib/core/serviceTerms/types';
import { localeApp } from '@lib/tools/locale/source/web/app';
import { VH_VARIANTS } from '@lib/tools/shared/helpers/consts';

import { ReactComponent as WarningIcon } from '@components/web/src/assets/legacy/b2c/icons/warning_icon.svg';
import Button from '@components/web/src/atoms/Buttons/Button';
import { CloseButton } from '@components/web/src/atoms/Buttons/CloseButton/CloseButton';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { GeneralTermsCheckboxesComponent } from '@components/web/src/templates/Modals/GeneralTermsCheckboxesComponent/GeneralTermsCheckboxesComponent';
import * as S from '@components/web/src/templates/Modals/ManageModal/styles';
import { IModalOption } from '@components/web/src/templates/Modals/ModalOptionItem/ModalOptionItem';
import ModalWrapper from '@components/web/src/templates/Modals/ModalWrapper/ModalWrapper';

type Props = {
  accountOptions: Record<string, IModalOption>;
  isModalOpen: boolean;
  hideModal: () => void;
  handleConfirmButton?: (values: Record<string, IModalOption>) => void;
  handleCancelButton?: () => void;
};

const ManageModal = ({ accountOptions, isModalOpen, handleConfirmButton, handleCancelButton, hideModal }: Props) => {
  const [accountState, setAccountState] = useState(accountOptions);

  const handleOnContinue = () => {
    handleConfirmButton(accountState);
  };

  const handleOptionChange = ({ category }: IParsedServiceTerm | IModalOption) => {
    setAccountState({
      ...accountState,
      [category]: {
        ...accountState[category],
        is_selected: !accountState[category].is_selected,
      },
    });
  };
  const { manageModal } = localeApp;

  return (
    <ModalWrapper hideModal={hideModal} isOpen={isModalOpen}>
      <S.ManageModalContainer>
        <Flexbox justify="space-between">
          <WarningIcon />
          <CloseButton handleClick={hideModal} />
        </Flexbox>
        <S.Title color={STATIC_COLORS.gray['900']} size="subtitle1" text={manageModal.title} />

        <S.Description color={STATIC_COLORS.base.black} size="body1" text={manageModal.description} />

        <S.ModalItems>
          <GeneralTermsCheckboxesComponent
            hideToggleText
            isManageModalVariant
            flexGap={24}
            options={Object.values(accountState)}
            onToggle={handleOptionChange}
          />
        </S.ModalItems>
        <S.ModalActions>
          <Button
            fontSize="subtitle2"
            handleClick={handleCancelButton}
            size="sm"
            text={manageModal.buttonCancelText}
            textWeight="medium"
            variant={VH_VARIANTS.LIGHT}
          />
          <Button
            fontSize="subtitle2"
            handleClick={handleOnContinue}
            size="sm"
            text={manageModal.buttonConfirmText}
            textWeight="medium"
          />
        </S.ModalActions>
      </S.ManageModalContainer>
    </ModalWrapper>
  );
};

export default ManageModal;
