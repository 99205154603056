import { useEffect, useMemo, useState } from 'react';

import { useProductFeedback } from '@lib/core/products/hooks/useProductFeedback';
import { ProductFeedbackSteps } from '@lib/core/products/slices/productFeedback';
import { useRetailerLocation } from '@lib/core/retailers/hooks';
import { useApp } from '@lib/core/service/hooks';

import { ModalWrapperNew } from '@components/web/src/templates/Modals/ModalWrapper/ModalWrapperNew';
import { ProductFeedbackExitAwarenessModal } from '@components/web/src/templates/ProductFeedback/ProductFeedbackExitAwarenessModal/ProductFeedbackExitAwarenessModal';
import { ProductFeedbackPageDone } from '@components/web/src/templates/ProductFeedback/ProductFeedbackPageDone/ProductFeedbackPageDone';
import { ProductFeedbackPageStart } from '@components/web/src/templates/ProductFeedback/ProductFeedbackPageStart/ProductFeedbackPageStart';
import { ProductFeedbackQuestionPage } from '@components/web/src/templates/ProductFeedback/ProductFeedbackQuestionPage/ProductFeedbackQuestionPage';
import { ProductFeedbackResultPage } from '@components/web/src/templates/ProductFeedback/ProductFeedbackResultPage/ProductFeedbackResultPage';
import { ProductFeedbackTutorial } from '@components/web/src/templates/ProductFeedback/ProductFeedbackTutorial/ProductFeedbackTutorial';

import { SafeComponent } from 'providers/SafeComponent';

export const ProductFeedbackModalContainer = () => {
  const [isExitAwarenessModalOpen, setIsExitAwarenessModalOpen] = useState(false);
  const toggleAwarenessModal = () => {
    setIsExitAwarenessModalOpen(!isExitAwarenessModalOpen);
  };

  const {
    getAllSubmittedProductFeedbacks,
    getProductFeedbackQuestions,
    getProductFeedbackBanners,
    isRouteToResetProductFeedbackFlow,
    isProductFeedbackDataOrOptionsLoading,
    isProductFeedbackCurrentQuestionAllAnswersAreLoaded,
    isProductFeedbackAnswersSubmitLoading,
    productFeedbackQuestionsData,
    productFeedbackStep,
    productFeedbackCurrentQuestion,
    parsedProductInstanceData,
    productFeedbackBanners,
    productFeedbackDataLocale,
    productFeedbackTutorialProduct,
    finishedFeedbacksForProduct,
    handleForwardProductFeedbackStep,
    handleSaveProductFeedbackAnswer,
    handleProductFeedbackAnswerDone,
    handleToggleProductFeedbackTutorial,
    handleResetProductFeedbackFlow,
    handleSubmitProductFeedbackAnswers,
    handleToggleOpenedFeedbackModal,
  } = useProductFeedback();

  const { locale } = useApp();
  const { retailerLocationName } = useRetailerLocation();

  useEffect(() => {
    if (!productFeedbackBanners?.length || productFeedbackDataLocale !== locale) {
      getProductFeedbackBanners();
    }
    if (
      !parsedProductInstanceData?.productFeedbackStatus?.isFinished &&
      (productFeedbackStep === ProductFeedbackSteps.Questions || productFeedbackStep === ProductFeedbackSteps.Start)
    ) {
      getProductFeedbackQuestions();
    }

    if (parsedProductInstanceData?.productFeedbackStatus?.isFinished && productFeedbackDataLocale !== locale) {
      getAllSubmittedProductFeedbacks(true);
    }
  }, []);

  useEffect(() => {
    if (isRouteToResetProductFeedbackFlow) {
      handleResetProductFeedbackFlow();
    }
  }, [isRouteToResetProductFeedbackFlow]);

  let Component;

  const StartComponent = useMemo(
    () => (
      <ProductFeedbackPageStart
        handleNavigate={handleForwardProductFeedbackStep}
        isLoading={isProductFeedbackDataOrOptionsLoading}
        parsedProductInstanceData={parsedProductInstanceData}
      />
    ),
    [isProductFeedbackDataOrOptionsLoading],
  );

  switch (productFeedbackStep) {
    case ProductFeedbackSteps.Start:
      Component = StartComponent; // Memoize because re-renders of FeedbackModalContainer.tsx resets a timer
      break;
    case ProductFeedbackSteps.Questions:
    case ProductFeedbackSteps.Comment:
    case ProductFeedbackSteps.Rating:
      if (productFeedbackQuestionsData) {
        Component = (
          <ProductFeedbackQuestionPage
            banners={productFeedbackBanners}
            handleClose={handleToggleOpenedFeedbackModal}
            handleProductFeedbackAnswerDone={handleProductFeedbackAnswerDone}
            handleSkip={handleForwardProductFeedbackStep}
            handleSubmitAnswer={handleSaveProductFeedbackAnswer}
            handleSubmitProductFeedbackAnswers={handleSubmitProductFeedbackAnswers}
            isLoading={!isProductFeedbackCurrentQuestionAllAnswersAreLoaded || isProductFeedbackDataOrOptionsLoading}
            isProductFeedbackAnswersSubmitLoading={isProductFeedbackAnswersSubmitLoading}
            parsedProductInstanceData={parsedProductInstanceData}
            productFeedbackCurrentQuestion={productFeedbackCurrentQuestion}
            productFeedbackStep={productFeedbackStep}
            questionsAmount={productFeedbackQuestionsData.choices?.questions?.length}
            subtitle={retailerLocationName}
          />
        );
      }
      break;
    case ProductFeedbackSteps.Done:
      Component = (
        <ProductFeedbackPageDone
          handleNavigate={handleToggleOpenedFeedbackModal}
          locale={locale}
          parsedProductInstanceData={parsedProductInstanceData}
        />
      );
      break;
    case ProductFeedbackSteps.ProductFeedbackResult:
      Component = (
        <ProductFeedbackResultPage
          answers={finishedFeedbacksForProduct}
          handleGoBack={handleToggleOpenedFeedbackModal}
          parsedProductInstanceData={parsedProductInstanceData}
          retailerLocationName={retailerLocationName}
        />
      );
      break;
    case ProductFeedbackSteps.Tutorial:
    default:
      Component = (
        <ProductFeedbackTutorial
          handleClose={() => handleToggleProductFeedbackTutorial({})}
          parsedProductInstanceData={productFeedbackTutorialProduct}
        />
      );
      break;
  }

  const isTutorialProductFeedbackStep = productFeedbackStep === ProductFeedbackSteps.Tutorial;

  return (
    <SafeComponent onError={handleResetProductFeedbackFlow}>
      <ModalWrapperNew
        isOpen
        isTransparent={isTutorialProductFeedbackStep}
        minModalContentHeight={!isTutorialProductFeedbackStep && '100%'}
        withoutDefaultPadding={!isTutorialProductFeedbackStep}
        handleBackButtonClick={
          productFeedbackStep === ProductFeedbackSteps.Questions ||
          productFeedbackStep === ProductFeedbackSteps.Rating ||
          productFeedbackStep === ProductFeedbackSteps.Comment
            ? toggleAwarenessModal
            : handleToggleOpenedFeedbackModal
        }
        withBackButton={
          !isTutorialProductFeedbackStep && productFeedbackStep !== ProductFeedbackSteps.ProductFeedbackResult
        }
      >
        {Component}
        {isExitAwarenessModalOpen && (
          <ProductFeedbackExitAwarenessModal
            isOpen
            handleCancelTasteClick={handleToggleOpenedFeedbackModal}
            handleContinueTasteClick={toggleAwarenessModal}
            hideModal={toggleAwarenessModal}
          />
        )}
      </ModalWrapperNew>
    </SafeComponent>
  );
};
