import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useApp } from '@lib/core/service/hooks';
import { useThirdParty } from '@lib/core/service/hooks/useThirdParty';
import { setIsTasteIdGuideShown } from '@lib/core/service/slices';
import { prependBasename } from '@lib/core/service/utils';
import { useServiceTerms } from '@lib/core/serviceTerms/hooks/useServiceTerms';
import { IParsedServiceTerm } from '@lib/core/serviceTerms/types';
import { useCarryUserData, useUser } from '@lib/core/users/hooks';
import {
  actionRegister,
  facebookLoginCallback,
  googleLoginCallback,
  resetAuthError,
} from '@lib/core/users/slices/auth';
import { actionSetAnonymousUserCharacter, actionSetAnonymousUserProfileId } from '@lib/core/users/slices/user';
import { USER_DATA_FIELDS } from '@lib/core/users/types';
import { useAddons } from '@lib/tools/views/hooks';
import { PAGES } from '@lib/tools/views/urls';

import { RegistrationFormInputObject } from '@components/web/src/pages/Authentication/AuthFormInterface';
import { CharacterOverwritingPage } from '@components/web/src/templates/CharacterOverwriting/CharacterOverwritingPage';
import { LoadingSpinner } from '@components/web/src/templates/Loading/LoadingSpinner/LoadingSpinner';
import { AuthenticationPage } from '@components/web/src/templates/Login&Registration/AuthenticationPage/AuthenticationPage';
import { ModalWrapperNew } from '@components/web/src/templates/Modals/ModalWrapper/ModalWrapperNew';

export const AuthenticationContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const {
    serviceTermsByCategory: { policy, marketing },
    serviceTermsListUpdateIsLoading,
    updateServiceTerms,
  } = useServiceTerms();
  const { isEnableTastePathAddon, isShowSkipAndGoToProductsAddon } = useAddons();
  const { isRetailerSupportsThirdPartyOption } = useThirdParty();
  const { productCategory } = useApp();
  const {
    isUserAuthenticated,
    isUserDataLoading,
    authError,
    isAuthDataLoading,
    userBestMatchCharacterForCurrentServiceProductCategory,
    isUserCharactersLoading,
    userFirstName,
    profileId,
    anonymousUserCharacter,
    isProfileLoading,
    isLoadingAnonymousData,
    isUserFullUserDataLoadedTemporaryHint,
  } = useUser();
  const { carryUserData } = useCarryUserData();

  const isSocialAuthTesting = searchParams.get('is_social_test') === 'true';
  const setAnonymousUserCharacter = userBestMatchCharacterForCurrentServiceProductCategory && !anonymousUserCharacter;
  const isAuthUserDataLoaded = isUserAuthenticated && isUserFullUserDataLoadedTemporaryHint && !isUserCharactersLoading;
  const redirectAuthUserAfterRegistration =
    isAuthUserDataLoaded &&
    anonymousUserCharacter.characterId === userBestMatchCharacterForCurrentServiceProductCategory.characterId;
  const showCharacterOverwritingModal =
    isAuthUserDataLoaded &&
    anonymousUserCharacter &&
    !!userBestMatchCharacterForCurrentServiceProductCategory &&
    anonymousUserCharacter.characterId !== userBestMatchCharacterForCurrentServiceProductCategory.characterId;

  const [isEmailAuth, setIsEmailAuth] = useState(false);
  const [registerPageServiceTerms, setRegisterPageServiceTerms] = useState({
    marketing,
    policy,
  });

  useEffect(() => {
    setRegisterPageServiceTerms({ marketing, policy });
  }, [policy, marketing]);

  useEffect(() => {
    return () => {
      dispatch(resetAuthError());
    };
  }, []);

  useEffect(() => {
    if (setAnonymousUserCharacter) {
      dispatch(actionSetAnonymousUserProfileId(profileId));
      dispatch(actionSetAnonymousUserCharacter(userBestMatchCharacterForCurrentServiceProductCategory));
    }
  }, [userBestMatchCharacterForCurrentServiceProductCategory]);

  const handleOnServiceTermToggle = ({ category }: IParsedServiceTerm) =>
    setRegisterPageServiceTerms({
      ...registerPageServiceTerms,
      [category]: {
        ...registerPageServiceTerms[category],
        is_selected: !registerPageServiceTerms[category].is_selected,
      },
    });

  const handleEmailAuthButtonClick = () => {
    setIsEmailAuth(state => !state);
    dispatch(resetAuthError());
  };

  const handleFormSubmit = async (data: RegistrationFormInputObject) => {
    const { email, password, lastName, name } = data;

    await dispatch(
      actionRegister({
        email,
        first_name: name,
        last_name: lastName,
        password,
      }),
    );

    updateServiceTerms(Object.values(registerPageServiceTerms));
    dispatch(setIsTasteIdGuideShown(false));
  };

  const navigateToCatalog = () => navigate(prependBasename(PAGES.vinhood.catalog));

  const navigateToLoginPage = () => navigate(prependBasename(PAGES.vinhood.signIn));

  const handleRedirectUser = () => {
    const page =
      !isRetailerSupportsThirdPartyOption && !isEnableTastePathAddon ? PAGES.vinhood.catalog : PAGES.vinhood.thirdParty;

    navigate(prependBasename(page));
  };

  const handleCarryUserData = async () => {
    await carryUserData([USER_DATA_FIELDS.QUIZ]);

    handleRedirectUser();
  };

  useEffect(() => {
    if (redirectAuthUserAfterRegistration) {
      handleRedirectUser();
    }
  }, [redirectAuthUserAfterRegistration]);

  const isLoading =
    isUserDataLoading ||
    isAuthDataLoading ||
    isProfileLoading ||
    serviceTermsListUpdateIsLoading ||
    isUserCharactersLoading ||
    isLoadingAnonymousData;

  if (isLoading) {
    return <LoadingSpinner variant="spinner" />;
  }

  return (
    <>
      <AuthenticationPage
        authError={authError}
        handleEmailAuthButtonClick={handleEmailAuthButtonClick}
        handleFormSubmit={handleFormSubmit}
        handleOnServiceTermToggle={handleOnServiceTermToggle}
        isEmailAuth={isEmailAuth}
        isEnableTastePathAddon={isEnableTastePathAddon}
        isShowSkipAndGoToProductsAddon={isShowSkipAndGoToProductsAddon}
        isSocialAuthTesting={isSocialAuthTesting}
        navigateToCatalog={navigateToCatalog}
        navigateToLoginPage={navigateToLoginPage}
        productCategory={productCategory}
        registerPageServiceTerms={registerPageServiceTerms}
        responseFacebook={facebookLoginCallback(dispatch)}
        responseGoogle={googleLoginCallback(dispatch)}
        userCharacterData={userBestMatchCharacterForCurrentServiceProductCategory}
      />
      {showCharacterOverwritingModal && (
        <ModalWrapperNew isOpen withoutDefaultPadding minModalContentHeight="100%">
          <CharacterOverwritingPage
            handleKeepOldCharacter={handleRedirectUser}
            handleOverwriteCharacter={handleCarryUserData}
            newCharacter={anonymousUserCharacter}
            oldCharacter={userBestMatchCharacterForCurrentServiceProductCategory}
            userName={userFirstName || ''}
          />
        </ModalWrapperNew>
      )}
    </>
  );
};
