/* eslint-disable sort-keys-fix/sort-keys-fix */
import { defineMessages } from '@formatjs/intl';

export const localeApp = {
  productCatalogPage: defineMessages({
    productTabAllTitle: {
      defaultMessage: 'All',
      id: 'vinhood.b2c.productCatalog.productTabAll',
    },
    productTabBeerTitle: {
      defaultMessage: 'Beer',
      id: 'vinhood.b2c.productCatalog.productTabBeer',
    },
    productTabCoffeeTitle: {
      defaultMessage: 'Coffee',
      id: 'vinhood.b2c.productCatalog.productTabCoffee',
    },
    productTabWineTitle: {
      defaultMessage: 'Wine',
      id: 'vinhood.b2c.productCatalog.productTabWine',
    },
  }),
  b2cModalsHoc: defineMessages({
    deactivateAccountDesc: {
      defaultMessage: `Reversible choice: you can reactivate it at any time.`,
      id: 'vinhood.b2c.permissionstartpage.deactivateAccountDesc',
    },
    deactivateAccountTitle: {
      defaultMessage: `Deactivate account =/`,
      id: 'vinhood.b2c.permissionstartpage.deactivateAccountTitle',
    },
    deleteAccountDesc: {
      defaultMessage: `Irreversible choice: your test and your preferences will be lost forever.`,
      id: 'vinhood.b2c.permissionstartpage.deleteAccountDesc',
    },
    deleteAccountTitle: {
      defaultMessage: `Delete account =(`,
      id: 'vinhood.b2c.permissionstartpage.deleteAccountTitle',
    },
    profilingDesc: {
      defaultMessage:
        'When you come across content that interests you, it’s not magic: it’s thanks to the information that you provide to us! ||This information helps the algorithm to build a profile that matches who you are. To better manage it, we enlist the help of third-party tools.',
      id: 'vinhood.b2c.permissionstartpage.profilingDesc',
    },
    profilingTitle: {
      defaultMessage: `Profiling`,
      id: 'vinhood.b2c.permissionstartpage.profilingTitle',
    },
    technicalDesc: {
      defaultMessage: `Essential for making sure that the entire service works. These data can't be deactivated. But don't worry, they don't include personal information.`,
      id: 'vinhood.b2c.permissionstartpage.technicalDesc',
    },
    technicalTitle: {
      defaultMessage: `Technical Stuff`,
      id: 'vinhood.b2c.permissionstartpage.technicalTitle',
    },
  }),
  closeComponent: defineMessages({
    closeText: {
      defaultMessage: 'Close',
      id: 'vinhood.b2c.modal.footer.closeText',
    },
  }),
  createCharacterTasteDataText: defineMessages({
    beerGlassDescription: {
      defaultMessage: 'Use a',
      id: 'vinhood.b2c.character.tasteData.beerGlassDescription',
    },
    classicCoffeeText: {
      defaultMessage: 'Classic',
      id: 'vinhood.b2c.character.tasteData.classicCoffeeText',
    },
    foodPariringDescription: {
      defaultMessage: 'Ideal to pair with',
      id: 'vinhood.b2c.character.tasteData.foodPariringDescription',
    },
    foodPariringText: {
      defaultMessage: 'Food pairing',
      id: 'vinhood.b2c.character.tasteData.foodPariringText',
    },
    glassText: {
      defaultMessage: 'Glass',
      id: 'vinhood.b2c.character.tasteData.glassText',
    },
    orText: {
      defaultMessage: 'or',
      id: 'vinhood.b2c.character.tasteData.orText',
    },
    specialCoffeeText: {
      defaultMessage: 'Special',
      id: 'vinhood.b2c.character.tasteData.specialCoffeeText',
    },
    temperatureDescription: {
      defaultMessage: 'Serve at',
      id: 'vinhood.b2c.character.tasteData.temperatureDescription',
    },
    temperatureText: {
      defaultMessage: 'Temperature',
      id: 'vinhood.b2c.character.tasteData.temperatureText',
    },
    wineGlassDescription: {
      defaultMessage: 'Use the',
      id: 'vinhood.b2c.character.tasteData.wineGlassDescription',
    },
    wineGlassText: {
      defaultMessage: 'Wine Glass',
      id: 'vinhood.b2c.character.tasteData.wineGlassText',
    },
  }),
  edutainmentFormPage: defineMessages({
    backBtn: {
      defaultMessage: 'Previous',
      id: 'vinhood.b2c.edutainmentFormPage.backBtn',
    },
    headingTitle: {
      defaultMessage: 'Exploring is amusing',
      id: 'vinhood.b2c.edutainmentFormPage.headingTitle',
    },
  }),
  edutainmentPage: defineMessages({
    description: {
      defaultMessage: 'Enjoy the quizzes to discover all the Characters.',
      id: 'vinhood.b2c.edutainment.description',
    },
    heading: {
      defaultMessage: 'Exploring is amusing.',
      id: 'vinhood.b2c.edutainment.heading',
    },
  }),
  footerApp: defineMessages({
    articlesText: {
      defaultMessage: 'Articles',
      id: 'vinhood.b2c.footer.articlesText',
    },
    experiencesText: {
      defaultMessage: 'Experiences',
      id: 'vinhood.b2c.footer.experiencesText',
    },
    trainingsText: {
      defaultMessage: 'Trainings',
      id: 'vinhood.b2c.footer.trainingsText',
    },
    homeText: {
      defaultMessage: 'Home',
      id: 'vinhood.b2c.footer.homeText',
    },
    productsText: {
      defaultMessage: 'Products',
      id: 'vinhood.b2c.footer.productsText',
    },
    profileText: {
      defaultMessage: 'Profile',
      id: 'vinhood.b2c.footer.profileText',
    },
    tasteIdText: {
      defaultMessage: 'Taste Id',
      id: 'vinhood.b2c.footer.tasteIdText',
    },
  }),
  hintPage: defineMessages({
    backButtonText: {
      defaultMessage: 'Back to home',
      id: 'vinhood.b2c.myhome.backButtonText',
    },
    bannerSectionDescText: {
      defaultMessage: 'Choose like a true pro, at the supermarket and in your place of the heart.',
      id: 'vinhood.b2c.myhome.bannerSectionDescText',
    },
    bannerSectionHeadingText: {
      defaultMessage: 'A pocket tip',
      id: 'vinhood.b2c.myhome.bannerSectionHeadingText',
    },
  }),
  manageModal: defineMessages({
    buttonCancelText: {
      defaultMessage: 'Cancel',
      id: 'vinhood.b2c.manageModal.buttonCancelTex',
    },
    buttonConfirmText: {
      defaultMessage: 'Confirm Your Choice',
      id: 'vinhood.b2c.manageModal.buttonConfirmText',
    },
    description: {
      defaultMessage:
        'If you want to take a break, you can put your account on hold. ||If, on the other hand, this is a goodbye, you can delete it forever.',
      id: 'vinhood.b2c.manageModal.description',
    },
    title: {
      defaultMessage: 'Deactivate or Delete Your Vinhood Account',
      id: 'vinhood.b2c.manageModal.title',
    },
  }),
  languageBtnGroup: defineMessages({
    englishLanguageText: {
      defaultMessage: 'English',
      id: 'vinhood.b2c.profile.settings.englishLanguageText',
    },
    italianLanguageText: {
      defaultMessage: 'Italian',
      id: 'vinhood.b2c.profile.settings.italianLanguageText',
    },
    languageTitle: {
      defaultMessage: 'Language',
      id: 'vinhood.b2c.profile.settings.languageTitle',
    },
  }),
  legalComponent: defineMessages({
    conditionsCardButtonText: {
      defaultMessage: 'Read the Terms and Conditions',
      id: 'vinhood.b2c.legalPage.conditionsCardButtonText',
    },
    conditionsCardDescriptionText: {
      defaultMessage:
        'The last step in learning everything there is to know about how we process your information and promise to manage it. The last step to eternal awareness.',
      id: 'vinhood.b2c.legalPage.conditionsCardDescriptionText',
    },
    conditionsCardTitle: {
      defaultMessage: 'Terms and Conditions (you made it!)',
      id: 'vinhood.b2c.legalPage.conditionsCardTitle',
    },
    cookieCardButtonText: {
      defaultMessage: 'Read the Cookie Policy',
      id: 'vinhood.b2c.legalPage.cookieCardButtonText',
    },
    cookieCardDescriptionText: {
      defaultMessage:
        'To personalize the content and experiences that we offer you, we collect information on how and when you use our app. This makes it possible for us and our collaborators to improve your experience.',
      id: 'vinhood.b2c.legalPage.cookieyCardDescriptionText',
    },
    cookieCardTitle: {
      defaultMessage: 'Cookies Policy (the sister of the Privacy Policy)',
      id: 'vinhood.b2c.legalPage.cookieCardTitle',
    },
    legalDescription: {
      defaultMessage:
        'What information do we collect? Why? There’s no need for an existential crisis. Here you’ll find out everything you need to know about how your information is handled. If later you regret your choice or change your mind, come back to this page to make any adjustments.',
      id: 'vinhood.b2c.legalPage.legalDescription',
    },
    legalTitle: {
      defaultMessage: 'This is Your Control Room',
      id: 'vinhood.b2c.legalPage.legalTitle',
    },
    permissionCardButtonText: {
      defaultMessage: 'Your Preferences',
      id: 'vinhood.b2c.legalPage.permissionCardButtonText',
    },
    permissionCardDescriptionText: {
      defaultMessage:
        'The fact that you’re here means that you have the utmost trust in us. We owe you and want to reciprocate. ||Don’t worry about your information: we’d give up the last glass of wine to protect it.',
      id: 'vinhood.b2c.legalPage.permissionCardDescriptionText',
    },
    permissionCardTitle: {
      defaultMessage: 'Permissions and Privacy',
      id: 'vinhood.b2c.legalPage.permissionCardTitle',
    },
    privacyCardButtonText: {
      defaultMessage: 'Read the Privacy Policy',
      id: 'vinhood.b2c.legalPage.privacyCardButtonText',
    },
    privacyCardDescriptionText: {
      defaultMessage:
        'Vinhood has adopted a Privacy Policy in order to ensure transparency in the way your personal data is processed. ||To know how and why we collect, save, and sometimes use the information, give it a read. Even before going to bed.',
      id: 'vinhood.b2c.legalPage.privacyCardDescriptionText',
    },
    privacyCardTitle: {
      defaultMessage: 'Privacy Policy (the part everyone skips over)',
      id: 'vinhood.b2c.legalPage.privacyCardTitle',
    },
  }),
  modalOptionItem: defineMessages({
    alwaysActiveText: {
      defaultMessage: 'ALWAYS ACTIVE',
      id: 'vinhood.b2c.functionalmodal.alwaysActiveText',
    },
    toggleDisabledText: {
      defaultMessage: 'NOT ACTIVE',
      id: 'vinhood.b2c.functionalmodal.toggleDisabledText',
    },
    toggleEnabledText: {
      defaultMessage: 'ACTIVE',
      id: 'vinhood.b2c.functionalmodal.toggleEnabledText',
    },
  }),
  tagsTabContainer: defineMessages({
    headingDescription: {
      defaultMessage: `You'll find your tags here. They're the values that shape your taste experience. Update them every now and then!`,
      id: 'vinhood.b2c.profile.myTags.headingDescription',
    },
    headingTitle: {
      defaultMessage: 'Buyer Principles',
      id: 'vinhood.b2c.profile.myTags.headingTitle',
    },
    subHeadingDescription: {
      defaultMessage:
        'Here you can choose the topics that interest you. That way your profile will be more complete and your experience even more consistent with it.',
      id: 'vinhood.b2c.profile.myTags.subHeadingDescription',
    },
    subHeadingTitle: {
      defaultMessage: 'What to Know Before Choosing',
      id: 'vinhood.b2c.profile.myTags.subHeadingTitle',
    },
  }),
  preferencesModal: defineMessages({
    buttonCancelText: {
      defaultMessage: 'Cancel',
      id: 'vinhood.b2c.functionalmodal.buttonCancelTex',
    },
    buttonConfirmText: {
      defaultMessage: 'Save and Continue',
      id: 'vinhood.b2c.functionalmodal.buttonConfirmText',
    },
    cookieTitle: {
      defaultMessage: 'Cookies for Every Taste',
      id: 'vinhood.b2c.functionalmodal.cookieTitle',
    },
    modalHeaderDescription: {
      defaultMessage:
        'Still here? At Vinhood there are people who, like you right now, find themselves filling out these things every single day. So your privacy is our absolute priority. We thank you in advance for the time that you’ll take to read this policy. It’s not something everyone does. The more information you give us, the more complete your experience will be. And since here experience is everything, this is a crucial moment! ||The information has to do with your choices and your device. Not you, not your privacy. That’s sacred. Still not convinced? That’s too bad. Your experience could end up being somewhat limited. But we love you anyway.',
      id: 'vinhood.b2c.functionalmodal.modalHeaderDescription',
    },
    modalHeaderTitle: {
      defaultMessage: 'Manage Permissions and Privacy',
      id: 'vinhood.b2c.functionalmodal.modalHeaderTitle',
    },
    privacyOptionDescription: {
      defaultMessage:
        'You can directly unsubscribe from the newsletter at any time by pressing the Unsubscribe button.',
      id: 'vinhood.b2c.functionalmodal.privacyOptionDescription',
    },
    privacyTitle: {
      defaultMessage: 'Privacy Preferences',
      id: 'vinhood.b2c.functionalmodal.privacyTitle',
    },
  }),
  profilePageContainer: defineMessages({
    legalTabText: {
      defaultMessage: 'Legal',
      id: 'vinhood.b2c.profile.tabs.legalTabText',
    },
    settingsTabText: {
      defaultMessage: 'Settings',
      id: 'vinhood.b2c.profile.tabs.settingsTabText',
    },
    tagsTabText: {
      defaultMessage: 'Tags',
      id: 'vinhood.b2c.profile.tabs.myTagsTabText',
    },
    tasteTabText: {
      defaultMessage: 'Taste',
      id: 'vinhood.b2c.profile.tabs.tasteTabText',
    },
  }),
  profilePictureSelection: defineMessages({
    profileHeaderText: {
      defaultMessage: 'Profile Photo',
      id: 'vinhood.b2c.settingPage.profileHeaderText',
    },
    profileImageInfoText: {
      defaultMessage: '.jpg or .png - max 5Mb',
      id: 'vinhood.b2c.settingPage.profileImageInfoText',
    },
    profileImageInvalidErrorText: {
      defaultMessage: "The uploaded image is not the correct format. Let's try again?",
      id: 'vinhood.b2c.settingPage.profileImageInvalidErrorText',
    },
    profileLargeImageErrorText: {
      defaultMessage: 'The uploaded image is too large. Try again.',
      id: 'vinhood.b2c.settingPage.profileErrorText',
    },
  }),
  registrationWithIcon: defineMessages({
    title: {
      defaultMessage: 'Rate, Save, Note & much more...',
      id: 'vinhood.b2c.registrationWithIcon.title',
    },
    description: {
      defaultMessage: 'Save this product in your Taste ID, take your personal notes and rate the product',
      id: 'vinhood.b2c.registrationWithIcon.description',
    },
    extraDescription: {
      defaultMessage: 'Create an account now',
      id: 'vinhood.b2c.registrationWithIcon.extraDescription',
    },
  }),
  registrationProfilingModal: defineMessages({
    btnConfirm: {
      defaultMessage: 'Save and continue',
      id: 'vinhood.b2c.registrationProfilingModal.btnConfirm',
    },
    cancelBtn: {
      defaultMessage: 'Cancel',
      id: 'vinhood.b2c.registrationProfilingModal.cancelBtn',
    },
    headerTitle: {
      defaultMessage:
        "If you want to receive personalized notifications, then you must first agree to the profiling in the Cookies section. It's just a formality",
      id: 'vinhood.b2c.registrationProfilingModal.headerTitle',
    },
  }),
  startPage: defineMessages({
    btnTakeTest: {
      defaultMessage: 'START THE TEST',
      id: 'vinhood.b2c.startpage.btnTakeTest',
    },
    subtitleText: {
      defaultMessage: `Science fiction? No, it's right here.`,
      id: 'vinhood.b2c.startpage.subtitleText',
    },
    title: {
      defaultMessage: 'There is a test that helps you find the best products for you.',
      id: 'vinhood.b2c.startpage.title',
    },
    loginLinkText: {
      defaultMessage: `Log in!`,
      id: `vinhood.b2c.startpage.login`,
    },
  }),
  settings: defineMessages({
    accountSubTitle: {
      defaultMessage: 'Exit From the Device',
      id: 'vinhood.b2c.profile.settings.accountSubTitle',
    },
    accountTitle: {
      defaultMessage: 'Account',
      id: 'vinhood.b2c.profile.settings.accountTitle',
    },
    exitBtnText: {
      defaultMessage: 'Exit',
      id: 'vinhood.b2c.profile.settings.exitBtnText',
    },
    foodPreferencesSubTitle: {
      defaultMessage: 'Manage your Customary Behaviour',
      id: 'vinhood.b2c.profile.settings.foodPreferencesSubTitle',
    },
    foodPreferencesTitle: {
      defaultMessage: 'Food Preferences',
      id: 'vinhood.b2c.profile.settings.foodPreferencesTitle',
    },
    interestsSubTitle: {
      defaultMessage: 'I would like content about',
      id: 'vinhood.b2c.profile.settings.interestsSubTitle',
    },
    interestsTitle: {
      defaultMessage: 'Interests',
      id: 'vinhood.b2c.profile.settings.interestsTitle',
    },
    manageBtnText: {
      defaultMessage: 'Manage',
      id: 'vinhood.b2c.profile.settings.manageBtnText',
    },
    manageTextDescription: {
      defaultMessage: 'You can permanently delete your Vinhood account, or temporarily deactivate it. You never know.',
      id: 'vinhood.b2c.profile.settings.manageTextDescription',
    },
    settingsSubTitle: {
      defaultMessage: 'Keep Your Profile Updated',
      id: 'vinhood.b2c.profile.settings.settingsSubTitle',
    },
    settingsTitle: {
      defaultMessage: 'Settings',
      id: 'vinhood.b2c.profile.settings.settingsTitle',
    },
  }),
  useB2cBannersText: defineMessages({
    recipeBannerText: {
      defaultMessage: 'If you do not find your recipe you could try to start searching from the main ingredient',
      id: 'vinhood.b2c.recipeSearch.bannerText',
    },
  }),
  userCharacterInfo: defineMessages({
    tasteIdentityText: {
      defaultMessage: 'Your **Taste Identity**',
      id: 'vinhood.b2c.userCharacterInfo.tasteIdentityText',
    },
  }),
  userCharacterInfoItem: defineMessages({
    characterInfoTitle: {
      defaultMessage: '**#{characterName}** {productCategoryText}',
      id: 'vinhood.b2c.userCharacterInfo.characterInfoTitle',
    },
    missingBeerExpereince: {
      defaultMessage: 'You are missing the beer experience!',
      id: 'vinhood.b2c.userCharacterInfo.missingBeerExpereince',
    },
    missingCharacterTitle: {
      defaultMessage: 'Take the test!',
      id: 'vinhood.b2c.userCharacterInfo.missingCharacterTitle',
    },
    missingCoffeeExpereince: {
      defaultMessage: 'You are missing the coffee experience!',
      id: 'vinhood.b2c.userCharacterInfo.missingCoffeeExpereince',
    },
    missingWineExpereince: {
      defaultMessage: 'You are missing the wine experience!',
      id: 'vinhood.b2c.userCharacterInfo.missingWineExpereince',
    },
  }),
  userCharacterPersonalizeSection: defineMessages({
    personalizeExperienceDesc: {
      defaultMessage: `Select the values that you prefer: we'll become great friends and we'll only suggest content that's in line with your interests.`,
      id: 'vinhood.b2c.userCharacterInfo.personalizeExperienceDesc',
    },
    personalizeExperienceText: {
      defaultMessage: 'Personalize your experience',
      id: 'vinhood.b2c.userCharacterInfo.personalizeExperienceText',
    },
  }),
  userInfoForm: defineMessages({
    cancelBtnText: {
      defaultMessage: 'Cancel',
      id: 'vinhood.b2c.profile.settings.cancelBtnText',
    },
    countryFieldTitle: {
      defaultMessage: 'Country You Live In',
      id: 'vinhood.b2c.profile.settings.form.countryFieldTitle',
    },
    countryValidationText: {
      defaultMessage: 'Select a country',
      id: 'vinhood.b2c.profile.settings.form.countryValidationText',
    },
    emailFieldTitle: {
      defaultMessage: 'Email',
      id: 'vinhood.b2c.profile.settings.form.emailFieldTitle',
    },
    emailValidationText: {
      defaultMessage: 'Enter correct email',
      id: 'vinhood.b2c.profile.settings.form.emailValidationText',
    },
    lastNameFieldTitle: {
      defaultMessage: 'Last Name',
      id: 'vinhood.b2c.profile.settings.form.lastNameFieldTitle',
    },
    lastNameValidationText: {
      defaultMessage: 'Last Name should be at least two characters.',
      id: 'vinhood.b2c.profile.settings.form.lastNameValidationText',
    },
    nameFieldTitle: {
      defaultMessage: 'Name',
      id: 'vinhood.b2c.profile.settings.form.nameFieldTitle',
    },
    nameValidationText: {
      defaultMessage: 'First Name should be at least two characters.',
      id: 'vinhood.b2c.profile.settings.form.nameValidationText',
    },
    saveBtnText: {
      defaultMessage: 'Save',
      id: 'vinhood.b2c.profile.settings.saveBtnText',
    },
    birthYearText: {
      defaultMessage: 'Birth Year',
      id: 'vinhood.b2c.profile.settings.form.birthYearText',
    },
    genderText: {
      defaultMessage: 'Gender',
      id: 'vinhood.b2c.profile.settings.form.genderText',
    },
    selectText: {
      defaultMessage: 'Select',
      id: 'vinhood.b2c.profile.settings.form.select',
    },
  }),
  loadingScreen: defineMessages({
    textOne: {
      defaultMessage: `Just a sec.`,
      id: 'vinhood.b2c.loadingScreen.textOne',
    },
    textTwo: {
      defaultMessage: `Turning your responses into a scrumptious outcome 🤤`,
      id: 'vinhood.b2c.loadingScreen.textTwo',
    },
  }),
  globalSearchPage: defineMessages({
    pageHeading: {
      defaultMessage: 'Results for:',
      id: 'vinhood.b2c.globalSearchPage.pageHeading',
    },
    productsTabText: {
      defaultMessage: 'Products',
      id: 'vinhood.b2c.globalSearchPage.productsTabText',
    },
  }),
  globalSearchNoResult: defineMessages({
    noMatchesFound: {
      defaultMessage: 'Sorry, no matches were found.',
      id: 'vinhood.b2c.globalSearchNoResult.noMatchesFoundText',
    },
    suggestion: {
      defaultMessage: 'Please try a new search or use our suggestions below:',
      id: 'vinhood.b2c.globalSearchNoResult.suggestionText',
    },
    suggestionWithProductCategory: {
      defaultMessage: `#{characterName} {productCategoryPluralText}`,
      id: 'vinhood.b2c.globalSearchNoResult.suggestionWithProductCategory',
    },
  }),
  b2cSearchModal: defineMessages({
    buttonText: {
      defaultMessage: 'Search',
      id: 'vinhood.b2c.searchModal.buttonText',
    },
    inputFieldLabel: {
      defaultMessage: 'What are you looking for?',
      id: 'vinhood.b2c.searchModal.inputFieldLabel',
    },
  }),
  searchResult: defineMessages({
    resultCardBtn: {
      defaultMessage: 'Give it a peek!',
      id: 'vinhood.b2c.searchResultCard.resultCardBtn',
    },
  }),
};
