import styled, { keyframes } from 'styled-components';

import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';

const fadeInAnimation = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const ProductFeedbackTutorialContainer = styled(Flexbox)`
  animation: ${fadeInAnimation} 0.3s ease-out;
`;

export const MascotImage = styled.img`
  width: 165px;
`;
