import { FC, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { useTechnical } from '@lib/core/service/hooks/useTechnical';
import { prependBasename } from '@lib/core/service/utils';
import { retailerServiceTermsCategories } from '@lib/core/serviceTerms/consts';
import { useServiceTerms } from '@lib/core/serviceTerms/hooks/useServiceTerms';
import { useCarryUserData, useUser } from '@lib/core/users/hooks';
import {
  actionLogin,
  actionResetAuthSlice,
  facebookLoginCallback,
  googleLoginCallback,
  resetAuthError,
} from '@lib/core/users/slices/auth';
import { actionSetAnonymousUserCharacter, actionSetAnonymousUserProfileId } from '@lib/core/users/slices/user';
import { USER_DATA_FIELDS } from '@lib/core/users/types';
import { useTypedDispatch } from '@lib/tools/views/hooks/useTypedDispatch';
import { PAGES } from '@lib/tools/views/urls';

import { LoginFormInputObject } from '@components/web/src/pages/Authentication/AuthFormInterface';
import { CharacterOverwritingPage } from '@components/web/src/templates/CharacterOverwriting/CharacterOverwritingPage';
import {
  LOADING_SPINNER_VARIANTS,
  LoadingSpinner,
} from '@components/web/src/templates/Loading/LoadingSpinner/LoadingSpinner';
import LoginPage from '@components/web/src/templates/Login&Registration/LoginPage/LoginPage';
import { ModalWrapperNew } from '@components/web/src/templates/Modals/ModalWrapper/ModalWrapperNew';

const LoginContainer: FC = () => {
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const recaptchaRef: any = useRef();
  const [searchParams] = useSearchParams();

  const {
    serviceTermsListUpdateIsLoading,
    serviceTermsByCategory: { [retailerServiceTermsCategories.thirdParty]: thirdParty },
  } = useServiceTerms();
  const { isCaptchaDisabled } = useTechnical();
  const {
    isUserFullUserDataLoadedTemporaryHint,
    isUserDataLoading,
    isUserAuthenticated,
    isProfileLoading,
    authError,
    isAuthDataLoading,
    userFirstName,
    userBestMatchCharacterForCurrentServiceProductCategory,
    profileId,
    isUserCharactersLoading,
    anonymousUserCharacter,
    isLoadingAnonymousData,
  } = useUser();
  const { carryUserData } = useCarryUserData();

  const isFromWidgetHomePage = location.state?.isFromWidgetHomePage;
  const isSocialAuthTesting = searchParams.get('is_social_test') === 'true';

  const isAuthUserDataLoaded = isUserAuthenticated && isUserFullUserDataLoadedTemporaryHint;
  const redirectAuthUserAfterLogin = isAuthUserDataLoaded && !anonymousUserCharacter;
  const carryAnonCharacterToAuthUser =
    isAuthUserDataLoaded && anonymousUserCharacter && !userBestMatchCharacterForCurrentServiceProductCategory;
  const showCharacterOverwritingModal =
    isAuthUserDataLoaded && anonymousUserCharacter && !!userBestMatchCharacterForCurrentServiceProductCategory;

  const [isEmailAuthorization, setIsEmailAuthorization] = useState(false);
  const [showTimer, setShowTimer] = useState(false);
  const [loginFormData, setLoginFormData] = useState({ email: '', password: '' });

  useEffect(() => {
    dispatch(actionSetAnonymousUserCharacter(userBestMatchCharacterForCurrentServiceProductCategory));
    dispatch(actionSetAnonymousUserProfileId(profileId));
  }, []);

  useEffect(() => {
    if (authError && !showTimer) {
      setShowTimer(true);
    }

    return () => setShowTimer(false);
  }, [authError]);

  const handleFormSubmit = (data: LoginFormInputObject) => {
    const { email, password } = data;
    // form submitBtn click
    // sets state and executes the recaptcha
    setLoginFormData({ email, password });
    if (recaptchaRef.current) {
      recaptchaRef.current.execute();
    }
  };

  const handleLogin = async (captchaValue: string) => {
    // called after recaptcha successfully executes
    dispatch(actionResetAuthSlice());
    dispatch(
      actionLogin({
        captchaValue,
        email: loginFormData.email,
        isCaptchaDisabled,
        password: loginFormData.password,
      }),
    );
  };

  const handleForgotPasswordClick = () =>
    navigate(prependBasename(PAGES.vinhood.resetPassword), { state: { ...location?.state } });

  const handleGoBack = () =>
    navigate(prependBasename(isFromWidgetHomePage ? PAGES.vinhood.home : PAGES.vinhood.registration));

  const handleEmailAuthButtonClick = () => {
    setIsEmailAuthorization(state => !state);
    dispatch(resetAuthError());
  };

  const handleRedirectUser = () =>
    navigate(prependBasename(thirdParty?.is_selected ? PAGES.vinhood.home : PAGES.vinhood.catalog));

  const handleCarryUserData = async () => {
    await carryUserData([USER_DATA_FIELDS.QUIZ]);

    handleRedirectUser();
  };

  useEffect(() => {
    if (redirectAuthUserAfterLogin) {
      handleRedirectUser();
    }

    if (carryAnonCharacterToAuthUser) {
      handleCarryUserData();
    }
  }, [redirectAuthUserAfterLogin, carryAnonCharacterToAuthUser]);

  const isLoading =
    isUserDataLoading ||
    isAuthDataLoading ||
    isProfileLoading ||
    serviceTermsListUpdateIsLoading ||
    isUserCharactersLoading ||
    isLoadingAnonymousData;

  if (isLoading) {
    return <LoadingSpinner variant={LOADING_SPINNER_VARIANTS.LOGIN} />;
  }

  return (
    <>
      <LoginPage
        errors={authError}
        handleEmailAuthButtonClick={handleEmailAuthButtonClick}
        handleForgotPasswordClick={handleForgotPasswordClick}
        handleFormSubmit={handleFormSubmit}
        handleGoBack={handleGoBack}
        handleLogin={handleLogin}
        isCaptchaDisabled={isCaptchaDisabled}
        isEmailAuthorization={isEmailAuthorization}
        isSocialAuthTesting={isSocialAuthTesting}
        recaptchaRef={recaptchaRef}
        responseApple={response => console.info(response)}
        responseFacebook={facebookLoginCallback(dispatch)}
        responseGoogle={googleLoginCallback(dispatch)}
        setShowTimer={setShowTimer}
        showTimer={showTimer}
      />
      {showCharacterOverwritingModal && (
        <ModalWrapperNew isOpen withoutDefaultPadding minModalContentHeight="100%">
          <CharacterOverwritingPage
            handleKeepOldCharacter={handleRedirectUser}
            handleOverwriteCharacter={handleCarryUserData}
            newCharacter={anonymousUserCharacter}
            oldCharacter={userBestMatchCharacterForCurrentServiceProductCategory}
            userName={userFirstName || ''}
          />
        </ModalWrapperNew>
      )}
    </>
  );
};

export default LoginContainer;
