import { TQuizAnswerTag } from '@lib/core/quizzes/types';

// Consts
// Answers tags
export const REDIRECT_PAGE_RECIPE = 'redirect-page-recipe';
export const REDIRECT_TEST_RECIPE = 'redirect-test-recipe';
export const REDIRECT_TEST_EXPERT = 'redirect-test-expert';
export const REDIRECT_TEST_TASTE = 'redirect-test-taste';
export const REDIRECT_TEST_MULTI_DISH = 'redirect-test-multi-dish';
export const PRODUCT_CATEGORY_TAG = 'product-type-';
export const FILTER_CHARACTER_TAG = 'filter-character-';
export const PRODUCT_TAG = 'tag-product-';
export const SHUFFLE_ENABLED_TAG = 'shuffleenabled';

export const isQuizAnswerTagRedirectToTasteQuiz = (tag: TQuizAnswerTag): boolean => tag.name === REDIRECT_TEST_TASTE;
export const isQuizAnswerTagRedirectToMultiDishQuiz = (tag: TQuizAnswerTag): boolean =>
  tag.name === REDIRECT_TEST_MULTI_DISH;
export const isQuizAnswerTagNameFilterCharacterTag = (tag: TQuizAnswerTag): boolean =>
  tag.name.includes(FILTER_CHARACTER_TAG);
export const getQuizAnswerFilterCharacterTag = (tag: TQuizAnswerTag): string => tag.name.split(FILTER_CHARACTER_TAG)[1];
export const isQuizAnswerTagNameProductTag = (tag: TQuizAnswerTag): boolean => tag.name.includes(PRODUCT_TAG);
export const getQuizAnswerProductTag = (tag: TQuizAnswerTag): string => tag.name.split(PRODUCT_TAG)[1];
export const isQuizAnswerTagNameProductTypeTag = (tag: TQuizAnswerTag): boolean =>
  tag.name.includes(PRODUCT_CATEGORY_TAG);
export const isTagProductTypeTag = (tag: TQuizAnswerTag): string => tag.name.split(PRODUCT_CATEGORY_TAG)[1];
export const isQuizAnswerIncludesShuffleAnswerTag = (tags: TQuizAnswerTag[]): boolean =>
  tags.some(tag => tag.name === SHUFFLE_ENABLED_TAG);
