import { TokenResponse, useGoogleLogin } from '@react-oauth/google';

import { ILocaleText } from '@lib/tools/locale/views/LocaleFragment';
import { GOOGLE } from '@lib/tools/shared/helpers/consts';

import AuthorizationButton from '@components/web/src/atoms/Buttons/SocialButtons/AuthorizationButton/AuthorizationButton';

type TGoogleLoginButtonProps = {
  isDisabled?: boolean;
  isHidden?: boolean;
  isRegistrationPage?: boolean;
  authOptions?: Record<string, any>;
  text?: ILocaleText;
  responseGoogle: (tokenResponse: TokenResponse) => void;
};

const GoogleLoginButton = ({
  isDisabled = false,
  isHidden = false,
  isRegistrationPage = false,
  authOptions = {},
  responseGoogle,
  text = '',
}: TGoogleLoginButtonProps) => {
  const login = useGoogleLogin({
    onError: error => {
      console.error('Login Failed with error:', error);
    },
    onNonOAuthError: error => {
      console.error('Non OAuth Error:', error);
    },
    onSuccess: (tokenResponse: TokenResponse) => {
      try {
        responseGoogle(tokenResponse);
      } catch (error) {
        console.error('Error in responseGoogle callback:', error);
      }
    },
  });

  return (
    <AuthorizationButton
      key={GOOGLE}
      isDisabled={isDisabled}
      isHidden={isHidden}
      text={text || (isRegistrationPage ? authOptions.googleSignup : authOptions.googleLogIn)}
      type={GOOGLE}
      variant="secondary"
      handleClick={() => {
        login();
      }}
    />
  );
};

export default GoogleLoginButton;
