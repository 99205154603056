import styled, { css, keyframes } from 'styled-components';

import { STATIC_COLORS, STATIC_GRADIENTS, STATIC_SHADOWS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';

export const skeletonLoadingKeyframes = keyframes`
  0% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
`;

export const skeletonCommonStyles = css`
  background: ${STATIC_GRADIENTS.warmGradientForSkeleton};
  animation: ${skeletonLoadingKeyframes} 1s linear infinite alternate;
  margin: 0;
  padding-right: 20px;
`;

export const ExperienceCardSkeletonContainer = styled.div<{ $isResponsive?: boolean }>`
  width: ${({ $isResponsive }) => ($isResponsive ? '100%' : '310px')};
  max-height: 394px;
  overflow: hidden;
  border: 1px solid #e4e7ec;
  border-radius: 8px;
  box-shadow: ${STATIC_SHADOWS.sm};
  background-color: ${STATIC_COLORS.base.white};
`;

export const Image = styled.div`
  width: 100%;
  height: 180px;
  overflow: hidden;
  ${skeletonCommonStyles}
`;

export const TextsWrapper = styled(Flexbox)`
  padding: 16px 16px 8px;
  & > * {
    margin-bottom: 8px;
    border-radius: 4px;
  }
  gap: 4px;
`;

export const SectionOneText = styled.div`
  width: 100%;
  height: 18px;
  ${skeletonCommonStyles}
`;

export const SectionTwoText = styled.div`
  width: 100%;
  height: 90px;
  ${skeletonCommonStyles};
`;

export const SectionFiveText = styled.div`
  min-width: 57px;
  height: 30px;
  align-self: flex-end;
  border-radius: 4px;
  margin-left: auto;
  ${skeletonCommonStyles}
`;

export const FooterWrapper = styled(Flexbox)`
  padding: 10px 14px;
`;

export const FooterSection = styled.div`
  min-width: 97px;
  height: 18px;
  border-radius: 4px;
  ${skeletonCommonStyles}
`;
