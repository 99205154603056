import { FC } from 'react';

import { localeCommon } from '@lib/tools/locale/source/web/common';
import { ILocaleText } from '@lib/tools/locale/views/LocaleFragment';
import { VH_VARIANTS } from '@lib/tools/shared/helpers/consts';
import { ICustomStyles } from '@lib/tools/shared/helpers/interfaces';

import { TestAnswer } from '@components/web/src/atoms/TestAnswer/TestAnswerItem/TestAnswer';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Text } from '@components/web/src/foundations/Text/Text';
import { KioskCirclesBackground } from '@components/web/src/kiosk/KioskCirclesBackground/KioskCirclesBackground';
import * as S from '@components/web/src/templates/TestFlowsPages/FoodHabitsPage/styles';

type Props = {
  answers: {
    text: ILocaleText;
    image: string;
    isDisabled: boolean;
    isSelected: boolean;
    isVisible: boolean;
    slug: string;
  }[];
  isDisabledBtn: boolean;
  isApplicationKiosk: boolean;
  isDevToolsEnabled: boolean;
  customStyles: ICustomStyles;
  handleUserPreferences: () => void;
  onAnswerSelectHandler: (id: string) => void;
  goBack?: () => void;
};

const FoodHabits: FC<Props> = ({
  answers,
  isDisabledBtn,
  isApplicationKiosk,
  isDevToolsEnabled,
  customStyles,
  onAnswerSelectHandler,
  handleUserPreferences,
  goBack,
}) => {
  const { foodHabitsPage } = localeCommon;

  const { customQuizBackgroundColor, customTextColor, customSecondaryColor } = customStyles;

  return (
    <S.FoodHabitsContainer $background={customQuizBackgroundColor} direction="column" gap={24}>
      {isApplicationKiosk && <KioskCirclesBackground />}
      <Text
        color={customTextColor || STATIC_COLORS.base.black}
        fontFamily="Fraunces"
        size="h5"
        text={foodHabitsPage.titleQuestionText}
        textAlign="center"
        weight="semibold"
      />
      <S.AnswersContainer align="center" justify="center">
        {answers.map(answer => {
          const { slug, image, text, isDisabled, isSelected, isVisible } = answer;
          return (
            isVisible && (
              <TestAnswer
                key={slug}
                isFoodHabitsQuiz
                customTextColor={customTextColor}
                id={slug}
                image={image}
                isApplicationKiosk={isApplicationKiosk}
                isDevToolsEnabled={isDevToolsEnabled}
                isDisabled={isDisabled}
                isSelected={isSelected}
                text={text}
                onSelect={id => onAnswerSelectHandler(id)}
              />
            )
          );
        })}
      </S.AnswersContainer>
      <S.StyledButton
        disabled={isDisabledBtn}
        size={isApplicationKiosk ? 'lg' : 'sm'}
        text={foodHabitsPage.ctaBtn}
        variant={VH_VARIANTS.PRIMARY}
        onClick={handleUserPreferences}
      />
      <S.GoBackButton
        customArrowColor={customSecondaryColor}
        handleClick={goBack}
        style={{ color: customTextColor }}
        variant="long"
      />
    </S.FoodHabitsContainer>
  );
};

export default FoodHabits;
