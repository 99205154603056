import styled from 'styled-components';

import Button from '@components/web/src/atoms/Buttons/Button';
import { DEFAULT_COLOR_THEME } from '@components/web/src/foundations';
import SwiperHOC from '@components/web/src/organisms/Swiper/SwiperHOC';

export const JournalSwiper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 30px 0 30px 16px;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-right: 16px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledButton = styled(Button)`
  border: none;
  padding: 10px 14px;
  gap: 8px;
  border-radius: 28px;
  background: ${DEFAULT_COLOR_THEME.secondaryColor['-500']};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  height: 100%;
  padding: 0px 80px 0px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const SingleCardWrapper = styled.div`
  padding-right: 16px;
`;

export const SwiperHOCSection = styled(SwiperHOC)<{ $isSingleCard?: boolean }>`
  padding-right: ${({ $isSingleCard }) => ($isSingleCard ? '16px' : '0')};

  .swiper-wrapper {
    .swiper-slide {
      width: ${({ $isSingleCard }) => ($isSingleCard ? '100%' : 'auto')};
    }
    > *:last-child {
      margin-inline-end: 16px;
    }
  }
`;
