import { FC, useEffect, useState } from 'react';

import { TParsedProductInstance } from '@lib/core/products/types';
import MixpanelTracker from '@lib/tools/dat/mixpanel';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import {
  DISCOVER_QUIZ_AROMAS_ATTRIBUTE,
  DISCOVER_QUIZ_STEP_ANSWER_AROMA,
  DISCOVER_QUIZ_STEP_ANSWER_TASTE,
  DISCOVER_QUIZ_STEP_START_QUIZ,
  DISCOVER_QUIZ_TASTE_ATTRIBUTE,
  VH_VARIANTS,
} from '@lib/tools/shared/helpers/consts';

import headerIcon from '@components/web/src/assets/legacy/icons/tongue_icon.png';
import Confetti from '@components/web/src/atoms/Confetti/Confetti';
import { IDiscoveryQuizAnswer } from '@components/web/src/atoms/DiscoveryQuizAnswersItem/DiscoveryQuizAnswersItem';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Image } from '@components/web/src/foundations/Image/Image';
import { Text } from '@components/web/src/foundations/Text/Text';
import DiscoveryQuizAnswers from '@components/web/src/organisms/DiscoveryQuizAnswers/DiscoveryQuizAnswers';
import * as S from '@components/web/src/templates/Modals/DiscoveryQuizModal/styles';
import { ModalWrapperNew } from '@components/web/src/templates/Modals/ModalWrapper/ModalWrapperNew';

export interface IDiscoveryQuizData {
  answers: IDiscoveryQuizAnswer[];
  btnText: string;
  quizTitle: string;
  quizType: string;
}

export interface IDiscoveryQuiz {
  discoveryQuizData: IDiscoveryQuizData[];
  parsedDiscoveryQuizProductInstance: TParsedProductInstance;
}

interface IProps {
  isModalOpen: boolean;
  discoveryQuiz: IDiscoveryQuiz;
  hideModal: () => void;
}

export const DiscoveryQuizModal: FC<IProps> = ({ isModalOpen, discoveryQuiz, hideModal }) => {
  const {
    discoveryQuizModal: {
      initiateQuizHeaderTitle,
      initiateQuizBtn,
      skipBtn,
      yesRight,
      ohNo,
      initiateQuizContentTitle,
      initiateQuizContentSubtitle,
    },
    productCategories,
  } = localeCommon;

  const { discoveryQuizData, parsedDiscoveryQuizProductInstance } = discoveryQuiz;

  const [quizStep, setQuizStep] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [isSelectedCorrectAnswer, setIsSelectedCorrectAnswer] = useState(null);

  const { productCategory, productName, productImage, productProducerName } = parsedDiscoveryQuizProductInstance;

  const {
    quizTitle = initiateQuizHeaderTitle,
    quizType = '',
    answers = [],
    btnText = initiateQuizBtn,
  } = discoveryQuizData[quizStep - 1] || {};

  const isInitiateQuizView = !quizStep && !selectedAnswer;
  const isSelectedAnswerQuizVew = !!quizStep && !!selectedAnswer;
  const showButton = isInitiateQuizView || isSelectedAnswerQuizVew;
  const resultText = isSelectedCorrectAnswer === true ? yesRight : isSelectedCorrectAnswer === false && ohNo;

  const handleCloseModal = () => {
    MixpanelTracker.events.discoverQuizSkip();

    setQuizStep(0);
    setSelectedAnswer(null);
    setIsSelectedCorrectAnswer(null);
    hideModal();
  };

  const handleAnswerClick = selectedAnswerId => {
    const correctAnswer = answers.find(answer => answer.id === selectedAnswerId)?.isCorrectAnswer;

    setSelectedAnswer(selectedAnswerId);
    setIsSelectedCorrectAnswer(correctAnswer);

    const quizAttribute =
      quizStep === DISCOVER_QUIZ_STEP_ANSWER_AROMA ? DISCOVER_QUIZ_AROMAS_ATTRIBUTE : DISCOVER_QUIZ_TASTE_ATTRIBUTE;

    MixpanelTracker.events.discoverQuizSubmit(quizAttribute, correctAnswer);
  };

  const handleButtonClick = () => {
    setQuizStep(quizStep + 1);
    setSelectedAnswer(null);
    setIsSelectedCorrectAnswer(null);

    if (quizStep === DISCOVER_QUIZ_STEP_START_QUIZ) {
      MixpanelTracker.events.discoverQuizStart();
    } else if (quizStep === DISCOVER_QUIZ_STEP_ANSWER_TASTE) {
      setQuizStep(0);
      hideModal();
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isSelectedAnswerQuizVew) {
        handleButtonClick();
      }

      // Total: 6000ms
      // 2000ms is delay of displaying Loading bar
      // 4000ms is animation of Loading bar
    }, 6000);

    return () => clearTimeout(timer);
  }, [isSelectedAnswerQuizVew]);

  return (
    <ModalWrapperNew isOpen={isModalOpen} minModalContentHeight="525px" onClose={() => handleCloseModal()}>
      <S.DiscoveryQuizModalContainer isFullWidth direction="column" gap={0}>
        <S.SkipButton align="center" gap={4} padding="8px 16px" onClick={() => handleCloseModal()}>
          <Text
            color={STATIC_COLORS.base.white}
            size="body3"
            text={skipBtn}
            textTransform="lowercase"
            weight="medium"
          />
          <svg fill="none" height="14" viewBox="0 0 8 14" width="8">
            <path d="M1 13L7 7L1 1" stroke="white" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.6" />
          </svg>
        </S.SkipButton>
        {isSelectedCorrectAnswer && <Confetti />}
        <S.HeaderWrapper align="center" justify="space-between" padding="16px 16px 0 16px">
          <Text color={STATIC_COLORS.base.black} fontFamily="Fraunces" size="h5" text={quizTitle} weight="semibold" />
          <img alt="test icon" src={headerIcon} />
        </S.HeaderWrapper>
        <S.ContentWrapper direction="column" gap={0} padding="16px">
          {quizStep ? (
            <Flexbox direction="column" gap={16}>
              <Flexbox direction="column" gap={4}>
                <Text
                  color={STATIC_COLORS.base.black}
                  fontFamily="Fraunces"
                  linesLimit={2}
                  size="subtitle1"
                  text={productName}
                  weight="semibold"
                />
                <Text
                  color={STATIC_COLORS.base.black}
                  linesLimit={1}
                  size="body1"
                  text={productProducerName}
                  weight="semibold"
                />
              </Flexbox>
              <S.AnimatedFlexbox $isAnimated={quizStep && !selectedAnswer} direction="column" gap={4}>
                <Text color={STATIC_COLORS.green[600]} size="body1" text={quizType} weight="semibold" />
                <DiscoveryQuizAnswers
                  answers={answers}
                  handleAnswerClick={handleAnswerClick}
                  selectedAnswer={selectedAnswer}
                />
              </S.AnimatedFlexbox>
              <S.ResultText
                $isVisible={selectedAnswer}
                color={STATIC_COLORS.base.black}
                fontFamily="Fraunces"
                size="h4"
                text={resultText}
                textAlign="center"
                weight="bold"
              />
            </Flexbox>
          ) : (
            <S.InitiateContentWrapper align="center" direction="column" gap={16}>
              <Image alt={productName} height="200px" objectFit="contain" src={productImage} width="200px" />
              <Flexbox isFullWidth align="flex-start" direction="column" gap={0}>
                <Text
                  color={STATIC_COLORS.base.black}
                  fontFamily="Fraunces"
                  size="h6"
                  text={initiateQuizContentTitle}
                  weight="semibold"
                />
                <Text
                  color={STATIC_COLORS.base.black}
                  fontFamily="Fraunces"
                  size="subtitle2"
                  text={initiateQuizContentSubtitle}
                  localeOptions={{
                    productCategory,
                  }}
                  localeVariables={{
                    productCategoryText: productCategories[productCategory],
                  }}
                />
              </Flexbox>
            </S.InitiateContentWrapper>
          )}
          {showButton && (
            <S.Button
              $isVisible={selectedAnswer}
              fontSize="subtitle2"
              handleClick={handleButtonClick}
              size="lg"
              text={btnText}
              variant={VH_VARIANTS.PRIMARY}
            />
          )}
          {isSelectedAnswerQuizVew && <S.LoadingBar />}
        </S.ContentWrapper>
      </S.DiscoveryQuizModalContainer>
    </ModalWrapperNew>
  );
};
