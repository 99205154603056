import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// eslint-disable-next-line import/order
import { actionGetQuizzes } from '@lib/core/quizzes/slices';
import { useRetailer } from '@lib/core/retailers/hooks/retailer';
import { useRetailerLocation } from '@lib/core/retailers/hooks/retailerLocation';
import { actionGetRetailerDetail, actionGetRetailerLocations } from '@lib/core/retailers/slices';
import { TLanguage } from '@lib/core/retailers/types';
import {
  PRODUCT_CATEGORY_NONE,
  REQUEST_ERROR_404,
  REQUEST_ERROR_MARKET_DEACTIVATION_CODE,
  STATE,
  isApplicationKiosk,
  isApplicationPmi,
} from '@lib/core/service/consts';
import { useApp } from '@lib/core/service/hooks';
import { selectRouteBasename } from '@lib/core/service/selectors/routes';
import { setProductCategory, setServiceLocale } from '@lib/core/service/slices';
import { actionUpdateServiceInstance } from '@lib/core/service/slices/serviceInstanceSlice';
import { isAppInIframe, migrateAppSliceToServiceSlice } from '@lib/core/service/utils';
import { history } from '@lib/core/service/utils/Navigator';
import { useUser } from '@lib/core/users/hooks';
import { useUserKiosk } from '@lib/core/users/hooks/useUserKiosk';
import { actionResetUserSlice } from '@lib/core/users/slices/user';
import { actionEnableLogging } from '@lib/tools/dat/slices';
import { LocaleProvider } from '@lib/tools/locale/providers/LocaleProvider';
import { RouteUtils } from '@lib/tools/routes';
import { setRouteBasename } from '@lib/tools/routes/slices/route';
import { useRetailerDesignSet } from '@lib/tools/views/hooks';
import { PAGES } from '@lib/tools/views/urls';
import { ErrorPageNotFound } from '@lib/tools/views/web/components/ErrorPageNotFound';

import { Error410Page } from '@components/pmi/src/common/Error410Page';
import { Spinner } from '@components/pmi/src/common/Spinner';
import {
  LOADING_SPINNER_VARIANTS,
  LoadingSpinner,
} from '@components/web/src/templates/Loading/LoadingSpinner/LoadingSpinner';

// Non-view code requires the history object to be accessible.

/**
 * * Service Launcher (HOC)
 *
 * 1. Fetches retailer and retailer location data.
 * 2. Sets app locale based on the retailer's default language and URL locale.
 * 3. Configures default product category different types of apps.
 * 4. Generates the basename for navigation and handles entrypoints.
 * 5. Pre-fetches relevant resources like quizzes.
 *
 * @summary Designed to handle locale configuration, retailer and location setup, product category selection,
 * and generates the basename for window navigation.
 */
export default Component => {
  const ServiceLauncher: FC<JSX.Element> = (App: JSX.Element) => {
    const dispatch = useDispatch();

    const { locale, productCategory, isDevToolsEnabled } = useApp();
    const { retailerId, retailerSlug, retailerDefaultLanguage, retailerLanguages, retailerError } = useRetailer();
    const { retailerLocationId, retailerLocationProductCategories } = useRetailerLocation();
    const { isDesignSetVinhoodApp } = useRetailerDesignSet();
    const { profileId } = useUser();
    const { isUserKioskAdmin, kioskUserRetailerSlug, kioskUserRetailerLocationId, kioskUserProductCategory } =
      useUserKiosk();
    const routeBasename = useSelector(selectRouteBasename);
    const isKioskLoggedOut = isApplicationKiosk && !isUserKioskAdmin;
    const isPmiEcommerceLoading = isAppInIframe && isApplicationPmi && (!retailerLocationId || !retailerId);

    const urlRetailerSlug = RouteUtils.getRetailerSlug();
    const urlRetailerLocationId = RouteUtils.getRetailerLocationId();
    const currentRetailerSlug = isUserKioskAdmin ? kioskUserRetailerSlug : urlRetailerSlug;
    const currentRetailerLocationId = isUserKioskAdmin ? kioskUserRetailerLocationId : urlRetailerLocationId;
    const isRetailerUnset = urlRetailerSlug !== retailerSlug && !isApplicationKiosk;
    const isKioskActive = isApplicationKiosk && isUserKioskAdmin;

    const hasValidProfile = !!profileId;
    const hasValidKioskLocation = isKioskActive && retailerLocationId;
    const hasValidRetailerData = !isKioskActive && !!retailerId && !!retailerLocationId;

    const isBasicDataLoaded = hasValidProfile && (hasValidKioskLocation || hasValidRetailerData);

    // useWhatChanged([isAppLaunched, userRole, locale, appProductCategory]);

    /**
     * * Locale setup to verify if the language is supported by the retailer
     */
    useEffect(() => {
      const localeFromUrl = RouteUtils.getLocale();
      if (retailerLanguages.length) {
        const isValidUrlLocale = retailerLanguages.some((language: TLanguage) => language.code === localeFromUrl);
        dispatch(setServiceLocale(isValidUrlLocale ? localeFromUrl : retailerDefaultLanguage));
      }
    }, [retailerLanguages]);

    /**
     * * Load retailer
     */
    useEffect(() => {
      if (currentRetailerSlug && (isRetailerUnset || (isKioskActive && !retailerSlug))) {
        Promise.resolve(dispatch(actionGetRetailerDetail({ retailerSlug: currentRetailerSlug }))).then(() => {
          dispatch(
            actionGetRetailerLocations({
              retailerLocationId: currentRetailerLocationId,
              retailerSlug: currentRetailerSlug,
            }),
          );
        });
      }

      // Logout kiosk when not in kiosk env
      if (!isApplicationKiosk && isUserKioskAdmin) {
        dispatch(actionResetUserSlice());
      }
    }, [retailerSlug, isUserKioskAdmin]);

    /**
     * * Handle default product category for non-kiosk applications
     */
    useEffect(() => {
      if (!isApplicationKiosk) {
        const urlProductCategory = RouteUtils.getProductCategory();

        // This check is needed for handling entrypoints without productCategory in the URL for widgets.
        // `isDesignSetVinhoodExperience` will return `false` if there's no productCategory in the URL.
        const isVinhoodExperience = !isApplicationPmi && !isDesignSetVinhoodApp;

        const shouldFallbackToFirstProductCategory =
          retailerLocationProductCategories.length === 1 ||
          (isVinhoodExperience && retailerLocationProductCategories.length > 1 && !urlProductCategory);
        if (isBasicDataLoaded) {
          if (RouteUtils.isPageRemoteGuidedTrialGenerator()) {
            dispatch(setProductCategory(PRODUCT_CATEGORY_NONE));
          } else if (productCategory === PRODUCT_CATEGORY_NONE) {
            if (shouldFallbackToFirstProductCategory) {
              dispatch(setProductCategory(retailerLocationProductCategories[0]));
            } else if (urlProductCategory) {
              dispatch(setProductCategory(urlProductCategory));
            } else {
              dispatch(setProductCategory(PRODUCT_CATEGORY_NONE));
            }
          }
        }
      }
    }, [retailerId, retailerLocationId, isUserKioskAdmin, isBasicDataLoaded]);

    /**
     * * Handle default product category for kiosk application
     */
    useEffect(() => {
      if (isApplicationKiosk && isUserKioskAdmin) {
        // Kiosk logins
        dispatch(setProductCategory(kioskUserProductCategory));
      }
    }, [isUserKioskAdmin]);

    /**
     * * Generate basename for routing
     */
    useEffect(() => {
      const basename = RouteUtils.generateBasename();
      const urlProductCategory = RouteUtils.getProductCategory();

      if (isBasicDataLoaded && productCategory) {
        if (basename) {
          const isStaleProductCategory = productCategory !== urlProductCategory;
          const resetBasename =
            !window.location.pathname.includes(basename) || isStaleProductCategory || isDesignSetVinhoodApp;

          if (!routeBasename || resetBasename) {
            dispatch(setRouteBasename({ basename }));
          }
        }
      }
    }, [isBasicDataLoaded, locale, productCategory]);

    /**
     * ? Move logic into routers
     * * Handle entrypoints
     */
    useEffect(() => {
      let [page] = RouteUtils.getPage().split('?');
      const params = RouteUtils.getSearch();
      if (productCategory) {
        if (page === '/') {
          if (isApplicationPmi) {
            if (isAppInIframe) {
              page = PAGES.ta.quiz.local;
            } else {
              page = PAGES.ta.landing;
            }
          } else if (isApplicationKiosk) {
            if (isUserKioskAdmin) {
              page = PAGES.vinhood.welcome;
            }
          } else if (!isDesignSetVinhoodApp) {
            page = PAGES.vinhood.home;
          }
        }
        // * Replace window location to force API translations for essences.
        if (history) {
          if (isBasicDataLoaded && routeBasename) history.push(routeBasename + page + (params || ''));
          if (isApplicationKiosk && !routeBasename) {
            history.push(routeBasename + PAGES.vinhood.kioskLogin);
          }
        }
      }
    }, [productCategory, isUserKioskAdmin, routeBasename, isBasicDataLoaded]);

    // Pre-fetch relevant resources
    useEffect(() => {
      if (isBasicDataLoaded && retailerSlug && !isApplicationPmi) {
        dispatch(actionGetQuizzes());
      }
    }, [isBasicDataLoaded, retailerSlug]);

    /**
     * * Clears states prior to v0.5.0
     */
    useEffect(() => {
      localStorage.removeItem(STATE);
      migrateAppSliceToServiceSlice();
    }, []);

    /**
     * * Enable the duty free service if the entry point url is `/duty-free/`
     */
    const serviceInstanceType = RouteUtils.getSpecificServiceInstanceType();
    useEffect(() => {
      if (isApplicationPmi && serviceInstanceType) {
        dispatch(actionUpdateServiceInstance({ serviceInstanceType }));
      }
    }, [serviceInstanceType]);

    /**
     * * Enable logging
     */
    useEffect(() => {
      if (isDevToolsEnabled) {
        dispatch(actionEnableLogging());
      }
    }, [isDevToolsEnabled]);

    const loader = isApplicationPmi ? (
      <Spinner />
    ) : (
      <LocaleProvider>
        <LoadingSpinner variant={LOADING_SPINNER_VARIANTS.TRANSPARENT} />
      </LocaleProvider>
    );

    if (retailerError) {
      if (isApplicationPmi && retailerError === REQUEST_ERROR_MARKET_DEACTIVATION_CODE) {
        return (
          <LocaleProvider>
            <Error410Page />
          </LocaleProvider>
        );
      }
      if (retailerError === REQUEST_ERROR_404) {
        return <ErrorPageNotFound />;
      }
    }

    return (isBasicDataLoaded && !isUserKioskAdmin) ||
      (isUserKioskAdmin && productCategory && isBasicDataLoaded) ||
      isPmiEcommerceLoading ||
      isKioskLoggedOut ? (
      <Component {...App} />
    ) : (
      loader
    );
  };

  return ServiceLauncher;
};
