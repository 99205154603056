import { FC, SyntheticEvent } from 'react';

import { TExperience } from '@lib/core/experiences/types';
import MixpanelTracker from '@lib/tools/dat/mixpanel';
import { localeWidget } from '@lib/tools/locale/source/web/widget';
import { LocaleUtils } from '@lib/tools/locale/utils';
import { EXPERIENCES_PRICE_RANGE, VH_VARIANTS } from '@lib/tools/shared/helpers/consts';

import PriceRange from '@components/web/src/atoms/PriceRange/PriceRange';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';
import * as S from '@components/web/src/organisms/Cards/ExperienceCard/styles';
import ExperienceCardSkeleton from '@components/web/src/organisms/Skeleton/ExperienceCardSkeleton/ExperienceCardSkeleton';
import { SkeletonWrapper } from '@components/web/src/organisms/Skeleton/SkeletonWrapper';

interface IProps {
  experienceData: TExperience;
  isLoading?: boolean;
  isResponsive?: boolean;
}

export const ExperienceCard: FC<IProps> = ({ experienceData, isLoading = false, isResponsive = false }) => {
  const { price, image, link, name, type } = experienceData || {};

  const experienceType = type?.name || '';
  const [priceValue = '0', currency = ''] = price?.split(' ') || [];
  const parsedPriceValue = Math.floor(Number(priceValue));
  const parsedCurrencyValue = LocaleUtils.getCurrencySymbolFromCode(currency);

  const handleClick = (e: SyntheticEvent) => {
    e.stopPropagation();
    if (link) {
      window.open(link, '_blank');
      MixpanelTracker.events.viewProducerExperiencePage(experienceData, currency, false);
    }
  };

  return (
    <SkeletonWrapper showSkeleton={isLoading} skeleton={() => ExperienceCardSkeleton({ isResponsive })}>
      <S.ExperienceCardContainer $isResponsive={isResponsive} direction="column" gap={0} onClick={handleClick}>
        <S.ImageWrapper>
          <img alt={experienceType} src={image} />
        </S.ImageWrapper>
        <Flexbox direction="column" gap={4} padding="16px 16px 0px">
          <Text color={STATIC_COLORS.green['600']} linesLimit={1} size="body1" text={experienceType} weight="medium" />
          <S.StyledProductName
            color={STATIC_COLORS.base.black}
            fontFamily="Fraunces"
            linesLimit={2}
            size="h5"
            text={name}
            weight="semibold"
          />
          <S.PriceRangeWrapper>
            <PriceRange
              currencySymbolValue={parsedCurrencyValue}
              customPriceRange={EXPERIENCES_PRICE_RANGE}
              originalPrice={parsedPriceValue}
            />
          </S.PriceRangeWrapper>
        </Flexbox>
        {link && (
          <S.Button
            text={localeWidget.experienceCard.btnText}
            textWeight="semibold"
            variant={VH_VARIANTS.LINK}
            onClick={handleClick}
          />
        )}
      </S.ExperienceCardContainer>
    </SkeletonWrapper>
  );
};
