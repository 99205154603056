import { FC, ReactElement, cloneElement, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import fetchPostFlashLabel from '@lib/core/products/slices/postFlashLabel';
import { ModalTypes } from '@lib/core/service/consts';
import { ILocaleText } from '@lib/tools/locale/views/LocaleFragment';

import { LOCATION_ICON, NONE_ICON } from '@components/web/src/kiosk/consts';
import EventsModal from '@components/web/src/templates/Modals/Events/EventsModal/EventsModal';

export type IFindProductPopup = {
  children: ReactElement;
  productLocationMap: string;
  productLocationDescription: ILocaleText;
  productEcommerceId: string;
  isEnableLocationMapAddon: boolean;
  isEnableVusionAddon: boolean;
};

const FindProductPopup: FC<IFindProductPopup> = ({
  children,
  productLocationMap = '',
  productLocationDescription = '',
  productEcommerceId = '',
  isEnableLocationMapAddon,
  isEnableVusionAddon,
}) => {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const icon = isEnableVusionAddon ? LOCATION_ICON : NONE_ICON;

  const findProductButton = useMemo(() => {
    return cloneElement(children, {
      onClick: () => {
        if (children.props.onClick instanceof Function) children.props.onClick();
        setIsModalOpen(value => !value);
        if (isEnableVusionAddon && productEcommerceId) {
          dispatch(fetchPostFlashLabel(productEcommerceId));
        }
      },
    });
  }, [children, dispatch, isEnableVusionAddon, productEcommerceId]);

  return (
    <>
      {findProductButton}
      {isModalOpen && isEnableLocationMapAddon ? (
        <EventsModal
          description={productLocationDescription}
          hideModal={() => setIsModalOpen(false)}
          isOpen={isModalOpen}
          locationImageUrl={productLocationMap}
          modalType={ModalTypes.LocationMapModal}
        />
      ) : (
        <EventsModal
          description={productLocationDescription}
          hideModal={() => setIsModalOpen(false)}
          icon={icon}
          isOpen={isModalOpen}
          modalType={ModalTypes.FindMeModal}
        />
      )}
    </>
  );
};

export default FindProductPopup;
