import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { isApplicationKiosk } from '@lib/core/service/consts';
import { prependBasename } from '@lib/core/service/utils';
import { useUser } from '@lib/core/users/hooks';
import { actionUpdateProfileData } from '@lib/core/users/slices/profile';
import { PRODUCT_CATEGORY_URL_PARAM } from '@lib/tools/shared/helpers/consts';
import { birthYearValidation } from '@lib/tools/shared/helpers/utils';
import { useCustomStyles } from '@lib/tools/views/hooks/useCustomStyles';
import { PAGES } from '@lib/tools/views/urls';

import { ICheckboxAnswer } from '@components/web/src/atoms/Checkboxes&Radio/CheckboxAnswer/CheckboxAnswer';
import { LoadingSpinner } from '@components/web/src/templates/Loading/LoadingSpinner/LoadingSpinner';
import { AgePage } from '@components/web/src/templates/TestFlowsPages/AgePage/AgePage';
import { GenderPage } from '@components/web/src/templates/TestFlowsPages/GenderPage/GenderPage';

enum Page {
  AGE = 'AGE',
  GENDER = 'GENDER',
}

export const AgeAndGenderContainer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const productCategoryUrlParam: any = searchParams.get(PRODUCT_CATEGORY_URL_PARAM) || '';
  const customStyles = useCustomStyles();

  const { profileGenderSlug, profileBirthYear, profileGenderOptions, isProfileLoading, isUserAuthenticated } =
    useUser();

  const [page, setPage] = useState<Page>(Page.GENDER);
  const [genderOptions, setGenderOptions] = useState<ICheckboxAnswer[]>([]);
  const [birthYear, setBirthYear] = useState(profileBirthYear?.toString() || '');

  useEffect(() => {
    setGenderOptions(
      profileGenderOptions?.map(option => ({
        ...option,
        isSelected: option.slug === profileGenderSlug,
      })),
    );
  }, [profileGenderOptions, profileGenderSlug]);

  const { maxValidBirthYear, isValidBirthYear } = birthYearValidation(birthYear);

  const isDisabledGenderContinueBtn = !genderOptions?.some(data => data.isSelected);

  useEffect(() => {
    if (!isApplicationKiosk && isUserAuthenticated) {
      navigate(
        prependBasename(PAGES.vinhood.quiz.chooseExpert, {
          [PRODUCT_CATEGORY_URL_PARAM]: productCategoryUrlParam,
        }),
      );
    }
  }, [isUserAuthenticated]);

  const handleGenderOptionClick = (slug: string) => {
    const updatedGenderOptions = genderOptions.map(data =>
      data.slug === slug ? { ...data, isSelected: !data.isSelected } : { ...data, isSelected: false },
    );

    setGenderOptions(updatedGenderOptions);
  };

  const handleBirthYearChange = (value: string) => setBirthYear(value);

  const handleContinueClick = () => {
    if (page === Page.GENDER) {
      const selectedGenderOptionSlug = genderOptions.filter(data => data.isSelected).map(data => data.slug)[0];

      dispatch(actionUpdateProfileData({ gender: selectedGenderOptionSlug }));
      setPage(Page.AGE);
      return;
    }

    dispatch(actionUpdateProfileData({ birth_year: Number(birthYear) }));

    const nextPage = isApplicationKiosk ? PAGES.vinhood.quiz.taste : PAGES.vinhood.quiz.chooseExpert;
    const params = isApplicationKiosk ? undefined : { [PRODUCT_CATEGORY_URL_PARAM]: productCategoryUrlParam };

    navigate(prependBasename(nextPage, params));
  };

  const handleSkipClick = () => {
    if (page === Page.GENDER) {
      setPage(Page.AGE);
      return;
    }

    const nextPage = isApplicationKiosk ? PAGES.vinhood.quiz.taste : PAGES.vinhood.quiz.chooseExpert;
    const params = isApplicationKiosk ? undefined : { [PRODUCT_CATEGORY_URL_PARAM]: productCategoryUrlParam };

    navigate(prependBasename(nextPage, params));
  };

  const handleGoBackClick = () => {
    if (page === Page.GENDER) {
      const params = isApplicationKiosk ? undefined : { [PRODUCT_CATEGORY_URL_PARAM]: productCategoryUrlParam };
      navigate(prependBasename(PAGES.vinhood.home, params));
      return;
    }

    setPage(Page.GENDER);
  };

  const pageView =
    page === Page.GENDER ? (
      <GenderPage
        customStyles={customStyles}
        genderOptions={genderOptions}
        handleContinueClick={handleContinueClick}
        handleGenderOptionClick={handleGenderOptionClick}
        handleGoBack={() => handleGoBackClick()}
        handleSkipClick={() => handleSkipClick()}
        isApplicationKiosk={isApplicationKiosk}
        isDisabledBtn={isDisabledGenderContinueBtn}
      />
    ) : (
      <AgePage
        birthYear={birthYear}
        customStyles={customStyles}
        handleBirthYearChange={handleBirthYearChange}
        handleContinueClick={handleContinueClick}
        handleGoBack={() => handleGoBackClick()}
        handleSkipClick={() => handleSkipClick()}
        isApplicationKiosk={isApplicationKiosk}
        isValidBirthYear={isValidBirthYear}
        maxValidBirthYear={maxValidBirthYear}
      />
    );

  return isProfileLoading ? <LoadingSpinner /> : pageView;
};
