import { PayloadAction, createSlice, isAnyOf } from '@reduxjs/toolkit';

import {
  selectQuizData,
  selectQuizFirstQuestionId,
  selectQuizPath,
  selectQuizViewAnswerTags,
  selectQuizViewPausedQuestionId,
  selectQuizViewQuestionDepths,
  selectQuizViewQuestionId,
  selectQuizViewUserPath,
} from '@lib/core/quizzes/selectors';
import { actionGetQuiz } from '@lib/core/quizzes/slices';
import { TQuizAnswerTag, TQuizDetail, TQuizQuestion } from '@lib/core/quizzes/types';
import { IQuizView, TQuizViewUserPath } from '@lib/core/quizzes/types/quizView';
import QuizUtils from '@lib/core/quizzes/utils';
import { createTypedAsyncThunk } from '@lib/core/service/createTypedAsyncThunk';
import { RouteUtils } from '@lib/tools/routes';

const initialState: IQuizView = {
  answerTags: [],
  currentDepth: 0,
  isBackward: false,
  isCompleted: false,
  isPaused: false,
  isPostNewUserQuiz: false,
  pausedQuestionId: null,
  pausedRoute: '',
  previousQuestionId: null,
  questionId: null,
  questionsDepths: null,
  userPath: null,
  viewData: null,
};

/**
 * * Handles `answerTags`, `depth`, and `userPath` during `quizPath` traversal.
 */
export const actionTraverseQuizViewData = createTypedAsyncThunk(
  'quizView/navigate',
  (
    {
      steps = 1,
      answerIds,
      forceBacktrack = false,
    }: { steps?: number; answerIds?: string[]; forceBacktrack?: boolean },
    { getState },
  ): any => {
    try {
      const state = getState();

      // * quiz data
      const quizData = selectQuizData(state);
      const quizPath = selectQuizPath(state);
      const firstQuestionId = selectQuizFirstQuestionId(state);

      // * quizView data
      const answerTags = selectQuizViewAnswerTags(state);
      const questionDepths = selectQuizViewQuestionDepths(state);
      const userPath = selectQuizViewUserPath(state);
      const questionId = selectQuizViewQuestionId(state);
      const pausedQuestionId = selectQuizViewPausedQuestionId(state);

      const quizQuestions = Object.values(userPath);
      const userPathQuestionIds = [];
      const newAnswerTags = [...answerTags];

      let currentQuestionId: string;
      let newPreviousQuestionId: string;
      let viewData: TQuizQuestion;
      let depth: number;
      let pause = false;
      let isBackward = false;

      quizQuestions.forEach(questionData => userPathQuestionIds.push(Object.keys(questionData)[0]));

      const newUserPath: TQuizViewUserPath = { ...userPath };

      switch (steps) {
        case 0:
          // * First question loaded
          currentQuestionId = firstQuestionId;
          newUserPath[questionDepths[firstQuestionId]] = { [firstQuestionId]: [] };

          viewData = quizData[firstQuestionId];
          depth = questionDepths[firstQuestionId];
          break;
        case 1:
          // * Going forward
          const nextQuestionId =
            quizPath[questionId]?.answers[Array.isArray(answerIds) ? answerIds[0] : answerIds]?.question_pool?.[0];
          const selectedAnswers: string[] = Array.isArray(answerIds) ? answerIds : [answerIds];

          // Update answer tags
          selectedAnswers.forEach(answerId => {
            quizData[questionId]?.answers?.forEach(quizAnswerData => {
              if (quizAnswerData[answerId] && quizAnswerData[answerId].tags?.length) {
                newAnswerTags.push(...quizAnswerData[answerId].tags);
              }
            });
          });

          // Insert selected answers against the current question
          newUserPath[questionDepths[questionId]] = { [questionId]: selectedAnswers };

          // Insert next question without any answers
          if (nextQuestionId) newUserPath[questionDepths[nextQuestionId]] = { [nextQuestionId]: [] };

          // Reduce to quizView state
          newPreviousQuestionId = questionId;
          currentQuestionId = nextQuestionId;
          depth = questionDepths[nextQuestionId];
          viewData = quizData[nextQuestionId];
          break;
        default:
          // * Going backward
          if (steps < 0) {
            let lastQuestionDepth = questionDepths[questionId];

            for (let step = 0; step < steps * -1; step += 1) {
              const userPathDepths = Object.keys(newUserPath).map(Number);
              // eslint-disable-next-line @typescript-eslint/no-loop-func
              const secondLastQuestionDepth = Math.min(...userPathDepths.filter(d => d !== lastQuestionDepth));
              const isFirstQuestion = Object.keys(newUserPath).length === 1;

              const lastQuestionId = Object.keys(newUserPath[lastQuestionDepth])[0];
              const secondLastQuestionId = isFirstQuestion ? '' : Object.keys(newUserPath[secondLastQuestionDepth])[0];

              const secondLastQuestionAnswerIds = Object.keys(quizPath[secondLastQuestionId].answers);
              const userPathItems = Object.values(newUserPath);
              const userPathAnswerIds = [];

              /**
               * * Backtrack conditions:
               *
               * 1. The quiz needn't be paused - `secondLastQuestionId !== pausedQuestionId`
               * 2. The quiz needs to be forcefully backtracked from the bundles page - `forceBacktrack`
               * 3. The quiz needs to go back several steps (steps = -2 or steps = -3) from product reference cards
               */
              const shouldBacktrack = secondLastQuestionId !== pausedQuestionId || forceBacktrack || steps < -1;

              userPathItems.forEach(item => {
                Object.values(item).forEach(_ => userPathAnswerIds.push(..._));
              });

              // Don't backtrack if the quizView needs to be paused
              if (shouldBacktrack) {
                // Delete quiz answer tags
                if (secondLastQuestionAnswerIds.some(answer => userPathAnswerIds.includes(answer))) {
                  const deleteIndex = secondLastQuestionAnswerIds.indexOf(userPathAnswerIds[0]);

                  const answer = quizData[secondLastQuestionId].answers[deleteIndex];

                  if (answer) {
                    const tagsToDelete = Object.values(answer)?.[0]?.tags;
                    tagsToDelete.forEach(tag => newAnswerTags.splice(newAnswerTags.indexOf(tag), 1));
                  }
                }

                // Delete current question from userPath
                delete newUserPath[questionDepths[lastQuestionId]];
              } else {
                pause = true;
              }

              depth = questionDepths[secondLastQuestionId];
              newUserPath[secondLastQuestionDepth] = { [secondLastQuestionId]: [] };
              viewData = quizData[secondLastQuestionId];
              currentQuestionId = secondLastQuestionId;
              isBackward = true;
              lastQuestionDepth = depth;
              newPreviousQuestionId = secondLastQuestionId;
            }
          }
          break;
      }

      const reducePayload: Partial<IQuizView> = {
        answerTags: newAnswerTags,
        userPath: newUserPath,
      };

      if (currentQuestionId) {
        reducePayload.isPaused = pause;
        reducePayload.questionId = currentQuestionId;
        reducePayload.currentDepth = depth;
        reducePayload.viewData = viewData;
        reducePayload.previousQuestionId = newPreviousQuestionId;
        reducePayload.isBackward = isBackward;
      }

      return reducePayload;
    } catch (e) {
      console.error(e);
      return {};
    }
  },
);

/**
 * * For use by DevTools for dev and QA purposes.
 * ! DO NOT use in apps.
 */
export const actionUpdateQuizAnswerTag = createTypedAsyncThunk(
  'quizView/updateAnswerTags',
  ({ answerTag }: { answerTag: TQuizAnswerTag }, { getState }): any => {
    const state = getState();
    const quizViewAnswerTags = selectQuizViewAnswerTags(state);

    const isAnswerTagAlreadyPresent = quizViewAnswerTags.some(tag => JSON.stringify(tag) === JSON.stringify(answerTag));

    return {
      answerTags: isAnswerTagAlreadyPresent
        ? quizViewAnswerTags.filter(tag => JSON.stringify(tag) !== JSON.stringify(answerTag)) // remove tag
        : [...quizViewAnswerTags, answerTag], // add tag
    };
  },
);

const quizViewSlice = createSlice({
  extraReducers: builder => {
    builder.addCase(actionGetQuiz.fulfilled, (state, action: PayloadAction<TQuizDetail>) => {
      const questionDepths = QuizUtils.getQuestionsDepths(action.payload.quiz_path);
      state.questionsDepths = questionDepths;
      state.currentDepth = Object.keys(questionDepths).length - 1;
    });
    builder.addCase(actionUpdateQuizAnswerTag.fulfilled, (state, action: PayloadAction<Partial<IQuizView>>) => {
      state.answerTags = action.payload.answerTags;
    });
    builder.addMatcher(
      isAnyOf(actionTraverseQuizViewData.fulfilled),
      (state, action: PayloadAction<Partial<IQuizView>>) => {
        const { answerTags, viewData } = action.payload;

        if (action.payload.isPaused) {
          state.isPaused = true;
          state.pausedRoute = RouteUtils.getPage();
        } else {
          if (answerTags) state.answerTags = answerTags;
          if (viewData) state.viewData = viewData;
          if (action.payload.currentDepth === undefined) {
            state.isCompleted = true;
            state.viewData = null;
          } else {
            state.isBackward = action.payload.isBackward;
            state.currentDepth = action.payload.currentDepth;
            state.previousQuestionId = action.payload.previousQuestionId;
            state.questionId = action.payload.questionId;
            state.userPath = action.payload.userPath;
          }
        }
      },
    );
    builder.addMatcher(isAnyOf(actionTraverseQuizViewData.rejected), (action: any) => {
      console.error('QuizView', action.payload);
    });
  },
  initialState,
  name: 'quizView',
  reducers: {
    actionPauseQuizView: state => {
      return {
        ...state,
        isPaused: true,
        pausedQuestionId: state.previousQuestionId,
        pausedRoute: RouteUtils.getPage(),
      };
    },
    actionPostNewUserQuiz: state => {
      return {
        ...state,
        isPostNewUserQuiz: true,
      };
    },
    actionResetQuizView: () => initialState,
    actionResumeQuizView: state => {
      return {
        ...state,
        isPaused: false,
        pausedRoute: '',
      };
    },
  },
});

export default quizViewSlice.reducer;

export const { actionPostNewUserQuiz, actionResetQuizView, actionPauseQuizView, actionResumeQuizView } =
  quizViewSlice.actions;
