import { styled } from 'styled-components';

import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';

export const FoodPreferencesModalContainer = styled(Flexbox)`
  padding: 16px;
  border-radius: 16px;
  background: ${STATIC_COLORS.base.white};
`;
