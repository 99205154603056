import { css, styled } from 'styled-components';

import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';

export const NoProductsAvailableWrapper = styled(Flexbox)`
  background: ${({ theme }) => theme?.colors?.secondaryColor?.['-100']};
  border-radius: 16px 16px 0 0;
  gap: ${({ theme }) => (theme?.isKioskRootElementType ? '0' : '32px')};
`;

export const ImageWrapper = styled.div`
  background-repeat: no-repeat;
  background-position: center;
  background-size: 150% 150%;

  ${({ theme }) => {
    if (theme?.isKioskRootElementType) {
      return css`
        width: 430px;
        height: 453px;
      `;
    }

    return css`
      width: 187px;
      height: 197px;
    `;
  }}
`;
