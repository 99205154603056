import { FC, useEffect, useState } from 'react';

import { TParsedProductInstance } from '@lib/core/products/types';
import MixpanelTracker from '@lib/tools/dat/mixpanel';
import { localeWidget } from '@lib/tools/locale/source/web/widget';

import { Text } from '@components/web/src/foundations/Text/Text';
import { ProductFeedbackStartProductSection } from '@components/web/src/organisms/ProductFeedback/ProductFeedbackStartProductSection/ProductFeedbackStartProductSection';
import * as S from '@components/web/src/templates/ProductFeedback/ProductFeedbackPageStart/styles';

interface IProps {
  parsedProductInstanceData: TParsedProductInstance;
  handleNavigate: () => void;
  isLoading?: boolean;
}

export const ProductFeedbackPageStart: FC<IProps> = ({ parsedProductInstanceData, handleNavigate, isLoading }) => {
  const { productName, productCategory, productImage } = parsedProductInstanceData || {};

  const { startText, productCategoryDescription, countdownText } = localeWidget.ProductFeedbackStartPage;

  const [showCountdownView, setShowCountdownView] = useState(false);
  const [countdown, setCountdown] = useState(3);

  useEffect(() => {
    if (showCountdownView) {
      const interval = setInterval(() => {
        setCountdown(prev => {
          if (prev <= 1) {
            clearInterval(interval);
            if (!isLoading) {
              handleNavigate();
            }
          }
          return prev - 1;
        });
      }, 1000);

      return () => clearInterval(interval);
    }
    return undefined;
  }, [showCountdownView, handleNavigate, isLoading]);

  useEffect(() => {
    if (!isLoading && countdown < 0) {
      handleNavigate();
    }
  }, [isLoading, countdown]);

  return (
    <S.ProductFeedbackStartPageContainer
      isFullWidth
      $isTimer={showCountdownView}
      align="center"
      direction="column"
      gap={0}
      justify={showCountdownView ? 'center' : 'flex-start'}
    >
      {showCountdownView ? (
        <S.CountdownContainer isFullWidth align="center" direction="column" gap={0} justify="center">
          <S.NumberContainer>
            <Text fontFamily="Fraunces" text={countdown > 0 ? countdown.toString() : '1'} weight="bold" />
          </S.NumberContainer>
          <Text size="body1" text={countdownText} weight="semibold" />
        </S.CountdownContainer>
      ) : (
        <>
          <S.Header>
            <S.TitleText fontFamily="Fraunces" size="h3" text={startText} weight="semibold" />
            <Text
              localeOptions={{ productCategory }}
              size="subtitle2"
              text={productCategoryDescription}
              weight="bold"
            />
          </S.Header>
          <ProductFeedbackStartProductSection
            productImage={productImage}
            productName={productName}
            handleClick={() => {
              MixpanelTracker.events.interactiveTastingStart(parsedProductInstanceData);

              setShowCountdownView(true);
            }}
          />
        </>
      )}
    </S.ProductFeedbackStartPageContainer>
  );
};
