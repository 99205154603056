import styled from 'styled-components';

import { STATIC_GRADIENTS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';

export const AgePageContainer = styled(Flexbox)<{ $background?: string }>`
  flex: 1;
  background-color: ${({ $background }) => $background || 'var(--color-secondary-100)'};
`;

export const ContentWrapper = styled(Flexbox)`
  gap: ${({ theme }) => (theme?.isKioskRootElementType ? '60px' : '24px')};
  padding: ${({ theme }) => (theme?.isKioskRootElementType ? '208px 170px' : '16px 16px 30px')};
  z-index: 1;
`;

export const AgeInput = styled.input`
  all: unset;
  margin: 0;
  padding: 0;
  border: none;
  height: ${({ theme }) => (theme?.isKioskRootElementType ? '110px' : '66px')};

  font-family: Fraunces;
  font-size: ${({ theme }) => (theme?.isKioskRootElementType ? '80px' : '48px')};
  font-style: normal;
  font-weight: 600;
  line-height: ${({ theme }) => (theme?.isKioskRootElementType ? '110px' : '60px')};
  letter-spacing: ${({ theme }) => (theme?.isKioskRootElementType ? '-1.6px' : '-0.96px')};
  text-align: center;
  background: ${STATIC_GRADIENTS.primaryGradient};
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  &::placeholder {
    opacity: 0.3;
    background: ${STATIC_GRADIENTS.primaryGradient};
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

export const ErrorText = styled(Text)`
  min-height: ${({ theme }) => (theme?.isKioskRootElementType ? '24px' : '36px')};
`;

export const CtaWrapper = styled(Flexbox)`
  gap: ${({ theme }) => (theme?.isKioskRootElementType ? '12px' : '10px')};
  margin: ${({ theme }) => (theme?.isKioskRootElementType ? '40px 0 108px' : '12px 0 4px')};

  button {
    width: ${({ theme }) => (theme?.isKioskRootElementType ? 'fit-content' : '100%')};
  }
`;
