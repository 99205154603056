import styled, { css, keyframes } from 'styled-components';

import { STATIC_COLORS, STATIC_GRADIENTS, STATIC_SHADOWS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';

export const skeletonLoadingKeyframes = keyframes`
  0% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
`;

export const skeletonCommonStyles = css`
  background: ${STATIC_GRADIENTS.warmGradientForSkeleton};
  animation: ${skeletonLoadingKeyframes} 1s linear infinite alternate;
  border-radius: 4px;
  padding-right: 20px;
`;

export const PlacesCardSkeletonContainer = styled(Flexbox)<{ $isResponsive?: boolean }>`
  width: ${({ $isResponsive }) => ($isResponsive ? '100%' : '310px')};
  height: 100%;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: ${STATIC_SHADOWS.sm};
  background-color: ${STATIC_COLORS.base.white};
  gap: 0;
`;

export const ImageSkeleton = styled.div`
  width: 100%;
  height: 150px;
  overflow: hidden;
  border-top-left-radius: 8px;
  ${skeletonCommonStyles}
`;

export const ContentWrapperSkeleton = styled(Flexbox)`
  padding: 16px;
`;

export const LogoSkeleton = styled.div`
  height: 40px;
  width: 90px;
  ${skeletonCommonStyles}
`;

export const TitleSkeleton = styled.div`
  height: 24px;
  width: 100%;
  ${skeletonCommonStyles}
`;

export const SubtitleSkeleton = styled.div`
  height: 36px;
  width: 100%;
  ${skeletonCommonStyles}
`;
