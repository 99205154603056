import classNames from 'classnames';
import { FC, ReactNode } from 'react';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';

type Props = {
  children: ReactNode[];
  showPaginationDots?: boolean;
  slidesPerView?: number | 'auto';
  className?: string;
  spaceBetween?: number;
};

const SwiperHOC: FC<Props> = ({
  children,
  showPaginationDots = true,
  slidesPerView = 'auto',
  className,
  spaceBetween = 13,
}) => (
  <div className={classNames('vh-swiper-container', className)}>
    <Swiper
      modules={[Pagination]}
      pagination={showPaginationDots ? { clickable: true } : false}
      slidesPerView={slidesPerView}
      spaceBetween={spaceBetween}
    >
      {children.map((child, idx) => (
        <SwiperSlide key={idx}>{child}</SwiperSlide>
      ))}
      {showPaginationDots && <div className="hidden-pagination-block" />}
    </Swiper>
  </div>
);

export default SwiperHOC;
