import { FC, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import LoginContainer from '@lib/tools/shared/auths/views/containers/LoginContainer';
import RegistrationContainer from '@lib/tools/shared/auths/views/containers/RegistrationContainer';
import ResetPasswordContainer from '@lib/tools/shared/auths/views/containers/ResetPasswordContainer';
import ConfirmResetPasswordContainer from '@lib/tools/shared/auths/views/containers/SetPasswordContainer';
import FoodHabitsContainer from '@lib/tools/shared/quizzes/views/containers/FoodHabitsContainer';
import QuizContainer from '@lib/tools/shared/quizzes/views/containers/QuizContainer';
import { FoodPairingContainer } from '@lib/tools/shared/recipes/views/containers/FoodPairingContainer';
import { PAGES } from '@lib/tools/views/urls';
import { ErrorPageNotFound } from '@lib/tools/views/web/components/ErrorPageNotFound';

import { EdutainmentContainer } from 'containers/Edutainment/EdutainmentContainer';
import { EdutainmentFormContainer } from 'containers/Edutainment/EdutainmentFormContainer';
import { GlobalSearchContainer } from 'containers/GlobalSearchContainer';
import { HintContainer } from 'containers/HintContainer';
import CookiePolicyContainer from 'containers/Legal/CookiePolicyContainer';
import GeneralServiceTermsContainer from 'containers/Legal/GeneralServiceTermsContainer';
import PrivacyPolicyContainer from 'containers/Legal/PrivacyPolicyContainer';
import TermsConditionsContainer from 'containers/Legal/TermsConditionsContainer';
import JournalPlacesContainer from 'containers/TasteId/Journal/JournalPlacesContainer';
import JournalProductsContainer from 'containers/TasteId/Journal/JournalProductsContainer';
import TasteIdLegalPageContainer from 'containers/TasteId/TasteIdLegalPageContainer';
import TasteIdPageContainer from 'containers/TasteId/TasteIdPageContainer';
import TasteIdSettingsPageContainer from 'containers/TasteId/TasteIdSettingsPageContainer';
import TasteIdTagsPageContainer from 'containers/TasteId/TasteIdTagsPageContainer';
import { AnonRoutes, AuthRoutes } from 'routes/AccessApp';

const Router: FC = () => {
  const RouteGroup = (c, ...paths) => {
    return paths.map(p => <Route key={p} Component={c} path={p} />);
  };

  const basenameApp = '/:lang/:retailer/loc/:location/';
  const appUrl = url => basenameApp + url;

  return (
    <Suspense fallback={null}>
      <Routes>
        <Route path={basenameApp}>
          {/* Protected routes */}
          <Route Component={AuthRoutes}>
            <Route key="urlEdutainment" Component={EdutainmentContainer} path={appUrl(PAGES.vinhood.edutainment)} />

            <Route
              key="urlVinhoodEdutainmentFormPage"
              Component={EdutainmentFormContainer}
              path={appUrl(PAGES.vinhood.edutainmentForm)}
            />

            <Route key="urlTasteId" Component={TasteIdPageContainer} path={appUrl(PAGES.vinhood.tasteId.tasteId)} />
            <Route
              key="urlTasteIdTags"
              Component={TasteIdTagsPageContainer}
              path={appUrl(PAGES.vinhood.tasteId.tasteIdTags)}
            />
            <Route
              key="urlTasteIdLegal"
              Component={TasteIdLegalPageContainer}
              path={appUrl(PAGES.vinhood.tasteId.tasteIdLegal)}
            />
            <Route
              key="urlTasteIdSettings"
              Component={TasteIdSettingsPageContainer}
              path={appUrl(PAGES.vinhood.tasteId.tasteIdSettings)}
            />

            <Route
              key="urlVinhoodGlobalSearchResults"
              Component={GlobalSearchContainer}
              path={appUrl(PAGES.vinhood.globalSearchResults)}
            />

            <Route key="urlHint" Component={HintContainer} path={appUrl(PAGES.vinhood.hint)} />

            <Route
              key="urlTasteIdProductsJournal"
              Component={JournalProductsContainer}
              path={appUrl(PAGES.vinhood.tasteId.tasteIdProductsJournal)}
            />
            <Route
              key="urlTasteIdPlacesJournal"
              Component={JournalPlacesContainer}
              path={appUrl(PAGES.vinhood.tasteId.tasteIdPlacesJournal)}
            />

            <Route key="urlFoodPairing" Component={FoodPairingContainer} path={appUrl(PAGES.vinhood.recipeSearch)} />
          </Route>

          {/* Anonymous routes */}
          <Route Component={AnonRoutes}>
            <Route key="urlLogin" Component={LoginContainer} path={appUrl(PAGES.vinhood.signIn)} />

            <Route
              key="urlResetPassword"
              Component={ResetPasswordContainer}
              path={appUrl(PAGES.vinhood.resetPassword)}
            />

            <Route
              key="urlConfirmResetPassword"
              Component={ConfirmResetPasswordContainer}
              path={appUrl(PAGES.vinhood.confirmPasswordReset)}
            />
            <Route
              key="urlRegistrationPart"
              Component={RegistrationContainer}
              path={appUrl(PAGES.vinhood.registration)}
            />
          </Route>

          {/* Public routes */}
          <Route key="urlFoodHabits" Component={FoodHabitsContainer} path={appUrl(PAGES.vinhood.quiz.chooseTaste)} />
          <Route
            key="urlGeneralServiceTermsPart"
            Component={GeneralServiceTermsContainer}
            path={appUrl(PAGES.vinhood.legal.generalServiceTerms)}
          />
          <Route
            key="urlPrivacyPolicyPart"
            Component={PrivacyPolicyContainer}
            path={appUrl(PAGES.vinhood.legal.privacy)}
          />
          <Route
            key="urlCookiePolicyPart"
            Component={CookiePolicyContainer}
            path={appUrl(PAGES.vinhood.legal.cookies)}
          />
          <Route
            key="urlTermsAndConditionsPart"
            Component={TermsConditionsContainer}
            path={appUrl(PAGES.vinhood.legal.termsConditions)}
          />

          {/* ! Conditionally handle multi-product quizzes as public route and rest as protected  */}
          {RouteGroup(QuizContainer, appUrl('/quiz/:quizType/'))}
        </Route>

        {/* No basename */}
        <Route key="urlErrorNotFound" Component={ErrorPageNotFound} path={PAGES.error.notFound} />
      </Routes>
    </Suspense>
  );
};

export default Router;
