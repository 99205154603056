import { FC, useMemo, useState } from 'react';

import { TExperience } from '@lib/core/experiences/types';
import { TParsedProductInstance } from '@lib/core/products/types';
import { TRetailerLocationStoreType } from '@lib/core/retailers/types';
import { getRandomItemFromArray } from '@lib/core/service/utils';
import { IUpdateLocationListParams } from '@lib/core/users/types';
import MixpanelTracker from '@lib/tools/dat/mixpanel';
import { MP_POSITION_CONTEXT } from '@lib/tools/dat/mixpanel/consts';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { localeWidget } from '@lib/tools/locale/source/web/widget';
import { LocaleUtils } from '@lib/tools/locale/utils';
import { PRODUCT_CARD_VARIANTS } from '@lib/tools/shared/helpers/consts';
import { parseTasteMatchLevel } from '@lib/tools/tasteMatch';

import fallback_location_image_1 from '@components/web/src/assets/backgrounds/fallback_location_image_1.png';
import fallback_location_image_2 from '@components/web/src/assets/backgrounds/fallback_location_image_2.png';
import addressIcon from '@components/web/src/assets/icons/explore/address_icon.svg';
import mailIcon from '@components/web/src/assets/icons/explore/mail_icon.svg';
import phoneIcon from '@components/web/src/assets/icons/explore/phone_icon.svg';
import siteIcon from '@components/web/src/assets/icons/explore/site_icon.svg';
import TasteMatchButton from '@components/web/src/atoms/TasteMatchButton/TasteMatchButton';
import TooltipComponent from '@components/web/src/atoms/Tooltips/Tooltip';
import Wishlist from '@components/web/src/atoms/Wishlist/Wishlist';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';
import { ExperienceCard } from '@components/web/src/organisms/Cards/ExperienceCard/ExperienceCard';
import { IMapLocation, MapCard } from '@components/web/src/organisms/Cards/MapCard/MapCard';
import ProductCard from '@components/web/src/organisms/ProductCards/OldProductCard/ProductCard';
import SwiperHOC from '@components/web/src/organisms/Swiper/SwiperHOC';
import * as S from '@components/web/src/templates/ExplorePage/styles';
import { IDiscoveryQuiz } from '@components/web/src/templates/Modals/DiscoveryQuizModal/DiscoveryQuizModal';

interface IProps {
  retailerLocationAddress: string;
  retailerLocationImages: string[];
  retailerLocationId: string;
  retailerLocationPhone: string;
  retailerLocationWebsite: string;
  retailerLocationEmail: string;
  retailerLocationName: string;
  retailerLocationDescription: string;
  retailerLocationSlug: string;
  retailerLocationTasteMatchValue: number | null;
  productCategory: string;
  parsedProductInstanceData: TParsedProductInstance;
  storeType: TRetailerLocationStoreType;
  isUserHasAnyCharacter: boolean;
  isUserAuthenticated: boolean;
  isExplorePageDataLoading: boolean;
  discoveryQuiz: IDiscoveryQuiz;
  experienceData: TExperience[];
  isLocationInWishlist: boolean;
  isLocationListLoading: boolean;
  isCompactVariant: boolean;
  shouldHideTasteMatchButton?: boolean;
  shouldHideAllLocationsSection: boolean;
  mapLocationList: IMapLocation[];
  navigateToCatalogPage: () => void;
  navigateToQuizSelectionPage: () => void;
  navigateToExploreListPage: () => void;
  handleUpdateLocationList: ({ retailerLocationSlug }: IUpdateLocationListParams) => void;
}

export const ExplorePage: FC<IProps> = ({
  retailerLocationAddress = '',
  retailerLocationImages = [],
  retailerLocationPhone = '',
  retailerLocationWebsite = '',
  retailerLocationEmail = '',
  retailerLocationName = '',
  retailerLocationDescription = '',
  retailerLocationId,
  retailerLocationSlug,
  retailerLocationTasteMatchValue,
  productCategory,
  parsedProductInstanceData,
  storeType,
  isUserHasAnyCharacter,
  isExplorePageDataLoading,
  discoveryQuiz,
  experienceData = [],
  isLocationInWishlist,
  isLocationListLoading,
  isCompactVariant,
  shouldHideTasteMatchButton = false,
  mapLocationList,
  shouldHideAllLocationsSection,
  navigateToCatalogPage,
  navigateToExploreListPage,
  navigateToQuizSelectionPage,
  handleUpdateLocationList,
}) => {
  const { titleText, experiencesText, listTitle, listSubtitle, listBtn, productsViewBtn } = localeWidget.explorePage;

  const { productCategoriesPlural, productCategories, productDetails } = localeCommon;
  const { publishedTerms } = LocaleUtils;
  const productCategoryText = localeCommon.productCategories[productCategory];
  const productCategoryPluralText = publishedTerms[productCategoriesPlural[productCategory].id];
  const isShowMapCard = !!mapLocationList?.length && !isExplorePageDataLoading;
  const [isTooltipVisible, setTooltipVisible] = useState(true);

  const contactData = [
    retailerLocationWebsite && {
      href: retailerLocationWebsite,
      icon: siteIcon,
      isSiteContact: true,
      text: retailerLocationWebsite,
    },
    retailerLocationPhone && {
      href: `tel:${retailerLocationPhone}`,
      icon: phoneIcon,
      text: retailerLocationPhone,
    },
    retailerLocationEmail && {
      href: `mailto:${retailerLocationEmail}`,
      icon: mailIcon,
      text: retailerLocationEmail,
    },
    retailerLocationAddress && {
      href: `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(retailerLocationAddress)}`,
      icon: addressIcon,
      isAddressContact: true,
      text: retailerLocationAddress,
    },
  ].filter(Boolean);

  const locationImages = useMemo(
    () =>
      retailerLocationImages.length
        ? retailerLocationImages
        : [getRandomItemFromArray([fallback_location_image_1, fallback_location_image_2])],
    [retailerLocationImages],
  );

  const experienceList = isExplorePageDataLoading ? Array(3).fill({}) : experienceData || [];
  const isSingleExperienceCard = experienceList.length === 1;

  const locationImageList = isExplorePageDataLoading ? Array(3).fill('') : locationImages || [];
  const showExperienceSection = isExplorePageDataLoading || !!experienceData.length;

  return (
    <S.ExplorePageContainer>
      {isShowMapCard && <MapCard mapHeight={144} mapLocationList={mapLocationList} productCategory={productCategory} />}

      <S.ImagesWrapper>
        <SwiperHOC
          showPaginationDots={locationImageList.length > 1}
          slidesPerView={isExplorePageDataLoading ? 'auto' : 1}
          spaceBetween={isExplorePageDataLoading ? 20 : null}
        >
          {locationImageList.map((image, idx) => (
            <S.LocationImage key={idx} $image={image} $isLoading={isExplorePageDataLoading} />
          ))}
        </SwiperHOC>
        <S.WishlistWrapper $isLoading={isExplorePageDataLoading}>
          <Wishlist
            isItemInWishlist={isLocationInWishlist}
            isItemListLoading={isLocationListLoading}
            variant="light"
            handleUpdateWishlist={() => {
              MixpanelTracker.events.locationBookmark({
                listName: '',
                locationBookmarked: isLocationInWishlist,
                locationId: retailerLocationId,
                locationName: retailerLocationName,
                locationPosition: MP_POSITION_CONTEXT.EXPLORE_PAGE,
                locationPositionIndex: null,
                locationSlug: retailerLocationSlug,
              });

              handleUpdateLocationList({ retailerLocationSlug });
            }}
          />
        </S.WishlistWrapper>
      </S.ImagesWrapper>

      <S.ContentWrapper>
        <S.InfoSection>
          <Text
            color={STATIC_COLORS.base.black}
            fontFamily="Fraunces"
            size="h5"
            text={retailerLocationName}
            weight="semibold"
          />
          {retailerLocationDescription && (
            <Text color={STATIC_COLORS.base.black} size="body1" text={retailerLocationDescription} />
          )}
        </S.InfoSection>
        <S.ContactSection>
          <Flexbox direction="column" gap={8}>
            {contactData.map(({ icon, text, isSiteContact = false, isAddressContact = false, href }, idx) => (
              <S.Contact key={idx} $isAddressContact={isAddressContact}>
                <img alt="contact link" src={icon} />
                <a href={href} {...((isSiteContact || isAddressContact) && { target: '_blank' })}>
                  <Text color={STATIC_COLORS.base.black} size="body2" text={text} />
                </a>
              </S.Contact>
            ))}
          </Flexbox>
        </S.ContactSection>
        {parsedProductInstanceData && !shouldHideTasteMatchButton && (
          <TooltipComponent
            isAlwaysOpen={isTooltipVisible}
            placement="top-end"
            title={productDetails.tasteMatchTooltip}
            trigger={
              <TasteMatchButton
                isLocationTasteMatchEvent
                hideTooltip={() => setTooltipVisible(false)}
                navigateToQuizSelectionPage={navigateToQuizSelectionPage}
                retailerLocationId={retailerLocationId}
                retailerLocationName={retailerLocationName}
                retailerLocationSlug={retailerLocationSlug}
                tasteMatchLevel={parseTasteMatchLevel(retailerLocationTasteMatchValue, isUserHasAnyCharacter)}
                tasteMatchValue={retailerLocationTasteMatchValue}
                variant="catalog"
              />
            }
          />
        )}
        {!isCompactVariant && (
          <>
            <S.ProductSection>
              <Text
                color={STATIC_COLORS.base.black}
                fontFamily="Fraunces"
                localeIndex={{ productCategory }}
                localeVariables={{ productCategoryText: productCategories[productCategory] }}
                size="h5"
                text={titleText}
                weight="semibold"
              />

              <ProductCard
                isResponsive
                discoveryQuiz={discoveryQuiz}
                isLoading={isExplorePageDataLoading}
                mixpanelIndex={0}
                mixpanelPositionContext={MP_POSITION_CONTEXT.SWIPER}
                parsedProductInstanceData={parsedProductInstanceData}
                storeType={storeType}
                variant={PRODUCT_CARD_VARIANTS.COMPACT}
              />
            </S.ProductSection>

            <S.Button
              size="md"
              text={productsViewBtn}
              handleClick={() => {
                MixpanelTracker.events.seeAllProducts();
                navigateToCatalogPage();
              }}
              localeOptions={{
                productCategory,
                productCategoryPluralText,
                retailerLocationName,
              }}
            />
          </>
        )}
      </S.ContentWrapper>
      {!isCompactVariant && (
        <>
          {showExperienceSection && (
            <S.ExperienceSection $isSingleCard={experienceData.length === 1}>
              <Text
                color={STATIC_COLORS.base.black}
                fontFamily="Fraunces"
                size="h5"
                text={experiencesText}
                weight="semibold"
              />
              <S.SwiperHOCSection $isSingleCard={isSingleExperienceCard} showPaginationDots={experienceList.length > 1}>
                {experienceList.map((experience, idx) => (
                  <ExperienceCard
                    key={idx}
                    experienceData={experience}
                    isLoading={isExplorePageDataLoading}
                    isResponsive={isSingleExperienceCard}
                  />
                ))}
              </S.SwiperHOCSection>
            </S.ExperienceSection>
          )}

          {!shouldHideAllLocationsSection && (
            <S.CtaWrapper>
              <Flexbox direction="column" gap={0}>
                <Text
                  color={STATIC_COLORS.productColors[productCategory][500]}
                  size="subtitle2"
                  text={listTitle}
                  weight="medium"
                />
                <Text
                  color={STATIC_COLORS.productColors[productCategory][500]}
                  fontFamily="Fraunces"
                  localeIndex={{ productCategory }}
                  localeVariables={{ productCategoryText }}
                  size="h4"
                  text={listSubtitle}
                  textTransform="capitalize"
                  weight="semibold"
                />
              </Flexbox>
              <S.Button
                handleClick={() => navigateToExploreListPage()}
                size="md"
                text={listBtn}
                localeOptions={{
                  retailerName: retailerLocationName,
                }}
              />
            </S.CtaWrapper>
          )}
        </>
      )}
    </S.ExplorePageContainer>
  );
};
