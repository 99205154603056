import { TCharacter } from '@lib/core/characters/types';
import { IParsedCharacter } from '@lib/core/characters/utils/parseCharacter';
import { TProductCategory, TProductInstance } from '@lib/core/products/types';
import { TAssociatedKioskRetailerLocation } from '@lib/core/retailers/types';
import { IRetailerDetail } from '@lib/core/retailers/types/retailerDetail';
import {
  USER_ROLE_ANONYMOUS,
  USER_ROLE_DATABASE_MANAGER,
  USER_ROLE_EMPLOYEE,
  USER_ROLE_KIOSK,
  USER_ROLE_NORMAL,
} from '@lib/core/users/utils/consts';

// ! addons should not in the serialized data for the service launcher kiosk behavior
export type TUserRetailerLocation = {
  associated_kiosk_retailer_location?: TAssociatedKioskRetailerLocation;
  identifier: string;
  slug: string;
  physical_address: string;
  web_address: string;
  coordinates: string;
};

export type TUserRole =
  | typeof USER_ROLE_ANONYMOUS
  | typeof USER_ROLE_NORMAL
  | typeof USER_ROLE_KIOSK
  | typeof USER_ROLE_EMPLOYEE
  | typeof USER_ROLE_DATABASE_MANAGER;
export type TUserGroup = { name: string };

export type TProfileCountryOption = {
  name: string;
  flag: string;
  code: string;
};

export type TProfileGenderOption = {
  name: string;
  slug: string;
};

export type TUser = {
  birth_year: number | null;
  gender: {
    name: string;
    slug: string;
  };
  access_token: string;
  closeBannerForMobile: boolean;
  email: string;
  first_name: string;
  isFetching: boolean;
  is_profiled: Record<string, never> | Record<TProductCategory, boolean>;
  is_staff_member: boolean;
  lastUpdated: number;
  last_name: string;
  pk: number;
  role: TUserRole;
  country: string;
  characters: TCharacter[];
  groups: { name: string }[];
  was_created_new_social_user: boolean;
  is_social_registration: boolean;
  initial_retailer: IRetailerDetail;
  profile_image: string;
  language: string;
  retailer_location: TUserRetailerLocation | null;
  user_session_id: string;
  user_product_preferences: TDietaryPreferenceOption[];
};

export type TProfile = {
  birth_year: number | null;
  country: string;
  identifier: string;
  gender: TProfileGenderOption;
  language: string;
  retailer: { identifier: string; name: string; slug: string } | null;
  retailer_location: {
    associated_kiosk_retailer_location: string | null;
    identifier: string;
    images: string[];
    name: string;
    physical_address: string;
    producer: string | null;
    product_categories: { name: string }[];
    retailer: string;
    slug: string;
    web_address: string;
  } | null;
  user: string | null;
};

export type TProfileOptions = {
  birth_year_range: { max: number; min: number };
  countries: TProfileCountryOption[];
  genders: TProfileGenderOption[];
  languages: [string, string];
};

export interface IProductList {
  list_name: string;
  list_slug: string;
  product: TProductInstance;
}

export interface IGetProductListParams {
  listName: string;
}

export interface IUpdateProductListParams {
  productId: string;
  listName: string;
}

export interface IProductListResponse {
  listData: IProductList[] | null;
  listName: string;
}

export interface IUpdateLocationListParams {
  retailerLocationSlug: string;
}

export interface ILocation {
  retailer_location: {
    identifier: string;
    slug: string;
    name: string;
    retailer: string;
    physical_address: string;
    web_address: string;
    associated_kiosk_retailer_location?: TAssociatedKioskRetailerLocation;
    producer: {
      identifier: string;
      name: string;
      image: string;
    };
    images: string[];
    product_categories?: { name: string }[];
  };
  retailerLocation?: {
    identifier: string;
    slug: string;
    name: string;
    retailer: string;
    physical_address: string;
    web_address: string;
    associated_kiosk_retailer_location?: TAssociatedKioskRetailerLocation;
    producer: {
      identifier: string;
      name: string;
      image: string;
    };
    images: string[];
    product_categories?: { name: string }[];
  };
}

export interface IUpdateProfileRequestBody {
  gender?: string;
  birth_year?: number;
  country?: string;
  language?: string;
}

export interface IUserCharacter {
  character: TCharacter;
  product_category: {
    name: TProductCategory;
  };
}

export type TDietaryPreferenceOption = {
  description: string;
  exposure: string;
  name: string;
  slug: string;
};

export type TDietaryPreference = {
  dietary_preference: TDietaryPreferenceOption;
};

export type TDietaryPreferenceOptionsResponse = {
  choices: { dietary_preferences: TDietaryPreferenceOption[] };
  description?: string;
  name?: string;
  parses?: string;
  renders?: string;
};

export interface IUpdateDietaryPreferenceParams {
  slugToUpdate: string;
  exposureToUpdate: string;
}

export interface IUpdateMultipleDietaryPreferenceParams {
  slugsToUpdate: string[];
  exposureToUpdate: string;
}

export const USER_DATA_FIELDS = {
  QUIZ: 'quiz',
} as const;

export type TUserDataField = (typeof USER_DATA_FIELDS)[keyof typeof USER_DATA_FIELDS];

export type TAnonymousUserData = {
  profileId: string;
  character: IParsedCharacter;
  isLoading: boolean;
};
