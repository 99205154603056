import { FC } from 'react';

import { ExtendedAllProductCategoryCode } from '@lib/core/service/types/interface';
import { localeApp } from '@lib/tools/locale/source/web/app';
import { LocaleFragment } from '@lib/tools/locale/views/LocaleFragment';
import { getQueryValue } from '@lib/tools/shared/helpers';
import {
  PRODUCT_ALL,
  PRODUCT_CATEGORY_BEER,
  PRODUCT_CATEGORY_COFFEE,
  PRODUCT_CATEGORY_WINE,
} from '@lib/tools/shared/helpers/consts';

import { BannerCard, TBannerCard } from '@components/web/src/atoms/Banner/BannerCard';
import { SectionHeader } from '@components/web/src/atoms/Headers/SectionHeader/SectionHeader';
import Tabs from '@components/web/src/atoms/Tabs/Tabs/Tabs';
import { PRODUCT_CATEGORY_QUERY_KEY } from '@components/web/src/foundations/consts';

interface IProps {
  bannersList: TBannerCard[];
  activeTab: ExtendedAllProductCategoryCode;
  handleRedirectToForm?: (route?: string, linkParams?: string) => void;
  handleTabClick?: (selectedTabCode) => void;
}

export const EdutainmentPage: FC<IProps> = ({ bannersList, activeTab, handleRedirectToForm, handleTabClick }) => {
  const {
    productCatalogPage: { productTabAllTitle, productTabWineTitle, productTabBeerTitle, productTabCoffeeTitle },
    edutainmentPage: { heading, description },
  } = localeApp;

  const tabs = [
    { tabCode: PRODUCT_ALL, tabText: productTabAllTitle },
    { tabCode: PRODUCT_CATEGORY_WINE, tabText: productTabWineTitle },
    { tabCode: PRODUCT_CATEGORY_BEER, tabText: productTabBeerTitle },
    { tabCode: PRODUCT_CATEGORY_COFFEE, tabText: productTabCoffeeTitle },
  ];

  const getProductCategoryText = productCategory => tabs.find(({ tabCode }) => tabCode === productCategory).tabText;
  return (
    <div className="edutainment-page-container">
      <SectionHeader className="section-header" subtitle={description} title={heading} />
      <Tabs active={activeTab} tabs={tabs} onTabClick={handleTabClick} />
      <div className="banner-list-wrapper">
        {bannersList.map(({ identifier, text, link, linkParams, currentPosition }, idx) => {
          const productCategory = getQueryValue(linkParams, PRODUCT_CATEGORY_QUERY_KEY);

          return (
            <BannerCard
              key={idx}
              className="edutainment-banner"
              currentPosition={currentPosition}
              identifier={identifier}
              link={link}
              linkParams={linkParams}
              jsxText={() => (
                <div className={`banner-card-text ${productCategory}`}>
                  <span className="text-uppercase">
                    <LocaleFragment message={getProductCategoryText(productCategory)} />
                  </span>
                  <br />
                  <strong>
                    <LocaleFragment message={text} />
                  </strong>
                </div>
              )}
              onClickHandler={handleRedirectToForm}
            />
          );
        })}
      </div>
    </div>
  );
};
