import { Input } from '@mui/base';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useRemoteAccess } from '@lib/core/retailers/hooks/remoteAccess';
import { useRetailer } from '@lib/core/retailers/hooks/retailer';
import { useRetailerLocation } from '@lib/core/retailers/hooks/retailerLocation';
import {
  actionGenerateRemoteAccessKeyDevTools,
  actionResetRemoteAccess,
} from '@lib/core/retailers/slices/remoteAccess';
import { useApp } from '@lib/core/service/hooks';
import { uuidv4 } from '@lib/core/service/utils';
import { useUser } from '@lib/core/users/hooks';
import LoginForm from '@lib/tools/devtools/components/forms/LoginForm';
import { Button } from '@lib/tools/devtools/components/inputs/Button';
import { IRemoteAccessTableDataRow } from '@lib/tools/devtools/components/interfaces';
import { TableComponent } from '@lib/tools/devtools/components/table';
import { themeDevTools } from '@lib/tools/devtools/components/theme';
import RemoteAccessUtils from '@lib/tools/devtools/panels/remoteAccess';
import { RouteUtils } from '@lib/tools/routes';
import { PAGES } from '@lib/tools/views/urls';

export const RemoteAccessPanel = () => {
  const dispatch = useDispatch();

  useRetailerLocation();
  const { userGroups, isUserAuthenticated } = useUser();
  const { retailerId, retailerSlug } = useRetailer();
  const { locale, productCategory } = useApp();
  const { remoteAccessLinks } = useRemoteAccess();

  const [tableData, setTableData] = useState<IRemoteAccessTableDataRow[]>();
  const [numberOfRetries, setNumberOfRetries] = useState('');
  const [expiryYear, setExpiryYear] = useState('');
  const [expiryMonth, setExpiryMonth] = useState('');
  const [expiryDay, setExpiryDay] = useState('');
  const [customIds, setCustomIds] = useState('');
  const [customIdArray, setCustomIdArray] = useState([]);

  const isUserLinkGenerator = userGroups?.some(userGroup => userGroup?.name === 'Link Generator');

  const handleLinkGeneration = () => {
    if (customIdArray.length) {
      customIdArray.forEach(cid => {
        dispatch(
          actionGenerateRemoteAccessKeyDevTools({
            custom_id: `${cid}_${uuidv4()}`,
            retailer_id: retailerId,
            retries: numberOfRetries,
            /* YYYY-MM-DDTHH:MM:SS+00:00 */
            timestamp: `${expiryYear}-${expiryMonth}-${expiryDay}T00:00:00+00:00`,
          }),
        );
      });
    } else {
      console.error('Enter valid custom ids');
    }
  };

  useEffect(() => {
    if (customIds.length) setCustomIdArray(customIds.split(','));
    else setCustomIdArray([]);
  }, [customIds]);

  useEffect(() => {
    const remoteLinksTableData = [];

    remoteAccessLinks.forEach(link => {
      const remoteLink = new URL(
        `${window.location.origin}/${locale}/${retailerSlug}/p/${productCategory}${PAGES.ta.landingRemote}`,
      );

      remoteLink.searchParams.append(RouteUtils.remoteAccessParams.accessKey, link.access_key);
      remoteLink.searchParams.append(RouteUtils.remoteAccessParams.expiry, link.expiry);
      remoteLink.searchParams.append(RouteUtils.remoteAccessParams.retries, `${link.retries}`);
      remoteLink.searchParams.append(RouteUtils.remoteAccessParams.customId, link.custom_id);

      remoteLinksTableData.push({
        customId: link.custom_id,
        link: remoteLink.toString(),
      });
    });

    setTableData(remoteLinksTableData);
  }, [remoteAccessLinks]);

  return (
    <>
      {/* ? Allow force RTL */}
      <div className="panel collapsible" role="presentation">
        Link Generator
      </div>

      <Box sx={{ margin: themeDevTools.spacing.medium }}>
        {!isUserAuthenticated && <LoginForm />}

        {isUserAuthenticated && isUserLinkGenerator && (
          <>
            <TextareaAutosize
              aria-label="Custom IDs"
              minRows={3}
              placeholder="Custom IDs"
              onChange={e => setCustomIds(e.target.value)}
            />

            <Input
              aria-label="Expiry Year"
              placeholder="Expiry year"
              style={{
                paddingBottom: 10,
              }}
              onChange={e => setExpiryYear(e.target.value)}
            />
            <Input
              aria-label="Expiry Month"
              placeholder="Expiry month"
              style={{
                paddingBottom: 10,
              }}
              onChange={e => setExpiryMonth(e.target.value)}
            />
            <Input
              aria-label="Expiry Day"
              placeholder="Expiry day"
              style={{
                paddingBottom: 10,
              }}
              onChange={e => setExpiryDay(e.target.value)}
            />
            <Input
              aria-label="Retries"
              placeholder="Retries"
              style={{
                paddingBottom: 10,
              }}
              onChange={e => setNumberOfRetries(e.target.value)}
            />

            <Button onClick={handleLinkGeneration}>Generate</Button>

            {remoteAccessLinks.length ? (
              <>
                <Button onClick={() => dispatch(actionResetRemoteAccess())}>Reset</Button>
                <Button onClick={() => RemoteAccessUtils.exportTerms(tableData)}>Export</Button>

                <TableComponent
                  defaultRowsPerPage={-1}
                  rowData={tableData}
                  rowType="RemoteAccessRow"
                  tableHeadInfo={['custom_id', 'initial_link']}
                />
              </>
            ) : null}
          </>
        )}
      </Box>
    </>
  );
};
