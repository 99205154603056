import { FC } from 'react';

import { TBanner } from '@lib/core/banners/types';
import {
  IProductFeedbackAnswer,
  IProductFeedbackQuestion,
  ProductFeedbackSteps,
} from '@lib/core/products/slices/productFeedback';
import { TParsedProductInstance } from '@lib/core/products/types';
import { localeWidget } from '@lib/tools/locale/source/web/widget';

import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';
import { ProductFeedbackCommentCard } from '@components/web/src/organisms/ProductFeedback/ProductFeedbackCommentCard/ProductFeedbackCommentCard';
import { ProductFeedbackQuestionsCard } from '@components/web/src/organisms/ProductFeedback/ProductFeedbackQuestionsCard/ProductFeedbackQuestionsCard';
import { ProductFeedbackRatingCard } from '@components/web/src/organisms/ProductFeedback/ProductFeedbackRatingCard/ProductFeedbackRatingCard';
import { LoadingSpinner } from '@components/web/src/templates/Loading/LoadingSpinner/LoadingSpinner';
import { ProductFeedbackAnswerPage } from '@components/web/src/templates/ProductFeedback/ProductFeedbackAnswerPage/ProductFeedbackAnswerPage';
import * as S from '@components/web/src/templates/ProductFeedback/ProductFeedbackQuestionPage/styles';

interface IProps {
  parsedProductInstanceData: TParsedProductInstance;
  productFeedbackCurrentQuestion: IProductFeedbackQuestion;
  isLoading?: boolean;
  handleSkip: () => void;
  handleSubmitAnswer: ({ answer }: { answer: IProductFeedbackAnswer }) => void;
  handleProductFeedbackAnswerDone: ({ questionId }: { questionId: string }) => void;
  handleSubmitProductFeedbackAnswers: () => Promise<void>;
  handleClose: (args?: {
    productFeedbackModalStep?: ProductFeedbackSteps;
    parsedProductInstanceData?: TParsedProductInstance;
  }) => void;
  productFeedbackStep: number;
  isProductFeedbackAnswersSubmitLoading?: boolean;
  isExitAwarenessModalOpen?: boolean;
  questionsAmount: number;
  banners: TBanner[];
  subtitle?: string;
}

export const ProductFeedbackQuestionPage: FC<IProps> = ({
  handleSubmitAnswer,
  handleSkip,
  handleProductFeedbackAnswerDone,
  productFeedbackStep,
  questionsAmount,
  productFeedbackCurrentQuestion,
  parsedProductInstanceData,
  banners,
  isProductFeedbackAnswersSubmitLoading,
  subtitle,
  handleSubmitProductFeedbackAnswers,
  isLoading,
}) => {
  const { questionPageDescription, questionHeaderTitle } = localeWidget.productFeedback;

  const {
    isCommentUpdateLoading,
    productCategory,
    productName,
    handleUpdateProductRating,
    productRate,
    isProductRateUpdateLoading,
    productRateOptions,
  } = parsedProductInstanceData;

  if (productFeedbackCurrentQuestion?.answer) {
    return (
      <ProductFeedbackAnswerPage
        answer={productFeedbackCurrentQuestion.answer.answer}
        handleProductFeedbackAnswerDone={handleProductFeedbackAnswerDone}
        parsedProductInstanceData={parsedProductInstanceData}
        productFeedbackCurrentQuestion={productFeedbackCurrentQuestion}
        questionId={productFeedbackCurrentQuestion.identifier}
      />
    );
  }

  if (isLoading && productFeedbackStep === ProductFeedbackSteps.Questions) {
    return (
      <div>
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <S.ProductFeedbackQuestionPageContainer>
      <S.ProductFeedbackQuestionHeader>
        <Flexbox align="center" direction="column" gap={8}>
          <Text color={STATIC_COLORS.base.white} size="body2" text={questionHeaderTitle} weight="bold" />
          <Text color={STATIC_COLORS.base.white} size="subtitle1" text={productName} weight="bold" />
          <Text color={STATIC_COLORS.base.white} size="body2" text={subtitle} />
          <Flexbox isFullWidth gap={8} justify="center" padding="5px 0 0">
            {new Array(questionsAmount + 2).fill(null).map((_, index) => (
              <S.StyledDot
                key={index}
                $isCurrentDotStyle={
                  productFeedbackCurrentQuestion?.spot === index + 1 ||
                  (productFeedbackStep === ProductFeedbackSteps.Rating && index === questionsAmount) ||
                  (productFeedbackStep === ProductFeedbackSteps.Comment && index === questionsAmount + 1)
                }
              />
            ))}
          </Flexbox>
        </Flexbox>
      </S.ProductFeedbackQuestionHeader>
      <S.QuestionHeaderWrapper>
        <S.StyledBlockOne />
        <S.StyledBlockTwo />
        <>
          {productFeedbackCurrentQuestion &&
            !productFeedbackCurrentQuestion.answer &&
            productFeedbackStep === ProductFeedbackSteps.Questions && (
              <ProductFeedbackQuestionsCard
                answers={productFeedbackCurrentQuestion.answers}
                banner={banners[productFeedbackCurrentQuestion?.spot - 1]}
                handleSubmitAnswer={handleSubmitAnswer}
                isProductFeedbackAnswersSubmitLoading={isProductFeedbackAnswersSubmitLoading}
                productCategory={productCategory}
                productFeedbackCurrentQuestion={productFeedbackCurrentQuestion}
              />
            )}
          {productFeedbackStep === ProductFeedbackSteps.Rating && (
            <ProductFeedbackRatingCard
              handleSkip={handleSkip}
              handleUpdateProductRating={handleUpdateProductRating}
              isProductRateUpdateLoading={isProductRateUpdateLoading}
              parsedProductInstanceData={parsedProductInstanceData}
              productCategory={productCategory}
              productRate={productRate}
              productRateOptions={productRateOptions}
            />
          )}
          {productFeedbackStep === ProductFeedbackSteps.Comment && (
            <ProductFeedbackCommentCard
              handleSkip={handleSkip}
              handleSubmitProductFeedbackAnswers={handleSubmitProductFeedbackAnswers}
              isLoading={isCommentUpdateLoading || isProductFeedbackAnswersSubmitLoading}
              parsedProductInstanceData={parsedProductInstanceData}
            />
          )}
        </>
      </S.QuestionHeaderWrapper>
      {productFeedbackStep === ProductFeedbackSteps.Questions && (
        <Flexbox>
          <Text color={STATIC_COLORS.base.white} size="body1" text={questionPageDescription} />
        </Flexbox>
      )}
    </S.ProductFeedbackQuestionPageContainer>
  );
};
