import { actionGetUserCharacters } from '@lib/core/users/slices/characters';
import { actionCarryAnonymousUserData } from '@lib/core/users/slices/user';
import { TUserDataField, USER_DATA_FIELDS } from '@lib/core/users/types';
import { useTypedDispatch } from '@lib/tools/views/hooks/useTypedDispatch';

export const useCarryUserData = () => {
  const dispatch = useTypedDispatch();

  const dispatchActionMap: Record<TUserDataField, () => any> = {
    [USER_DATA_FIELDS.QUIZ]: actionGetUserCharacters,
  };

  // Copies selected user data fields from one profile to another
  const carryUserData = async (fields: TUserDataField[]) => {
    // Send request to copy data from anonymous profile to current user
    await dispatch(actionCarryAnonymousUserData(fields));

    // Refresh only the parts of user data that were copied
    const promises = fields.map(field => dispatch(dispatchActionMap[field]()));
    await Promise.all(promises);
  };

  return { carryUserData };
};
