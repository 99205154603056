import { FC, HTMLAttributes, useEffect, useState } from 'react';

import { testConnectionQuality } from '@lib/core/service/utils';
import MixpanelTracker from '@lib/tools/dat/mixpanel';
import { localeApp } from '@lib/tools/locale/source/web/app';

import loadingGIF from '@components/web/src/assets/gif/loading.gif';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';
import * as S from '@components/web/src/templates/Loading/LoadingSpinner/styles';
import { LowSpeedWarning } from '@components/web/src/templates/Loading/LowSpeedWarning/LowSpeedWarning';
import { ScanFidelityCardWarning } from '@components/web/src/templates/Loading/ScanFidelityCardWarning/ScanFidelityCardWarning';

export const LOADING_SPINNER_VARIANTS = {
  FULL_TEXT: 'fullText',
  LOGIN: 'login',
  SPINNER: 'spinner',
  TRANSPARENT: 'transparent',
} as const;

export const SLOW_PAGE_SCENARIOS = {
  isLowSpeedAPI: 'isLowSpeedAPI',
  isLowSpeedConnection: 'isLowSpeedConnection',
} as const;

export type LoadingSpinnerVariant = (typeof LOADING_SPINNER_VARIANTS)[keyof typeof LOADING_SPINNER_VARIANTS];

interface IProps extends HTMLAttributes<HTMLDivElement> {
  variant?: LoadingSpinnerVariant;
  isScannerLoading?: boolean;
}

export const LoadingSpinner: FC<IProps> = ({
  variant = LOADING_SPINNER_VARIANTS.SPINNER,
  isScannerLoading,
  ...rest
}) => {
  const { textOne, textTwo } = localeApp.loadingScreen;
  const isWithNoText = variant === LOADING_SPINNER_VARIANTS.SPINNER || variant === LOADING_SPINNER_VARIANTS.TRANSPARENT;
  const [slowPageScenario, setSlowPageScenario] = useState(null);

  useEffect(() => {
    const lowSpeedTimer = setTimeout(async () => {
      const scenario = await testConnectionQuality();
      setSlowPageScenario(scenario);
      MixpanelTracker.events.trackSlowConnection();
    }, 4000);

    return () => clearTimeout(lowSpeedTimer);
  }, []);

  return (
    <S.LoadingSpinnerContainer $isTransparent={variant === LOADING_SPINNER_VARIANTS.TRANSPARENT} {...rest}>
      <div />
      <Flexbox align="center" direction="column" gap={10}>
        <img alt="loading" src={loadingGIF} />
        {!isWithNoText && (
          <S.TextContainer align="center" direction="column" gap={8}>
            <Text size="subtitle1" text={textOne} textAlign="center" />
            {variant === LOADING_SPINNER_VARIANTS.FULL_TEXT && (
              <Text size="subtitle1" text={textTwo} textAlign="center" weight="semibold" />
            )}
          </S.TextContainer>
        )}
        {slowPageScenario && !isScannerLoading && <LowSpeedWarning slowPageScenario={slowPageScenario} />}
        {isScannerLoading && <ScanFidelityCardWarning />}
      </Flexbox>
    </S.LoadingSpinnerContainer>
  );
};
