import { zodResolver } from '@hookform/resolvers/zod';
import { FC, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { localeApp } from '@lib/tools/locale/source/web/app';
import { LocaleFragment } from '@lib/tools/locale/views/LocaleFragment';

import Tabs, { ITabs } from '@components/web/src/atoms/Tabs/Tabs/Tabs';
import InputField from '@components/web/src/organisms/Input/InputFields';
import { GlobalSearchSkeleton } from '@components/web/src/organisms/Skeleton/GlobalSearchSkeleton/GlobalSearchSkeleton';

interface IProps {
  searchedQuery?: string;
  tabsData: ITabs;
  isLoading?: boolean;
  handleSearchSubmit?: (searchedQuery: string) => void;
}

export const GlobalSearchPage: FC<IProps> = ({ searchedQuery, tabsData, isLoading, handleSearchSubmit }) => {
  const validationSchema = useMemo(() => {
    return z.object({
      searchField: z.string(),
    });
  }, []);
  const { register, handleSubmit } = useForm({
    mode: 'onChange',
    resolver: zodResolver(validationSchema),
    values: {
      searchField: searchedQuery,
    },
  });

  const onSearchSubmit = data => {
    const { searchField } = data;
    handleSearchSubmit(searchField);
  };

  const { pageHeading } = localeApp.globalSearchPage;

  return (
    <div className="global-search-page-container">
      <div className="page-header">
        <p className="title">
          <LocaleFragment message={pageHeading} />
        </p>
        <form noValidate autoComplete="off" onSubmit={handleSubmit(onSearchSubmit)}>
          <InputField className="search-modal-input" name="searchField" {...register('searchField')} />
        </form>
      </div>
      {isLoading ? <GlobalSearchSkeleton /> : <Tabs tabsVariant="autoSpaced" {...tabsData} />}
    </div>
  );
};

export default GlobalSearchPage;
