import { FC } from 'react';
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import { IParsedCharacter } from '@lib/core/characters/utils/parseCharacter';

import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { CharacterScrollerItem } from '@components/web/src/organisms/CharacterScroller/CharacterScrollerItem';
import * as S from '@components/web/src/organisms/CharacterScroller/styles';
import { Skeleton } from '@components/web/src/organisms/Skeleton/Skeleton';

interface IProps {
  charactersData: IParsedCharacter[];
  isCharacterDataLoading: boolean;
}

export const CharacterScroller: FC<IProps> = ({ charactersData, isCharacterDataLoading }) => {
  const isSingleCardView = charactersData?.length === 1;
  const slidesPerView = isSingleCardView ? 1 : 3;
  const showCharacterSwiper = !!charactersData?.length && !isCharacterDataLoading;

  return (
    <>
      {isCharacterDataLoading && (
        <Flexbox isFullWidth align="center" justify="center" padding="0 16px">
          <Skeleton borderRadius="8px" margin="20px 20px 20px 0" minHeight="308px" width="100%" />
        </Flexbox>
      )}

      {showCharacterSwiper && (
        <S.CharacterScrollerContainer>
          <Swiper
            centeredSlides
            autoplay={{ delay: 3000 }}
            modules={[Autoplay]}
            slidesPerView={slidesPerView}
            spaceBetween={140}
          >
            {charactersData.map((character, idx) => (
              <SwiperSlide key={idx}>
                {({ isActive }) => <CharacterScrollerItem character={character} isActive={isActive} />}
              </SwiperSlide>
            ))}
          </Swiper>
        </S.CharacterScrollerContainer>
      )}
    </>
  );
};
