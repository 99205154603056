import { localeCommon } from '@lib/tools/locale/source/web/common';

import { CloseButton } from '@components/web/src/atoms/Buttons/CloseButton/CloseButton';
import { Text } from '@components/web/src/foundations/Text/Text';
import * as S from '@components/web/src/templates/Modals/DownloadAppModal/styles';
import ModalWrapper from '@components/web/src/templates/Modals/ModalWrapper/ModalWrapper';

interface IProps {
  hideModal: () => void;
  isModalOpen: boolean;
}

const DownloadAppModal = ({ hideModal, isModalOpen }: IProps) => {
  const { modalTitle, modalDescription } = localeCommon.downloadApp;

  return (
    <ModalWrapper maxContentHeight hideModal={hideModal} isOpen={isModalOpen}>
      <S.DownloadAppModalWrapper direction="column" gap={16}>
        <CloseButton handleClick={hideModal} />
        <Text fontFamily="Fraunces" size="h6" text={modalTitle} weight="semibold" />
        <Text size="body1" text={modalDescription} weight="medium" />
      </S.DownloadAppModalWrapper>
    </ModalWrapper>
  );
};

export default DownloadAppModal;
