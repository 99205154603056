import styled, { keyframes } from 'styled-components';

import { ReactComponent as ValidatorIcon } from '@components/web/src/assets/icons/validator.svg';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';

const fadeInAnimation = () => keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const ScanBarcodeContainer = styled(Flexbox)`
  position: absolute;
  z-index: 99999;
  inset: 0;
  overflow-y: scroll;
  scrollbar-width: none;
  background: ${STATIC_COLORS.base.white};
  animation: ${fadeInAnimation} 0.3s ease-out;
`;

export const ScannerWrapper = styled.div`
  position: relative;

  div {
    svg {
      // to hide default validator svg
      display: none;
    }

    video {
      background-color: rgb(0 0 0 / 5%);
    }
  }
`;

export const CustomValidatorIcon = styled(ValidatorIcon)<{ $isError: boolean }>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  path {
    stroke: ${({ $isError }) => ($isError ? STATIC_COLORS.base.red : STATIC_COLORS.base.white)};
  }
`;

export const BannerWrapper = styled(Flexbox)`
  margin-top: auto;
  border-radius: 16px 16px 0px 0px;
  background: ${STATIC_COLORS.teal[50]};
`;

export const ProductImage = styled.img`
  width: 102px;
  height: 102px;
`;
