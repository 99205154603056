import { FC } from 'react';

import { localeWidget } from '@lib/tools/locale/source/web/widget';

import noProductsAvailableGif from '@components/web/src/assets/gif/no_products_available.gif';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Text } from '@components/web/src/foundations/Text/Text';
import * as S from '@components/web/src/organisms/Catalog/NoProductsAvailable/styles';

const NoProductsAvailable: FC = () => {
  const { titleText } = localeWidget.noAvailableProductsCatalog;

  return (
    <S.NoProductsAvailableWrapper align="center" direction="column" justify="center" padding="16px">
      <Text
        color={STATIC_COLORS.gray['900']}
        fontFamily="Fraunces"
        size="h6"
        text={titleText}
        textAlign="center"
        weight="semibold"
      />
      <S.ImageWrapper style={{ backgroundImage: `url('${noProductsAvailableGif}')` }} />
    </S.NoProductsAvailableWrapper>
  );
};

export default NoProductsAvailable;
