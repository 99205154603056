import { css, styled } from 'styled-components';

import { TProductCategory } from '@lib/core/products/types';

import Button from '@components/web/src/atoms/Buttons/Button';
import { STATIC_COLORS, STATIC_GRADIENTS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Image } from '@components/web/src/foundations/Image/Image';
import { Text } from '@components/web/src/foundations/Text/Text';

export const ProductCardContainer = styled(Flexbox)<{
  $productCategory: TProductCategory;
  $isProductFeedbackFinished: boolean;
  $isCardClickDisabled: boolean;
  $isResponsive: boolean;
}>`
  width: ${({ $isResponsive }) => ($isResponsive ? '100%' : '164px')};
  border-radius: 16px;
  position: relative;
  overflow: hidden;
  pointer-events: ${({ $isCardClickDisabled }) => ($isCardClickDisabled ? 'none' : 'auto')};
  background: ${({ $isProductFeedbackFinished, $productCategory }) =>
    $isProductFeedbackFinished ? STATIC_GRADIENTS.characterGradients[$productCategory] : STATIC_COLORS.warmGray[100]};
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.2);
`;

export const ProductImageWrapper = styled(Flexbox)`
  position: relative;
`;

export const ProductImage = styled(Image)<{ $isProductFeedbackEnabled: boolean }>`
  ${({ $isProductFeedbackEnabled }) =>
    $isProductFeedbackEnabled &&
    css`
      background: transparent;
      filter: grayscale(1) opacity(0.2);
    `}
`;

export const ProductFeedbackIconWrapper = styled(Flexbox)`
  position: absolute;
  width: 48px;
  height: 48px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

  img {
    width: 32px;
    height: 32px;
  }
`;

export const PromoLabel = styled.div`
  position: absolute;
  z-index: 1;
  top: 10px;
  left: -30px;
  width: 100px;
  height: 20px;
  padding: 3px 0;
  background-color: ${STATIC_COLORS.base.red};
  transform: rotate(-45deg);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

export const CTAContainer = styled(Flexbox)`
  position: absolute;
  right: 0;
  top: 0;
`;

export const CharacterTitle = styled(Text)<{ $productCategory: TProductCategory }>`
  strong {
    color: ${({ $productCategory }) => STATIC_COLORS.productColors[$productCategory]['500'] ?? 'inherit'};
  }
`;

export const ProductCardCTA = styled(Button)`
  padding: 0;

  span {
    line-height: 18px;
    text-decoration: underline;
  }
`;

export const FindProductWrapper = styled.div`
  width: 100%;
  min-height: 28px;

  button {
    padding: 4px 14px;
    box-shadow: none;
    border-width: 0px;
    min-height: 28px;
  }
`;
