import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useQuiz, useQuizView, useQuizzes, useUserQuiz, useUserQuizType } from '@lib/core/quizzes/hooks';
import { useSituationalQuiz } from '@lib/core/quizzes/hooks/useSituationalQuiz';
import { TQuizAnswerData } from '@lib/core/quizzes/types';
import QuizUtils from '@lib/core/quizzes/utils';
import { QUIZ_TYPE_RECIPE, QUIZ_TYPE_TASTE } from '@lib/core/quizzes/utils/consts';
import {
  isAnswerTagFilterCharacterContext,
  isAnswerTagProductCategoryContext,
  isAnswerTagProductPreferencesContext,
  isAnswerTagRedirectRouteContext,
} from '@lib/core/quizzes/utils/filters';
import QuizLauncher from '@lib/core/quizzes/views/QuizLauncher';
import { useRetailer } from '@lib/core/retailers/hooks/retailer';
import {
  DISABLE_USER_CHARACTER_TOGGLE_URL_PARAM,
  IS_FROM_SITUATIONAL_TEST_URL_PARAM,
  PRODUCT_PREFERENCES_URL_PARAM,
  isApplicationKiosk,
} from '@lib/core/service/consts';
import { useApp } from '@lib/core/service/hooks';
import { selectServiceProductCategory } from '@lib/core/service/selectors';
import { prependBasename } from '@lib/core/service/utils';
import { useUser } from '@lib/core/users/hooks';
import {
  ALL_PRODUCTS_TAB,
  CHARACTERS_URL_PARAM,
  PREFERENCES_URL_PARAM,
  PRODUCT_CATEGORY_URL_PARAM,
  SITUATIONAL_PRESELECTED_URL_PARAM,
  TAB_URL_PARAM,
} from '@lib/tools/shared/helpers/consts';
import { isQuizAnswerIncludesShuffleAnswerTag } from '@lib/tools/shared/utils/quizzes/answerTags';
import { useEffectSkipFirst } from '@lib/tools/views/hooks';
import { useCustomStyles } from '@lib/tools/views/hooks/useCustomStyles';
import { PAGES } from '@lib/tools/views/urls';

import { LoadingSpinner } from '@components/web/src/templates/Loading/LoadingSpinner/LoadingSpinner';
import QuizPage from '@components/web/src/templates/TestFlowsPages/QuizPage/QuizPage';

const QuizContainer: any = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { isDevToolsEnabled } = useApp();
  const customStyles = useCustomStyles();
  const { isAnonymousRetailerAccess } = useRetailer();

  const { isQuizFetching, quizPath, quizFirstQuestionId } = useQuiz();
  const { userQuizId, isUserQuizComplete, isUserQuizLoading, userQuizCharacters, userQuizType } = useUserQuiz();

  const { isQuizzesFetching, quizzes } = useQuizzes();
  const { isUserAuthenticated, userBestMatchCharacterForCurrentServiceProductCategory } = useUser();
  const {
    quizViewData: quizViewQuestion,
    quizViewAnswerTags,
    handleQuizViewGoBack,
    handleQuizViewSubmitAnswer,
    quizViewQuestionId,
    isQuizViewCompleted,
  } = useQuizView();

  const {
    isUserQuizTypeSituational,
    isUserQuizTypeExpert,
    isUserQuizTypeRecipe,
    isUserQuizTypeTaste,
    isUserQuizTypeMultiProduct,
  } = useUserQuizType();

  const { isSituationalQuizDropQuestion, situationalQuizSourcePage } = useSituationalQuiz();

  const preselectedAnswers = QuizUtils.getPreselectedAnswerIds();

  const [shuffledAnswers, setShuffledAnswers] = useState<TQuizAnswerData[]>([]);
  const [initialPreselectedAnswers, setInitialPreselectedAnswers] = useState([]);

  const [quizViewProgress, setQuizViewProgress] = useState(0);
  const productCategory = useSelector(selectServiceProductCategory);
  const isUrlParamsIncludesQuizTypeTaste = window.location.href.includes(QUIZ_TYPE_TASTE);
  const isUrlParamsIncludesQuizTypeRecipe = window.location.href.includes(QUIZ_TYPE_RECIPE);
  const isLoading =
    !userQuizType ||
    isQuizzesFetching ||
    isQuizFetching ||
    isQuizViewCompleted ||
    !quizViewQuestion ||
    !userQuizId ||
    preselectedAnswers.length;

  useEffect(() => {
    if (
      isUrlParamsIncludesQuizTypeTaste &&
      !isQuizzesFetching &&
      !QuizUtils.isTasteQuizSpecificPreferencesAvailable(searchParams.get(PREFERENCES_URL_PARAM))
    ) {
      navigate(
        prependBasename(PAGES.vinhood.quiz.chooseTaste, {
          [PRODUCT_CATEGORY_URL_PARAM]: productCategory,
        }),
      );
    }

    if (isUrlParamsIncludesQuizTypeRecipe && !isLoading && !QuizUtils.getQuiz(QUIZ_TYPE_RECIPE)) {
      navigate(
        prependBasename(PAGES.vinhood.recipeSearch, {
          [PRODUCT_CATEGORY_URL_PARAM]: productCategory,
        }),
      );
    }
  }, [isQuizzesFetching]);

  useEffect(() => {
    if (preselectedAnswers?.length && !initialPreselectedAnswers.length) {
      setInitialPreselectedAnswers(preselectedAnswers || []);
    }
  }, [preselectedAnswers]);

  /**
   * * Progress bar
   */
  useEffect(() => {
    setQuizViewProgress(QuizUtils.getProgressPercentage());
  }, [quizViewQuestionId]);

  const isFirstQuestion = quizFirstQuestionId === quizViewQuestionId;

  const userQuizCharacter = userQuizCharacters[0];

  const onAnswerSelectHandler = (answerId: string) => {
    handleQuizViewSubmitAnswer([answerId]);
  };

  /**
   * * Handle preselected answers from url params
   */
  useEffectSkipFirst(() => {
    if (userQuizId && preselectedAnswers.length && quizViewQuestionId && userQuizType) {
      const answerIds = quizViewQuestion.answers.map(answer => Object.keys(answer)[0]);
      preselectedAnswers.forEach(preselectedAnswer => {
        const isPreselectedAnswerIdValid = !!quizPath[quizViewQuestionId]?.answers[preselectedAnswer];
        if (answerIds.includes(preselectedAnswer) && isPreselectedAnswerIdValid) {
          onAnswerSelectHandler(preselectedAnswer);
          const newPreselectedString = preselectedAnswers.filter(answerId => answerId !== preselectedAnswer).join(',');

          if (newPreselectedString) {
            searchParams.set(
              SITUATIONAL_PRESELECTED_URL_PARAM,
              preselectedAnswers.filter(answerId => answerId !== preselectedAnswer).join(','),
            );
          } else {
            searchParams.delete(SITUATIONAL_PRESELECTED_URL_PARAM);
          }

          setSearchParams(searchParams);
        }
      });
    }
  }, [userQuizId, quizViewQuestionId, userQuizType]);

  /**
   * * Redirection cases after quiz completion
   * ! Improve answerTag handing with context. Move params to state to reduce url param proliferation.
   */
  useEffectSkipFirst(() => {
    if (isUserQuizComplete) {
      if (isUserQuizTypeTaste || isUserQuizTypeExpert || isUserQuizTypeMultiProduct) {
        const getRedirectPage = () => {
          if (isApplicationKiosk) {
            return PAGES.vinhood.quizResults.result;
          }
          if (isUserAuthenticated || isAnonymousRetailerAccess) {
            return PAGES.vinhood.catalog;
          }
          return PAGES.vinhood.registration;
        };

        navigate(prependBasename(getRedirectPage()));
      } else if (isUserQuizTypeSituational) {
        const productCategoryFromAnswerTags = quizViewAnswerTags.filter(isAnswerTagProductCategoryContext)[0]?.name;

        const charactersFromAnswerTagsArray = quizViewAnswerTags.filter(isAnswerTagFilterCharacterContext);
        const productPreferencesFromAnswerTagsArray = quizViewAnswerTags.filter(isAnswerTagProductPreferencesContext);

        const charactersFromAnswerTags = [...new Set(charactersFromAnswerTagsArray.map(i => i.name))]?.join('-');

        const productPreferencesFromAnswerTags = [
          ...new Set(productPreferencesFromAnswerTagsArray.map(i => i.name)),
        ]?.join(',');
        const routeFromAnswerTags = quizViewAnswerTags.find(isAnswerTagRedirectRouteContext)?.name?.replace(/_/g, '/');

        // Check if we are in a situational taste test scenario
        const isSituationalTasteTestScenario =
          userBestMatchCharacterForCurrentServiceProductCategory &&
          routeFromAnswerTags === PAGES.vinhood.quiz.chooseTaste;

        // Determine the default redirect path
        let defaultRedirectRoute = PAGES.vinhood.catalog; // Default for non-kiosk
        if (isApplicationKiosk) {
          defaultRedirectRoute = isSituationalTasteTestScenario
            ? PAGES.vinhood.quizResults.result // Kiosk + situational scenario
            : PAGES.vinhood.quizResults.situational; // Standard kiosk
        }

        // Choose between default path or answer-based path
        const finalRoute =
          !routeFromAnswerTags || isSituationalTasteTestScenario ? defaultRedirectRoute : routeFromAnswerTags;

        const finalUrlParams = {
          [IS_FROM_SITUATIONAL_TEST_URL_PARAM]: true,
          ...(!isSituationalTasteTestScenario && {
            [DISABLE_USER_CHARACTER_TOGGLE_URL_PARAM]: true,
          }),
          ...(charactersFromAnswerTags && { [CHARACTERS_URL_PARAM]: charactersFromAnswerTags }),
          ...(productPreferencesFromAnswerTags && {
            [PRODUCT_PREFERENCES_URL_PARAM]: productPreferencesFromAnswerTags,
          }),
          ...(productCategoryFromAnswerTags && { [PRODUCT_CATEGORY_URL_PARAM]: productCategoryFromAnswerTags }),
        };

        navigate(prependBasename(finalRoute, finalUrlParams));
      } else if (isUserQuizTypeRecipe) {
        if (isApplicationKiosk) {
          navigate(
            prependBasename(PAGES.vinhood.quizResults.situational, {
              [CHARACTERS_URL_PARAM]: userQuizCharacter.identifier,
              [DISABLE_USER_CHARACTER_TOGGLE_URL_PARAM]: true,
            }),
          );
        } else {
          navigate(
            prependBasename(PAGES.vinhood.catalog, {
              [CHARACTERS_URL_PARAM]: userQuizCharacter.identifier,
              [TAB_URL_PARAM]: ALL_PRODUCTS_TAB,
            }),
          );
        }
      }
    }
  }, [isUserQuizComplete]);

  useEffect(() => {
    if (quizViewQuestion?.answers?.length && userQuizType) {
      if (!isDevToolsEnabled) {
        const { answersToShuffle, answersNotShuffled } = quizViewQuestion.answers?.reduce(
          (acc, answer) => {
            const isQuizAnswerTagShuffleAnswerTag = isQuizAnswerIncludesShuffleAnswerTag(Object.values(answer)[0].tags);

            if (isQuizAnswerTagShuffleAnswerTag) {
              acc.answersToShuffle.push(answer);
            } else {
              acc.answersNotShuffled.push(answer);
            }
            return acc;
          },
          { answersNotShuffled: [], answersToShuffle: [] },
        );

        const shuffled = [...answersToShuffle];
        for (let i = shuffled.length - 1; i > 0; i -= 1) {
          const j = Math.floor(Math.random() * (i + 1));
          [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
        }
        setShuffledAnswers([...shuffled, ...answersNotShuffled]);
      } else {
        setShuffledAnswers(quizViewQuestion.answers);
      }
    }
  }, [quizViewQuestion, isDevToolsEnabled, userQuizType]);

  const onGoBackBtnClick = () => {
    /**
     * Situational test for Kiosk and App applies preselectedAnswers for each
     * product category and navigate user to the next page. On the page we show
     * Go Back button to navigate to Home page.
     */

    if (isFirstQuestion) {
      if (quizzes.length === 1) {
        navigate(prependBasename(PAGES.vinhood.home));
      } else {
        navigate(-1);
      }

      return;
    }

    if (isSituationalQuizDropQuestion) {
      navigate(situationalQuizSourcePage);
      return;
    }

    handleQuizViewGoBack();
  };

  if (isLoading) return <LoadingSpinner />;

  return (
    <QuizPage
      answers={shuffledAnswers}
      customStyles={customStyles}
      firstQuestion={isFirstQuestion}
      goBack={onGoBackBtnClick}
      isApplicationKiosk={isApplicationKiosk}
      isDevToolsEnabled={isDevToolsEnabled}
      isUserQuizLoading={isUserQuizLoading}
      progressPercent={quizViewProgress}
      question={quizViewQuestion.text}
      withoutProgressBar={isUserQuizTypeSituational}
      onAnswerSelectHandler={onAnswerSelectHandler}
    />
  );
};

export default QuizLauncher(QuizContainer);
