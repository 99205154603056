import { FC, ReactNode, useLayoutEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

import { localeCommon } from '@lib/tools/locale/source/web/common';

import * as S from '@components/web/src/templates/Modals/ModalWrapper/ModalWrapperNew.styles';

interface IProps {
  children: ReactNode;
  isOpen?: boolean;
  modalContentWidth?: string;
  minModalContentHeight?: string;
  maxModalContentHeight?: string;
  isTransparent?: boolean;
  withBackButton?: boolean;
  withoutDefaultPadding?: boolean;
  onClose?: () => void;
  handleBackButtonClick?: () => void;
}

export const ModalWrapperNew: FC<IProps> = ({
  children,
  isOpen,
  modalContentWidth = '100%',
  minModalContentHeight = 'auto',
  maxModalContentHeight = '100%',
  isTransparent = true,
  withoutDefaultPadding = false,
  withBackButton = false,
  onClose,
  handleBackButtonClick,
}) => {
  const modalContentRef = useRef<HTMLDivElement>(null);

  const { back } = localeCommon.commonMessages;

  useLayoutEffect(() => {
    const rootElement = document.querySelector('#root') as HTMLDivElement;
    if (isOpen && rootElement) {
      rootElement.style.overflowY = 'hidden';
      rootElement.style.height = '100svh';
    }

    return () => {
      if (rootElement) {
        rootElement.style.overflowY = 'unset';
        rootElement.style.height = '100%';
      }
    };
  }, [isOpen]);

  useLayoutEffect(() => {
    if (modalContentRef.current) {
      modalContentRef.current.scrollTo({
        behavior: 'smooth',
        top: 0,
      });
    }
  }, [children]);

  if (!isOpen) return null;
  const modalRootElement =
    document.getElementById('storybook-root') ||
    document.getElementById('iframe-imitation-root') ||
    document.getElementById('root-element') ||
    document.getElementById('root');

  return ReactDOM.createPortal(
    <S.ModalOverlay
      $withoutDefaultPadding={withoutDefaultPadding}
      align="center"
      aria-modal="true"
      justify="center"
      role="dialog"
      onClick={onClose}
    >
      <S.ModalContent
        ref={modalContentRef}
        $isTransparent={isTransparent}
        $maxModalContentHeight={maxModalContentHeight}
        $minModalContentHeight={minModalContentHeight}
        $modalContentWidth={modalContentWidth}
        direction="column"
        gap={0}
        onClick={e => e.stopPropagation()}
      >
        {withBackButton && <S.ModalBackButton handleClick={handleBackButtonClick} text={back} />}
        {children}
      </S.ModalContent>
    </S.ModalOverlay>,
    modalRootElement,
  );
};
