import { FC, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import useTasteIdData from '@app/web/src/hooks/useTasteIdData';

import { useCommentsHandler } from '@lib/core/comments/hooks/useCommentsHandler';
import { useParsedProducts } from '@lib/core/products/hooks/useParsedProducts';
import { useRetailerLocation } from '@lib/core/retailers/hooks/retailerLocation';
import { prependBasename } from '@lib/core/service/utils';
import { useDietaryPreferences, useLocationList, useUser } from '@lib/core/users/hooks';
import useDiscoveryQuizData from '@lib/tools/discoveryQuiz/hooks';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { PRODUCT_CATEGORY_URL_PARAM } from '@lib/tools/shared/helpers/consts';
import { useAddons } from '@lib/tools/views/hooks';

import { TTabIcon } from '@components/web/src/atoms/Tabs/TabButton/TabButton';
import Tabs, { ITabs } from '@components/web/src/atoms/Tabs/Tabs/Tabs';
import TasteIdPage from '@components/web/src/templates/TasteId/TasteIdPage/TasteIdPage';

const TasteIdPageContainer: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const { retailerStoreType, retailerLocationProducerId } = useRetailerLocation();
  const { discoveryQuiz } = useDiscoveryQuizData();

  const { productCategories: localeProductCategories } = localeCommon;

  const [activeTab] = useState(searchParams.get(PRODUCT_CATEGORY_URL_PARAM));

  useEffect(() => {
    if (activeTab) {
      searchParams.delete(PRODUCT_CATEGORY_URL_PARAM);
      setSearchParams(searchParams);
    }
  }, [activeTab]);

  const { isTasteIdResultPageAddon } = useAddons();
  const {
    isUserAuthenticated,
    userImage,
    userFirstName,
    userLastName,
    userEmail,
    profileCountry,
    profileBirthYear,
    profileGenderSlug,
  } = useUser();
  const { userDietaryPreferencePreferences } = useDietaryPreferences();
  const { isLocationListLoading, handleUpdateLocationList } = useLocationList();

  const {
    productCategories,
    userBestMatchCharacterByProductCategory,
    isTasteIdDataLoading,
    characterAdjectiveItemsData,
    characterAdjectiveModalData,
    characterInfoCardsData,
    characterDetailCardsData,
    filteredUserDietaryPreferenceTags,
    productsByCategoryData,
    locationsByCategoryData,
  } = useTasteIdData();

  const userProfileData = [
    !!userImage,
    !!userFirstName,
    !!userLastName,
    !!userEmail,
    !!profileCountry,
    !!profileBirthYear,
    !!profileGenderSlug,
    !!userDietaryPreferencePreferences.length,
  ];

  const profileProgress = (100 / userProfileData.length) * userProfileData.filter(Boolean).length;

  const handleNavigateClick = (link: string, params?: { [key: string]: string }) => {
    navigate(prependBasename(link, params), {
      state: { from: pathname + search },
    });
  };
  const { isCommentsDataLoading, isNewCommentsDataFetching } = useCommentsHandler();

  const { parseProductsArray } = useParsedProducts();

  const tabsData: ITabs = useMemo(
    () => ({
      active: activeTab || productCategories[0],
      tabs: productCategories.map(category => ({
        children: (
          <TasteIdPage
            key={category}
            characterAdjectiveItemsData={characterAdjectiveItemsData?.[category]}
            characterAdjectiveModalData={characterAdjectiveModalData?.[category]}
            characterData={userBestMatchCharacterByProductCategory?.[category]}
            characterDetailCardsData={characterDetailCardsData?.[category]}
            characterInfoCardsData={characterInfoCardsData?.[category]}
            discoveryQuiz={discoveryQuiz}
            filteredUserDietaryPreferenceTags={filteredUserDietaryPreferenceTags}
            handleNavigateClick={handleNavigateClick}
            handleUpdateLocationList={handleUpdateLocationList}
            isLocationListLoading={isLocationListLoading}
            isTasteIdDataLoading={isTasteIdDataLoading}
            isTasteIdResultPageAddon={isTasteIdResultPageAddon}
            isUserAuthenticated={isUserAuthenticated}
            locationSwiperData={locationsByCategoryData?.[category]}
            productCategory={category}
            productSwiperData={parseProductsArray(productsByCategoryData[category])}
            profileProgress={profileProgress}
            retailerLocationProducerId={retailerLocationProducerId}
            storeType={retailerStoreType}
          />
        ),
        tabCode: category,
        tabIcon: category as TTabIcon,
        tabText: localeProductCategories[category] || category,
      })),
    }),
    [
      isTasteIdDataLoading,
      filteredUserDietaryPreferenceTags,
      productsByCategoryData,
      isCommentsDataLoading,
      isNewCommentsDataFetching,
    ],
  );

  return (
    <Tabs
      active={tabsData.active}
      customZIndex={2}
      gradientVariant="custom-down"
      isHiddenTabs={productCategories?.length <= 1}
      tabs={tabsData.tabs}
      tabsVariant="autoSpaced"
    />
  );
};

export default TasteIdPageContainer;
