import { styled } from 'styled-components';

import { STATIC_COLORS } from '@components/web/src/foundations';

export const ScanProductButtonContainer = styled.button`
  position: relative;
  display: flex;
  padding: 10px 16px;
  align-items: center;
  width: 100%;
  border-radius: 8px;
  box-shadow: 2px -4px 0px 0px rgba(0, 0, 0, 0.25) inset;
  background: ${STATIC_COLORS.teal[50]};
  border: none;
  cursor: pointer;

  span {
    margin: 0 auto;
    padding: 0 20px 0 66px;
  }

  svg {
    float: right;
  }
`;

export const ProductImage = styled.img`
  position: absolute;
  left: -10px;
  bottom: -10px;
  width: 102px;
  height: 102px;
`;
