import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import moment from 'moment';

import { IRequestParams, getProductData, getProductsListData } from '@lib/core/products/utils';
import { createTypedAsyncThunk } from '@lib/core/service/createTypedAsyncThunk';
import { selectBestMatchCharacterForServiceProductCategory } from '@lib/core/users/selectors/characters';
import { selectFidelityCardId } from '@lib/core/users/selectors/fidelityCard';
import { selectWishlistProductInstanceIds } from '@lib/core/users/selectors/productList';
import { isProductInstanceInWishlistFilter } from '@lib/core/users/utils/filters';
import { IProductTip, IUserProductTip } from '@lib/tools/productTip/types/interfaces';
import { parseError } from '@lib/tools/shared/helpers';

const initialState: IProductTip = {
  currentProductTip: {
    currentProductTipData: null,
    isCurrentProductTipLoading: false,
    requestError: '',
  },
  usersProductTipsStorage: {},
};

const createProductTipForStorage = (fidelityCardId = '', characterId = '', productTipId = ''): IUserProductTip => ({
  [fidelityCardId]: {
    lastProductTipDate: moment().format('DD.MM.YYYY'),
    productTipCharacter: characterId,
    productTipId,
  },
});

export const fetchAndCreateNewProductTip = createTypedAsyncThunk(
  'productTip/fetchAndCreateNewProductTip',
  async (requestParams: IRequestParams, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      const wishlistProductInstanceIds = selectWishlistProductInstanceIds(state);
      const fidelityCardId = selectFidelityCardId(state);
      const characterId = selectBestMatchCharacterForServiceProductCategory(state)?.characterId;

      const { results: productsList = [] } = await getProductsListData(requestParams);

      const productTipData = productsList.find(
        product => !isProductInstanceInWishlistFilter(wishlistProductInstanceIds, product),
      );

      return {
        productTipData,
        productTipForStorage: createProductTipForStorage(fidelityCardId, characterId, productTipData?.identifier),
      };
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  },
);

export const fetchProductTipData = createTypedAsyncThunk(
  'productTip/fetchProductTipData',
  async (productId: string, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      const fidelityCardId = selectFidelityCardId(state);
      const characterId = selectBestMatchCharacterForServiceProductCategory(state)?.characterId;
      const productTipData = await getProductData({ productId });

      return {
        productTipData,
        productTipForStorage: createProductTipForStorage(fidelityCardId, characterId, productId),
      };
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  },
);

const productTipSlice = createSlice({
  extraReducers: builder => {
    builder
      .addMatcher(isAnyOf(fetchAndCreateNewProductTip.pending, fetchProductTipData.pending), state => {
        state.currentProductTip = { ...state.currentProductTip, isCurrentProductTipLoading: true };
      })
      .addMatcher(isAnyOf(fetchAndCreateNewProductTip.fulfilled, fetchProductTipData.fulfilled), (state, action) => {
        const { productTipData, productTipForStorage } = action.payload;

        state.currentProductTip = {
          ...state.currentProductTip,
          currentProductTipData: productTipData,
          isCurrentProductTipLoading: false,
        };

        state.usersProductTipsStorage = {
          ...state.usersProductTipsStorage,
          ...productTipForStorage,
        };
      })
      .addMatcher(isAnyOf(fetchAndCreateNewProductTip.rejected, fetchProductTipData.rejected), (state, action) => {
        state.currentProductTip = {
          ...initialState.currentProductTip,
          isCurrentProductTipLoading: false,
          requestError: action?.error?.message,
        };
      });
  },
  initialState,
  name: 'productTip',
  reducers: {
    resetCurrentProductTip(state) {
      state.currentProductTip = initialState.currentProductTip;
    },
  },
});

export const { resetCurrentProductTip } = productTipSlice.actions;
export default productTipSlice.reducer;
