import styled from 'styled-components';

import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';

export const GenderPageContainer = styled(Flexbox)<{ $background?: string }>`
  flex: 1;
  background-color: ${({ $background }) => $background || 'var(--color-secondary-100)'};
`;

export const ContentWrapper = styled(Flexbox)`
  gap: ${({ theme }) => (theme?.isKioskRootElementType ? '60px' : '24px')};
  padding: ${({ theme }) => (theme?.isKioskRootElementType ? '208px 170px' : '16px 16px 30px')};
  z-index: 1;
`;

export const GenderOptionsWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${({ theme }) => (theme?.isKioskRootElementType ? '40px 24px' : '20px 16px')};

  > *:nth-last-child(1):nth-child(odd) {
    grid-column: span 2;
  }
`;

export const CtaWrapper = styled(Flexbox)`
  gap: ${({ theme }) => (theme?.isKioskRootElementType ? '12px' : '10px')};
  margin: ${({ theme }) => (theme?.isKioskRootElementType ? '40px 0 108px' : '12px 0 4px')};

  button {
    min-width: ${({ theme }) => (theme?.isKioskRootElementType ? '110px' : '100%')};
  }
`;
