import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { useCharacters } from '@lib/core/characters/hooks';
import { actionGetRetailerCharacterEssences, actionGetRetailerCharacterFoodPairing } from '@lib/core/characters/slices';
import { isCharacterByIdentifiers } from '@lib/core/characters/utils/filters';
import { useRecipes } from '@lib/core/productAttributes/hooks';
import { useParsedProducts } from '@lib/core/products/hooks/useParsedProducts';
import { IS_FROM_PRODUCT_DETAILS_PAGE } from '@lib/core/service/consts';
import { prependBasename, promotionsFilter } from '@lib/core/service/utils';
import { useUser } from '@lib/core/users/hooks';
import { isFromSharedLink } from '@lib/tools/comms/utils';
import useDiscoveryQuizData from '@lib/tools/discoveryQuiz/hooks';
import { actionResetCatalogFilters } from '@lib/tools/filterManager/slices/productFilter';
import { resetProductCatalogState } from '@lib/tools/productCatalog/slices';
import { RouteUtils } from '@lib/tools/routes';
import {
  ALL_PRODUCTS_TAB,
  CHARACTERS_URL_PARAM,
  IS_FROM_KIOSK_QR_URL_PARAM,
  PRODUCT_CATEGORY_WINE,
  PROMOTION_LABEL_SLUG,
  TAB_URL_PARAM,
} from '@lib/tools/shared/helpers/consts';
import { useSustainability } from '@lib/tools/sustainability/hooks';
import { useAddons } from '@lib/tools/views/hooks';
import useGetProductInstance from '@lib/tools/views/hooks/useGetProductInstance';
import { PAGES } from '@lib/tools/views/urls';

import { LoadingSpinner } from '@components/web/src/templates/Loading/LoadingSpinner/LoadingSpinner';
import ProductPage from '@components/web/src/templates/ProductPage/Web/ProductPage';

const ProductPageContainer: FC = () => {
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { state: locationState } = useLocation();

  const isFromKioskQR = !!searchParams.get(IS_FROM_KIOSK_QR_URL_PARAM);
  const { parseProduct } = useParsedProducts();
  const { isProductPDFAddon, isEnableTastePathAddon, isHideProductPageRecipes } = useAddons();
  const { isUserHasAnyCharacter, isUserAuthenticated } = useUser();

  const { productInstanceData, isProductInstanceDataLoading } = useGetProductInstance();
  const parsedProductInstanceData = parseProduct(productInstanceData);
  const {
    productCharacterId,
    productFeedbackStatus,
    productCategory,
    productPerfectFor,
    productFormatIdentifier,
    preparationsData,
  } = parsedProductInstanceData || {};

  const { recipesData, isRecipesDataLoading } = useRecipes(productCharacterId, productCategory);

  const { sustainabilityData, isSustainabilityDataLoading } = useSustainability(
    productCategory,
    productFormatIdentifier,
  );

  const { discoveryQuiz } = useDiscoveryQuizData();

  const { characters } = useCharacters();
  const productCharacterData = characters.find(character => isCharacterByIdentifiers(character, [productCharacterId]));

  const navigateToQuizSelectionPage = () => navigate(prependBasename(PAGES.vinhood.quiz.chooseTaste));

  const { characterRelatedEssencesSorted } = productCharacterData || {};

  const onBackButtonClick = () => {
    const paramSeparator = locationState?.from?.includes('?') ? '&' : '?';
    const url = `${locationState?.from}${paramSeparator}${IS_FROM_PRODUCT_DETAILS_PAGE}=true`;

    return isFromKioskQR || !locationState?.from ? navigate(prependBasename(PAGES.vinhood.catalog)) : navigate(url);
  };

  const navigateToCatalog = () =>
    navigate(
      prependBasename(PAGES.vinhood.catalog, {
        [CHARACTERS_URL_PARAM]: productCharacterId,
        ...(isEnableTastePathAddon ? { [TAB_URL_PARAM]: ALL_PRODUCTS_TAB } : {}),
      }),
    );

  const isLoading =
    !productInstanceData ||
    isProductInstanceDataLoading ||
    !recipesData ||
    isRecipesDataLoading ||
    isSustainabilityDataLoading;

  useEffect(() => {
    if (productCharacterId && !productCharacterData?.characterEssences) {
      dispatch(actionGetRetailerCharacterEssences({ characterId: productCharacterId }));
    }
    if (productCharacterId && !productCharacterData?.characterFoodPairing) {
      dispatch(actionGetRetailerCharacterFoodPairing({ characterId: productCharacterId }));
    }
  }, [productCharacterId]);

  // We don't reset catalog/filter data if we go from catalog to product page
  // That's why we should do it here, in case if we are navigating from product page to another page (not catalog)
  useEffect(() => {
    return () => {
      if (!RouteUtils.getPage().includes(PAGES.vinhood.catalog)) {
        dispatch(actionResetCatalogFilters());
        dispatch(resetProductCatalogState());
      }
    };
  }, []);

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <ProductPage
      discoveryQuiz={discoveryQuiz}
      essencesData={characterRelatedEssencesSorted}
      isFromSharedLink={isFromSharedLink}
      isHideProductPageRecipes={isHideProductPageRecipes}
      isProductPDFDownload={isProductPDFAddon && productCategory === PRODUCT_CATEGORY_WINE}
      isRecipesDataLoading={isRecipesDataLoading}
      isShowPromoLabel={!!promotionsFilter(productInstanceData?.promotions, [PROMOTION_LABEL_SLUG]).length}
      isUserHasAnyCharacter={isUserHasAnyCharacter}
      navigateToCatalog={navigateToCatalog}
      navigateToQuizSelectionPage={navigateToQuizSelectionPage}
      parsedProductInstanceData={parsedProductInstanceData}
      perfectForData={productPerfectFor}
      preparationsData={preparationsData}
      productCategory={productCategory}
      recipesData={recipesData}
      shouldHideComment={!isUserAuthenticated}
      shouldHideWishlist={!isUserAuthenticated}
      sustainabilityData={sustainabilityData}
      shouldHideRatingButton={
        !isUserAuthenticated &&
        !isEnableTastePathAddon &&
        !productFeedbackStatus?.isDisabled &&
        !productFeedbackStatus?.isFinished
      }
      onBackButtonClick={onBackButtonClick}
    />
  );
};

export default ProductPageContainer;
