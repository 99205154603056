import { FC } from 'react';

import { TParsedProductInstance, TProductCategory } from '@lib/core/products/types';
import { TRetailerLocationStoreType } from '@lib/core/retailers/types';
import { MP_POSITION_CONTEXT } from '@lib/tools/dat/mixpanel/consts';
import { IFilterChangeAction } from '@lib/tools/filterManager/slices/sublistFilterSlice';
import { localeWidget } from '@lib/tools/locale/source/web/widget';
import {
  LIMITED_CATALOG,
  PRODUCT_CATEGORY_BEER,
  PRODUCT_CATEGORY_COFFEE,
  PRODUCT_CATEGORY_WINE,
} from '@lib/tools/shared/helpers/consts';
import { IHandleProductsRequestParams } from '@lib/tools/shared/helpers/interfaces';

import { STATIC_COLORS } from '@components/web/src/foundations';
import { Text } from '@components/web/src/foundations/Text/Text';
import ProductCard from '@components/web/src/organisms/ProductCards/OldProductCard/ProductCard';
import SwiperHOC from '@components/web/src/organisms/Swiper/SwiperHOC';
import { IDiscoveryQuiz } from '@components/web/src/templates/Modals/DiscoveryQuizModal/DiscoveryQuizModal';
import { LimitedFilter } from '@components/web/src/widget/Catalog/LimitedFilter/LimitedFilter';
import * as S from '@components/web/src/widget/Catalog/styles';

interface IProps {
  matchedProducts: TParsedProductInstance[];
  catalogType: string;
  isProductsRequestLoading: boolean;
  productCategory: TProductCategory;
  storeType: TRetailerLocationStoreType;
  discoveryQuiz?: IDiscoveryQuiz;
  shouldHideWishlist: boolean;
  handleProductsRequest: (args: IHandleProductsRequestParams) => void;
  handleUpdateFilterType: (filterItem: IFilterChangeAction) => void;
}

const MatchedProducts: FC<IProps> = ({
  isProductsRequestLoading,
  matchedProducts,
  productCategory = PRODUCT_CATEGORY_COFFEE,
  catalogType,
  storeType,
  discoveryQuiz,
  shouldHideWishlist,
  handleProductsRequest,
  handleUpdateFilterType,
}) => {
  const { productCatalog } = localeWidget;

  const swiperHeadingTextByProduct = {
    [PRODUCT_CATEGORY_BEER]: productCatalog.pourBeer,
    [PRODUCT_CATEGORY_COFFEE]: productCatalog.pourCoffee,
    [PRODUCT_CATEGORY_WINE]: productCatalog.uncorkTheWineShort,
  };

  const isLimitedCatalog = catalogType === LIMITED_CATALOG;
  const isSingleProduct = !isProductsRequestLoading && matchedProducts.length === 1;

  const productView = isSingleProduct ? (
    <ProductCard
      isResponsive
      discoveryQuiz={discoveryQuiz}
      isLoading={isProductsRequestLoading}
      mixpanelIndex={0}
      mixpanelPositionContext={MP_POSITION_CONTEXT.SWIPER}
      parsedProductInstanceData={matchedProducts[0]}
      shouldHideWishlist={shouldHideWishlist}
      storeType={storeType}
    />
  ) : (
    <SwiperHOC>
      {matchedProducts.map((parsedProductInstanceData, index) => (
        <ProductCard
          key={index}
          discoveryQuiz={discoveryQuiz}
          isLoading={false}
          mixpanelIndex={index}
          mixpanelPositionContext={MP_POSITION_CONTEXT.SWIPER}
          parsedProductInstanceData={parsedProductInstanceData}
          shouldHideWishlist={shouldHideWishlist}
          storeType={storeType}
        />
      ))}
    </SwiperHOC>
  );

  return (
    <S.SwiperSection $isSingleProduct={isSingleProduct}>
      <S.SwiperTitleSection>
        <Text
          color={STATIC_COLORS.base.black}
          fontFamily="Fraunces"
          size="h5"
          text={swiperHeadingTextByProduct[productCategory]}
          weight="semibold"
        />
        {isLimitedCatalog && false && (
          <LimitedFilter
            handleProductsRequest={handleProductsRequest}
            handleUpdateFilterType={handleUpdateFilterType}
          />
        )}
      </S.SwiperTitleSection>

      {isProductsRequestLoading ? (
        <SwiperHOC>
          {[1, 2, 3].map(key => (
            <ProductCard key={key} isLoading={isProductsRequestLoading} storeType={storeType} />
          ))}
        </SwiperHOC>
      ) : (
        productView
      )}
    </S.SwiperSection>
  );
};

export default MatchedProducts;
