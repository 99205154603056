import styled from 'styled-components';

import { STATIC_COLORS, STATIC_SHADOWS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';

export const PlacesCardContainer = styled.div<{ $isResponsive: boolean }>`
  display: flex;
  flex-direction: column;
  width: ${({ $isResponsive }) => ($isResponsive ? '100%' : '310px')};
  height: 100%;
  max-height: 290px;
  overflow: hidden;
  cursor: pointer;
  background-color: ${STATIC_COLORS.base.white};
  border-radius: 10px;
  border: 0.4px solid ${STATIC_COLORS.gray[200]};
  box-shadow: ${STATIC_SHADOWS.sm};
`;

export const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 150px;
  overflow: hidden;
`;

export const Overlay = styled.div`
  width: 100%;
  height: 150px;
  overflow: hidden;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.21) 45%,
      rgba(0, 0, 0, 0.56) 70.22%,
      rgba(0, 0, 0, 0.7) 100%
    );
    pointer-events: none;
  }
`;

export const WishlistWrapper = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 2;

  .wishlist-container {
    position: absolute;
    top: 15px;
    right: 15px;
  }
`;

export const ContentWrapper = styled(Flexbox)`
  flex: 1;
`;

export const LogoWrapper = styled.div`
  height: 40px;

  img {
    height: 40px;
    width: fit-content;
  }
`;
