import { FC } from 'react';

import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import * as S from '@components/web/src/organisms/Skeleton/ProductCardSkeleton/styles';

interface IProps {
  isResponsive?: boolean;
}

export const ProductCardSkeleton: FC<IProps> = ({ isResponsive = false }) => (
  <S.ProductCardSkeletonContainer $isResponsive={isResponsive} align="flex-start" direction="column" gap={0}>
    <S.ImageSkeleton />
    <Flexbox isFullWidth align="flex-start" direction="column" gap={4} padding="16px">
      <S.SectionOne />
      <S.SectionTwo />
      <S.SectionThree />
      <Flexbox isFullWidth justify="flex-end">
        <S.SectionFour />
      </Flexbox>
    </Flexbox>
  </S.ProductCardSkeletonContainer>
);
