import { FC } from 'react';

import MixpanelTracker from '@lib/tools/dat/mixpanel';
import { localeCommon } from '@lib/tools/locale/source/web/common';

import Button from '@components/web/src/atoms/Buttons/Button';
import { CloseButton } from '@components/web/src/atoms/Buttons/CloseButton/CloseButton';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';
import ModalWrapper from '@components/web/src/templates/Modals/ModalWrapper/ModalWrapper';
import * as S from '@components/web/src/templates/Modals/TasteMatchModal/styles';

interface IProps {
  isOpen: boolean;
  shouldDisplayButton: boolean;
  hideModal: () => void;
  handleBtnClick: () => void;
}

const TasteMatchModal: FC<IProps> = ({ shouldDisplayButton, isOpen, hideModal, handleBtnClick }) => {
  const { btnText, actionTitle, modalTitle, subtitle, description } = localeCommon.tasteMatchModal;

  return (
    <ModalWrapper maxContentHeight hideModal={hideModal} isOpen={isOpen}>
      <S.TasteMatchModalWrapper isFullWidth align="center" direction="column" padding="40px 16px 24px">
        <S.Icon>
          <CloseButton handleClick={hideModal} type="bold" />
        </S.Icon>
        <S.TasteMatchContent>
          <Text color={STATIC_COLORS.base.black} fontFamily="Fraunces" size="h6" text={modalTitle} weight="semibold" />
          <Flexbox isFullWidth direction="column" gap={20}>
            <Text color={STATIC_COLORS.base.black} size="body1" text={subtitle} />
            <Text color={STATIC_COLORS.base.black} size="body1" text={description} />
          </Flexbox>
          {shouldDisplayButton && (
            <S.TasteMatchActions>
              <Text color={STATIC_COLORS.base.black} size="body1" text={actionTitle} weight="medium" />
              <Button
                fontSize="subtitle2"
                size="md"
                text={btnText}
                onClick={() => {
                  handleBtnClick();
                  MixpanelTracker.events.startTasteTestClick();
                }}
              />
            </S.TasteMatchActions>
          )}
        </S.TasteMatchContent>
      </S.TasteMatchModalWrapper>
    </ModalWrapper>
  );
};

export default TasteMatchModal;
