import { css, keyframes, styled } from 'styled-components';

import { STATIC_COLORS, STATIC_GRADIENTS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';

const backgroundColorTransition = keyframes`
  0% {
    background: ${STATIC_COLORS.base.white};
  }
  90% {
    background: ${STATIC_COLORS.base.white};
  }
  100% {
    background: ${STATIC_GRADIENTS.primaryGradient};
  }
`;

const showRetailerLogo = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.7);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;

const hideRetailerLogo = keyframes`
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0.9);
  }
`;

const showVinhoodLogo = keyframes`
  0% {
    opacity: 0;
    transform: translateY(5px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const hideVinhoodLogo = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const showLoadingSpinner = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const PartnerSplashScreenContainer = styled(Flexbox)<{
  $retailerLogo: string;
  $isAppInIframe: boolean;
  $isDevToolsEnabled: boolean;
}>`
  position: absolute;
  inset: 0;
  z-index: 999999999;
  background: ${STATIC_COLORS.base.white};

  .retailer_logo,
  .vinhood_logo,
  .loading_spinner {
    opacity: 0;
  }

  .loading_spinner {
    width: 100%;
    height: 100%;
  }

  ${({ $isDevToolsEnabled }) =>
    $isDevToolsEnabled &&
    css`
      animation: ${backgroundColorTransition} 0s forwards;

      .loading_spinner {
        animation: ${showLoadingSpinner} 0s forwards;
      }
    `}

  ${({ $isDevToolsEnabled, $retailerLogo, $isAppInIframe }) =>
    !$isDevToolsEnabled &&
    ($retailerLogo && !$isAppInIframe
      ? css`
          animation: ${backgroundColorTransition} 3.5s forwards;

          .retailer_logo {
            position: absolute;
            animation:
              ${showRetailerLogo} 0.5s forwards 0.5s,
              ${hideRetailerLogo} 0.5s forwards 2.5s;
          }

          .vinhood_logo {
            position: absolute;
            animation:
              ${showVinhoodLogo} 1s forwards 3.5s,
              ${hideVinhoodLogo} 1s forwards 5.5s;
          }

          .loading_spinner {
            animation: ${showLoadingSpinner} 0s forwards 6s;
          }
        `
      : css`
          animation: ${backgroundColorTransition} 0s forwards;

          .vinhood_logo {
            position: absolute;
            animation:
              ${showVinhoodLogo} 0.5s forwards 0.5s,
              ${hideVinhoodLogo} 0.5s forwards 2.5s;
          }

          .loading_spinner {
            animation: ${showLoadingSpinner} 0s forwards 3s;
          }
        `)}
`;
