import { FC } from 'react';

import { IParsedCharacter } from '@lib/core/characters/utils/parseCharacter';
import { useApp } from '@lib/core/service/hooks';
import { ILocaleText } from '@lib/tools/locale/views/LocaleFragment';

import tipIcon from '@components/web/src/assets/icons/icon_tip.svg';
import { CloseButton } from '@components/web/src/atoms/Buttons/CloseButton/CloseButton';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { PRODUCT_CATEGORY_QUERY_KEY, URL_PARAMS_HINT_TYPE } from '@components/web/src/foundations/consts';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Image } from '@components/web/src/foundations/Image/Image';
import { Text } from '@components/web/src/foundations/Text/Text';
import * as S from '@components/web/src/templates/Modals/HintModal/style';
import { ModalWrapperNew } from '@components/web/src/templates/Modals/ModalWrapper/ModalWrapperNew';

interface IProps {
  bannerText?: ILocaleText;
  isModalOpen?: any;
  image?: string;
  params?: string;
  userBestMatchCharacterByProductCategory?: Record<string, IParsedCharacter>;
  isUserHasAnyCharacter?: boolean;
  hideModal?: () => void;
}

export const HintModal: FC<IProps> = ({
  isUserHasAnyCharacter,
  userBestMatchCharacterByProductCategory,
  bannerText,
  isModalOpen,
  image,
  params,
  hideModal,
}) => {
  const { locale } = useApp();
  const productCategory = new URLSearchParams(params).get(PRODUCT_CATEGORY_QUERY_KEY) || '';
  const hintType = new URLSearchParams(params).get(URL_PARAMS_HINT_TYPE) || '';

  const attributes = userBestMatchCharacterByProductCategory[productCategory]?.characterAttributes;

  if (!isUserHasAnyCharacter || !attributes) {
    return null;
  }
  const headerDescription = attributes[`${hintType}_description_${locale}`] || '';
  const footerDescription = attributes[`${hintType}_footer_${locale}`] || '';

  return (
    <ModalWrapperNew isOpen={isModalOpen} onClose={hideModal}>
      <S.HintModalContainer direction="column" gap={0}>
        <Flexbox direction="column" gap={0} padding="16px 16px 24px">
          <CloseButton handleClick={hideModal} position="right" />
          <Flexbox direction="column" gap={16}>
            <Image alt="banner icon" height="40px" src={image} width="40px" />
            <Text
              color={STATIC_COLORS.base.black}
              fontFamily="Fraunces"
              size="h6"
              text={bannerText}
              weight="semibold"
            />
            <Text color={STATIC_COLORS.base.black} size="body1" text={headerDescription} />
          </Flexbox>
        </Flexbox>
        <S.ContentWrapper gap={16} padding="16px 16px 24px">
          <Image alt="banner icon" height="24px" maxWidth="24px" minWidth="24px" src={tipIcon} />
          <Text color={STATIC_COLORS.base.black} size="body1" text={footerDescription} />
        </S.ContentWrapper>
      </S.HintModalContainer>
    </ModalWrapperNew>
  );
};
