/* eslint-disable class-methods-use-this */
/* eslint-disable no-dupe-class-members */
import mixpanel from 'mixpanel-browser';

import { TBannerLink, TBannerPosition } from '@lib/core/banners/types';
import { IParsedCharacter } from '@lib/core/characters/utils/parseCharacter';
import { TExperience } from '@lib/core/experiences/types';
import { parseBasicProductData } from '@lib/core/products/hooks/useParsedProducts';
import { TParsedProductInstance, TProductCategory } from '@lib/core/products/types';
import { TQuizType } from '@lib/core/quizzes/types';
import { TUserQuizDetail } from '@lib/core/quizzes/types/userQuiz';
import { EXPERIENCES_TYPES } from '@lib/core/service/consts';
import { TProductRateValue } from '@lib/core/users/slices/productRate';
import { MixpanelExtras } from '@lib/tools/dat/mixpanel';
import {
  MP_EVENTS,
  MixpanelActionPerformedContext,
  MixpanelCommentStatus,
  MixpanelCommentVisibility,
  MixpanelInteractiveTastingStatus,
  MixpanelPositionContext,
} from '@lib/tools/dat/mixpanel/consts';
import {
  checkIfMixpanelExists,
  disableOnApp,
  disableOnKiosk,
  disableOnWidget,
  trackFacebookPixelEvent,
} from '@lib/tools/dat/mixpanel/decorators';
import {
  TMPBannerClick,
  TMPCharacterDescriptionClick,
  TMPContactTheProductProducer,
  TMPDiscoverMorePairingsClick,
  TMPDiscoverQuizSubmit,
  TMPEntryPoint,
  TMPExperienceCategoryCatalogView,
  TMPExploreSectionView,
  TMPFindMe,
  TMPFooterNavigationClick,
  TMPInteractiveTastingClick,
  TMPInteractiveTastingCompleted,
  TMPInteractiveTastingStart,
  TMPLocationBookmark,
  TMPLocationTasteMatchClick,
  TMPParsedProductDataProps,
  TMPProductBookmark,
  TMPProductCatalogFilter,
  TMPProductCatalogView,
  TMPProductClick,
  TMPProductComment,
  TMPProductData,
  TMPProductFeedback,
  TMPProductStory,
  TMPPromotionDescriptionClick,
  TMPRecipeClick,
  TMPResultPageView,
  TMPScanFailed,
  TMPScanProduct,
  TMPSeeSimilarProducts,
  TMPShareProductPageClick,
  TMPSkipCharacterType,
  TMPSpecialPromoDisplayed,
  TMPTasteMatchClick,
  TMPTestCompleted,
  TMPTestStarted,
  TMPThirdPartyChoiceClick,
  TMPViewProducerExperiencePage,
} from '@lib/tools/dat/mixpanel/types';
import Utilities from '@lib/tools/dat/mixpanel/utils';
import { IProductFilterState } from '@lib/tools/filterManager/slices/productFilter';
import { languages } from '@lib/tools/locale/utils/consts';
import { RouteUtils } from '@lib/tools/routes';
import {
  FILTER_TYPE_CHARACTERISTICS,
  FILTER_TYPE_ORIGIN,
  FILTER_TYPE_STYLE,
  PRODUCT_CATEGORY_WINE,
} from '@lib/tools/shared/helpers/consts';

import { TRecipe } from '@components/web/src/templates/TestFlowsPages/FoodPairingPage/components/RecipeItem';

// NOTE: for some reason, tracking event for actions that open an external website
// (such as product click on widget) get triggered twice. (only the event, not the whole method).
// However it's not a big deal because Mixpanel servers recognize it as being
// duplicate and automatically dedupe it.

/** Mixpanel tracking events. */
@checkIfMixpanelExists()
export default class Events {
  private utils: Utilities;

  constructor() {
    this.utils = new Utilities();
  }

  /**
   * This method triggers when the solution loads.
   * It's used to check where user land when coming from mktg campaigns and to allow Mixpanel to track UTM params.
   */
  @disableOnKiosk()
  public entryPoint() {
    const page = RouteUtils.getPage();
    const entryPage = page.split('?')[0];
    const args: TMPEntryPoint = { entryPage };

    this.utils.track(MP_EVENTS.ENTRY_POINT, args);
  }

  // ###########################################################################
  // #                                                                         #
  // #                      Start page and identification                      #
  // #                                                                         #
  // ###########################################################################

  /**
   * Invoke this event when the Start Page is loaded.
   */
  @trackFacebookPixelEvent(MP_EVENTS.START_PAGE_VIEW)
  public startPageView() {
    this.utils.track(MP_EVENTS.START_PAGE_VIEW);
  }

  /**
   * Invoke this event when the user clicks "Enter" in the Start Page.
   */
  public startPageEnterClick() {
    this.utils.track(MP_EVENTS.START_PAGE_ENTER_CLICK);
  }

  /**
   * Invoke this event when the user successfully completes the registration process.
   *
   * @beta
   */
  @trackFacebookPixelEvent(MP_EVENTS.USER_REGISTRATION_COMPLETED)
  public userRegistrationCompleted() {
    // WARN: currently only implemented for email registration
    const registrationDate = this.utils.getDateTime();

    this.utils.track(MP_EVENTS.USER_REGISTRATION_COMPLETED);
    this.utils.setUserProfileProperties({ registrationDate }, false);
  }

  /**
   * Invoke this event when the user successfully logs in and an account already exists.
   *
   * @beta
   */
  public userLogin(isSocial: boolean, isRegister: boolean) {
    // NOTE: probably merge login and registration if necessary and use props to
    // distinguish between the two.
    if (isSocial && isRegister) {
      // pass: placeholder for when is_register_new_social_user works
    }
    const lastLogin = this.utils.getDateTime();

    this.utils.track(MP_EVENTS.LOGIN);
    this.utils.setUserProfileProperties({ lastLogin }, true);
  }

  /**
   * Invoke this event when the user logs out, either explicitly or implicitly
   * (e.g. due to clearing local storage).
   *
   * Sends a "Sign Out" event to Mixpanel event stream, then clears all Mixpanel
   * super properties, generates a new random distinct_id for this instance and sets
   * Retailer super properties back again.
   */
  public signOut() {
    // NOTE: this is kind of a hack.
    // The purpose is to prevent invoking the method `mixpanel.reset()` when a user is not authenticated,
    // which would incorrectly create many anonymous profiles in the Mixpanel platform.
    const vinhoodUserPkPattern = /^[0-9]+$/;
    if (vinhoodUserPkPattern.test(mixpanel.get_distinct_id())) {
      this.utils.track(MP_EVENTS.SIGN_OUT);
      this.utils.reset();
      MixpanelExtras.initializeSuperProperties();
    }
  }

  /**
   * Invoke this event when the user clicks "Play as anonymous" CTA in the kiosk.
   */
  public playAsAnonymous() {
    this.utils.track(MP_EVENTS.PLAY_AS_ANONYMOUS);
  }

  /**
   * Invoke this event when the user scans their retailer fidelity card in a physical kiosk.
   *
   * @param fidelityCardID - The ID of the fidelity card
   */
  @disableOnWidget()
  @disableOnApp()
  public scanFidelityCard(fidelityCardID: string, retailerSlug: string) {
    const combinedFidelityCardId = `${retailerSlug}_${fidelityCardID}`;

    if (!combinedFidelityCardId) return;
    if (this.utils.didIdentify) {
      MixpanelExtras.initializeSuperProperties();
    }

    this.utils.identify(combinedFidelityCardId);
    this.utils.setUserProfileProperties({ fidelityCardID: combinedFidelityCardId }, false);
    this.utils.track(MP_EVENTS.SCAN_FIDELITY_CARD, { fidelityCardID: combinedFidelityCardId });
  }

  /**
   * Invoke this event when the user manually disconnects their fidelity card from a physical kiosk.
   */
  @disableOnWidget()
  @disableOnApp()
  public detachFidelityCard() {
    this.utils.track(MP_EVENTS.DETACH_FIDELITY_CARD);
    MixpanelExtras.initializeSuperProperties();
  }

  // ###########################################################################
  // #                                                                         #
  // #                                Products                                 #
  // #                                                                         #
  // ###########################################################################

  /**
   * @param parsedProductInstanceData - Product {@link TParsedProductInstance} data
   * @param isProductBookmarkEvent - To identify bookmark event
   * @param productIndex - Numerical index of the product in the displayed list,
   *  we pass {@param productIndex} only when using Swiper or Scrollable Catalog
   * @param productPosition - Name of the parent component {@link MixpanelPositionContext} wrapping the product card
   */
  private parseMPProductData({
    parsedProductInstanceData,
    isProductBookmarkEvent = false,
    productIndex = null,
    productPosition = null,
  }: TMPParsedProductDataProps): TMPProductData {
    const {
      productCharacterId,
      productInstanceId: gprlID,
      productOriginalPrice,
      productPriceCurrency,
      productCategory,
      productCharacteristicsNames,
      productDiscountPrice,
      productFormat,
      productId,
      productName,
      region: regionOpenSearch = [],
      regions: regionOldCatalog = [],
      producer,
      promotions,
      productPreferencesSlug,
      isProductInstanceInWishlist,
    } = parsedProductInstanceData || {};

    const productPrice = productOriginalPrice || null;
    const productCharacterID = productCharacterId || null;
    // NOTE: for `isProductBookmarkEvent` we use the value BEFORE the action is executed, so we invert it.
    const productBookmarked = isProductBookmarkEvent ? !isProductInstanceInWishlist : isProductInstanceInWishlist;
    const priceCurrency = productPriceCurrency || null;
    const productCharacteristics = productCharacteristicsNames || [];
    const productDiscounted = productPrice ? !!productDiscountPrice : null;
    const productDiscountedPrice = productPrice && productDiscountPrice ? productPrice - productDiscountPrice : null;
    const { identifier: productFormatID, name: productFormatName } = productFormat || {};
    const productID = productId || null;
    const productPositionIndex = typeof productIndex === 'number' ? productIndex + 1 : null;
    const region = [...(regionOpenSearch || []), ...(regionOldCatalog || [])];
    const productOriginsID = region?.map(v => v.identifier) || [];
    const productOriginsName = region?.map(v => v.name) || [];
    const productOriginsCountry = region?.map(v => v.country) || [];
    const { identifier: productProducerID = null } = producer || {};
    const { name: productProducerName = null } = producer || {};
    const { country: productProducerCountry = null } = producer || {};
    const productPromotions = promotions?.map(v => v.typeSlug || v.type_slug) || [];
    const productTags = productPreferencesSlug;

    return {
      gprlID,
      priceCurrency,
      productBookmarked,
      productCategory,
      productCharacterID,
      productCharacteristics,
      productDiscounted,
      productDiscountedPrice,
      productFormatID,
      productFormatName,
      productID,
      productName,
      productOriginsCountry,
      productOriginsID,
      productOriginsName,
      productPosition,
      productPositionIndex,
      productPrice,
      productProducerCountry,
      productProducerID,
      productProducerName,
      productPromotions,
      productTags,
    };
  }

  /**
   * Invoke this event when a user clicks on a product card.
   *
   * @param parsedProductInstanceData - Product information conforming to {@link TParsedProductInstance} data structure
   * @param productIndex - Numerical index of the product in the displayed list
   * @param productPosition - Descriptive name for the parent component wrapping the product card
   */
  public productClick(
    parsedProductInstanceData: TParsedProductInstance,
    productIndex: number,
    productPosition: MixpanelPositionContext,
  ) {
    const args: TMPProductClick = this.parseMPProductData({
      parsedProductInstanceData,
      productIndex,
      productPosition,
    });

    this.utils.track(MP_EVENTS.PRODUCT_CLICK, args);
  }

  /**
   * Invoke this event when a user clicks on Download Product Details as PDF.
   *
   * @param productInstanceData - Product information conforming to {@link TParsedProductInstance} data structure
   */
  public createProductPDF(parsedProductInstanceData: TParsedProductInstance) {
    const args: TMPProductClick = this.parseMPProductData({
      parsedProductInstanceData,
    });

    this.utils.track(MP_EVENTS.CREATE_PRODUCT_PDF, args);
  }

  /**
   * Invoke this event when the user clicks on a product card or CTA that redirects
   * the user to an external product page.
   *
   * @param productInstanceData - Product information conforming to {@link TProductInstance} data structure
   * @param productIndex - Numerical index of the product in the displayed list
   * @param productPosition - Descriptive name for the component displaying the product
   */
  public contactTheProductProducer(
    parsedProductInstanceData: TParsedProductInstance,
    productIndex?: number,
    productPosition?: MixpanelPositionContext,
  ) {
    const args: TMPContactTheProductProducer = this.parseMPProductData({
      parsedProductInstanceData,
      productIndex,
      productPosition,
    });

    this.utils.track(MP_EVENTS.CONTACT_THE_PRODUCT_PRODUCER, args);
  }

  /**
   * Invoke this event when the user clicks on the "Find Me" CTA.
   *
   * @param productInstanceData - Product information conforming to {@link TProductInstance} data structure
   * @param productIndex - Numerical index of the product in the displayed list
   * @param productPosition - Descriptive name for the component displaying the product
   */
  public findMe(
    parsedProductInstanceData: TParsedProductInstance,
    productIndex?: number,
    productPosition?: MixpanelPositionContext,
  ) {
    const args: TMPFindMe = this.parseMPProductData({
      parsedProductInstanceData,
      productIndex,
      productPosition,
    });

    this.utils.track(MP_EVENTS.FIND_ME, args);
  }

  /**
   * Invoke this event when the user clicks on the "See Similar Products" CTA in Product page.
   *
   * @param productInstanceData - Product information conforming to {@link TProductInstance} data structure
   */
  public seeSimilarProductsClick(parsedProductInstanceData: TParsedProductInstance) {
    const args: TMPSeeSimilarProducts = this.parseMPProductData({ parsedProductInstanceData });

    this.utils.track(MP_EVENTS.SEE_SIMILAR_PRODUCTS_CLICK, args);
  }

  /**
   * Invoke this event when the user clicks on the "more" CTA in Product page.
   *
   * @param parsedProductInstanceData - Product information conforming to {@link TParsedProductInstance} data structure
   * @param productPosition - Descriptive name for the component where the click occurs
   */
  public productStoryClick(
    parsedProductInstanceData: TParsedProductInstance,
    productPosition?: MixpanelPositionContext,
  ) {
    const args: TMPProductStory = this.parseMPProductData({
      parsedProductInstanceData,
      productPosition,
    });

    this.utils.track(MP_EVENTS.PRODUCT_STORY_CLICK, args);
  }

  /**
   * Invoke this event when the user clicks the bookmark in a product card.
   *
   * @param parsedProductInstanceData - Product information conforming to {@link TParsedProductInstance} data structure
   * @param productIndex - Numerical index of the product in the displayed list
   * @param productPosition - Descriptive name for the component displaying the product
   */
  public productBookmark(
    parsedProductInstanceData: TParsedProductInstance,
    productIndex: number,
    productPosition: MixpanelPositionContext,
  ) {
    const args: TMPProductBookmark = this.parseMPProductData({
      isProductBookmarkEvent: true,
      parsedProductInstanceData,
      productIndex,
      productPosition,
    });

    this.utils.track(MP_EVENTS.PRODUCT_BOOKMARK, args);
  }

  /**
   * Invoke this event when a product is scanned and the scan is successful
   * (i.e. it redirects to the product page).
   *
   * @param productInstanceDataOld - Object containing product information
   * @param code - The code scanned
   */
  public scanProduct(productInstanceDataOld, code: string) {
    const parsedProductInstanceData = parseBasicProductData(productInstanceDataOld);
    const productData = this.parseMPProductData({ parsedProductInstanceData });
    const args: TMPScanProduct = { ...productData, productEAN: code };

    this.utils.track(MP_EVENTS.SCAN_PRODUCT, args);
  }

  /**
   * Invoke this event when the scan action fails, i.e. it's neither a product nor a fidelity card.
   *
   * @param code - The code scanned
   */
  public scanFailed(code: string) {
    const args: TMPScanFailed = { codeScanned: code };

    this.utils.track(MP_EVENTS.SCAN_FAILED, args);
  }

  /**
   *Invoke this event when the user clicks on Scan button
   */
  public scanButtonClick() {
    this.utils.track(MP_EVENTS.SCAN_BUTTON_CLICK);
  }

  /**
   * Invoke this event when the user leaves feedback to a product.
   *
   * @param parsedProductInstanceData - Object containing product information
   * @param feedback - Product feedback, see {@link TFeedback this type}
   */
  public productFeedback(parsedProductInstanceData: TParsedProductInstance, feedback: TProductRateValue) {
    const productData = this.parseMPProductData({ parsedProductInstanceData });
    const args: TMPProductFeedback = { ...productData, feedbackScore: feedback / 10 };

    this.utils.track(MP_EVENTS.PRODUCT_FEEDBACK, args);
  }

  /**
   * Invoke this event when the user clicks on the "Check The Full List" CTA.
   *
   * @param parsedProductInstanceData - Object containing product information
   */
  public discoverMorePairingsClick(parsedProductInstanceData: TParsedProductInstance) {
    const args: TMPDiscoverMorePairingsClick = this.parseMPProductData({ parsedProductInstanceData });

    this.utils.track(MP_EVENTS.DISCOVER_MORE_PAIRINGS_CLICK, args);
  }

  /**
   * Invoke this event when the user clicks on a CTA that leads directly to the product catalog.
   */
  public productCatalogClick() {
    this.utils.track(MP_EVENTS.PRODUCT_CATALOG_CLICK);
  }

  /**
   * Invoke this event when the user lands on the Product Catalog in the `vinhood-experience` designset.
   *
   * @param productCategory - Type of product being displayed as per {@link TProductCategory this interface}
   */
  public productCatalogView(productCategory: Exclude<TProductCategory, typeof PRODUCT_CATEGORY_WINE>): void;
  /**
   * Invoke this event when the user lands on the Product Catalog in the `vinhood-app` or `kiosk` designset.
   *
   * @param productCategory - Type of product being displayed as per {@link TProductCategory this interface}
   * @param shouldUseUserCharacter - Whether the product list reflects the user's character(s) or not
   */
  public productCatalogView(productCategory: TProductCategory, shouldUseUserCharacter: boolean | string): void;
  public productCatalogView(productCategory: TProductCategory, shouldUseUserCharacter?: boolean | string) {
    let followMyCharacters: boolean = null;
    if (shouldUseUserCharacter !== undefined) {
      followMyCharacters =
        typeof shouldUseUserCharacter === 'string'
          ? JSON.parse(shouldUseUserCharacter.toLowerCase())
          : !!shouldUseUserCharacter;
    }

    const args: TMPProductCatalogView = { followMyCharacters, productCategory };

    this.utils.track(MP_EVENTS.PRODUCT_CATALOG_VIEW, args);
  }

  /**
   * Invoke this event when the user clicks on a CTA to show more products.
   *
   * @param isOpenedState - Optional flag indicating whether the expanded product
   * list is opened or closed (where available)
   */
  public seeAllProducts(isOpenedState?: boolean) {
    if (isOpenedState) return; // if already opened, don't track
    this.utils.track(MP_EVENTS.SEE_ALL_PRODUCTS);
  }

  // ###########################################################################
  // #                                                                         #
  // #                               Experiences                               #
  // #                                                                         #
  // ###########################################################################

  /**
   * Invoke this event when the user clicks on an experience card ("Contact Organizer" CTA).
   *
   * @param experience - Experience data. See {@link TExperience} for the data structure
   * @param currency - Currency in ISO 4217 format
   * @param isLike - Whether the user bookmarked the experience or not
   *
   * @beta
   */
  public viewProducerExperiencePage(experience: Partial<TExperience>, currency: string, isLike: boolean) {
    const {
      type: experienceType,
      productCategory: productType,
      price,
      identifier: experienceID,
      name: experienceName,
    } = experience;
    const { identifier: experienceCategoryID = null } = experienceType || {};
    const { name: productCategory = null } = productType || {};
    const experienceBookmarked = !!isLike;

    const experienceCategoryName =
      Object.keys(this.utils.pickBy(EXPERIENCES_TYPES, v => v === experienceCategoryID))[0] || null;

    const priceCurrencyInEnglish = new Intl.DisplayNames([languages.ENGLISH], { type: 'currency' });
    const experiencePrice = Number(price) || null;
    let priceCurrency = null;
    try {
      priceCurrency = priceCurrencyInEnglish.of(currency) || null;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error when assigning priceCurrency:', error);
    }

    const args: TMPViewProducerExperiencePage = {
      experienceBookmarked,
      experienceCategoryID,
      experienceCategoryName,
      experienceID,
      experienceName,
      experiencePrice,
      priceCurrency,
      productCategory,
    };

    this.utils.track(MP_EVENTS.VIEW_PRODUCER_EXPERIENCE_PAGE, args);
  }

  /**
   * Invoke this event when the user lands on the Experience Catalog not filtered
   * by category (e.g. when clicking on the footer).
   */
  public experienceGenericCatalogView() {
    this.utils.track(MP_EVENTS.EXPERIENCE_GENERIC_CATALOG_VIEW);
  }

  /**
   * Invoke this event when the user lands on the Experience Search Catalog, i.e.
   * the experience catalog already filtered by category (e.g. "Online classes", "Courses", etc).
   *
   * @param experienceCategoryID - Identifier of the experience type
   */
  public experienceCategoryCatalogView(experienceCategoryID: string = null) {
    const experienceCategoryName =
      Object.keys(this.utils.pickBy(EXPERIENCES_TYPES, v => v === experienceCategoryID))[0] || null;

    const args: TMPExperienceCategoryCatalogView = {
      experienceCategoryID,
      experienceCategoryName,
    };

    this.utils.track(MP_EVENTS.EXPERIENCE_CATEGORY_CATALOG_VIEW, args);
  }

  // ###########################################################################
  // #                                                                         #
  // #                                  Test                                   #
  // #                                                                         #
  // ###########################################################################

  private getTestData(testJson: any) {
    const { pk: userTestID = null }: { pk: number } = testJson || {};
    const { characters = [] } = testJson || {};
    const testCharacters = characters?.map(v => v.identifier) || [];

    const { quiz } = testJson || {};
    const {
      identifier: quizID = null,
      name: testName = null,
      quiz_type: quizTypeData = null,
      product_categories: categories = [],
    }: {
      identifier: string;
      name: string;
      quiz_type: { name: TQuizType; slug: TQuizType };
      product_categories: { name: string }[];
    } = quiz || {};
    const quizType = quizTypeData?.slug;
    const testProductCategories = categories?.map(v => v.name) || [];

    return { quizID, quizType, testCharacters, testName, testProductCategories, userTestID };
  }

  /**
   * Invoke this event when a user test starts (e.g. the first question shows up).
   *
   * @param testJson - JSON body of test data
   */
  @trackFacebookPixelEvent(MP_EVENTS.TEST_STARTED)
  public testStarted(testJson: TUserQuizDetail, foodPreferences?: string[] | null) {
    const { userTestID, quizID, testName, quizType, testProductCategories } = this.getTestData(testJson);
    const args: TMPTestStarted = { foodPreferences, quizID, quizType, testName, testProductCategories, userTestID };

    this.utils.track(MP_EVENTS.TEST_STARTED, args);
  }

  /**
   * Invoke this event when a user test successfully completes.
   *
   * @param testJson - JSON body of test data
   */
  @trackFacebookPixelEvent(MP_EVENTS.TEST_COMPLETED)
  public testCompleted(testJson: any) {
    const { userTestID, quizID, testName, quizType, testProductCategories, testCharacters } =
      this.getTestData(testJson);
    const args: TMPTestCompleted = { quizID, quizType, testCharacters, testName, testProductCategories, userTestID };

    this.utils.track(MP_EVENTS.TEST_COMPLETED, args);
  }

  /**
   * Invoke this event when the user clicks on a CTA that starts the taste test.
   */
  public startTasteTestClick() {
    this.utils.track(MP_EVENTS.START_TASTE_TEST_CLICK);
  }

  /**
   * Invoke this event when the user clicks on a CTA that starts a situational test.
   */
  public startSituationalTestClick() {
    this.utils.track(MP_EVENTS.START_SITUATIONAL_TEST_CLICK);
  }

  /**
   * Invoke this event when the user clicks on the "Start Food Pairing" CTA.
   */
  public startFoodPairingClick() {
    this.utils.track(MP_EVENTS.START_FOOD_PAIRING_CLICK);
  }

  /**
   * Invoke this event when the user lands on the result page.
   *
   * @param characters - Characters data of the result page as per {@link IParsedCharacter this interface}
   */
  public resultPageView(characters: IParsedCharacter[]) {
    const testCharacters = characters.map(c => c.characterId);
    const args: TMPResultPageView = { testCharacters };

    this.utils.track(MP_EVENTS.RESULT_PAGE_VIEW, args);
  }

  /**
   * Invoke this event when overlay for slow internet is shown.
   */
  public trackSlowConnection() {
    this.utils.track(MP_EVENTS.SLOW_CONNECTION_PAGE_VIEW);
  }

  // ###########################################################################
  // #                                                                         #
  // #                                  Other                                  #
  // #                                                                         #
  // ###########################################################################

  /**
   * Invoke this event when the user clicks on a banner.
   *
   * @param bannerID - Identifier of the banner (e.g. `NL0005`)
   * @param bannerText - Text of the banner
   * @param linkParams - Link query parameters or empty string
   * @param link - Describes where the banner leads as per {@link TBannerLink this interface}
   * @param currentPosition - Describes where the banner was clicked as per {@link TBannerPosition this interface}
   *
   * @beta
   */
  public bannerClick(
    bannerID: string = null,
    bannerText: string = null,
    link: TBannerLink,
    currentPosition: Partial<TBannerPosition>,
  ) {
    const { identifier: bannerDestinationID = null, route: bannerDestination = null } = link || {};
    const { identifier: bannerPositionID = null, route: bannerPosition = null } = currentPosition || {};

    const args: TMPBannerClick = {
      bannerDestination,
      bannerDestinationID,
      bannerID,
      bannerPosition,
      bannerPositionID,
      bannerText,
    };

    this.utils.track(MP_EVENTS.BANNER_CLICK, args);
  }

  /**
   * Invoke this event when the user clicks on a recipe card during recipe search.
   *
   * @param recipe - Data of the recipe as per {@link TRecipe this interface}
   */
  public recipeClick(recipe: TRecipe) {
    const { identifier: recipeID = null, name: recipeName = null, slug: recipeSlug = null } = recipe;
    const { characters } = recipe;
    const recipeCharactersID = characters.map(character => character.identifier) || [];

    const args: TMPRecipeClick = {
      recipeCharactersID,
      recipeID,
      recipeName,
      recipeSlug,
    };

    this.utils.track(MP_EVENTS.RECIPE_CLICK, args);
  }

  /**
   * Invoke this event when the user clicks on "Skip to <next-character-type>" CTA during taste path experience.
   *
   * @param characterTypeID - The ID of the character type that was skipped
   */
  public skipCharacterType(characterTypeID: string) {
    const args: TMPSkipCharacterType = { characterTypeID };
    this.utils.track(MP_EVENTS.SKIP_CHARACTER_TYPE, args);
  }

  /**
   * Invoke this event when the user clicks on "Discover the aroma and test" CTA during taste path experience.
   */
  public discoverQuizStart() {
    this.utils.track(MP_EVENTS.DISCOVER_QUIZ_START);
  }

  /**
   * Invoke this event when the user clicks on "Next, Please" CTA during taste path experience.
   *
   * @param questionType - The value of the quiz opened by the user
   * @param isCorrectAnswer - The boolean value of the answer (true or false)
   */
  public discoverQuizSubmit(questionType: string, correctAnswer: boolean) {
    const args: TMPDiscoverQuizSubmit = { correctAnswer, questionType };
    this.utils.track(MP_EVENTS.DISCOVER_QUIZ_SUBMIT, args);
  }

  /**
   * Invoke this event when the user skips the "Discover quiz" during taste path experience.
   */
  public discoverQuizSkip() {
    this.utils.track(MP_EVENTS.DISCOVER_QUIZ_SKIP);
  }

  /**
   * Invoke this event when a user clicks on Apply button in Product Catalog Filter:
   * in App, Widget, Mobile and physical kiosks
   *
   * @param productFilters - product filter state data.
   * See {@link IProductFilterState} for the data structure
   */
  public productCatalogFilter(productFilters: IProductFilterState) {
    const {
      toggle: { isCharacterToggleActive },
      range: { userLowerPriceRangeValue, userUpperPriceRangeValue },
      showOnly: { isWishlistToggleActive },
      sublist: {
        [FILTER_TYPE_STYLE]: styles = {},
        [FILTER_TYPE_CHARACTERISTICS]: characteristics = {},
        [FILTER_TYPE_ORIGIN]: origins = {},
      } = {},
    } = productFilters;

    const productOriginsID = Object.values(origins)
      .filter(({ isActive }) => isActive)
      .map(({ value }) => value);

    const productOriginsName = Object.values(origins)
      .filter(({ isActive }) => isActive)
      .map(({ name }) => name);

    const productCharacteristics = Object.values(characteristics)
      .filter(({ isActive }) => isActive)
      .map(({ value }) => value);

    const productStyles = Object.values(styles)
      .filter(({ isActive }) => isActive)
      .map(({ value }) => value);

    const args: TMPProductCatalogFilter = {
      followMyCharacters: isCharacterToggleActive,
      productCharacteristics,
      productMaximumPrice: userUpperPriceRangeValue,
      productMinimumPrice: userLowerPriceRangeValue,
      productOriginsID,
      productOriginsName,
      productStyles,
      showOnlySaved: isWishlistToggleActive,
    };

    this.utils.track(MP_EVENTS.PRODUCT_CATALOG_FILTER, args);
  }

  /**
   * Invoke this event when a user opens the "Promotion Coupon".
   *
   * @param fidelityCardID - The ID of the fidelity card
   * @param actionPerformed - Name of the action that executed this event
   */
  public specialPromoDisplayed(fidelityCardID: string, actionPerformed: MixpanelActionPerformedContext) {
    const args: TMPSpecialPromoDisplayed = {
      actionPerformed,
      fidelityCardID,
    };

    this.utils.track(MP_EVENTS.SPECIAL_PROMO_DISPLAYED.NAME, args);
  }

  /**
   * Invoke this event when the user clicks on a TasteMatch button on the product page.
   *
   * @param productInstanceData - Object containing {@link TProductInstance} data structure
   */
  public tasteMatchClick(parsedProductInstanceData: TParsedProductInstance) {
    const args: TMPTasteMatchClick = this.parseMPProductData({ parsedProductInstanceData });

    this.utils.track(MP_EVENTS.TASTE_MATCH_CLICK, args);
  }

  /**
   * Invoke this event when the user clicks on a TasteMatch button on the explore page.
   *
   * @param locationName - retailer location name
   * @param locationId - retailer location Id
   * @param locationSlug - retailer location slug
   * @param tasteMatchLevel - retailer location taste_match value
   */
  public locationTasteMatchClick({
    locationName,
    locationId,
    locationSlug,
    tasteMatchLevel,
  }: TMPLocationTasteMatchClick) {
    const args: TMPLocationTasteMatchClick = { locationId, locationName, locationSlug, tasteMatchLevel };

    this.utils.track(MP_EVENTS.LOCATION_TASTE_MATCH_CLICK, args);
  }

  /**
   * Invoke this event when the user lands on the explore page.
   * @param locationName - retailer location name
   * @param locationId - retailer location Id
   * @param locationSlug - retailer location slug
   * @param tasteMatchLevel - retailer location taste_match value
   */
  public exploreSectionView({ locationName, locationId, locationSlug, tasteMatchLevel }: TMPExploreSectionView) {
    const args: TMPExploreSectionView = { locationId, locationName, locationSlug, tasteMatchLevel };

    this.utils.track(MP_EVENTS.EXPLORE_SECTION_VIEW, args);
  }

  /**
   * Invoke this event when the user bookmarks location on the explore page
   *
   * @param locationName - retailer location name
   * @param locationSlug - retailer location slug
   * @param locationId- retailer location id
   * @param locationBookmarked - status of location (saved or unsaved)
   * @param locationPositionIndex - location position index
   * @param locationPosition - location position
   * @param listName - location list name TBC

   */
  public locationBookmark({
    locationName,
    locationId,
    locationSlug,
    locationBookmarked,
    locationPositionIndex,
    locationPosition,
    listName,
  }: TMPLocationBookmark) {
    // NOTE: `locationBookmarked` represents the value BEFORE the action is executed, so we invert it.
    const args: TMPLocationBookmark = {
      listName,
      locationBookmarked: !locationBookmarked,
      locationId,
      locationName,
      locationPosition,
      locationPositionIndex,
      locationSlug,
    };

    this.utils.track(MP_EVENTS.LOCATION_BOOKMARK, args);
  }

  /**
   * Invoke this event when the user clicks on footer link.
   * @param pageName - page name
   */
  public footerNavigationClick({ pageName }: TMPFooterNavigationClick) {
    const args: TMPFooterNavigationClick = { pageName };

    this.utils.track(MP_EVENTS.FOOTER_NAVIGATION_CLICK, args);
  }

  /**
   * Invoke this event when the user clicks on a PromotionsBadges on productCard or ProductDetails page.
   * @param promotionId - promotion identifier
   * @param promotionSlug - retailer promotion slug
   * @param promotionTypeSlug - retailer promotion type slug
   * @param promotionDescription - promotion description text
   */
  public promotionDescriptionClick({
    promotionId,
    promotionSlug,
    promotionTypeSlug,
    promotionDescription,
  }: TMPPromotionDescriptionClick) {
    const args: TMPPromotionDescriptionClick = { promotionDescription, promotionId, promotionSlug, promotionTypeSlug };

    this.utils.track(MP_EVENTS.PROMOTION_DESCRIPTION_CLICK, args);
  }

  /**
   * Invoke this event when the user clicks Result page buttons to get character info.
   * @param productCharacterID - character Id
   */
  public characterDescriptionClick({ productCharacterID }: TMPCharacterDescriptionClick) {
    const args: TMPCharacterDescriptionClick = { productCharacterID };

    this.utils.track(MP_EVENTS.CHARACTER_DESCRIPTION_CLICK, args);
  }

  /**
   * Invoke this event each time the user expresses their preferences about the third-party selection.
   * @param optionSelected - Yes, No or Later selected option
   */
  public thirdPartyChoiceClick({ optionSelected }: TMPThirdPartyChoiceClick) {
    const args: TMPThirdPartyChoiceClick = { optionSelected };

    this.utils.track(MP_EVENTS.THIRD_PARTY_CHOICE_CLICK, args);
  }

  /**
    Invoke this event when user clicks on Start button on kiosk Welcome page.
   */
  public startButtonClick() {
    this.utils.track(MP_EVENTS.START_BUTTON_CLICK);
  }

  /**
   * Invoke this event when the user clicks on the Share icon.
   *
   * @param parsedProductInstanceData - Product information conforming to {@link TParsedProductInstance} data structure
   * @param productPosition - Descriptive name for the component where the click occurs
   * @param profileId
   */
  public shareProductPageClick(
    parsedProductInstanceData: TParsedProductInstance,
    productPosition: MixpanelPositionContext,
    profileId: string,
  ) {
    const productData = this.parseMPProductData({
      parsedProductInstanceData,
      productPosition,
    });
    const args: TMPShareProductPageClick = { ...productData, profileId };

    this.utils.track(MP_EVENTS.SHARE_PRODUCT_PAGE_CLICK, args);
  }

  /**
   * Invoke this event when the user clicks on a product card before or after starting the interactive tasting.
   * @param parsedProductInstanceData - Product information conforming to {@link TParsedProductInstance} data structure
   * @param interactiveTastingStatus - Product Card status of Interactive Tasting
   */
  public interactiveTastingClick(
    parsedProductInstanceData: TParsedProductInstance,
    interactiveTastingStatus: MixpanelInteractiveTastingStatus,
  ) {
    const productData = this.parseMPProductData({ parsedProductInstanceData });
    const args: TMPInteractiveTastingClick = { ...productData, interactiveTastingStatus };

    this.utils.track(MP_EVENTS.INTERACTIVE_TASTING_CLICK, args);
  }

  /**
   * Invoke this event when the user leaves a comment about product or retailer.
   * @param parsedProductInstanceData - Product information conforming to {@link TParsedProductInstance} data structure
   * @param commentVisibility - Status of comment visibility
   * @param commentStatus - New or edited comment
   */
  public productComment(
    parsedProductInstanceData: TParsedProductInstance,
    commentVisibility: MixpanelCommentVisibility,
    commentStatus: MixpanelCommentStatus,
  ) {
    const productData = this.parseMPProductData({ parsedProductInstanceData });
    const args: TMPProductComment = { ...productData, commentStatus, commentVisibility };

    this.utils.track(MP_EVENTS.PRODUCT_COMMENT, args);
  }

  /**
   * Invoke this event when the user completes interactive tasting.
   * @param parsedProductInstanceData - Product information conforming to {@link TParsedProductInstance} data structure
   */
  public interactiveTastingCompleted(parsedProductInstanceData: TParsedProductInstance) {
    const args: TMPInteractiveTastingCompleted = this.parseMPProductData({
      parsedProductInstanceData,
    });

    this.utils.track(MP_EVENTS.INTERACTIVE_TASTING_COMPLETED, args);
  }

  /**
   * Invoke this event when the user clicks on a button to get into the interactive tasting.
   * @param parsedProductInstanceData - Product information conforming to {@link TParsedProductInstance} data structure
   */
  public interactiveTastingStart(parsedProductInstanceData: TParsedProductInstance) {
    const args: TMPInteractiveTastingStart = this.parseMPProductData({
      parsedProductInstanceData,
    });

    this.utils.track(MP_EVENTS.INTERACTIVE_TASTING_START, args);
  }
}
