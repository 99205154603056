import { styled } from 'styled-components';

import { Text } from '@components/web/src/foundations/Text/Text';

export const CharacterInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 100%;
  padding: 0 16px;
  margin-top: 32px;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 13px;
  width: 100%;
`;

export const Title = styled(Text)`
  display: flex;
  align-self: start;
`;
