import { useCharacters } from '@lib/core/characters/hooks';
import { useApp } from '@lib/core/service/hooks';

import CharactersMapScroller from '@components/web/src/organisms/CharactersMapScroller/CharactersMapScroller';

const CharacterMapScrollerContainer = ({ productCategory, characterName }) => {
  const { charactersByCharacterType } = useCharacters() || {};
  const { locale } = useApp();

  const charactersByProductCategory = charactersByCharacterType[productCategory] || {};
  const charactersCount = Object.values(charactersByProductCategory).flat().length;
  const groupCount = Object.keys(charactersByProductCategory).length;

  return (
    <CharactersMapScroller
      charactersByCharacterTypeForCurrentProductCategory={charactersByProductCategory}
      charactersCount={charactersCount}
      groupCount={groupCount}
      locale={locale}
      productCategory={productCategory}
      userCharacterName={characterName}
    />
  );
};

export default CharacterMapScrollerContainer;
