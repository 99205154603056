import { FC, useMemo } from 'react';

import { getMultipleUniqueRandomItemsFromArray } from '@lib/core/service/utils';
import { localeWidget } from '@lib/tools/locale/source/web/widget';
import { ILocaleText } from '@lib/tools/locale/views/LocaleFragment';
import { VH_VARIANTS } from '@lib/tools/shared/helpers/consts';
import { ICustomStyles } from '@lib/tools/shared/helpers/interfaces';

import BackButton from '@components/web/src/atoms/Buttons/BackButton/BackButton';
import Button from '@components/web/src/atoms/Buttons/Button';
import {
  CheckboxAnswer,
  ICheckboxAnswer,
} from '@components/web/src/atoms/Checkboxes&Radio/CheckboxAnswer/CheckboxAnswer';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';
import { KioskCirclesBackground } from '@components/web/src/kiosk/KioskCirclesBackground/KioskCirclesBackground';
import { TastingTip } from '@components/web/src/organisms/Cards/TastingTip/TastingTip';
import * as S from '@components/web/src/templates/TestFlowsPages/GenderPage/styles';

interface IProps {
  genderOptions: ICheckboxAnswer[];
  isDisabledBtn: boolean;
  isApplicationKiosk: boolean;
  customStyles: ICustomStyles;
  handleGenderOptionClick: (id: string) => void;
  handleContinueClick: () => void;
  handleSkipClick: () => void;
  handleGoBack: () => void;
}

export const GenderPage: FC<IProps> = ({
  genderOptions,
  isDisabledBtn,
  isApplicationKiosk,
  customStyles,
  handleGenderOptionClick,
  handleContinueClick,
  handleSkipClick,
  handleGoBack,
}) => {
  const {
    genderTitle,
    genderSubtitle,
    skipBtn,
    continueBtn,
    randomGenderTastingTip1,
    randomGenderTastingTip2,
    randomGenderTastingTip3,
    randomGenderTastingTip4,
    randomGenderTastingTip5,
    randomGenderTastingTip6,
  } = localeWidget.ageAndGenderPages;

  const tastingTipText: ILocaleText = useMemo(
    () =>
      getMultipleUniqueRandomItemsFromArray(
        [
          randomGenderTastingTip1,
          randomGenderTastingTip2,
          randomGenderTastingTip3,
          randomGenderTastingTip4,
          randomGenderTastingTip5,
          randomGenderTastingTip6,
        ],
        1,
      )[0],
    [],
  );

  const { customQuizBackgroundColor, customTextColor, customSecondaryColor } = customStyles;

  return (
    <S.GenderPageContainer isFullWidth $background={customQuizBackgroundColor} direction="column" gap={0}>
      {isApplicationKiosk && <KioskCirclesBackground />}
      <S.ContentWrapper direction="column">
        <Flexbox align="center" direction="column" gap={8}>
          <Text
            color={STATIC_COLORS.green['600']}
            size="body1"
            text={genderSubtitle}
            textAlign="center"
            weight="medium"
          />
          <Text
            color={customTextColor || STATIC_COLORS.base.black}
            fontFamily="Fraunces"
            size="h5"
            text={genderTitle}
            weight="semibold"
          />
        </Flexbox>
        <S.GenderOptionsWrapper>
          {genderOptions?.map((data, idx) => (
            <CheckboxAnswer key={idx} isResponsive data={data} handleClick={handleGenderOptionClick} />
          ))}
        </S.GenderOptionsWrapper>
        <S.CtaWrapper align="center" direction="column">
          <Button
            disabled={isDisabledBtn}
            fontSize={isApplicationKiosk ? 'body1' : 'subtitle2'}
            handleClick={handleContinueClick}
            size="sm"
            text={continueBtn}
          />
          <Button fontSize="body1" handleClick={handleSkipClick} size="sm" text={skipBtn} variant={VH_VARIANTS.LINK} />
        </S.CtaWrapper>
        <TastingTip text={tastingTipText} />
        <BackButton
          customArrowColor={customSecondaryColor}
          handleClick={handleGoBack}
          style={{ color: customTextColor }}
          variant="long"
        />
      </S.ContentWrapper>
    </S.GenderPageContainer>
  );
};
