import { css, keyframes, styled } from 'styled-components';

import Btn from '@components/web/src/atoms/Buttons/Button';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';

const ANIMATION_DELAY = '2000ms';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const DiscoveryQuizModalContainer = styled(Flexbox)`
  flex: 1;
`;

export const SkipButton = styled(Flexbox)`
  width: fit-content;
  margin-bottom: 8px;
  align-self: flex-end;
  background-color: var(--color-primary-600);
  border: none;
  border-radius: 16px;
  cursor: pointer;
`;

export const HeaderWrapper = styled(Flexbox)`
  background-color: #fc9078;
  border-radius: 16px 16px 0 0;
`;

export const ContentWrapper = styled(Flexbox)`
  position: relative;
  flex: 1;
  background-color: var(--color-secondary-100);
  border-radius: 0 0 16px 16px;
`;

export const InitiateContentWrapper = styled(Flexbox)`
  img {
    height: 90%;
  }
`;

export const ResultText = styled(Text)<{ $isVisible: boolean }>`
  position: absolute;
  bottom: -50px;
  left: 0;
  width: 100%;
  opacity: 0;

  @keyframes slideIn {
    from {
      bottom: -50px;
      opacity: 0;
    }

    to {
      bottom: 45px;
      opacity: 1;
    }
  }

  @keyframes slideOut {
    to {
      opacity: 0;
    }
  }

  ${({ $isVisible }) =>
    $isVisible &&
    css`
      animation:
        slideIn 300ms cubic-bezier(0.68, -0.55, 0.27, 1.55) 600ms forwards,
        slideOut 300ms cubic-bezier(0.08, 0.82, 0.17, 1) ${ANIMATION_DELAY} both;
    `}
`;

export const AnimatedFlexbox = styled(Flexbox)<{ $isAnimated: boolean }>`
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }

    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  ${({ $isAnimated }) =>
    $isAnimated &&
    css`
      animation: fadeInUp 200ms cubic-bezier(0.08, 0.82, 0.17, 1);
    `}
`;

export const Button = styled(Btn)<{ $isVisible: boolean }>`
  z-index: 1;
  margin-top: auto;

  ${({ $isVisible }) =>
    $isVisible &&
    css`
      opacity: 0;
      animation: ${fadeIn} 300ms cubic-bezier(0.08, 0.82, 0.17, 1) ${ANIMATION_DELAY} both;
    `}
`;

export const LoadingBar = styled.div`
  width: 100%;
  height: 10px;
  padding: 2px;
  margin-top: 6px;
  background: ${STATIC_COLORS.base.white};
  border: 1px solid var(--color-primary-600);
  border-radius: 50px;
  opacity: 0;
  animation: ${fadeIn} 0ms linear ${ANIMATION_DELAY} both;
  position: relative;
  overflow: hidden;

  @keyframes fillBar {
    to {
      width: calc(100% - 4px);
    }
  }

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 4px;
    width: 0;
    background-color: var(--color-primary-600);
    border-radius: 40px;
    animation: fillBar 4000ms linear ${ANIMATION_DELAY} forwards;
  }
`;
