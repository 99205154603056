import { FC } from 'react';

import { isAppInIframe } from '@lib/core/service/utils';
import { localeCommon } from '@lib/tools/locale/source/web/common';

import VinhoodLogoWhite from '@components/web/src/assets/png/vinhood_logo_white.png';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Image } from '@components/web/src/foundations/Image/Image';
import { Text } from '@components/web/src/foundations/Text/Text';
import {
  LOADING_SPINNER_VARIANTS,
  LoadingSpinner,
} from '@components/web/src/templates/Loading/LoadingSpinner/LoadingSpinner';
import * as S from '@components/web/src/templates/SplashScreen/PartnerSplashScreen/styles';

interface IProps {
  retailerLogo: string;
  isLoading: boolean;
  isDevToolsEnabled: boolean;
}

const PartnerSplashScreen: FC<IProps> = ({ retailerLogo, isLoading, isDevToolsEnabled }) => {
  const { title, vinhoodSubtitle, vinhoodTitle } = localeCommon.splashScreen;

  return (
    <S.PartnerSplashScreenContainer
      $isAppInIframe={isAppInIframe}
      $isDevToolsEnabled={isDevToolsEnabled}
      $retailerLogo={retailerLogo}
      align="center"
      direction="column"
      justify="center"
    >
      {isDevToolsEnabled ? (
        <div className="loading_spinner">
          <LoadingSpinner variant={LOADING_SPINNER_VARIANTS.TRANSPARENT} />
        </div>
      ) : (
        <>
          {retailerLogo && !isAppInIframe && (
            <Flexbox align="center" className="retailer_logo" direction="column" gap={40} justify="center">
              <Text fontFamily="Fraunces" size="h6" text={title} weight="semibold" />
              <Image alt="retailer" height="167px" objectFit="contain" src={retailerLogo} width="244px" />
            </Flexbox>
          )}
          <Flexbox align="center" className="vinhood_logo" direction="column" gap={16} justify="center">
            <Text color={STATIC_COLORS.base.white} size="body1" text={vinhoodTitle} />
            <img alt="splash" src={VinhoodLogoWhite} />
            <Text color={STATIC_COLORS.base.white} size="body1" text={vinhoodSubtitle} weight="bold" />
          </Flexbox>
          {isLoading && (
            <div className="loading_spinner">
              <LoadingSpinner variant={LOADING_SPINNER_VARIANTS.TRANSPARENT} />
            </div>
          )}
        </>
      )}
    </S.PartnerSplashScreenContainer>
  );
};

export default PartnerSplashScreen;
