import { FC, useState } from 'react';

import { useProductFeedback } from '@lib/core/products/hooks/useProductFeedback';
import { ProductFeedbackSteps } from '@lib/core/products/slices/productFeedback';
import { TParsedProductInstance, TProductFeedbackStatus } from '@lib/core/products/types';
import { promotionsFilter } from '@lib/core/service/utils';
import MixpanelTracker from '@lib/tools/dat/mixpanel';
import {
  MP_INTERACTIVE_TASTING_STATUS,
  MP_POSITION_CONTEXT,
  MixpanelInteractiveTastingStatus,
  MixpanelPositionContext,
} from '@lib/tools/dat/mixpanel/consts';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import {
  PROMOTION_BADGE_FIDELITY,
  PROMOTION_BADGE_OTHER,
  PROMOTION_BADGE_PROMOTION,
  VH_VARIANTS,
} from '@lib/tools/shared/helpers/consts';
import { parseTasteMatchLevel } from '@lib/tools/tasteMatch';
import { useAddons } from '@lib/tools/views/hooks';

import Button from '@components/web/src/atoms/Buttons/Button';
import PriceRange from '@components/web/src/atoms/PriceRange/PriceRange';
import { TestedBanner } from '@components/web/src/atoms/ProductCardElements/TestedBanner/TestedBanner';
import { RatingButton } from '@components/web/src/atoms/ProductRating/ProductRating/RatingButton';
import { Share } from '@components/web/src/atoms/Share/Share';
import TasteMatchButton from '@components/web/src/atoms/TasteMatchButton/TasteMatchButton';
import TooltipComponent from '@components/web/src/atoms/Tooltips/Tooltip';
import Wishlist from '@components/web/src/atoms/Wishlist/Wishlist';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Image } from '@components/web/src/foundations/Image/Image';
import { Text } from '@components/web/src/foundations/Text/Text';
import { IRecipe } from '@components/web/src/organisms/Cards/ChefRecommendsCard/ChefRecommendsCard';
import ReadMoreCard from '@components/web/src/organisms/Cards/ReadMoreCard/ReadMoreCard';
import { CommentForProductPage } from '@components/web/src/organisms/Comment/CommentForProductPage';
import * as S from '@components/web/src/organisms/ProductDetails/Web/styles';
import { PromotionBadges } from '@components/web/src/organisms/PromotionBadges/PromotionBadges';
import FindProductPopup from '@components/web/src/shared/FindProductPopup/FindProductPopup';
import { IDiscoveryQuiz } from '@components/web/src/templates/Modals/DiscoveryQuizModal/DiscoveryQuizModal';

interface IProps {
  parsedProductInstanceData: TParsedProductInstance;
  isShowPromoLabel?: boolean;
  shouldDisplayContactProducerBtn?: boolean;
  shouldHideWishlist?: boolean;
  shouldHideComment?: boolean;
  shouldHideRatingButton?: boolean;
  discoveryQuiz?: IDiscoveryQuiz;
  isUserHasAnyCharacter: boolean;
  isProductPDFDownload?: boolean;
  mixpanelPositionContext?: MixpanelPositionContext;
  isRecipesDataLoading?: boolean;
  recipesData?: IRecipe[];
  isFromSharedLink?: boolean;
  productFeedbackStatus: TProductFeedbackStatus;
  navigateToQuizSelectionPage: () => void;
  handleTestedBannerClick?: () => void;
}

export const ProductDetails: FC<IProps> = ({
  parsedProductInstanceData,
  isShowPromoLabel = false,
  shouldDisplayContactProducerBtn = false,
  shouldHideComment = false,
  shouldHideRatingButton = false,
  shouldHideWishlist = false,
  isUserHasAnyCharacter,
  mixpanelPositionContext = MP_POSITION_CONTEXT.PRODUCT_PAGE,
  discoveryQuiz,
  isFromSharedLink,
  productFeedbackStatus,
  navigateToQuizSelectionPage,
}) => {
  const {
    productDetails: { startTastingBtn, tasteMatchTooltip, descriptionTitle, findItOnShelf, contactProducerBtn },
    commonMessages,
  } = localeCommon;

  const {
    productId,
    productCategory,
    productCharacterName,
    productCharacterImage,
    productCharacterTechnicalCaption,
    productName,
    productImage,
    productStyle,
    productDescription,
    productTags,
    productFormat,
    productFormatSize,
    productFormatName,
    productPriceCurrencySymbol,
    productProducerName,
    productProducerUrl,
    productOriginalPrice,
    productDiscountPrice,
    productOriginalPriceAndCurrency,
    productDiscountPriceAndCurrency,
    productRegionName,
    promotions,
    productRegionCountry,
    productTasteMatchValue,
    isFindProductButtonEnable,
    productLocationDescription,
    productLocationMap,
    productEcommerceId,
    isCommentInList,
    isWishlistProductListLoading,
    isProductInstanceInWishlist,
    isEnableLocationMapAddon,
    isEnableVusionAddon,
    handleUpdateWishlistProductList,
    productInstanceData,
  } = parsedProductInstanceData || {};
  const isProductDiscountAvailable = !!productDiscountPrice && !!productOriginalPrice;

  const { handleToggleOpenedFeedbackModal } = useProductFeedback();

  const { isPriceRangeAddon } = useAddons();
  const [isTooltipVisible, setTooltipVisible] = useState(true);

  const isProductRegionAvailable = !!productRegionCountry && !!productRegionName;

  const promotionBadges = promotionsFilter(promotions, [
    PROMOTION_BADGE_FIDELITY,
    PROMOTION_BADGE_PROMOTION,
    PROMOTION_BADGE_OTHER,
  ]);

  const handleProductFeedbackBannerClick = (status: MixpanelInteractiveTastingStatus) => {
    MixpanelTracker.events.interactiveTastingClick(parsedProductInstanceData, status);
    handleToggleOpenedFeedbackModal({
      productInstanceData,
      ...(status === MP_INTERACTIVE_TASTING_STATUS.COMPLETED && {
        productFeedbackModalStep: ProductFeedbackSteps.ProductFeedbackResult,
      }),
    });
  };

  return (
    <S.ProductDetailsContainer>
      <S.ProductImageContainer>
        {isShowPromoLabel && (
          <S.TopPromoIcon>
            <S.PromoText size="body3" text={commonMessages.promo} weight="semibold" />
          </S.TopPromoIcon>
        )}
        <Flexbox isFullWidth align="center" justify="center">
          <Image alt="product" height="280px" objectFit="contain" src={productImage} width="280px" />
        </Flexbox>
        <S.TagsWrapper>
          {productTags?.map(tag => <Image key={tag.tagName} alt={tag.tagName} src={tag.icon} />)}
        </S.TagsWrapper>
        <S.ImageCtaWrapper align="center" direction="column" gap={4}>
          {!shouldHideWishlist && !isFromSharedLink && (
            <Wishlist
              isItemInWishlist={isProductInstanceInWishlist}
              isItemListLoading={isWishlistProductListLoading}
              handleUpdateWishlist={() => {
                handleUpdateWishlistProductList(productId, productName);
                MixpanelTracker.events.productBookmark(parsedProductInstanceData, null, mixpanelPositionContext);
              }}
            />
          )}
          {!shouldHideRatingButton && !isFromSharedLink && (
            <RatingButton discoveryQuiz={discoveryQuiz} parsedProductInstanceData={parsedProductInstanceData} />
          )}
          <Share
            isFromSharedLink={isFromSharedLink}
            mixpanelPositionContext={mixpanelPositionContext}
            parsedProductInstanceData={parsedProductInstanceData}
          />
        </S.ImageCtaWrapper>
      </S.ProductImageContainer>
      <Flexbox isFullWidth direction="column" gap={6} padding="16px 16px 32px">
        {productProducerName && (
          <Text color={STATIC_COLORS.base.black} size="body1" text={productProducerName} weight="semibold" />
        )}
        <Text
          color={STATIC_COLORS.base.black}
          fontFamily="Fraunces"
          linesLimit={2}
          size="h4"
          text={productName}
          weight="semibold"
        />
        {(productStyle || isProductRegionAvailable) && (
          <Text
            color={STATIC_COLORS.base.black}
            size="body1"
            text={productStyle || (isProductRegionAvailable && `${productRegionName}, ${productRegionCountry}`) || ''}
          />
        )}
        <Text
          color={STATIC_COLORS.green[600]}
          linesLimit={1}
          size="body2"
          text={`#${productCharacterName} • ${productCharacterTechnicalCaption}`}
          weight="medium"
        />
        {productFeedbackStatus.isFinished && (
          <S.FinishedProductFeedbackBanner
            onClick={() => handleProductFeedbackBannerClick(MP_INTERACTIVE_TASTING_STATUS.COMPLETED)}
          >
            <TestedBanner characterImage={productCharacterImage} productCategory={productCategory} />
          </S.FinishedProductFeedbackBanner>
        )}
        {productFeedbackStatus.isEnabled && (
          <S.EnabledProductFeedbackBanner>
            <Button
              isFullWidth
              fontSize="subtitle2"
              handleClick={() => handleProductFeedbackBannerClick(MP_INTERACTIVE_TASTING_STATUS.PENDING)}
              size="md"
              text={startTastingBtn}
              textWeight="medium"
              variant={VH_VARIANTS.LIGHT}
            />
          </S.EnabledProductFeedbackBanner>
        )}
        {!isFromSharedLink && (
          <TooltipComponent
            isAlwaysOpen={isTooltipVisible}
            placement="bottom-end"
            title={tasteMatchTooltip}
            trigger={
              <TasteMatchButton
                hideTooltip={() => setTooltipVisible(false)}
                navigateToQuizSelectionPage={navigateToQuizSelectionPage}
                parsedProductInstanceData={parsedProductInstanceData}
                tasteMatchLevel={parseTasteMatchLevel(productTasteMatchValue, isUserHasAnyCharacter)}
                tasteMatchValue={productTasteMatchValue}
              />
            }
          />
        )}
        {productDescription && (
          <S.DescriptionWrapper>
            <ReadMoreCard
              isTransparentVariant
              cardTitle={descriptionTitle}
              cardTitleColor={STATIC_COLORS.green['600']}
              description={productDescription}
              minimumCharacters={190}
              handleClick={() =>
                MixpanelTracker.events.productStoryClick(parsedProductInstanceData, mixpanelPositionContext)
              }
            />
          </S.DescriptionWrapper>
        )}
        {!shouldHideComment && (!isFromSharedLink || (isFromSharedLink && isCommentInList)) && (
          <CommentForProductPage
            isFromSharedLink={isFromSharedLink}
            parsedProductInstanceData={parsedProductInstanceData}
          />
        )}
        <S.PriceAndFormatWrapper>
          {productFormat && (
            <Text
              color={STATIC_COLORS.base.black}
              size="body2"
              text={`${productFormatName}${productFormatSize && `, ${productFormatSize}`}`}
            />
          )}
          {isPriceRangeAddon ? (
            <PriceRange
              currencySymbolValue={productPriceCurrencySymbol}
              originalPrice={productOriginalPrice}
              productCategory={productCategory}
            />
          ) : (
            <Flexbox align="center" gap={8}>
              {isProductDiscountAvailable && (
                <Text
                  color={STATIC_COLORS.gray[400]}
                  size="subtitle1"
                  text={productOriginalPriceAndCurrency}
                  textDecoration="line-through"
                />
              )}
              {(isProductDiscountAvailable || !!productOriginalPrice) && (
                <Text
                  color={STATIC_COLORS.base.black}
                  size="subtitle1"
                  text={isProductDiscountAvailable ? productDiscountPriceAndCurrency : productOriginalPriceAndCurrency}
                  weight="semibold"
                />
              )}
            </Flexbox>
          )}
        </S.PriceAndFormatWrapper>
        {!!promotionBadges?.length && <PromotionBadges promotionsData={promotionBadges} />}
        <S.CtaWrapper>
          {isFindProductButtonEnable && !shouldDisplayContactProducerBtn && !isFromSharedLink && (
            <FindProductPopup
              isEnableLocationMapAddon={isEnableLocationMapAddon}
              isEnableVusionAddon={isEnableVusionAddon}
              productEcommerceId={productEcommerceId}
              productLocationDescription={productLocationDescription}
              productLocationMap={productLocationMap}
            >
              <Button
                size="lg"
                text={findItOnShelf}
                type="button"
                variant={VH_VARIANTS.PRIMARY}
                onClick={() => MixpanelTracker.events.findMe(parsedProductInstanceData, null, mixpanelPositionContext)}
              />
            </FindProductPopup>
          )}
          {productProducerUrl && !isFromSharedLink && (
            <a {...(productProducerUrl ? { href: productProducerUrl, target: '_blank' } : {})}>
              <Button
                disabled={!productProducerUrl}
                handleClick={() => MixpanelTracker.events.contactTheProductProducer(parsedProductInstanceData)}
                size="lg"
                text={contactProducerBtn}
                variant={VH_VARIANTS.PRIMARY}
              />
            </a>
          )}
        </S.CtaWrapper>
      </Flexbox>
    </S.ProductDetailsContainer>
  );
};
