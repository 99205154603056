import { styled } from 'styled-components';

import { STATIC_COLORS, STATIC_GRADIENTS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';

export const LoadingSpinnerContainer = styled.div<{ $isTransparent: boolean }>`
  position: absolute;
  inset: 0;
  z-index: 99999999;
  display: grid;
  grid-template-rows: 1fr 2fr;
  background: ${({ $isTransparent }) => ($isTransparent ? 'transparent' : STATIC_GRADIENTS.primaryGradient)};

  img {
    width: 150px;
    height: 150px;
    object-fit: cover;
  }
`;

export const TextContainer = styled(Flexbox)`
  max-width: 311px;
  color: ${STATIC_COLORS.base.whiteGray};
`;
